import gql from 'graphql-tag'

export const UserRoleFragment = gql`
	fragment UserRoleFragment on CompanyRole {
		id
		users {
			id
			profile {
				id
				firstName
				lastName
				avatarPath
			}
		}
		role
	}
`
