import { ApolloLink } from 'apollo-link'
import { ApolloClient, InMemoryCache } from '@apollo/client'
// import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'

import {
	cleanHeaderName,
	TOKEN_HEADER,
	REFRESH_TOKEN_HEADER,
	BACKEND_URIS,
} from 'utils'

const httpLink = createHttpLink({
	// @ts-ignore
	uri: BACKEND_URIS[process.env.REACT_APP_ENVIRONMENT],
})

const authLink = setContext((_: any, { headers }: any) => {
	const token = localStorage.getItem(cleanHeaderName(TOKEN_HEADER))
	const refreshToken = localStorage.getItem(
		cleanHeaderName(REFRESH_TOKEN_HEADER),
	)

	return {
		headers: {
			...headers,
			authorization:
				token && refreshToken ? `Bearer ${token}|${refreshToken}` : '',
		},
	}
})

const afterwareLink = new ApolloLink((operation: any, forward: any) =>
	forward(operation).map((response: any) => {
		const context = operation.getContext()
		const {
			response: { headers },
		} = context

		const token = headers.get(cleanHeaderName(TOKEN_HEADER))
		const refreshToken = headers.get(cleanHeaderName(REFRESH_TOKEN_HEADER))
		if (token) {
			localStorage.setItem(cleanHeaderName(TOKEN_HEADER), token)
		} else if (token === '') {
			localStorage.removeItem(cleanHeaderName(TOKEN_HEADER))
		}

		if (refreshToken) {
			localStorage.setItem(
				cleanHeaderName(REFRESH_TOKEN_HEADER),
				refreshToken,
			)
		} else if (refreshToken === '') {
			localStorage.removeItem(cleanHeaderName(REFRESH_TOKEN_HEADER))
		}

		return response
	}),
)

const links = [authLink, afterwareLink, httpLink]
const link = ApolloLink.from(links)

const client: any = new ApolloClient({
	link: link as any,
	// @ts-ignore
	cache: new InMemoryCache({
		// @ts-ignore
		dataIdFromObject: object => {
			switch (object.__typename) {
				case 'ActiveArticleTag':
					if (object.hasOwnProperty('uniqueIdentifier')) {
						// @ts-ignore
						return object.uniqueIdentifier
					}
					return object.id // use `key` as the primary key
				default:
					return object.id // fall back to `id` and `_id` for all other types
			}
		},
	}),
	connectToDevTools: true,
})

export default client
