import gql from 'graphql-tag'

// Mutations

export const NotifyArticleAuthorMutation = gql`
	mutation notifyArticleAuthor(
		$articleId: String!
		$language: LanguageCodeType!
	) {
		notifyArticleAuthor(articleId: $articleId, language: $language) {
			error
			status
		}
	}
`
