export default {
	translation: {
		//Termeni si conditii / Privacy
		PrivacyDocument:
			'https://docs.google.com/document/d/e/2PACX-1vRes_TwsJKQaOTPI27qGf3HOYJ39fG_db5yxcY97dK-UwWnO6GBcD0njaYpexzrje5BiQMkV6BUP3K2/pub?embedded=true',
		TermsConditions:
			'https://docs.google.com/document/d/e/2PACX-1vT_RWootIivvFn7lCZpVsGmwPkxka-4UbUJXTFAsvgIXx2qHUkxQRruT45wL1dQZOk9BHeXrrM80PhQ/pub?embedded=true',
		// 'People News': 'People News',
		People: 'Persoane',
		// 'Explore Teams': 'Descopera Echipe',
		Teams: 'Echipe',
		'Teams-1': 'echipele noastre',
		Results: 'Rezultate',
		Companies: 'Angajatori',
		Articles: 'Articole',
		'Log In': 'Autentificare',
		'Sign up': 'Cont nou',
		'About CV30': 'Despre CV30',
		// Today: 'Astazi',
		// 'People say': 'Oamenii spun',
		'Create post': 'Adauga postare',
		// 'People all around the world, from different teams and domains, share their knowhow, work experience and team culture':
		// 	'Oamenii din intreaga lume, din diferite echipe si domenii, impartasesc cunostintele lor, experienta de lucru si cultura echipei',
		'Write a post': 'Scrie o postare',
		Post: 'Postare',
		'Write a Long Article': 'Scrie un Articol',
		Article: 'Articol',
		'Write your post content here': 'Scrie continutul postarii tale aici',
		'Write yout article content here':
			'Scrie continutul articolului tau aici',
		'Write your article content here':
			'Scrie continutul articolului tau aici',
		'Article title': 'Titlul articolului',
		'Publish article': 'Publica articolul',
		'Describe your article ...': 'Adauga o descriere articolului tau ...',
		'Describe your post in a sentence, to give a relevant sneak peack':
			'Descrie postarea intr-o propozite, pentru un preview relevant',
		'Add featured image': 'Adauga o imagine relevanta',
		'Tag your article': 'Adauga etichete articolului tau',
		'Add featured video': 'Adauga un film relevant',
		'Video url': 'Adresa URL a filmului',
		'Paste in a Video / Youtube link':
			'Adauga link-ul unui Film / sau link-ul de Youtube',
		'start typing': 'tasteaza',
		'Search for your desired tags': 'Cauta etichetele dorite',
		'Available Tags': 'Etichete disponibile',
		'Selected Tags': 'Etichete selectate',
		Done: 'Rezolvat',
		'Log out': 'Delogare',
		'LOG IN': 'Autentificare',
		'Email address': 'Adresa de email',
		'Forgot your password?': 'Ai uitat parola?',
		'You don’t have an account?': 'Nu ai cont pe CV30?',
		'Sign up now!': 'Inregistreaza-te acum!',
		'You already have an account?': 'Ai deja cont?',
		'Log in now!': 'Autentifica-te acum',
		'Back to': 'Inapoi la',
		'What’s new?': 'Noutati',
		'People, Teams, Jobs, Articles ...':
			'Persoane, Echipe, Joburi, Articole...',
		'Your successful career': 'Cariera ta de succes',
		'is based on the perfect team!': 'se construieste in echipa perfecta!',
		'To find the best team and work environment for you,':
			'Descopera angajatorii inainte de a aplica,',
		'discover employers of interest before you apply to jobs.':
			'pentru a gasi echipa potrivita si mediul de lucru ideal.',
		'What’s': 'Noutati de la',
		'What’s-1': null,
		'new?': 'profesionisti',
		'new?-1': 'Noutati',
		'Employers and professionals from various domains share their experience, talk about team culture and showcase their work environment, so that you make the best decisions in your career.':
			'Angajatori si profesionisti din diverse domenii impartasesc din experienta lor, povestesc despre cultura echipei si prezinta mediul de lucru, astfel incat tu sa iei cele mai bune decizii in cariera.',
		Employers: 'Angajatori',
		'See Employers': 'Angajatori',
		'Explore Employers': 'Vezi Angajatori',
		Settings: 'Setari',
		'My Jobs': 'Job-uri',
		'My profile': 'Profil',
		'Add company': 'Adauga companie',
		'Company settings': 'Setarile companiei',
		'Name of the Company': 'Numele companiei',
		'Name of the Company is required': 'Numele companiei este obligatoriu',
		Manage: 'Gestioneaza',
		'Publish post': 'Publica postarea',
		'Account was created successfully!': 'Contul a fost creat cu success!',
		'Please check your email and activate your account':
			'Verificati contul de email pentru a va activa profilul',
		'Account activated successfully!': 'Contul a fost activat cu success!',
		'Yey ... you can now login': 'Super ... te poti loga acum',
		John: 'Andrei',
		Smith: 'Popescu',
		Appreciate: 'Apreciaza',
		// 'Your career depends': 'Cariera ta depinde',
		// 'on the people you work with!': 'de oamenii cu care lucrezi!',
		// 'Discover your dream team,': 'Descoperiti echipa voastra de vis,',
		// 'choose your next career step.': 'alegeti pasul urmator in cariera.',
		'Post as': 'Posteaza ca',
		'Appreciate this post by tapping on one (or more) tags below':
			'Apreciaza acest post apasand pe unul (sau mai multe) din tag-urile afisate',
		'Endorse FirstName for Label':
			'Apreciaza {{firstName}} pentru {{label}}',
		'The Company': 'Compania',
		Stories: 'Povesti',
		'Company posts': 'Noutati',
		'My posts': 'Povestile mele ',
		'Meet our': 'Descopera',
		'Meet our-1': 'Descopera',
		About: 'Despre',
		'About-1': 'Descrierea echipei de',
		'Products and services': 'Produsele si serviciile',
		company: 'companiei',
		Life: 'Mediul',
		'inside the team': 'de lucru',
		'Q&A': 'Q&A',
		'Founded in': 'Fondat in',
		'Ofﬁces in': 'Birouri in',
		'choose up to 5 tags (other users will appreciate them)':
			'alege maxim 5 taguri pe baza carora vei fi apreciat',
		'People will endorse the post based on tags (add up to 5)':
			'Pentru a primi aprecieri adauga etichete (maxim 5)',
		'About me': 'Despre mine',
		Skills: 'Aptitudini',
		Values: 'Valori',
		'View all': 'Vezi tot',
		me: 'mine',
		Experience: 'Experienta',
		My: 'Mea',
		Present: 'Prezent',
		At: 'La',
		'About us': 'Despre CV30',
		'For companies': 'Pentru angajatori',
		'Terms & conditions': 'Termeni & conditii',
		Privacy: 'Politica de confidentialitate',
		Contact: 'Contact',
		FAQ: 'Intrebari frecvente',
		Comments: 'Comentarii',
		'your comment': 'Scrie comentariul aici',
		Sendit: 'Posteaza',
		'Delete comment': 'sterge',
		Endorsed: '',
		times: 'aprecieri',
		'see more': 'vezi mai mult',
		Select: 'Alege',
		'Team Type': 'Domeniul',
		'Discover our': 'Joburi',
		jobs: '',
		Our: 'Postari',
		'latest posts': '',
		Explore: 'Descopera',
		Advanced: 'Cautare',
		'Search-1': 'avansata',
		'Job Title': 'Nume job',
		'Team name': 'Echipa',
		'Min. salary': 'Salariu minim',
		'Max. salary': 'Salariul maxim',
		'Type of job': 'Tip',
		'Seniority level': 'Nivel',
		'Job OnSite or Remote': 'OnSite / Remote',
		'Save this job': 'Salveaza',
		'Post Type': 'Tip job',
		'Location Type': 'Localizare',
		'Seniority Level': 'Nivel',
		'20/week': '20/saptamana',
		'40/week': '40/saptamana',
		'About the': 'Descopera',
		Team: 'echipa',
		'Company-1': 'compania',
		Profile: 'Profil',
		whatsnew: '<1>Noutati</1>',
		' team': '',
		'Meet the teams of top employers, see how they work daily, engage with people alike through content of interest, all before you apply for a position.':
			'Descopera cum lucreaza viitorii tai colegi si interactioneaza cu ei prin postari dedicate carierei.',
		'Termeni și condiții': 'Termeni și condiții',
		'Acceptarea acordului de utilizare':
			'Acceptarea acordului de utilizare',
		'Acest acord se referă la paginile web operate de S.C. cv30 WORLDWIDE GROUP S.R.L. cu sediul social în București, Strada General Barbu Vlădoianu, nr. 26, sector 1, (denumită în continuare cv30). Site-ul, serviciile şi conţinutul www.cv30.co, denumit în continuare şi “Site-ul” vă sunt oferite condiţionat de acceptarea dumneavoastră fără nici o modificare a condiţiilor din prezentul acord. Dacă nu acceptaţi termenii şi condiţiile statuate în acest document, nu folosiţi site-ul şi serviciile oferite prin intermediul lui. Prin utilizarea site-ului, indicaţi că acceptaţi să respectaţi "Termenii şi Condiţiile" ':
			'Acest acord se referă la paginile web operate de S.C. cv30 WORLDWIDE GROUP S.R.L. cu sediul social în București, Strada General Barbu Vlădoianu, nr. 26, sector 1, (denumită în continuare cv30). Site-ul, serviciile şi conţinutul www.cv30.co, denumit în continuare şi “Site-ul” vă sunt oferite condiţionat de acceptarea dumneavoastră fără nici o modificare a condiţiilor din prezentul acord. Dacă nu acceptaţi termenii şi condiţiile statuate în acest document, nu folosiţi site-ul şi serviciile oferite prin intermediul lui. Prin utilizarea site-ului, indicaţi că acceptaţi să respectaţi "Termenii şi Condiţiile". ',
		'Descrierea serviciilor': 'Descrierea serviciilor',
		'Site-ul www.cv30.co oferă utilizatorilor acces la o colecţie bogată de resurse şi informaţii cu privire la educaţie şi carieră. Dumneavoastră înţelegeţi şi sunteţi de acord că aceste servicii includ şi publicitate, iar publicitatea este necesară site-ului pentru furnizarea serviciilor sale. Dumneavoastră înţelegeţi şi sunteţi de acord că serviciile site-ului includ diverse anunţuri şi informaţii ce sunt actualizate constant şi au ca subiect educaţia şi cariera. Dumneavoastră înţelegeţi şi sunteţi de acord că serviciile sunt oferite "aşa cum sunt", iar site-ul nu îşi asumă nicio responsabilitate pentru ştergerea, transmiterea sau salvarea greşită a setărilor personalizate. Dumneavoastră înţelegeţi şi sunteţi de acord cu faptul că informațiile prezente pe site sunt cu titlu orientativ.':
			'Site-ul www.cv30.co oferă utilizatorilor acces la o colecţie bogată de resurse şi informaţii cu privire la educaţie şi carieră. Dumneavoastră înţelegeţi şi sunteţi de acord că aceste servicii includ şi publicitate, iar publicitatea este necesară site-ului pentru furnizarea serviciilor sale. Dumneavoastră înţelegeţi şi sunteţi de acord că serviciile site-ului includ diverse anunţuri şi informaţii ce sunt actualizate constant şi au ca subiect educaţia şi cariera. Dumneavoastră înţelegeţi şi sunteţi de acord că serviciile sunt oferite "aşa cum sunt", iar site-ul nu îşi asumă nicio responsabilitate pentru ştergerea, transmiterea sau salvarea greşită a setărilor personalizate. Dumneavoastră înţelegeţi şi sunteţi de acord cu faptul că informațiile prezente pe site sunt cu titlu orientativ.',
		'Obligaţii la înregistrare': 'Obligaţii la înregistrare',
		'Pentru a folosi serviciile oferite de site, vă obligaţi să':
			'Pentru a folosi serviciile oferite de site, vă obligaţi să',
		'1. furnizaţi date adevărate, exacte şi complete despre dumneavoastră, aşa cum este cerut de formularul de înregistrare al site-ului ':
			'1. furnizaţi date adevărate, exacte şi complete despre dumneavoastră, aşa cum este cerut de formularul de înregistrare al site-ului ',
		'2. menţineţi şi înnoiţi, atunci când situaţia o cere, datele de înregistrare pentru a fi adevărate, exacte şi complete.':
			'2. menţineţi şi înnoiţi, atunci când situaţia o cere, datele de înregistrare pentru a fi adevărate, exacte şi complete.',
		'Dacă veţi furniza informaţii care nu sunt conforme cu realitatea, inexacte sau incomplete, compania are dreptul să suspende sau să desfiinţeze contul dumneavoastră şi să refuze toate încercările curente sau viitoare de folosire a site-ului.':
			'Dacă veţi furniza informaţii care nu sunt conforme cu realitatea, inexacte sau incomplete, compania are dreptul să suspende sau să desfiinţeze contul dumneavoastră şi să refuze toate încercările curente sau viitoare de folosire a site-ului.',
		'Responsabilităţile utilizatorului':
			'Responsabilităţile utilizatorului',
		'Ca utilizator sunteţi responsabil de propriile acţiuni, precum şi de consecinţele pe care acestea le pot avea, în urma publicării materialelor pe care le încărcaţi, puneţi la dispoziţie în mod public pe contul pus la dispoziţie de companie.':
			'Ca utilizator sunteţi responsabil de propriile acţiuni, precum şi de consecinţele pe care acestea le pot avea, în urma publicării materialelor pe care le încărcaţi, puneţi la dispoziţie în mod public pe contul pus la dispoziţie de companie.',
		'Vă obligaţi să nu faceţi următoarele lucruri':
			'Vă obligaţi să nu faceţi următoarele lucruri',
		'1. să publicaţi material cu drept de autor, dacă nu sunteţi autorul sau dacă nu aveţi permisiunea autorului de a publica materialul respectiv;':
			'1. să publicaţi material cu drept de autor, dacă nu sunteţi autorul sau dacă nu aveţi permisiunea autorului de a publica materialul respectiv;',
		'2. să publicaţi materiale obscene, defăimătoare, de ameninţare, discriminorii sau răuvoitoare faţă de un alt utilizator, persoană fizică sau juridică sau faţă de orice alt terţ;':
			'2. să publicaţi materiale obscene, defăimătoare, de ameninţare, discriminorii sau răuvoitoare faţă de un alt utilizator, persoană fizică sau juridică sau faţă de orice alt terţ;',
		'3. să publicaţi o imagine sau o afirmaţie explicit sexuală;':
			'3. să publicaţi o imagine sau o afirmaţie explicit sexuală;',
		'4. să publicaţi materiale care conţin viruşi, viermi sau alte programe cu intenţia de a distruge orice sistem sau informaţie;':
			'4. să publicaţi materiale care conţin viruşi, viermi sau alte programe cu intenţia de a distruge orice sistem sau informaţie;',
		'5. încarca, difuza sau transmite în alt mod orice conţinut pentru care nu aveţi dreptul legal de transmitere sau difuzare în orice condiţii, sub orice sistem juridic, român sau străin, relaţii contractuale sau de încredere (cum sunt informaţiile confidenţiale, cele aflate sub drept de proprietate, aflate sau dezvăluite ca parte a relaţiilor de serviciu sau aflate sub incidenţa acordurilor de confidenţialitate);':
			'5. încarca, difuza sau transmite în alt mod orice conţinut pentru care nu aveţi dreptul legal de transmitere sau difuzare în orice condiţii, sub orice sistem juridic, român sau străin, relaţii contractuale sau de încredere (cum sunt informaţiile confidenţiale, cele aflate sub drept de proprietate, aflate sau dezvăluite ca parte a relaţiilor de serviciu sau aflate sub incidenţa acordurilor de confidenţialitate);',
		'6. încarca, difuza sau transmite în alt mod orice tip de publicitate, materiale promoţionale, "junk mail", "spam", scrisori în lanţ, scheme piramidale, sau orice altă formă de solicitări, cu excepţia porţiunilor din serviciu care sunt formate cu acest scop;':
			'6. încarca, difuza sau transmite în alt mod orice tip de publicitate, materiale promoţionale, "junk mail", "spam", scrisori în lanţ, scheme piramidale, sau orice altă formă de solicitări, cu excepţia porţiunilor din serviciu care sunt formate cu acest scop;',
		'7. avea adrese multiple în cadrul site-ului care se aseamănă sau care sunt create pe aceeaşi temă;':
			'7. avea adrese multiple în cadrul site-ului care se aseamănă sau care sunt create pe aceeaşi temă;',
		'8. promova sau furniza informaţii despre modul de derulare a activităţilor ilegale, promova rănirea fizică sau verbală împotriva oricărui grup sau oricărei persoane, sau pentru a promova orice act de cruzime faţă de animale. Aceasta poate include, dar nu este limitat, a furniza informaţii despre modul de a fabrica, achiziţiona sau asambla bombe, grenade sau alte tipuri de arme şi crearea de site-uri "crush";':
			'8. promova sau furniza informaţii despre modul de derulare a activităţilor ilegale, promova rănirea fizică sau verbală împotriva oricărui grup sau oricărei persoane, sau pentru a promova orice act de cruzime faţă de animale. Aceasta poate include, dar nu este limitat, a furniza informaţii despre modul de a fabrica, achiziţiona sau asambla bombe, grenade sau alte tipuri de arme şi crearea de site-uri "crush";',
		'9. difuza sau transmite în alt mod date personale (nume, adresa, numere de telefon) sau înregistrarea acestora în rubricile care pot fi vizualizate de către ceilalţi utilizatori (descrieri, pseudonim etc.);':
			'9. difuza sau transmite în alt mod date personale (nume, adresa, numere de telefon) sau înregistrarea acestora în rubricile care pot fi vizualizate de către ceilalţi utilizatori (descrieri, pseudonim etc.);',
		'10. difuza sau transmite orice alt material care contravine, în orice fel, normelor legale în vigoare.':
			'10. difuza sau transmite orice alt material care contravine, în orice fel, normelor legale în vigoare.',
		'Compania nu garantează credibilitatea, acurateţea informaţiilor publicate de către utilizatori sau nu girează vreo părere exprimată de către utilizatori.':
			'Compania nu garantează credibilitatea, acurateţea informaţiilor publicate de către utilizatori sau nu girează vreo părere exprimată de către utilizatori.',
		'Compania acţionează ca un factor pasiv în distribuţia online şi publicarea informaţiilor furnizate de către utilizatori şi nu are obligaţia de a verifica în avans materialul publicat de către utilizatori. De aceea, compania nu răspunde în niciun mod cu privire la informaţiile şi datele postate, difuzate sau transmise de către utilizatorii săi. Dacă i se solicită de către un utilizator, compania poate investiga şi verifica afirmaţiile şi poate hotărî dacă informaţiile respective trebuie îndepărtate. De asemenea, permiteţi oricărei persoane care este abonată să acceseze, să vizualizeze, să stocheze sau să reproducă asemenea materiale în scopuri personale. Supus celor menţionate înainte, posesorul unui astfel de material publicat pe site îşi menţine orice drepturi care ar putea deriva de aici.':
			'Compania acţionează ca un factor pasiv în distribuţia online şi publicarea informaţiilor furnizate de către utilizatori şi nu are obligaţia de a verifica în avans materialul publicat de către utilizatori. De aceea, compania nu răspunde în niciun mod cu privire la informaţiile şi datele postate, difuzate sau transmise de către utilizatorii săi. Dacă i se solicită de către un utilizator, compania poate investiga şi verifica afirmaţiile şi poate hotărî dacă informaţiile respective trebuie îndepărtate. De asemenea, permiteţi oricărei persoane care este abonată să acceseze, să vizualizeze, să stocheze sau să reproducă asemenea materiale în scopuri personale. Supus celor menţionate înainte, posesorul unui astfel de material publicat pe site îşi menţine orice drepturi care ar putea deriva de aici.',
		'Informaţii pentru utilizatori': 'Informaţii pentru utilizatori',
		'În momentul în care vă înregistraţi pe site, vi se vor cere anumite informaţii incluzând o adresă de e-mail validă. Pe lângă termenii şi condiţiile care vor fi reglementate în politica site-ului, dumneavoastră înţelegeţi şi acceptaţi faptul că această companie poate divulga unei terţe persoane anumite informaţii conţinute în cererea dumneavoastră de înregistrare.':
			'În momentul în care vă înregistraţi pe site, vi se vor cere anumite informaţii incluzând o adresă de e-mail validă. Pe lângă termenii şi condiţiile care vor fi reglementate în politica site-ului, dumneavoastră înţelegeţi şi acceptaţi faptul că această companie poate divulga unei terţe persoane anumite informaţii conţinute în cererea dumneavoastră de înregistrare.',
		'Compania nu va divulga unei terţe persoane numele, adresa de e-mail sau numărul dumneavoastră de telefon fără consimţământul dumneavoastră, cu excepţia cazurilor prevăzute de lege când comunicarea acestor informaţii este necesară şi în conformitate cu politica de confidenţialitate.':
			'Compania nu va divulga unei terţe persoane numele, adresa de e-mail sau numărul dumneavoastră de telefon fără consimţământul dumneavoastră, cu excepţia cazurilor prevăzute de lege când comunicarea acestor informaţii este necesară şi în conformitate cu politica de confidenţialitate.',
		'Compania îşi rezervă dreptul de a oferi serviciile şi produsele unui terţ, bazându-se pe datele menţionate în înregistrarea dumneavoastră în orice moment după ce aceasta a avut loc; asemenea oferte pot fi făcute de către companie sau de către un terţ implicat.':
			'Compania îşi rezervă dreptul de a oferi serviciile şi produsele unui terţ, bazându-se pe datele menţionate în înregistrarea dumneavoastră în orice moment după ce aceasta a avut loc; asemenea oferte pot fi făcute de către companie sau de către un terţ implicat.',
		'Pentru mai multe detalii despre divulgarea datelor dumneavoastră, vedeți “Nota de informare privind confidențialitatea datelor”. Aceste operațiuni respectă Regulamentul General privind Protecția Datelor 2016/679.':
			'Pentru mai multe detalii despre divulgarea datelor dumneavoastră, vedeți “Nota de informare privind confidențialitatea datelor”. Aceste operațiuni respectă Regulamentul General privind Protecția Datelor 2016/679.',
		'Contul, parola şi securitatea datelor':
			'Contul, parola şi securitatea datelor',
		'Sunteţi răspunzători de păstrarea confidenţialităţii asupra informaţiilor şi a parolei dumneavoastră. Veţi fi răspunzători de utilizarea înregistrării dumneavoastră, indiferent dacă utilizarea se face cu sau fără voia dumneavoastră. Sunteţi de acord să sesizaţi compania în legătură cu orice utilizare neautorizată a parolei şi a înregistrării dumneavoastră. Sunteţi de asemenea de acord să folosiţi butonul de ieşire din contul dumneavoastră când doriţi să părăsiţi site-ul. Compania nu va fi răspunzătoare pentru nici o pagubă morală sau materială provocată prin nerespectarea acestei prevederi de către dumneavoastră.':
			'Sunteţi răspunzători de păstrarea confidenţialităţii asupra informaţiilor şi a parolei dumneavoastră. Veţi fi răspunzători de utilizarea înregistrării dumneavoastră, indiferent dacă utilizarea se face cu sau fără voia dumneavoastră. Sunteţi de acord să sesizaţi compania în legătură cu orice utilizare neautorizată a parolei şi a înregistrării dumneavoastră. Sunteţi de asemenea de acord să folosiţi butonul de ieşire din contul dumneavoastră când doriţi să părăsiţi site-ul. Compania nu va fi răspunzătoare pentru nici o pagubă morală sau materială provocată prin nerespectarea acestei prevederi de către dumneavoastră.',
		'Dacă consideră de cuviinţă, compania vă poate bloca parola, utilizarea serviciilor sau folosirea oricăror altor servicii ale sale de care beneficiaţi, sau să scoată şi să şteargă orice material din cadrul serviciilor, pentru orice motiv sau fără motiv. Compania poate de asemenea, oricând doreşte şi fără a da socoteală, întrerupe furnizarea serviciilor, sau a unei părţi a lor, cu sau fără nici o notificare prealabilă.':
			'Dacă consideră de cuviinţă, compania vă poate bloca parola, utilizarea serviciilor sau folosirea oricăror altor servicii ale sale de care beneficiaţi, sau să scoată şi să şteargă orice material din cadrul serviciilor, pentru orice motiv sau fără motiv. Compania poate de asemenea, oricând doreşte şi fără a da socoteală, întrerupe furnizarea serviciilor, sau a unei părţi a lor, cu sau fără nici o notificare prealabilă.',
		'Orice încetare a accesului dumneavoastră la serviciile site-ului, conform oricărei reguli din prezentul Regulament, poate fi efectuată fără o avertizare anticipată, şi luaţi la cunoştinţă şi sunteţi de acord că firma poate dezactiva sau şterge imediat contul dumneavoastră din cadrul site-ului şi toate informaţiile în legătură cu acesta şi/sau să interzică orice acces ulterior la serviciile site-ului. Mai mult, compania nu va fi răspunzătoare faţă de dumneavoastră sau orice terţă parte în orice mod, pentru interzicerea şi/sau ştergerea contului şi a accesului la serviciile puse la dispoziţie prin intermediul site-ului.':
			'Orice încetare a accesului dumneavoastră la serviciile site-ului, conform oricărei reguli din prezentul Regulament, poate fi efectuată fără o avertizare anticipată, şi luaţi la cunoştinţă şi sunteţi de acord că firma poate dezactiva sau şterge imediat contul dumneavoastră din cadrul site-ului şi toate informaţiile în legătură cu acesta şi/sau să interzică orice acces ulterior la serviciile site-ului. Mai mult, compania nu va fi răspunzătoare faţă de dumneavoastră sau orice terţă parte în orice mod, pentru interzicerea şi/sau ştergerea contului şi a accesului la serviciile puse la dispoziţie prin intermediul site-ului.',
		'Utilizarea materialelor': 'Utilizarea materialelor',
		'Compania vă autorizează să vizualizaţi informaţia de pe site doar în scopuri personale şi pentru uz necomercial. Conţinutul acestui site, adică textele, grafica, fotografiile, precum şi alte materiale sunt protejate de legea dreptului de autor. Întregul material este proprietatea companiei. Compilaţia (adică adunarea şi rearanjarea) conţinutului acestui site este dreptul exclusiv al companiei, drept protejat de legile în vigoare. Folosirea neautorizată a materialului reprezintă o încălcare a legii dreptului de autor sau a altor legi. Nu aveţi permisiunea de a vinde sau a modifica materialul, a-l expune public, a-l distribui sau a-l folosi în orice alt mod, în scopuri publice sau comerciale.':
			'Compania vă autorizează să vizualizaţi informaţia de pe site doar în scopuri personale şi pentru uz necomercial. Conţinutul acestui site, adică textele, grafica, fotografiile, precum şi alte materiale sunt protejate de legea dreptului de autor. Întregul material este proprietatea companiei. Compilaţia (adică adunarea şi rearanjarea) conţinutului acestui site este dreptul exclusiv al companiei, drept protejat de legile în vigoare. Folosirea neautorizată a materialului reprezintă o încălcare a legii dreptului de autor sau a altor legi. Nu aveţi permisiunea de a vinde sau a modifica materialul, a-l expune public, a-l distribui sau a-l folosi în orice alt mod, în scopuri publice sau comerciale.',
		'Pe serverele care aparţin sau care sunt închiriate de către companie accesul oricărui utilizator neautorizat este interzis. În caz de încercare şi/sau accesare, fără drept, a acestor servere, fapta constituie infracţiune şi se pedepseşte conform legislaţiei române în vigoare.':
			'Pe serverele care aparţin sau care sunt închiriate de către companie accesul oricărui utilizator neautorizat este interzis. În caz de încercare şi/sau accesare, fără drept, a acestor servere, fapta constituie infracţiune şi se pedepseşte conform legislaţiei române în vigoare.',
		'Proprietatea materialelor şi informaţiilor introduse pe site':
			'Proprietatea materialelor şi informaţiilor introduse pe site',
		'Compania nu va pretinde un drept de proprietate asupra materialelor pe care le furnizaţi (inclusiv observaţiile şi sugestiile) sau pe care le postaţi, încărcaţi sau trimiteţi către site. Totuşi, transmiţând aceste materiale, dumneavoastră sunteţi de acord să acordaţi companiei permisiunea de a utiliza materialele inclusiv să le copieze, distribuie, transmită, publice, reproducă, modifice şi să traducă; de a menţiona numele dumneavoastră în legătură cu materialul pe care l-aţi transmis; şi dreptul de a cesiona la rândul ei toate aceste drepturi oricărui partener al companiei.':
			'Compania nu va pretinde un drept de proprietate asupra materialelor pe care le furnizaţi (inclusiv observaţiile şi sugestiile) sau pe care le postaţi, încărcaţi sau trimiteţi către site. Totuşi, transmiţând aceste materiale, dumneavoastră sunteţi de acord să acordaţi companiei permisiunea de a utiliza materialele inclusiv să le copieze, distribuie, transmită, publice, reproducă, modifice şi să traducă; de a menţiona numele dumneavoastră în legătură cu materialul pe care l-aţi transmis; şi dreptul de a cesiona la rândul ei toate aceste drepturi oricărui partener al companiei.',
		'Nu se vor pretinde şi nici acorda niciun fel de compensaţii pentru utilizarea materialului în felul arătat mai sus. Compania nu are obligaţia de a transmite pe site sau folosi în orice mod materialul furnizat de dumneavoastră; compania are de asemenea dreptul să şteargă materialul dumneavoastră în orice moment şi fără a avea nevoie de un motiv în acest sens.':
			'Nu se vor pretinde şi nici acorda niciun fel de compensaţii pentru utilizarea materialului în felul arătat mai sus. Compania nu are obligaţia de a transmite pe site sau folosi în orice mod materialul furnizat de dumneavoastră; compania are de asemenea dreptul să şteargă materialul dumneavoastră în orice moment şi fără a avea nevoie de un motiv în acest sens.',
		'Relaţia cu companiile care fac publicitate pe site-ul www.cv30.co':
			'Relaţia cu companiile care fac publicitate pe site-ul www.cv30.co',
		'Compania rulează campanii publicitare şi promoţii în cadrul site-ului său. Corespondenţa sau afacerile sau participarea în promoţii ale companiilor de publicitate găsite pe sau prin intermediul site-urilor, inclusiv plăți sau livrări ale unor bunuri sau servicii şi orice alte condiţii, termene, garanţii sau reprezentări asociate cu astfel de relaţii sunt numai în responsabilitatea dumneavoastră şi a acestor companii. Compania nu este răspunzătoare şi nu va fi trasă la răspundere pentru orice pierdere sau prejudiciu de orice fel, rezultat din astfel de relaţii sau rezultat din prezenţa unor astfel de companii în cadrul campaniilor publicitare ale site-ului. Prin agrearea termenilor prezentului Contract, ne acordaţi dreptul de a vă trimite periodic e-mail-uri cu informaţii sau oferte speciale.':
			'Compania rulează campanii publicitare şi promoţii în cadrul site-ului său. Corespondenţa sau afacerile sau participarea în promoţii ale companiilor de publicitate găsite pe sau prin intermediul site-urilor, inclusiv plăți sau livrări ale unor bunuri sau servicii şi orice alte condiţii, termene, garanţii sau reprezentări asociate cu astfel de relaţii sunt numai în responsabilitatea dumneavoastră şi a acestor companii. Compania nu este răspunzătoare şi nu va fi trasă la răspundere pentru orice pierdere sau prejudiciu de orice fel, rezultat din astfel de relaţii sau rezultat din prezenţa unor astfel de companii în cadrul campaniilor publicitare ale site-ului. Prin agrearea termenilor prezentului Contract, ne acordaţi dreptul de a vă trimite periodic e-mail-uri cu informaţii sau oferte speciale.',
		'Legături către alţi furnizori de informaţii sau servicii':
			'Legături către alţi furnizori de informaţii sau servicii',
		'Site-ul poate furniza, sau terţe părţi pot furniza legături (link-uri) către alte site-uri World Wide Web sau alte resurse. Deoarece compania nu are niciun control asupra acestor site-uri sau resurse, luaţi cunoştinţă şi sunteţi de acord că nu suntem responsabili de disponibilitatea acestora şi nu garantăm şi nu suntem responsabili pentru niciun conţinut, publicitate, produse sau alte materiale de pe sau disponibile pe acest site sau resurse.':
			'Site-ul poate furniza, sau terţe părţi pot furniza legături (link-uri) către alte site-uri World Wide Web sau alte resurse. Deoarece compania nu are niciun control asupra acestor site-uri sau resurse, luaţi cunoştinţă şi sunteţi de acord că nu suntem responsabili de disponibilitatea acestora şi nu garantăm şi nu suntem responsabili pentru niciun conţinut, publicitate, produse sau alte materiale de pe sau disponibile pe acest site sau resurse.',
		'Mai mult, recunoaşteţi şi sunteţi de acord că nu vom fi responsabili sau pasibili de a plăti despăgubiri, direct sau indirect pentru orice pagubă sau pierdere cauzată sau presupusă a fi fost cauzată de sau în legătură cu folosirea sau încrederea în informaţiile furnizate de un asemenea conţinut, bunuri sau servicii disponibile pe sau prin intermediul unor astfel de site-uri sau resurse.':
			'Mai mult, recunoaşteţi şi sunteţi de acord că nu vom fi responsabili sau pasibili de a plăti despăgubiri, direct sau indirect pentru orice pagubă sau pierdere cauzată sau presupusă a fi fost cauzată de sau în legătură cu folosirea sau încrederea în informaţiile furnizate de un asemenea conţinut, bunuri sau servicii disponibile pe sau prin intermediul unor astfel de site-uri sau resurse.',
		'Responsabilităţile companiei': 'Responsabilităţile companiei',
		'Deoarece identificarea utilizatorilor pe Internet este dificilă, site- ul nu confirmă faptul că fiecare utilizator este ceea ce pretinde.Deoarece nu suntem şi nu putem fi implicaţi în relaţiile user- to - user sau nu putem controla comportamentul utilizatorilor www.cv30.co, în eventualitatea unei dispute cu unul sau mai mulţi utilizatori, compania(prin agenţii, administratorii şi angajaţii săi) este absolvită de orice răspundere sau despăgubire(directă sau indirectă) de orice fel şi de orice natură, cunoscută sau necunoscută, suspectată sau nu, dezvăluită sau nu, ivită în vreun mod care are legătură cu disputele.':
			'Deoarece identificarea utilizatorilor pe Internet este dificilă, site-ul nu confirmă faptul că fiecare utilizator este ceea ce pretinde. Deoarece nu suntem şi nu putem fi implicaţi în relaţiile user-to-user sau nu putem controla comportamentul utilizatorilor www.cv30.co, în eventualitatea unei dispute cu unul sau mai mulţi utilizatori, compania (prin agenţii, administratorii şi angajaţii săi) este absolvită de orice răspundere sau despăgubire (directă sau indirectă) de orice fel şi de orice natură, cunoscută sau necunoscută, suspectată sau nu, dezvăluită sau nu, ivită în vreun mod care are legătură cu disputele.',
		'Compania nu are obligaţia legală de a controla informaţiile oferite de alţi utilizatori, disponibile pe site. Informaţiile altor persoane pot fi neplăcute, vătămătoare sau inexacte.':
			'Compania nu are obligaţia legală de a controla informaţiile oferite de alţi utilizatori, disponibile pe site. Informaţiile altor persoane pot fi neplăcute, vătămătoare sau inexacte.',
		'Materialul poate conţine inexactităţi sau greşeli de scriere. Compania nu îşi asumă veridicitatea şi exactitatea materialului de pe site. Dumneavoastră luaţi la cunoştinţă şi sunteţi de acord cu faptul că sunteţi singurul responsabil pentru forma, conţinutul sau exactitatea materialului conţinut sau plasat de dumneavoastră pe site.':
			'Materialul poate conţine inexactităţi sau greşeli de scriere. Compania nu îşi asumă veridicitatea şi exactitatea materialului de pe site. Dumneavoastră luaţi la cunoştinţă şi sunteţi de acord cu faptul că sunteţi singurul responsabil pentru forma, conţinutul sau exactitatea materialului conţinut sau plasat de dumneavoastră pe site.',
		'Acord de confidenţialitate': 'Acord de confidenţialitate',
		'Echipa companiei încearcă să protejeze cât mai mult dreptul la intimitate şi la imagine al utilizatorilor site-ului. Ne propunem să vă oferim dumneavoastră, utilizatorilor site-ului, o experienţă online sigură, care să nu vă afecteze în mod negativ viaţa personală. În acest sens depunem toate eforturile pentru a ne asigura că informaţiile pe care dumneavoastră le introduceţi în baza noastră de date sunt folosite numai în scopurile pe care dumneavoastră le aveţi în vedere.':
			'Echipa companiei încearcă să protejeze cât mai mult dreptul la intimitate şi la imagine al utilizatorilor site-ului. Ne propunem să vă oferim dumneavoastră, utilizatorilor site-ului, o experienţă online sigură, care să nu vă afecteze în mod negativ viaţa personală. În acest sens depunem toate eforturile pentru a ne asigura că informaţiile pe care dumneavoastră le introduceţi în baza noastră de date sunt folosite numai în scopurile pe care dumneavoastră le aveţi în vedere.',
		'Afișarea datelor personale în spatiile/domeniile publice ale www.cv30.co':
			'Afișarea datelor personale în spatiile/domeniile publice ale www.cv30.co',
		'Informațiile cu caracter personal postate de către utilizatori în locurile/spatiile publice aparținând www.cv30.co, cum ar fi forum-uri, chat-uri, liste de discutii, baza de date cu CV-urile căutabile, pot fi copiate și stocate de către terți asupra cărora www.cv30.co nu are nici un control. Noi nu suntem responsabili pentru faptul că terțe părți utilizează informații pe care le-ați postat sau le-ați făcut disponibile în spatiile publice aparținând www.cv30.co.':
			'Informațiile cu caracter personal postate de către utilizatori în locurile/spatiile publice aparținând www.cv30.co, cum ar fi forum-uri, chat-uri, liste de discutii, baza de date cu CV-urile căutabile, pot fi copiate și stocate de către terți asupra cărora www.cv30.co nu are nici un control. Noi nu suntem responsabili pentru faptul că terțe părți utilizează informații pe care le-ați postat sau le-ați făcut disponibile în spatiile publice aparținând www.cv30.co.',
		'Profil ': 'Profil ',
		'Din moment ce www.cv30.co este un site de dezvoltare personala si profesionala online vă acordăm posibilitatea de înregistrare a propriului profil în baza noastră de date. Există două modalități 1. Vă puteți înregistra cv-ul în baza noastră de date. 2. Puteți crea profilul de cariera, iar angajatorii vor avea acces la datele dvs. de contact. Puteți să ștergeți profilul din baza noastră de date oricând (dar nu mai devreme de 6 luni de la cea mai recentă aplicare făcută de dvs. pentru un loc de munca). Dacă postați datele altei persoane este necesar ca persoana respectivă să își fi dat în prealabil acordul pentru acest act.':
			'Din moment ce www.cv30.co este un site de dezvoltare personala si profesionala online vă acordăm posibilitatea de înregistrare a propriului profil în baza noastră de date. Există două modalități 1. Vă puteți înregistra cv-ul în baza noastră de date. 2. Puteți crea profilul de cariera, iar angajatorii vor avea acces la datele dvs. de contact. Puteți să ștergeți profilul din baza noastră de date oricând (dar nu mai devreme de 6 luni de la cea mai recentă aplicare făcută de dvs. pentru un loc de munca). Dacă postați datele altei persoane este necesar ca persoana respectivă să își fi dat în prealabil acordul pentru acest act.',
		'Evenimente ': 'Evenimente ',
		'Compania organizează periodic evenimente la care vă înscrieți prin intermediul website-ului. Imaginile, video-urile și textele din cadrul evenimentelor sunt proprietatea companiei și dumneavoastră sunteţi de acord să acordaţi companiei permisiunea de a utiliza materialele inclusiv să le copieze, distribuie, transmită, publice, reproducă, modifice şi să traducă; de a menţiona numele dumneavoastră în legătură cu materialul pe care l-aţi transmis; şi dreptul de a cesiona la rândul ei toate aceste drepturi oricărui partener al companiei.':
			'Compania organizează periodic evenimente la care vă înscrieți prin intermediul website-ului. Imaginile, video-urile și textele din cadrul evenimentelor sunt proprietatea companiei și dumneavoastră sunteţi de acord să acordaţi companiei permisiunea de a utiliza materialele inclusiv să le copieze, distribuie, transmită, publice, reproducă, modifice şi să traducă; de a menţiona numele dumneavoastră în legătură cu materialul pe care l-aţi transmis; şi dreptul de a cesiona la rândul ei toate aceste drepturi oricărui partener al companiei.',
		'Nu se vor pretinde şi nici acorda niciun fel de compensaţii pentru utilizarea materialului în felul arătat mai sus. Compania nu are obligaţia de a transmite pe site sau folosi în orice mod materialul din cadrul evenimentelor; compania are de asemenea dreptul să şteargă materialul în orice moment şi fără a avea nevoie de un motiv în acest sens.':
			'Nu se vor pretinde şi nici acorda niciun fel de compensaţii pentru utilizarea materialului în felul arătat mai sus. Compania nu are obligaţia de a transmite pe site sau folosi în orice mod materialul din cadrul evenimentelor; compania are de asemenea dreptul să şteargă materialul în orice moment şi fără a avea nevoie de un motiv în acest sens.',
		'Dreptul de a modifica și șterge datele dvs. deținute de operator www.cv30.co conferă dreptul utilizatorului de a modifica datele de contact pe care le-ați înregistrat, unele aspecte de care sunteți interesat, incluzând noile informații despre produse și servicii lansate. De asemenea, puteți solicita operatorului ștergerea datelor dvs. oricând.':
			'Dreptul de a modifica și șterge datele dvs. deținute de operator www.cv30.co conferă dreptul utilizatorului de a modifica datele de contact pe care le-ați înregistrat, unele aspecte de care sunteți interesat, incluzând noile informații despre produse și servicii lansate. De asemenea, puteți solicita operatorului ștergerea datelor dvs. oricând.',
		'Securitatea datelor': 'Securitatea datelor',
		'Pentru confidențialitatea și securitatea datelor, contul dumneavoastra pe www.cv30.co este protejat de o parolă.':
			'Pentru confidențialitatea și securitatea datelor, contul dumneavoastra pe www.cv30.co este protejat de o parolă.',
		'Cookies ': 'Cookies',
		"Pentru a vă oferi o navigare cât mai fluidă pe site-ul www.cv30.co, folosim o facilitate a browserului Internet numita 'cookie'. Cookies sunt fișiere de dimensiuni reduse pe care browserul le plasează pe hard-diskul utilizatorului. Aceste cookies sunt folosite pentru a memora numele utilizatorilor, parolele și preferințele, pentru a monitoriza parcursuri pe site, și pentru a personaliza paginile în funcție de vizitator. Aveți opțiunea de a seta browserul astfel încât să respingă cookies. În acest caz însă, va exista un impact negativ asupra navigării pe site-ul nostru.":
			"Pentru a vă oferi o navigare cât mai fluidă pe site-ul www.cv30.co, folosim o facilitate a browserului Internet numita 'cookie'. Cookies sunt fișiere de dimensiuni reduse pe care browserul le plasează pe hard-diskul utilizatorului. Aceste cookies sunt folosite pentru a memora numele utilizatorilor, parolele și preferințele, pentru a monitoriza parcursuri pe site, și pentru a personaliza paginile în funcție de vizitator. Aveți opțiunea de a seta browserul astfel încât să respingă cookies. În acest caz însă, va exista un impact negativ asupra navigării pe site-ul nostru.",
		'Notificari SMS': 'Notificari SMS',
		'www.cv30.co poate trimite ocazional alerte de tip SMS utilizatorilor înregistrați cu cont creat pe site pentru a-i informa despre evenimente de carieră, job-uri, programe de recrutare ale companiilor prezente pe cv30.ro, produse și servicii de care ar putea fi interesați sau alte aspecte importante legate de contul lor.':
			'www.cv30.co poate trimite ocazional alerte de tip SMS utilizatorilor înregistrați cu cont creat pe site pentru a-i informa despre evenimente de carieră, job-uri, programe de recrutare ale companiilor prezente pe cv30.ro, produse și servicii de care ar putea fi interesați sau alte aspecte importante legate de contul lor.',
		'Feedback ': 'Feedback',
		'www.cv30.co oferă posibilitatea utilizatorilor site-ului de a trimite comentarii, întrebări și sugestii. Orice informație care este trimisă prin intermediul formularelor de contact va fi folosită ținând cont de dreptul la confidențialitate și la imagine al persoanelor.':
			'www.cv30.co oferă posibilitatea utilizatorilor site-ului de a trimite comentarii, întrebări și sugestii. Orice informație care este trimisă prin intermediul formularelor de contact va fi folosită ținând cont de dreptul la confidențialitate și la imagine al persoanelor.',
		'Modificări ale politicii de confidențialitate':
			'Modificări ale politicii de confidențialitate',
		'Dacă vom considera că este necesară o schimbare a regulilor de confidențialitate, vom publica respectivele modificări în această pagină pentru a vă informa cu privire la tipurile de informații pe care le colectam și modul în care le utilizam. Dacă aveți întrebări cu privire la politica noastră de confidențialitate vă rugăm sa ne scrieți la contact@cv30.co.':
			'Dacă vom considera că este necesară o schimbare a regulilor de confidențialitate, vom publica respectivele modificări în această pagină pentru a vă informa cu privire la tipurile de informații pe care le colectam și modul în care le utilizam. Dacă aveți întrebări cu privire la politica noastră de confidențialitate vă rugăm sa ne scrieți la contact@cv30.co.',
		'Notă de informare privind protecția datelor personale':
			'Notă de informare privind protecția datelor personale',
		'Începând cu data de 25 mai 2018, va intra în vigoare Regulamentul (UE) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (Regulamentul general privind protecția datelor).':
			'Începând cu data de 25 mai 2018, va intra în vigoare Regulamentul (UE) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (Regulamentul general privind protecția datelor).',
		'cv30 Worldwide Group SRL - cu sediul în Str. Gral Barbu Vladoianu, nr 26, sector 1, Bucuresti, este operator de date cu caracter personal în scopuri de marketing în baza notificării 35367.':
			'cv30 Worldwide Group SRL - cu sediul în Str. Gral Barbu Vladoianu, nr 26, sector 1, Bucuresti, este operator de date cu caracter personal în scopuri de marketing în baza notificării 35367.',
		'Conformitatea cv30 în legătură cu Regulamentul general privind protecția datelor':
			'Conformitatea cv30 în legătură cu Regulamentul general privind protecția datelor',
		'1. Cum ne contactați?': '1. Cum ne contactați?',
		'CV30 WORLDWIDE GROUP SRL - Strada General Barbu Vlădoianu':
			'CV30 WORLDWIDE GROUP SRL - Strada General Barbu Vlădoianu',
		'contact@cv30.co': 'contact@cv30.co',
		'2. Ce acoperă Nota noastră de informare?':
			'2. Ce acoperă Nota noastră de informare?',
		'2.1. Nota noastră de informare': '2.1. Nota noastră de informare',
		'2.1.1. produce efecte de la 25.05.2018':
			'2.1.1. produce efecte de la 25.05.2018',
		'2.1.2. este publicată la 15.05.2018':
			'2.1.2. este publicată la 15.05.2018',
		'2.2. Nota noastră de informare se aplică':
			'2.2. Nota noastră de informare se aplică',
		'2.2.1. websiteului nostru cv30.co':
			'2.2.1. websiteului nostru cv30.co',
		'2.2.2. newsletter-ului nostru.': '2.2.2. newsletter-ului nostru.',
		'3. De ce și cum prelucrăm datele dumneavoastră personale?':
			'3. De ce și cum prelucrăm datele dumneavoastră personale?',
		'Colectăm câteva informații personale despre vizitatori și userii site-ului.':
			'Colectăm câteva informații personale despre vizitatori și userii site-ului.',
		'Colectăm informații precum nume, prenume, email, telefon, oraș, comentarii și date din serviciile de web analytics. De asemenea, prelucrăm date când aplicați la job-uri (datele din cv) și datele pe care le furnizați când vă înscrieți la un eveniment cv30.':
			'Colectăm informații precum nume, prenume, email, telefon, oraș, comentarii și date din serviciile de web analytics. De asemenea, prelucrăm date când aplicați la job-uri (datele din cv) și datele pe care le furnizați când vă înscrieți la un eveniment cv30.',
		'Suntem siguri că primești zilnic o multitudine de mailuri, motiv pentru care vrem sa ne limităm la ceea ce este esențial și relevant. Pe scurt, fără spam.':
			'Suntem siguri că primești zilnic o multitudine de mailuri, motiv pentru care vrem sa ne limităm la ceea ce este esențial și relevant. Pe scurt, fără spam.',
		'4. Cine este responsabil pentru prelucrarea datelor dumneavoastră personale?':
			'4. Cine este responsabil pentru prelucrarea datelor dumneavoastră personale?',
		'4.2. cv30': '4.2. cv30',
		'- decide de ce sunt prelucrate datele dumneavoastră personale,':
			'- decide de ce sunt prelucrate datele dumneavoastră personale,',
		'- decide cum sunt prelucrate datele dumneavoastră personale, și':
			'- decide cum sunt prelucrate datele dumneavoastră personale, și',
		'- este responsabila pentru prelucrarea datelor dumneavoastră personale.':
			'- este responsabila pentru prelucrarea datelor dumneavoastră personale.',
		'4.2.1. cv30 are un responsabil cu protecția datelor personale.':
			'4.2.1. cv30 are un responsabil cu protecția datelor personale.',
		'- Datele de contact sunt Codrin-Andrei Pascariu, codrin.pascariu@cv30.co, 0311096740, Strada General Barbu Vladoianu, nr. 26':
			'- Datele de contact sunt Codrin-Andrei Pascariu, codrin.pascariu@cv30.co, ,0311096740, Strada General Barbu Vladoianu, nr. 26',
		'5. De la cine și cum colectăm datele dumneavoastră personale?':
			'5. De la cine și cum colectăm datele dumneavoastră personale?',
		'5.1. Colectăm datele dumneavoastră personale':
			'5.1. Colectăm datele dumneavoastră personale',
		'5.1.1. direct de la dumneavoastră.':
			'5.1.1. direct de la dumneavoastră.',
		'5.2. Colectăm datele dumneavoastră personale':
			'5.2. Colectăm datele dumneavoastră personale',
		'5.2.1. electronic, folosind un formular web.':
			'5.2.1. electronic, folosind un formular web.',
		'5.2.2. electronic': '5.2.2. electronic',
		'- prin stocarea de informație pe echipamentele dumneavoastră, sau':
			'- prin stocarea de informație pe echipamentele dumneavoastră, sau',
		'- accesând informație stocată pe echipamentele dumneavoastră, ori':
			'- accesând informație stocată pe echipamentele dumneavoastră, ori',
		'- citind informație emisă de echipamentele dumnevoastră.':
			'- citind informație emisă de echipamentele dumnevoastră.',
		'Când ne furnizați datele dumneavoastră personale, furnizarea dumneavoastră este':
			'Când ne furnizați datele dumneavoastră personale, furnizarea dumneavoastră este',
		'5.3.1. permisă și voluntară. Ni le puteți furniza liber nume, email, telefon, oraș, studii':
			'5.3.1. permisă și voluntară. Ni le puteți furniza liber nume, email, telefon, oraș, studii',
		'5.4. Dacă nu reușiți să ne furnizați datele dumneavoastră personale și această furnizare este voluntară, atunci acest lucru nu vă poate afecta.':
			'5.4. Dacă nu reușiți să ne furnizați datele dumneavoastră personale și această furnizare este voluntară, atunci acest lucru nu vă poate afecta.',
		'6. Care sunt temeiurile noastre legale pentru prelucrarea datelor dumneavoastră personale?':
			'6. Care sunt temeiurile noastre legale pentru prelucrarea datelor dumneavoastră personale?',
		'6.1. Noi procesăm date personale "nesensibile".':
			'6.1. Noi procesăm date personale "nesensibile".',
		'6.2. Temeiul legal pentru prelucrarea datelor dumneavoastră personale "nesensibile" este':
			'6.2. Temeiul legal pentru prelucrarea datelor dumneavoastră personale "nesensibile" este',
		'6.2.1. consimțământul dumneavoastră.':
			'6.2.1. consimțământul dumneavoastră.',
		'6.2.2. Interesul': '6.2.2. Interesul',
		'- nostru legitim, sau': '- nostru legitim, sau',
		'- legitim al unei terțe părți.': '- legitim al unei terțe părți.',
		'Prelucrăm datele dumneavoastră personale pe baza unor interese care sunt':
			'Prelucrăm datele dumneavoastră personale pe baza unor interese care sunt',
		'- legitime,': '- legitime,',
		'- reale, și': '- reale, și',
		'- prezente.': '- prezente.',
		'Prelucrarea datelor dumneavoastră personale este necesară pentru interesele legitime pe care le urmărim.':
			'Prelucrarea datelor dumneavoastră personale este necesară pentru interesele legitime pe care le urmărim.',
		'Interesele noastre legitime pot fi înlăturate de':
			'Interesele noastre legitime pot fi înlăturate de',
		'- interesele dumneavoastră și': '- interesele dumneavoastră și',
		'- drepturi dumneavoastră fundamentale.':
			'- drepturi dumneavoastră fundamentale.',
		'Vă protejăm în mod adecvat': 'Vă protejăm în mod adecvat',
		'- interesele și': '- interesele și',
		'- drepturile și libertățile.': '- drepturile și libertățile.',
		'7. În ce situații prelucrăm datele dumneavoastră personale?':
			'7. În ce situații prelucrăm datele dumneavoastră personale?',
		'7.1. Colectăm date personale': '7.1. Colectăm date personale',
		'7.1.1. ale cititorilor, utilizatori ai siteului, membrii ai comunității, participanți la evenimente':
			'7.1.1. ale cititorilor, utilizatori ai siteului, membrii ai comunității, participanți la evenimente',
		'7.2.Suntem': '7.2.Suntem',
		'7.2.1. o întreprindere.': '7.2.1. o întreprindere.',
		'7.3. Procesăm datele dumneavoastră personale din':
			'7.3. Procesăm datele dumneavoastră personale din',
		'7.3.1. sectorul privat.': '7.3.1. sectorul privat.',
		'7.4. Procesăm datele dumneavoastră personale într-o situație care implică':
			'7.4. Procesăm datele dumneavoastră personale într-o situație care implică',
		'7.4.1. o activitate online.': '7.4.1. o activitate online.',
		'8. Folosim procese de profilare automată și decizii automatizate?':
			'8. Folosim procese de profilare automată și decizii automatizate?',
		'8.1. Folosim datele dumneavoastră personale pentru a evalua automat aspecte ale personalității dumneavoastră.':
			'8.1. Folosim datele dumneavoastră personale pentru a evalua automat aspecte ale personalității dumneavoastră.',
		'Evaluarea automată': 'Evaluarea automată',
		'- poate include o analiză a caracteristicilor dumneavoastră.':
			'- poate include o analiză a caracteristicilor dumneavoastră.',
		'- poate include predicții cu privire la comportamentul dumneavoastră.':
			'- poate include predicții cu privire la comportamentul dumneavoastră.',
		'- este realizată exclusiv cu mijloace informatice.':
			'- este realizată exclusiv cu mijloace informatice.',
		'- este realizată fără implicare umană.':
			'- este realizată fără implicare umană.',
		'- va fi menționată în continuare prin referire la termenul “profilare complet automatizată”.':
			'- va fi menționată în continuare prin referire la termenul “profilare complet automatizată”.',
		'8.2. Folosim datele dumneavoastră personale pentru a lua decizii automate despre dumneavoastră.':
			'8.2. Folosim datele dumneavoastră personale pentru a lua decizii automate despre dumneavoastră.',
		'Deciziile automate': 'Deciziile automate',
		'- pot include "profilarea automată în totalitate" a dumneavoastră':
			'- pot include "profilarea automată în totalitate" a dumneavoastră',
		'- sunt realizate numai de un calculator.':
			'- sunt realizate numai de un calculator.',
		'- sunt făcute fără intervenție umană.':
			'- sunt făcute fără intervenție umană.',
		'- sunt denumite în continuare "procese decizionale complet automatizate".':
			'- sunt denumite în continuare "procese decizionale complet automatizate".',
		'8.3. Deciziile noastre "complet automate" despre dumneavoastră se bazează pe "profilarea automată" a dumneavoastră.':
			'8.3. Deciziile noastre "complet automate" despre dumneavoastră se bazează pe "profilarea automată" a dumneavoastră.',
		'8.4. Ne fundamentăm procesul de “decizie complet automată” despre dumneavoastră pe următoarea logică de prelucrare':
			'8.4. Ne fundamentăm procesul de “decizie complet automată” despre dumneavoastră pe următoarea logică de prelucrare',
		'8.4.1. Daca un cititor accesează articole dintr-o anumită categorie, vom considera că aria sa de interes este legată de acea categorie de articole și vom încerca să personalizăm, în viitor, conținutul pe baza acelei analize.':
			'8.4.1. Daca un cititor accesează articole dintr-o anumită categorie, vom considera că aria sa de interes este legată de acea categorie de articole și vom încerca să personalizăm, în viitor, conținutul pe baza acelei analize.',
		'8.5. Deciziile noastre "complet automate" nu au un impact asupra':
			'8.5. Deciziile noastre "complet automate" nu au un impact asupra',
		'- drepturilor dumneavoastră, sau': '- drepturilor dumneavoastră, sau',
		'- statutului dumneavoastră juridic.':
			'- statutului dumneavoastră juridic.',
		'Deciziile noastre "complet automate" nu au un impact real semnificativ asupra':
			'Deciziile noastre "complet automate" nu au un impact real semnificativ asupra',
		'- circumstanțelor aferente situației dumneavoastră,':
			'- circumstanțelor aferente situației dumneavoastră,',
		'- comportamentului dumneavoastră, sau':
			'- comportamentului dumneavoastră, sau',
		' - alegerilor dumneavoastră.': ' - alegerilor dumneavoastră.',
		'8.6. Temeiul legal pentru prelucrarea noastră referitoare la deciziile "complet automate" referitoare la dumneavoastră este':
			'8.6. Temeiul legal pentru prelucrarea noastră referitoare la deciziile "complet automate" referitoare la dumneavoastră este',
		'8.6.1. o prelucrare în scopul': '8.6.1. o prelucrare în scopul',
		'- intereselor noastre legitime, sau':
			'- intereselor noastre legitime, sau',
		'- intereselor legitime ale unei terțe părți.':
			'- intereselor legitime ale unei terțe părți.',
		'8.7. Datele dumneavoastră personale pe care sistemul nostru se bazează atunci când ia "decizii complet automate" sunt':
			'8.7. Datele dumneavoastră personale pe care sistemul nostru se bazează atunci când ia "decizii complet automate" sunt',
		'8.7.1. date personale “nesensibile”.':
			'8.7.1. date personale “nesensibile”.',
		'8.8. Deciziile noastre “complet automate” nu vă pot afecta.':
			'8.8. Deciziile noastre “complet automate” nu vă pot afecta.',
		'9. Despre scopurile pentru care prelucrăm date personale':
			'9. Despre scopurile pentru care prelucrăm date personale',
		'9.1. Prelucrăm datele dumneavoastră personale pentru scopurile descrise în Secțiunea 3.':
			'9.1. Prelucrăm datele dumneavoastră personale pentru scopurile descrise în Secțiunea 3.',
		'Scopurile noastre, pentru care prelucrăm date personale, sunt':
			'Scopurile noastre, pentru care prelucrăm date personale, sunt',
		'- reale,': '- reale,',
		'- prezente, și': '- prezente, și',
		'- legitime.': '- legitime.',
		'9.2. Nu vă prelucrăm datele dumneavoastră personale în scopuri secundare care sunt incompatibile cu scopurile principale pentru care datele dumneavoastră personale sunt colectate inițial,':
			'9.2. Nu vă prelucrăm datele dumneavoastră personale în scopuri secundare care sunt incompatibile cu scopurile principale pentru care datele dumneavoastră personale sunt colectate inițial,',
		'- fără consimțământul dumneavoastră prealabil,':
			'- fără consimțământul dumneavoastră prealabil,',
		'- fără să existe un interes legitim în acest sens, și':
			'- fără să existe un interes legitim în acest sens, și',
		'- fără un temei juridic.': '- fără un temei juridic.',
		'9.3. Vă informăm înainte de prelucrarea datelor dumneavoastră personale în scopuri secundare':
			'9.3. Vă informăm înainte de prelucrarea datelor dumneavoastră personale în scopuri secundare',
		'- în primul rând, dacă colectăm inițial datele dumneavoastră personale pentru un scop principal și':
			'- în primul rând, dacă colectăm inițial datele dumneavoastră personale pentru un scop principal și',
		'- dacă scopul nostru secundar este incompatibil cu scopul principal.':
			'- dacă scopul nostru secundar este incompatibil cu scopul principal.',
		'10. Cât timp păstrăm datele dumneavoastră personale?':
			'10. Cât timp păstrăm datele dumneavoastră personale?',
		'10.1. Limităm durata de stocare a datelor dumneavoastră personale la ceea ce este necesar pentru scopurile noastre de procesare.':
			'10.1. Limităm durata de stocare a datelor dumneavoastră personale la ceea ce este necesar pentru scopurile noastre de procesare.',
		'10.2. Revizuim necesitatea păstrării în continuare a datelor dumneavoastră personale În fiecare an analizăm datele colectate și prelucrate, în vederea filtrării, sortării și menținerii prelucrării doar pentru datele în cazul cărora scopul prelucrării este actual.':
			'10.2. Revizuim necesitatea păstrării în continuare a datelor dumneavoastră personale În fiecare an analizăm datele colectate și prelucrate, în vederea filtrării, sortării și menținerii prelucrării doar pentru datele în cazul cărora scopul prelucrării este actual.',
		'10.3. Ștergem datele dumneavoastră personale într-un interval de timp specificat Ștergem datele dumneavoastră la un an de la data la care relația dumneavoastră cu noi se încheie (clauza aplicabilă în cazul newsletterelor, de la momentul la care cititorul nu mai accesează conținutul newsletterelor noastre).':
			'10.3. Ștergem datele dumneavoastră personale într-un interval de timp specificat Ștergem datele dumneavoastră la un an de la data la care relația dumneavoastră cu noi se încheie (clauza aplicabilă în cazul newsletterelor, de la momentul la care cititorul nu mai accesează conținutul newsletterelor noastre).',
		'10.4. Ștergem datele dumneavoastră în momentul în care ne solicitați acest lucru, cu excepția datelor a căror furnizare și prelucrare este impusă de o prevedere legală, pe care le ștergem în termenul prevăzut de lege pentru aceasta.':
			'10.4. Ștergem datele dumneavoastră în momentul în care ne solicitați acest lucru, cu excepția datelor a căror furnizare și prelucrare este impusă de o prevedere legală, pe care le ștergem în termenul prevăzut de lege pentru aceasta.',
		'10.4.1. În cazul în care păstrarea datelor dumneavoastră personale este necesară în scopurile specificate de lege, putem păstra în continuare datele dumneavoastră personale.':
			'10.4.1. În cazul în care păstrarea datelor dumneavoastră personale este necesară în scopurile specificate de lege, putem păstra în continuare datele dumneavoastră personale.',
		'11. Dezvăluim datele dumneavoastră personale?':
			'11. Dezvăluim datele dumneavoastră personale?',
		'11.1. Dezvăluim datele dumneavoastră personale unor destinatari.':
			'11.1. Dezvăluim datele dumneavoastră personale unor destinatari.',
		'11.2. Încadrarea legală pe care se bazează divulgarea datelor dumneavoastră personale către destinatari este':
			'11.2. Încadrarea legală pe care se bazează divulgarea datelor dumneavoastră personale către destinatari este',
		'11.2.1. consimțământul dumneavoastră.':
			'11.2.1. consimțământul dumneavoastră.',
		'11.3. Dacă în viitor vom dezvălui datele dumneavoastră personale unui destinatar, atunci vă vom informa asupra':
			'11.3. Dacă în viitor vom dezvălui datele dumneavoastră personale unui destinatar, atunci vă vom informa asupra',
		'- momentului dezvăluirii și': '- momentului dezvăluirii și',
		'- numelui destinatarilor.': '- numelui destinatarilor.',
		'12. Transferăm datele dumneavoastră personale în afara UE sau SEE?':
			'12. Transferăm datele dumneavoastră personale în afara UE sau SEE?',
		'12.1. Nu vă transferăm datele personale':
			'12.1. Nu vă transferăm datele personale',
		'- în țări din afara UE sau SEE, sau':
			'- în țări din afara UE sau SEE, sau',
		'- către organizații internaționale.':
			'- către organizații internaționale.',
		'13. Sunt datele dumneavoastră personale în siguranță?':
			'13. Sunt datele dumneavoastră personale în siguranță?',
		'13.1. Îți ținem în siguranță datele personale':
			'13.1. Îți ținem în siguranță datele personale',
		'- cu măsuri tehnice corespunzătoare,':
			'- cu măsuri tehnice corespunzătoare,',
		'- cu măsuri organizatorice adecvate,':
			'- cu măsuri organizatorice adecvate,',
		'- cu un nivel adecvat de securitate,':
			'- cu un nivel adecvat de securitate,',
		'- împotriva prelucrării neautorizate,':
			'- împotriva prelucrării neautorizate,',
		'- împotriva prelucrării ilegale,': '- împotriva prelucrării ilegale,',
		'- împotriva pierderii accidentale sau ilegale,':
			'- împotriva pierderii accidentale sau ilegale,',
		'- împotriva distrugerii accidentale sau ilegale,':
			'- împotriva distrugerii accidentale sau ilegale,',
		'- împotriva daunelor accidentale sau ilegale.':
			'- împotriva daunelor accidentale sau ilegale.',
		'13.2. Am implementat măsuri pentru a':
			'13.2. Am implementat măsuri pentru a',
		'13.2.1. descoperi breșe de securitate.':
			'13.2.1. descoperi breșe de securitate.',
		'13.2.2. documenta cauzele incidentului de securitate.':
			'13.2.2. documenta cauzele incidentului de securitate.',
		'13.2.3. documenta care date personale sunt afectate de incidentul de securitate.':
			'13.2.3. documenta care date personale sunt afectate de incidentul de securitate.',
		'13.2.4. documenta acțiunile (și motivele acțiunilor) pentru a remedia încălcarea securității.':
			'13.2.4. documenta acțiunile (și motivele acțiunilor) pentru a remedia încălcarea securității.',
		'13.2.5. limita consecințele incidentului de securitate.':
			'13.2.5. limita consecințele incidentului de securitate.',
		'13.2.6. recupera date personale.': '13.2.6. recupera date personale.',
		'13.2.7. reveni la o stare normală de prelucrare a datelor cu caracter personal.':
			'13.2.7. reveni la o stare normală de prelucrare a datelor cu caracter personal.',
		'13.3. Dacă avem un grad de certitudine rezonabil că s-a produs o încălcare a securității prelucrării datelor dumneavoastră personale, atunci':
			'13.3. Dacă avem un grad de certitudine rezonabil că s-a produs o încălcare a securității prelucrării datelor dumneavoastră personale, atunci',
		'13.3.1. raportăm incidentul de securitate catre managementul companiei noastre.':
			'13.3.1. raportăm incidentul de securitate catre managementul companiei noastre.',
		'13.3.2. desemnăm o persoana responsabilă pentru':
			'13.3.2. desemnăm o persoana responsabilă pentru',
		'- a analiza dacă încălcarea securității poate avea efecte nefavorabile pentru dumneavoastră,':
			'- a analiza dacă încălcarea securității poate avea efecte nefavorabile pentru dumneavoastră,',
		'- a informa personalul relevant din organizația noastră,':
			'- a informa personalul relevant din organizația noastră,',
		'- a determina în ce măsură este necesară notificarea Autorității de Supraveghere cu privire la incidentul de securitate, și':
			'- a determina în ce măsură este necesară notificarea Autorității de Supraveghere cu privire la incidentul de securitate, și',
		'- a stabili dacă este necesar să vă comunicăm informații despre incidentul de securitate.':
			'- a stabili dacă este necesar să vă comunicăm informații despre incidentul de securitate.',
		'13.3.3. investigăm incidentul de securitate.':
			'13.3.3. investigăm incidentul de securitate.',
		'13.3.4. încercăm să împiedicăm incidentul de securitate să ducă la':
			'13.3.4. încercăm să împiedicăm incidentul de securitate să ducă la',
		'- distrugerea accidentală sau ilegală a datelor personale,':
			'- distrugerea accidentală sau ilegală a datelor personale,',
		'- pierderea accidentală sau ilegală a controlului datelor cu caracter personal,':
			'- pierderea accidentală sau ilegală a controlului datelor cu caracter personal,',
		'- pierderea accidentală sau ilegală a accesului la datele cu caracter personal,':
			'- pierderea accidentală sau ilegală a accesului la datele cu caracter personal,',
		'- alterarea accidentală sau ilegală a datelor cu caracter personal,':
			'- alterarea accidentală sau ilegală a datelor cu caracter personal,',
		'- divulgarea neautorizată a datelor cu caracter personal, sau':
			'- divulgarea neautorizată a datelor cu caracter personal, sau',
		'- acces neautorizat la datele cu caracter personal.':
			'- acces neautorizat la datele cu caracter personal.',
		'13.3.5. depunem toate eforturile pentru atenuarea riscului imediat de producere a unui prejudiciu.':
			'13.3.5. depunem toate eforturile pentru atenuarea riscului imediat de producere a unui prejudiciu.',
		'13.3.6. notificăm Autoritatea de Supraveghere cu privire la incidentul de securitate, daca încălcarea este susceptibilă să ducă la un risc ridicat pentru drepturile și libertățile dumneavoastră.':
			'13.3.6. notificăm Autoritatea de Supraveghere cu privire la incidentul de securitate, daca încălcarea este susceptibilă să ducă la un risc ridicat pentru drepturile și libertățile dumneavoastră.',
		'13.3.7. vă informăm despre încălcarea securității':
			'13.3.7. vă informăm despre încălcarea securității',
		'- dacă încălcarea este susceptibilă să ducă la un risc ridicat pentru drepturile și libertățile dumneavoastră,':
			'- dacă încălcarea este susceptibilă să ducă la un risc ridicat pentru drepturile și libertățile dumneavoastră,',
		'- cât mai repede posibil,': '- cât mai repede posibil,',
		'- prin canale de contact adecvate, de ex. prin e-mail, SMS, bannere proeminente pe site-ul nostru, comunicări poștale, reclame proeminente în mass-media etc.':
			'- prin canale de contact adecvate, de ex. prin e-mail, SMS, bannere proeminente pe site-ul nostru, comunicări poștale, reclame proeminente în mass-media etc.',
		'- Nu suntem obligați să vă informăm direct dacă':
			'- Nu suntem obligați să vă informăm direct dacă',
		'- am luat măsuri pentru a face ca datele dvs. personale să fie incomprehensibile oricărei persoane care nu este autorizată să le acceseze,':
			'- am luat măsuri pentru a face ca datele dvs. personale să fie incomprehensibile oricărei persoane care nu este autorizată să le acceseze,',
		'- imediat după incidentul de securitate, am luat măsuri pentru a ne asigura că riscul ridicat pentru drepturile și libertățile dumneavoastră nu mai este posibil să se producă sau':
			'- imediat după incidentul de securitate, am luat măsuri pentru a ne asigura că riscul ridicat pentru drepturile și libertățile dumneavoastră nu mai este posibil să se producă sau',
		'- ar implica eforturi disproporționate. Într-un astfel de caz, vă vom informa prin intermediul rețelelor publice.':
			'- ar implica eforturi disproporționate. Într-un astfel de caz, vă vom informa prin intermediul rețelelor publice.',
		'14. Care sunt drepturile dumneavoastră?':
			'14. Care sunt drepturile dumneavoastră?',
		'14.1. Vă respectăm drepturile care privesc protecția datelor dumneavoastră personale.':
			'14.1. Vă respectăm drepturile care privesc protecția datelor dumneavoastră personale.',
		'14.2. Aveți dreptul de acces la datele dumneavoastră personale.':
			'14.2. Aveți dreptul de acces la datele dumneavoastră personale.',
		'În cazul în care solicitați să confirmăm dacă prelucrăm sau nu datele dumneavoastră personale, atunci aveți un drept care ne obligă să confirmăm că noi':
			'În cazul în care solicitați să confirmăm dacă prelucrăm sau nu datele dumneavoastră personale, atunci aveți un drept care ne obligă să confirmăm că noi',
		'- prelucrăm datele dumneavoastră personale sau':
			'- prelucrăm datele dumneavoastră personale sau',
		'- nu prelucrăm datele dumneavoastră personale.':
			'- nu prelucrăm datele dumneavoastră personale.',
		'Dreptul dumneavoastră de a obține confirmarea de la noi că prelucrăm (sau nu prelucrăm) datele dumneavoastră personale':
			'Dreptul dumneavoastră de a obține confirmarea de la noi că prelucrăm (sau nu prelucrăm) datele dumneavoastră personale',
		'- nu include date anonime.': '- nu include date anonime.',
		'- include doar datele personale care vă privesc.':
			'- include doar datele personale care vă privesc.',
		'- include date pseudonime care pot fi în mod clar legate de dumneavoastră.':
			'- include date pseudonime care pot fi în mod clar legate de dumneavoastră.',
		'Trebuie să vă dăm acces la datele dumneavoastră personale dacă':
			'Trebuie să vă dăm acces la datele dumneavoastră personale dacă',
		'- solicitați să confirmăm dacă prelucrăm sau nu datele dumneavoastră personale și':
			'- solicitați să confirmăm dacă prelucrăm sau nu datele dumneavoastră personale și',
		'- prelucrăm datele dumneavoastră personale și':
			'- prelucrăm datele dumneavoastră personale și',
		'- solicitați accesul la datele dumneavoastră personale.':
			'- solicitați accesul la datele dumneavoastră personale.',
		'Trebuie să vă furnizăm o copie a datelor dumneavoastră personale dacă':
			'Trebuie să vă furnizăm o copie a datelor dumneavoastră personale dacă',
		'- solicitați o copie a datelor dumneavoastră personale.':
			'- solicitați o copie a datelor dumneavoastră personale.',
		'Dacă solicitați copii suplimentare ale datelor dumneavoastră personale, atunci vă putem percepe o taxă rezonabilă, care se bazează pe costurile administrative necesare pentru îndeplinirea cererii.':
			'Dacă solicitați copii suplimentare ale datelor dumneavoastră personale, atunci vă putem percepe o taxă rezonabilă, care se bazează pe costurile administrative necesare pentru îndeplinirea cererii.',
		'Aveți dreptul la informațiile privind garanțiile pe care le-am implementat pentru transferul datelor dumneavoastră personale către o țară care este în afara UE și SEE dacă':
			'Aveți dreptul la informațiile privind garanțiile pe care le-am implementat pentru transferul datelor dumneavoastră personale către o țară care este în afara UE și SEE dacă',
		'- vă transferăm datele dumneavoastră personale într-o țară care este în afara UE și SEE.':
			'- vă transferăm datele dumneavoastră personale într-o țară care este în afara UE și SEE.',
		'14.3. Aveți dreptul la rectificarea datelor dumneavoastră personale.':
			'14.3. Aveți dreptul la rectificarea datelor dumneavoastră personale.',
		'Dreptul de a obține rectificarea datelor dumneavoastră cu caracter personal care sunt inexacte':
			'Dreptul de a obține rectificarea datelor dumneavoastră cu caracter personal care sunt inexacte',
		'- include date pseudonime care pot fi în mod clar legate de dumneavoastră':
			'- include date pseudonime care pot fi în mod clar legate de dumneavoastră',
		'Trebuie să rectificăm datele dumneavoastră personale dacă':
			'Trebuie să rectificăm datele dumneavoastră personale dacă',
		'- prelucrăm datele dumneavoastră personale, și':
			'- prelucrăm datele dumneavoastră personale, și',
		'- datele dumneavoastră personale sunt inexacte, și':
			'- datele dumneavoastră personale sunt inexacte, și',
		'- solicitați să obțineți rectificarea datelor dumneavoastră personale.':
			'- solicitați să obțineți rectificarea datelor dumneavoastră personale.',
		'Trebuie să completăm datele dumneavoastră personale dacă':
			'Trebuie să completăm datele dumneavoastră personale dacă',
		'- datele dumneavoastră personale sunt incomplete, și':
			'- datele dumneavoastră personale sunt incomplete, și',
		'- solicitați să obțineți completarea datelor dumneavoastră personale.':
			'- solicitați să obțineți completarea datelor dumneavoastră personale.',
		'Aveți dreptul să ne furnizați o declarație suplimentară.':
			'Aveți dreptul să ne furnizați o declarație suplimentară.',
		'Trebuie să comunicăm rectificarea datelor dumneavoastră personale destinatarilor datelor dumneavoastră personale (dacă există).':
			'Trebuie să comunicăm rectificarea datelor dumneavoastră personale destinatarilor datelor dumneavoastră personale (dacă există).',
		'Nu comunicăm rectificarea datelor dumneavoastră personale destinatarilor datelor dumneavoastră personale în cazul în care comunicarea către destinatar':
			'Nu comunicăm rectificarea datelor dumneavoastră personale destinatarilor datelor dumneavoastră personale în cazul în care comunicarea către destinatar',
		'- este imposibilă, sau': '- este imposibilă, sau',
		'- implică un efort disproporționat.':
			'- implică un efort disproporționat.',
		'14.4. Aveți dreptul la ștergerea datelor dumneavoastră personale.':
			'14.4. Aveți dreptul la ștergerea datelor dumneavoastră personale.',
		'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate, dacă':
			'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate, dacă',
		'- solicitați ștergerea datelor dumneavoastră personale, și':
			'- solicitați ștergerea datelor dumneavoastră personale, și',
		'- datele dumneavoastră personale nu sunt necesare scopurilor noastre de prelucrare a datelor dumneavoastră personale.':
			'- datele dumneavoastră personale nu sunt necesare scopurilor noastre de prelucrare a datelor dumneavoastră personale.',
		'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate dacă':
			'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate dacă',
		'- vă retrageți consimțământul pe care se bazează prelucrarea datelor dumneavoastră personale, și':
			'- vă retrageți consimțământul pe care se bazează prelucrarea datelor dumneavoastră personale, și',
		'- nu există un alt temei juridic pentru prelucrarea datelor dumneavoastră personale.':
			'- nu există un alt temei juridic pentru prelucrarea datelor dumneavoastră personale.',
		'- solicitați să obțineți ștergerea datelor dumneavoastră personale, și':
			'- solicitați să obțineți ștergerea datelor dumneavoastră personale, și',
		'- prelucrarea datelor dumneavoastră personale este necesară pentru îndeplinirea unei sarcini pe care o îndeplinim în interes public, sau':
			'- prelucrarea datelor dumneavoastră personale este necesară pentru îndeplinirea unei sarcini pe care o îndeplinim în interes public, sau',
		'- prelucrarea datelor dumneavoastră personale este necesară în cadrul exercitării unei autorități oficiale cu care este învestit, și':
			'- prelucrarea datelor dumneavoastră personale este necesară în cadrul exercitării unei autorități oficiale cu care este învestit, și',
		'- prelucrarea este necesară în scopul intereselor legitime pe care le urmărim, sau':
			'- prelucrarea este necesară în scopul intereselor legitime pe care le urmărim, sau',
		'- prelucrarea este necesară în scopul intereselor legitime pe care o terță parte le urmărește, și':
			'- prelucrarea este necesară în scopul intereselor legitime pe care o terță parte le urmărește, și',
		'- obiectați la prelucrarea de către noi a datelor dumneavoastră personale, și':
			'- obiectați la prelucrarea de către noi a datelor dumneavoastră personale, și',
		'- prelucrarea datelor dumneavoastră cu caracter personal are un motiv legitim care nu prevalează asupra obiecției dumneavoastră':
			'- prelucrarea datelor dumneavoastră cu caracter personal are un motiv legitim care nu prevalează asupra obiecției dumneavoastră',
		'- solicitați ștergerea datelor dumneavoastră personale și':
			'- solicitați ștergerea datelor dumneavoastră personale și',
		'- contestați prelucrarea de către noi a datelor dumneavoastră personale în scop de marketing direct, și':
			'- contestați prelucrarea de către noi a datelor dumneavoastră personale în scop de marketing direct, și',
		'- prelucrarea datelor dumneavoastră personale este ilegală.':
			'- prelucrarea datelor dumneavoastră personale este ilegală.',
		'- datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale care ne revine în temeiul dreptului Uniunii sau al dreptului intern al unui stat membru.':
			'- datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale care ne revine în temeiul dreptului Uniunii sau al dreptului intern al unui stat membru.',
		'- datele dumneavoastră personale au fost colectate în legătură cu oferirea de servicii ale societății informaționale.':
			'- datele dumneavoastră personale au fost colectate în legătură cu oferirea de servicii ale societății informaționale.',
		'Trebuie să comunicăm ștergerea datelor dumneavoastră personale destinatarilor către care le dezvăluim (dacă există).':
			'Trebuie să comunicăm ștergerea datelor dumneavoastră personale destinatarilor către care le dezvăluim (dacă există).',
		'Nu comunicăm ștergerea datelor dumneavoastră personale destinatarilor către care le dezvăluim, în cazul în care comunicarea către destinatar':
			'Nu comunicăm ștergerea datelor dumneavoastră personale destinatarilor către care le dezvăluim, în cazul în care comunicarea către destinatar',
		'14.5. Aveți dreptul să obțineți de la noi restricționarea prelucrării datelor dumneavoastră personale.':
			'14.5. Aveți dreptul să obțineți de la noi restricționarea prelucrării datelor dumneavoastră personale.',
		'Dreptul dumneavoastră de a obține restricții privind prelucrarea datelor dumneavoastră personale':
			'Dreptul dumneavoastră de a obține restricții privind prelucrarea datelor dumneavoastră personale',
		'- include datele personale care vă privesc.':
			'- include datele personale care vă privesc.',
		'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale pentru o perioadă, în scopul de a verifica exactitatea datelor dumneavoastră personale dacă':
			'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale pentru o perioadă, în scopul de a verifica exactitatea datelor dumneavoastră personale dacă',
		'- solicitați să obțineți restricționarea prelucrării datelor dumneavoastră personale și':
			'- solicitați să obțineți restricționarea prelucrării datelor dumneavoastră personale și',
		'- contestați exactitatea datelor dumneavoastră personale.':
			'- contestați exactitatea datelor dumneavoastră personale.',
		'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale dacă':
			'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale dacă',
		'- prelucrarea datelor dumneavoastră personale este ilegală și':
			'- prelucrarea datelor dumneavoastră personale este ilegală și',
		'- vă opuneți ștergerii datelor dumneavoastră personale.':
			'- vă opuneți ștergerii datelor dumneavoastră personale.',
		'- nu avem nevoie de datele dumneavoastră personale în scopul prelucrării noastre și':
			'- nu avem nevoie de datele dumneavoastră personale în scopul prelucrării noastre și',
		'- solicitați datele dumneavoastră personale pentru a stabili o reclamație legală sau':
			'- solicitați datele dumneavoastră personale pentru a stabili o reclamație legală sau',
		'- solicitați datele dumneavoastră personale pentru a vă exercita o plângere legală sau':
			'- solicitați datele dumneavoastră personale pentru a vă exercita o plângere legală sau',
		'- aveți nevoie de datele dumneavoastră personale pentru a vă apăra împotriva unei plângeri legale.':
			'- aveți nevoie de datele dumneavoastră personale pentru a vă apăra împotriva unei plângeri legale.',
		'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale dacă solicitați să obțineți restricționarea':
			'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale dacă solicitați să obțineți restricționarea',
		'- prelucrării datelor dumneavoastră personale și':
			'- prelucrării datelor dumneavoastră personale și',
		'- obiectați de prelucrarea datelor dumneavoastră personale care sunt necesare pentru îndeplinirea unei sarcini pe care o îndeplinim în interes public, sau':
			'- obiectați de prelucrarea datelor dumneavoastră personale care sunt necesare pentru îndeplinirea unei sarcini pe care o îndeplinim în interes public, sau',
		'- vă opuneți prelucrării datelor dumneavoastră personale care sunt necesare în exercitarea unei autorități oficiale care ne este încredințată și':
			'- vă opuneți prelucrării datelor dumneavoastră personale care sunt necesare în exercitarea unei autorități oficiale care ne este încredințată și',
		'- vă opuneți prelucrării datelor dumneavoastră personale care sunt necesare în scopul intereselor legitime pe care le urmărim și':
			'- vă opuneți prelucrării datelor dumneavoastră personale care sunt necesare în scopul intereselor legitime pe care le urmărim și',
		'- așteptați să verificați dacă prelucrarea datelor dumneavoastră personale are un motiv legitim care nu depășește obiecția dumneavoastră':
			'- așteptați să verificați dacă prelucrarea datelor dumneavoastră personale are un motiv legitim care nu depășește obiecția dumneavoastră',
		'Trebuie să comunicăm restricționarea prelucrării datelor dumneavoastră personale către destinatarii datelor dumneavoastră personale (dacă există).':
			'Trebuie să comunicăm restricționarea prelucrării datelor dumneavoastră personale către destinatarii datelor dumneavoastră personale (dacă există).',
		'Nu comunicăm restricționarea prelucrării datelor dumneavoastră personale către destinatarii datelor dumneavoastră personale în cazul în care comunicarea către destinatar':
			'Nu comunicăm restricționarea prelucrării datelor dumneavoastră personale către destinatarii datelor dumneavoastră personale în cazul în care comunicarea către destinatar',
		'- este imposibilă sau': '- este imposibilă sau',
		'Dacă restricționăm prelucrarea datelor dumneavoastră personale, atunci putem':
			'Dacă restricționăm prelucrarea datelor dumneavoastră personale, atunci putem',
		'- stoca datele dumneavoastră personale,':
			'- stoca datele dumneavoastră personale,',
		'- prelucra datele dumneavoastră personale pe baza consimțământului dumneavoastră,':
			'- prelucra datele dumneavoastră personale pe baza consimțământului dumneavoastră,',
		'- prelucra datele dumneavoastră personale pentru a întemeia o reclamație legală,':
			'- prelucra datele dumneavoastră personale pentru a întemeia o reclamație legală,',
		'- prelucra datele dumneavoastră personale pentru a exercita o plângere legală,':
			'- prelucra datele dumneavoastră personale pentru a exercita o plângere legală,',
		'- prelucra datele dumneavoastră personale pentru a ne apăra împotriva unei plângeri legale,':
			'- prelucra datele dumneavoastră personale pentru a ne apăra împotriva unei plângeri legale,',
		'- prelucra datele dumneavoastră personale pentru a proteja drepturile unei persoane,':
			'- prelucra datele dumneavoastră personale pentru a proteja drepturile unei persoane,',
		'- prelucra datele dumneavoastră personale din motive de interes public ale Uniunii sau ale unui stat membru.':
			'- prelucra datele dumneavoastră personale din motive de interes public ale Uniunii sau ale unui stat membru.',
		'Dacă obțineți o restricționare privind prelucrarea datelor dumneavoastră personale, trebuie să vă informăm înainte de ridicarea restricționării.':
			'Dacă obțineți o restricționare privind prelucrarea datelor dumneavoastră personale, trebuie să vă informăm înainte de ridicarea restricționării.',
		'14.6. Dacă prelucrăm datele dumneavoastră personale în scopuri de marketing direct, inclusiv profilarea (în măsura în care este legată de un astfel de marketing direct), aveți dreptul să obiectați cu privire la prelucrarea datelor dumneavoastră personale în acest scop.':
			'14.6. Dacă prelucrăm datele dumneavoastră personale în scopuri de marketing direct, inclusiv profilarea (în măsura în care este legată de un astfel de marketing direct), aveți dreptul să obiectați cu privire la prelucrarea datelor dumneavoastră personale în acest scop.',

		'Dreptul dumneavoastră de a obiecta la prelucrarea datelor dumneavoastră personale în scopuri de marketing direct':
			'Dreptul dumneavoastră de a obiecta la prelucrarea datelor dumneavoastră personale în scopuri de marketing direct',
		'- este un drept pe care îl aveți în orice moment.':
			'- este un drept pe care îl aveți în orice moment.',
		'- nu include date personale care nu vă privesc.':
			'- nu include date personale care nu vă privesc.',
		'Dacă obiectați la prelucrarea datelor dumneavoastră personale în scopuri de marketing direct, atunci trebuie să omitem prelucrarea datelor dumneavoastră personale în acest scop.':
			'Dacă obiectați la prelucrarea datelor dumneavoastră personale în scopuri de marketing direct, atunci trebuie să omitem prelucrarea datelor dumneavoastră personale în acest scop.',
		'Dacă vă procesăm datele dumneavoastră personale în scopuri de marketing direct, inclusiv profilarea (în măsura în care este legată de un astfel de marketing direct), atunci':
			'Dacă vă procesăm datele dumneavoastră personale în scopuri de marketing direct, inclusiv profilarea (în măsura în care este legată de un astfel de marketing direct), atunci',
		'- trebuie să vă aducem la cunoștință, în mod explicit, acest drept, cel mai târziu la momentul primei comunicări cu dumneavoastră și':
			'- trebuie să vă aducem la cunoștință, în mod explicit, acest drept, cel mai târziu la momentul primei comunicări cu dumneavoastră și',
		'- trebuie să vă prezentăm acest drept în mod clar și separat de orice altă informație.':
			'- trebuie să vă prezentăm acest drept în mod clar și separat de orice altă informație.',
		'15. Cum vă puteți exercita drepturile?':
			'15. Cum vă puteți exercita drepturile?',
		'15.1. Vă invităm să comunicați cu noi despre exercitarea drepturilor dumneavoastră privind protecția datelor dumneavoastră personale.':
			'15.1. Vă invităm să comunicați cu noi despre exercitarea drepturilor dumneavoastră privind protecția datelor dumneavoastră personale.',
		'15.2. Acceptăm numai solicitări scrise, deoarece nu putem face față solicitărilor verbale imediat, fără să':
			'15.2. Acceptăm numai solicitări scrise, deoarece nu putem face față solicitărilor verbale imediat, fără să',
		'- analizăm mai întâi conținutului cererii și fără să':
			'- analizăm mai întâi conținutului cererii și fără să',
		'- vă identificăm mai întâi.': '- vă identificăm mai întâi.',
		'Cererea dumneavoastră trebuie să conțină o descriere detaliată și precisă a dreptului pe care doriți să-l exercitați.':
			'Cererea dumneavoastră trebuie să conțină o descriere detaliată și precisă a dreptului pe care doriți să-l exercitați.',
		'Trebuie să ne furnizați o copie a unui document de identificare pentru a vă confirma identitatea ca, de exemplu':
			'Trebuie să ne furnizați o copie a unui document de identificare pentru a vă confirma identitatea ca, de exemplu',
		'- un carnet de identitate sau': '- un carnet de identitate sau',
		'- un pașaport.': '- un pașaport.',
		'Documentul trebuie să conțină': 'Documentul trebuie să conțină',
		'- un număr de identificare,': '- un număr de identificare,',
		'- țara de emisiune,': '- țara de emisiune,',
		'- perioada de valabilitate,': '- perioada de valabilitate,',
		'- numele dumneavoastră,': '- numele dumneavoastră,',
		'- adresa dumneavoastră, și': '- adresa dumneavoastră, și',
		'- data dumneavoastră de naștere.': '- data dumneavoastră de naștere.',
		'Orice alte date conținute în copia documentului de identificare, cum ar fi o fotografie sau orice alte caracteristici personale, pot fi mascate.':
			'Orice alte date conținute în copia documentului de identificare, cum ar fi o fotografie sau orice alte caracteristici personale, pot fi mascate.',
		'Nu vom accepta alte mijloace de a vă asigura identitatea.':
			'Nu vom accepta alte mijloace de a vă asigura identitatea.',
		'Dacă doriți să propuneți alternative, le vom evalua de la caz la caz.':
			'Dacă doriți să propuneți alternative, le vom evalua de la caz la caz.',
		'Folosirea informațiilor din documentul dumneavoastră de identificare':
			'Folosirea informațiilor din documentul dumneavoastră de identificare',
		'- este limitată la activitatea de confirmare a identității dumneavoastră și':
			'- este limitată la activitatea de confirmare a identității dumneavoastră și',
		'- nu va genera o stocare a datelor dumneavoastră personale mai mult decât este necesar în acest scop.':
			'- nu va genera o stocare a datelor dumneavoastră personale mai mult decât este necesar în acest scop.',
		'Puteți trimite solicitarea dumneavoastră referitoare la protecția datelor dumneavoastră personale la':
			'Puteți trimite solicitarea dumneavoastră referitoare la protecția datelor dumneavoastră personale la',
		'15.2.1 email contact@cv30.co': '15.2.1 email contact@cv30.co',
		'15.3. Veți primi răspunsul nostru la cererile dumneavoastră care vizează protecția datelor dumneavoastră personale la adresa de pe care am primit solicitarea.':
			'15.3. Veți primi răspunsul nostru la cererile dumneavoastră care vizează protecția datelor dumneavoastră personale la adresa de pe care am primit solicitarea.',
		'15.4. Am desemnat o persoană responsabilă de tratarea cererilor dumneavoastră referitoare la protecția datelor dumneavoastră personale.':
			'15.4. Am desemnat o persoană responsabilă de tratarea cererilor dumneavoastră referitoare la protecția datelor dumneavoastră personale.',
		'15.5. Am implementat politici care ne asigură faptul că o cerere privind protecția datelor dumneavoastră personale este':
			'15.5. Am implementat politici care ne asigură faptul că o cerere privind protecția datelor dumneavoastră personale este',
		'- recunoscută, și': '- recunoscută, și',
		'- soluționată în termenele prevăzute de lege.':
			'- soluționată în termenele prevăzute de lege.',
		'15.6. Vă informăm despre modul în care ne ocupăm de cererea dumneavoastră (prin care vă exercitați drepturile) cu privire la protecția datelor dumneavoastră personale':
			'15.6. Vă informăm despre modul în care ne ocupăm de cererea dumneavoastră (prin care vă exercitați drepturile) cu privire la protecția datelor dumneavoastră personale',
		'15.6.1 intr-o lună de la data primirii cererii.':
			'15.6.1 intr-o lună de la data primirii cererii.',
		'16. Aveți dreptul de a depune o plângere?':
			'16. Aveți dreptul de a depune o plângere?',
		'16.1. Puteți depune o plângere la o autoritate de supraveghere':
			'16.1. Puteți depune o plângere la o autoritate de supraveghere',
		'- la domiciliul dumneavoastră obișnuit în UE și SEE.':
			'- la domiciliul dumneavoastră obișnuit în UE și SEE.',
		'- la locul dumneavoastră de muncă în UE și SEE.':
			'- la locul dumneavoastră de muncă în UE și SEE.',
		'- la locul presupusei încălcări în UE și în SEE.':
			'- la locul presupusei încălcări în UE și în SEE.',
		'Autoritatea de supraveghere trebuie să vă informeze într-un termen rezonabil cu privire la':
			'Autoritatea de supraveghere trebuie să vă informeze într-un termen rezonabil cu privire la',
		'- progresul plângerii și': '- progresul plângerii și',
		'- rezultatul plângerii.': '- rezultatul plângerii.',
		'16.2. Puteți mandata o organizație să depună o plângere în numele dumneavoastră la o autoritate de supraveghere.':
			'16.2. Puteți mandata o organizație să depună o plângere în numele dumneavoastră la o autoritate de supraveghere.',
		'Autoritatea de supraveghere trebuie să vă informeze într-un termen rezonabil cu privre la':
			'Autoritatea de supraveghere trebuie să vă informeze într-un termen rezonabil cu privre la',
		'16.3. Aveți dreptul de a exercita o cale de atac judiciară în UE și în SEE împotriva':
			'16.3. Aveți dreptul de a exercita o cale de atac judiciară în UE și în SEE împotriva',
		'- unui operator,': '- unui operator,',
		'- unui împuternicit și': '- unui împuternicit și',
		'- unei Autorități de Supraveghere.':
			'- unei Autorități de Supraveghere.',
		'16.4. Puteti mandata o organizație să exercite, în numele dumneavoastră, dreptul':
			'16.4. Puteti mandata o organizație să exercite, în numele dumneavoastră, dreptul',
		'- la o cale de atac judiciară.': '- la o cale de atac judiciară.',
		'- la o despăgubire pentru un prejudiciu rezultat ca urmare a unei încălcări a legii privind protecția datelor cu caracter personal.':
			'- la o despăgubire pentru un prejudiciu rezultat ca urmare a unei încălcări a legii privind protecția datelor cu caracter personal.',
		'17. Puteți alege setările de confidențialitate?':
			'17. Puteți alege setările de confidențialitate?',
		'17.1. În prezent, nu ne puteți declara alegerea dumneavoastră privind prelucrarea datelor dumneavoastră personale.':
			'17.1. În prezent, nu ne puteți declara alegerea dumneavoastră privind prelucrarea datelor dumneavoastră personale.',
		'18. Veți fi informat despre schimbările politicii de confidențialitate?':
			'18. Veți fi informat despre schimbările politicii de confidențialitate?',
		'18.1. Dacă ne schimbăm politica de confidențialitate, vom publica o nouă versiune a acesteia.':
			'18.1. Dacă ne schimbăm politica de confidențialitate, vom publica o nouă versiune a acesteia.',
		'18.2. Nu punem la dispoziție versiunile anterioare ale politicii noastre de confidențialitate.':
			'18.2. Nu punem la dispoziție versiunile anterioare ale politicii noastre de confidențialitate.',
		'19. Explicații cu privire la termenii și expresiile utilizați în această notă de informare':
			'19. Explicații cu privire la termenii și expresiile utilizați în această notă de informare',
		'19.1. Toţi termenii şi expresiile folosiți în această Notă de Informare vor avea înţelesul dat mai jos, cu excepţia cazului în care se prevede altfel în cuprinsul Notei de Informare.':
			'19.1. Toţi termenii şi expresiile folosiți în această Notă de Informare vor avea înţelesul dat mai jos, cu excepţia cazului în care se prevede altfel în cuprinsul Notei de Informare.',
		'19.1.1. Date cu caracter personal înseamnă orice informații privind o persoană fizică identificată sau identificabilă („persoana vizată”).':
			'19.1.1. Date cu caracter personal înseamnă orice informații privind o persoană fizică identificată sau identificabilă („persoana vizată”).',
		'O persoană fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, în special prin referire la un element de identificare, cum ar fi':
			'O persoană fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, în special prin referire la un element de identificare, cum ar fi',
		'- un nume': '- un nume',
		'- un număr de identificare': '- un număr de identificare',
		'- date de localizare': '- date de localizare',
		'- un identificator online': '- un identificator online',
		'- identitatea fizică a unei persoane persoane fizice':
			'- identitatea fizică a unei persoane persoane fizice',
		'- identitatea fiziologică a unei persoane persoane fizice':
			'- identitatea fiziologică a unei persoane persoane fizice',
		'- identitatea genetică a unei persoane persoane fizice':
			'- identitatea genetică a unei persoane persoane fizice',
		'- identitatea psihică a unei persoane persoane fizice':
			'- identitatea psihică a unei persoane persoane fizice',
		'- identitatea economică a unei persoane persoane fizice':
			'- identitatea economică a unei persoane persoane fizice',
		'- identitatea culturală a unei persoane persoane fizice':
			'- identitatea culturală a unei persoane persoane fizice',
		'- identitatea socială a unei persoane persoane fizice':
			'- identitatea socială a unei persoane persoane fizice',
		'19.1.2. Datele personale sensibile sunt - conform GDPR - numite categorii speciale de date personale.':
			'19.1.2. Datele personale sensibile sunt - conform GDPR - numite categorii speciale de date personale.',
		'Datele personale sunt sensibile dacă prelucrarea acestor date personale dezvăluie':
			'Datele personale sunt sensibile dacă prelucrarea acestor date personale dezvăluie',
		'- originea rasială,': '- originea rasială,',
		'- originea etnică,': '- originea etnică,',
		'- opiniile politice,': '- opiniile politice,',
		'- credințele religioase,': '- credințele religioase,',
		'- credințele filosofice,': '- credințele filosofice,',
		'- apartenența la un sindicat.': '- apartenența la un sindicat.',
		'De asemenea, datele personale sunt sensibile dacă':
			'De asemenea, datele personale sunt sensibile dacă',
		'- datele genetice sunt prelucrate pentru scopul de a identifica unic o persoană fizică,':
			'- datele genetice sunt prelucrate pentru scopul de a identifica unic o persoană fizică,',
		'- datele biometrice sunt prelucrate pentru scopul de a identifica unic o persoană fizică.':
			'- datele biometrice sunt prelucrate pentru scopul de a identifica unic o persoană fizică.',
		'Datele personale sensibile mai includ':
			'Datele personale sensibile mai includ',
		'- date care vizează starea de sănătate,':
			'- date care vizează starea de sănătate,',
		'- date care vizează viața sexuală a unei persoane fizice,':
			'- date care vizează viața sexuală a unei persoane fizice,',
		'- date care vizează orientarea sexuală a unei persoane fizice.':
			'- date care vizează orientarea sexuală a unei persoane fizice.',
		'19.1.3. Nu există o listă exhaustivă a acestor date cu caracter personal.':
			'19.1.3. Nu există o listă exhaustivă a acestor date cu caracter personal.',
		'19.1.4. Pseudonimizarea Datelor Personale înseamnă prelucrarea datelor cu caracter personal într-un asemenea mod încât acestea să nu mai poată fi atribuite unei anume persoane vizate fără a se utiliza informații suplimentare, cu condiția ca aceste informații suplimentare să fie stocate separat și să facă obiectul unor măsuri de natură tehnică și organizatorică care să asigure neatribuirea respectivelor date cu caracter personal unei persoane fizice identificate sau identificabile.':
			'19.1.4. Pseudonimizarea Datelor Personale înseamnă prelucrarea datelor cu caracter personal într-un asemenea mod încât acestea să nu mai poată fi atribuite unei anume persoane vizate fără a se utiliza informații suplimentare, cu condiția ca aceste informații suplimentare să fie stocate separat și să facă obiectul unor măsuri de natură tehnică și organizatorică care să asigure neatribuirea respectivelor date cu caracter personal unei persoane fizice identificate sau identificabile.',
		'19.1.5. Prelucrare semnifică orice operațiune sau set de operațiuni efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi':
			'19.1.5. Prelucrare semnifică orice operațiune sau set de operațiuni efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi',
		'- colectarea,': '- colectarea,',
		'- înregistrarea,': '- înregistrarea,',
		'- organizarea,': '- organizarea,',
		'- structurarea,': '- structurarea,',
		'- stocarea,': '- stocarea,',
		'- adaptarea,': '- adaptarea,',
		'- alterarea,': '- alterarea,',
		'- extragerea,': '- extragerea,',
		'- consultarea,': '- consultarea,',
		'- utilizarea,': '- utilizarea,',
		'- ștergerea sau distrugerea': '- ștergerea sau distrugerea',
		'- etc.': '- etc.',
		'19.1.6. Restricționarea prelucrării înseamnă marcarea datelor personale stocate cu scopul de a limita prelucrarea acestora în viitor.':
			'19.1.6. Restricționarea prelucrării înseamnă marcarea datelor personale stocate cu scopul de a limita prelucrarea acestora în viitor.',
		'19.1.7. Scopul prelucrării înseamnă motivul pentru care este realizată prelucrarea datelor personale.':
			'19.1.7. Scopul prelucrării înseamnă motivul pentru care este realizată prelucrarea datelor personale.',
		'19.1.8. Profilarea': '19.1.8. Profilarea',
		'- (1) trebuie să fie o formă automată de prelucrare, ce include':
			'- (1) trebuie să fie o formă automată de prelucrare, ce include',
		'- Prelucrare exclusiv automată (la care face referire Art. 22 din GDPR) și':
			'- Prelucrare exclusiv automată (la care face referire Art. 22 din GDPR) și',
		'- Prelucrare parțial automată (dacă o persoană fizică este implicată în prelucrarea datelor personale nu înseamnă neapărat ca prelucrarea nu constituie profilare)':
			'- Prelucrare parțial automată (dacă o persoană fizică este implicată în prelucrarea datelor personale nu înseamnă neapărat ca prelucrarea nu constituie profilare)',
		'- (2) trebuie să fie efectuată cu privire la datele cu caracter personal; și':
			'- (2) trebuie să fie efectuată cu privire la datele cu caracter personal; și',
		'- (3) obiectivul profilării trebuie să fie acela de a evalua aspectele personale legate de o persoană fizică, în special de a analiza sau de a face previziuni despre persoane.':
			'- (3) obiectivul profilării trebuie să fie acela de a evalua aspectele personale legate de o persoană fizică, în special de a analiza sau de a face previziuni despre persoane.',
		'Rețineți că simpla evaluare sau clasificare a persoanelor în mod automat pe baza unor caracteristici cum ar fi vârsta, sexul și înălțimea acestora ar putea fi considerate profilare automată, indiferent de scopul predictiv.':
			'Rețineți că simpla evaluare sau clasificare a persoanelor în mod automat pe baza unor caracteristici cum ar fi vârsta, sexul și înălțimea acestora ar putea fi considerate profilare automată, indiferent de scopul predictiv.',
		'19.1.9. Decizii bazate exclusiv pe prelucrarea automată':
			'19.1.9. Decizii bazate exclusiv pe prelucrarea automată',
		'- (1) înseamnă luarea unor decizii prin mijloace tehnologice fără implicare umană; și care':
			'- (1) înseamnă luarea unor decizii prin mijloace tehnologice fără implicare umană; și care',
		'- (2) se bazează pe datele cu caracter personal':
			'- (2) se bazează pe datele cu caracter personal',
		'- furnizate direct de persoanele în cauză (cum ar fi răspunsurile la un chestionar); sau':
			'- furnizate direct de persoanele în cauză (cum ar fi răspunsurile la un chestionar); sau',
		'- observate cu privire la persoane (cum ar fi datele privind locația, colectate prin intermediul unei aplicații) sau':
			'- observate cu privire la persoane (cum ar fi datele privind locația, colectate prin intermediul unei aplicații) sau',
		'- derivate sau deduse, cum ar fi profilul persoanei care a fost deja creat (de ex. un scor de credit) pot fi realizate cu sau fără profilare; profilarea poate avea loc fără a lua decizii automate.':
			'- derivate sau deduse, cum ar fi profilul persoanei care a fost deja creat (de ex. un scor de credit) pot fi realizate cu sau fără profilare; profilarea poate avea loc fără a lua decizii automate.',
		'19.1.10. Operator înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care, singur sau împreună cu altele, stabilește scopurile și mijloacele de prelucrare a datelor cu caracter personal; atunci când scopurile și mijloacele prelucrării sunt stabilite prin dreptul Uniunii sau dreptul intern, operatorul sau criteriile specifice pentru desemnarea acestuia pot fi prevăzute în dreptul Uniunii sau în dreptul intern.':
			'19.1.10. Operator înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care, singur sau împreună cu altele, stabilește scopurile și mijloacele de prelucrare a datelor cu caracter personal; atunci când scopurile și mijloacele prelucrării sunt stabilite prin dreptul Uniunii sau dreptul intern, operatorul sau criteriile specifice pentru desemnarea acestuia pot fi prevăzute în dreptul Uniunii sau în dreptul intern.',
		'19.1.11. Împuternicit înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care prelucrează datele cu caracter personal în numele și pe seama operatorului.':
			'19.1.11. Împuternicit înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care prelucrează datele cu caracter personal în numele și pe seama operatorului.',
		'19.1.12. Destinatar înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism căreia (căruia) îi sunt divulgate datele cu caracter personal, indiferent dacă este sau nu o parte terță. Cu toate acestea, autoritățile publice cărora li se pot comunica date cu caracter personal în cadrul unei anumite anchete în conformitate cu dreptul Uniunii sau cu dreptul intern nu sunt considerate destinatari; prelucrarea acestor date de către autoritățile publice respective respectă normele aplicabile în materie de protecție a datelor, în conformitate cu scopurile prelucrării.':
			'19.1.12. Destinatar înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism căreia (căruia) îi sunt divulgate datele cu caracter personal, indiferent dacă este sau nu o parte terță. Cu toate acestea, autoritățile publice cărora li se pot comunica date cu caracter personal în cadrul unei anumite anchete în conformitate cu dreptul Uniunii sau cu dreptul intern nu sunt considerate destinatari; prelucrarea acestor date de către autoritățile publice respective respectă normele aplicabile în materie de protecție a datelor, în conformitate cu scopurile prelucrării.',
		'19.1.13. Parte Terță înseamnă o persoană fizică sau juridică, autoritate publică, agenție sau organism altul decât persoana vizată, operatorul, persoana împuternicită de operator și persoanele care, sub directa autoritate a operatorului sau a persoanei împuternicite de operator, sunt autorizate să prelucreze date cu caracter personal.':
			'19.1.13. Parte Terță înseamnă o persoană fizică sau juridică, autoritate publică, agenție sau organism altul decât persoana vizată, operatorul, persoana împuternicită de operator și persoanele care, sub directa autoritate a operatorului sau a persoanei împuternicite de operator, sunt autorizate să prelucreze date cu caracter personal.',
		'19.1.14. Reprezentant înseamnă o persoană fizică sau juridică stabilită în Uniune, desemnată în scris de către operator sau persoana împuternicită de operator în temeiul articolului 27, care reprezintă operatorul sau persoana împuternicită în ceea ce privește obligațiile lor respective care le revin în temeiul prezentului regulament.':
			'19.1.14. Reprezentant înseamnă o persoană fizică sau juridică stabilită în Uniune, desemnată în scris de către operator sau persoana împuternicită de operator în temeiul articolului 27, care reprezintă operatorul sau persoana împuternicită în ceea ce privește obligațiile lor respective care le revin în temeiul prezentului regulament.',
		'19.1.15. Autoritate de supraveghere înseamnă o autoritate publică independentă instituită de un stat membru în temeiul articolului 51 GDPR.':
			'19.1.15. Autoritate de supraveghere înseamnă o autoritate publică independentă instituită de un stat membru în temeiul articolului 51 GDPR.',
		'19.1.16. Reguli corporatiste obligatorii înseamnă politicile în materie de protecție a datelor cu caracter personal care trebuie respectate de un operator sau de o persoană împuternicită de operator stabilită pe teritoriul unui stat membru, în ceea ce privește transferurile sau seturile de transferuri de date cu caracter personal către un operator sau o persoană împuternicită de operator în una sau mai multe țări terțe în cadrul unui grup de întreprinderi sau al unui grup de întreprinderi implicate într-o activitate economică comună.':
			'19.1.16. Reguli corporatiste obligatorii înseamnă politicile în materie de protecție a datelor cu caracter personal care trebuie respectate de un operator sau de o persoană împuternicită de operator stabilită pe teritoriul unui stat membru, în ceea ce privește transferurile sau seturile de transferuri de date cu caracter personal către un operator sau o persoană împuternicită de operator în una sau mai multe țări terțe în cadrul unui grup de întreprinderi sau al unui grup de întreprinderi implicate într-o activitate economică comună.',
		'19.1.17. Scutul de confidențialitate UE-SUA (Privacy Shield)':
			'19.1.17. Scutul de confidențialitate UE-SUA (Privacy Shield)',
		'- Cadrul UE - SUA Privacy Shield a fost pus la punct de către Departamentul de Comerț al SUA și Comisia Europeană pentru a oferi companiilor de pe ambele părți ale Atlanticului un mecanism pentru a se conforma cerințelor de protecție a datelor atunci când transferă date cu caracter personal din Uniunea Europeană către Statele Unite în sprijinul comerțului transatlantic. La 12 iulie 2016 Comisia Europeană a aprobat cadrul UE-SUA Privacy Shield ca fiind adecvat pentru a permite transferul de date în conformitate cu legislația UE.':
			'- Cadrul UE - SUA Privacy Shield a fost pus la punct de către Departamentul de Comerț al SUA și Comisia Europeană pentru a oferi companiilor de pe ambele părți ale Atlanticului un mecanism pentru a se conforma cerințelor de protecție a datelor atunci când transferă date cu caracter personal din Uniunea Europeană către Statele Unite în sprijinul comerțului transatlantic. La 12 iulie 2016 Comisia Europeană a aprobat cadrul UE-SUA Privacy Shield ca fiind adecvat pentru a permite transferul de date în conformitate cu legislația UE.',
		'19.1.18. Deciziile de Adecvare ale Comisiei':
			'19.1.18. Deciziile de Adecvare ale Comisiei',
		'- Comisia Europeană are competența de a determina, pe baza articolului 45 GDPR, dacă o țară din afara UE oferă un nivel adecvat de protecție a datelor, fie prin legislația internă, fie prin angajamentele internaționale pe care le-a încheiat.':
			'- Comisia Europeană are competența de a determina, pe baza articolului 45 GDPR, dacă o țară din afara UE oferă un nivel adecvat de protecție a datelor, fie prin legislația internă, fie prin angajamentele internaționale pe care le-a încheiat.',
		'- Efectul unei astfel de decizii este că datele cu caracter personal pot proveni din SEE (UE și Norvegia, Liechtenstein și Islanda) în acea țară terță, fără a fi necesară nicio altă măsură de protecție.':
			'- Efectul unei astfel de decizii este că datele cu caracter personal pot proveni din SEE (UE și Norvegia, Liechtenstein și Islanda) în acea țară terță, fără a fi necesară nicio altă măsură de protecție.',
		'- Comisia Europeană a recunoscut, până în prezent, un nivel de protecție adecvat, pentru Andorra, Argentina, Canada (organizații comerciale - PIPEDA), Insulele Feroe, Guernsey, Israel, Insula Man, Jersey, Noua Zeelandă, Elveția, Uruguay și SUA (limitată la cadrul Privacy Shield).':
			'- Comisia Europeană a recunoscut, până în prezent, un nivel de protecție adecvat, pentru Andorra, Argentina, Canada (organizații comerciale - PIPEDA), Insulele Feroe, Guernsey, Israel, Insula Man, Jersey, Noua Zeelandă, Elveția, Uruguay și SUA (limitată la cadrul Privacy Shield).',
		'19.1.19. Încălcarea securității datelor cu caracter personal înseamnă o încălcare a securității care duce, în mod accidental sau ilegal, la distrugerea, pierderea, modificarea, sau divulgarea neautorizată a datelor cu caracter personal transmise, stocate sau prelucrate într-un alt mod, sau la accesul neautorizat la acestea.':
			'19.1.19. Încălcarea securității datelor cu caracter personal înseamnă o încălcare a securității care duce, în mod accidental sau ilegal, la distrugerea, pierderea, modificarea, sau divulgarea neautorizată a datelor cu caracter personal transmise, stocate sau prelucrate într-un alt mod, sau la accesul neautorizat la acestea.',
		'19.1.20. Întreprindere înseamnă o persoană fizică sau juridică ce desfășoară o activitate economică, indiferent de forma juridică a acesteia, inclusiv parteneriate sau asociații care desfășoară în mod regulat o activitate economică.':
			'19.1.20. Întreprindere înseamnă o persoană fizică sau juridică ce desfășoară o activitate economică, indiferent de forma juridică a acesteia, inclusiv parteneriate sau asociații care desfășoară în mod regulat o activitate economică.',
		'19.1.21. Grup de întreprinderi înseamnă o întreprindere care exercită controlul și întreprinderile controlate de aceasta;':
			'19.1.21. Grup de întreprinderi înseamnă o întreprindere care exercită controlul și întreprinderile controlate de aceasta;',
		'19.1.22. Organizație internațională înseamnă o organizație și organismele sale subordonate reglementate de dreptul internațional public sau orice alt organism care este instituit printr-un acord încheiat între două sau mai multe țări sau în temeiul unui astfel de acord.':
			'19.1.22. Organizație internațională înseamnă o organizație și organismele sale subordonate reglementate de dreptul internațional public sau orice alt organism care este instituit printr-un acord încheiat între două sau mai multe țări sau în temeiul unui astfel de acord.',
		'Limitarea responsabilității': 'Limitarea responsabilității',
		'Afirmaţi în mod expres că aţi luat cunoştinţă şi sunteţi de acord că această companie nu va fi răspunzătoare pentru nici un fel de pagube directe, indirecte, accidentale, speciale, inclusiv, dar nu limitat la nerealizare de profit, posibilităţii de folosire, date sau alte pagube (chiar dacă compania a fost înainte informată de posibilitatea apariţiei unor asemenea pierderi), rezultând din':
			'Afirmaţi în mod expres că aţi luat cunoştinţă şi sunteţi de acord că această companie nu va fi răspunzătoare pentru nici un fel de pagube directe, indirecte, accidentale, speciale, inclusiv, dar nu limitat la nerealizare de profit, posibilităţii de folosire, date sau alte pagube (chiar dacă compania a fost înainte informată de posibilitatea apariţiei unor asemenea pierderi), rezultând din',
		'1. utilizarea sau imposibilitatea utilizării serviciilor site-ului;':
			'1. utilizarea sau imposibilitatea utilizării serviciilor site-ului;',
		'2. acces neautorizat la sau deteriorarea transmisiilor sau datelor dumneavoastră;':
			'2. acces neautorizat la sau deteriorarea transmisiilor sau datelor dumneavoastră;',
		'3. declaraţii sau acţiuni a oricărei terţe părţi asupra serviciilor site-ului;':
			'3. declaraţii sau acţiuni a oricărei terţe părţi asupra serviciilor site-ului;',
		'4. orice altă problemă legată de serviciile site-ului.':
			'4. orice altă problemă legată de serviciile site-ului.',
		'Declaraţi în mod expres că înţelegeţi şi că sunteţi de acord cu următoarele afirmaţii':
			'Declaraţi în mod expres că înţelegeţi şi că sunteţi de acord cu următoarele afirmaţii',
		'1. utilizarea serviciilor site-ului se face pe propria răspundere. Serviciile sunt furnizate pe principiul "aşa cum sunt" sau "aşa cum sunt disponibile".':
			'1. utilizarea serviciilor site-ului se face pe propria răspundere. Serviciile sunt furnizate pe principiul "aşa cum sunt" sau "aşa cum sunt disponibile".',
		'2. Compania nu oferă nici o garanţie că':
			'2. Compania nu oferă nici o garanţie că',
		'serviciile vor împlini toate cerinţele dumneavoastră;':
			'serviciile vor împlini toate cerinţele dumneavoastră;',
		'serviciile vor fi neîntrerupte, la timp, sigure sau fără erori;':
			'serviciile vor fi neîntrerupte, la timp, sigure sau fără erori;',
		'rezultatele ce se vor putea obţine din folosirea site-ului şi a serviciilor puse la dispoziţie vor fi corecte sau de încredere;':
			'rezultatele ce se vor putea obţine din folosirea site-ului şi a serviciilor puse la dispoziţie vor fi corecte sau de încredere;',
		'orice eroare de program va fi corectată;':
			'orice eroare de program va fi corectată;',
		'3. Orice material descărcat sau obţinut în alt fel prin utilizarea serviciilor puse la dispoziţie de companie se află astfel la discreţia şi poate fi folosit doar pe riscul propriu şi veţi singura persoană responsabilă de eventualele distrugeri cauzate computerului de pe care accesaţi site-ul sau alte pierderi de date ce pot rezulta din descărcarea acestui tip de conţinut.':
			'3. Orice material descărcat sau obţinut în alt fel prin utilizarea serviciilor puse la dispoziţie de companie se află astfel la discreţia şi poate fi folosit doar pe riscul propriu şi veţi singura persoană responsabilă de eventualele distrugeri cauzate computerului de pe care accesaţi site-ul sau alte pierderi de date ce pot rezulta din descărcarea acestui tip de conţinut.',
		'Generalităţi ': 'Generalităţi ',
		'Dacă vreo prevedere din acest capitol "Termeni şi condiţii" este considerată invalidă de către forurile legale competente, invaliditatea unei asemenea prevederi nu va afecta valabilitatea celorlalte prevederi ale "Termeni şi condiţii", acestea rămânând în vigoare. "Termeni şi condiţii" constituie în întregime un acord încheiat între dumneavoastră şi companie în privinţa utilizării site-ului www.cv30.co.Aceste condiţii pot fi modificate de noi la anumite intervale, fără o anunţare sau o acceptare prealabilă a utilizatorilor. Puteţi citi cea mai recentă versiune a acestor condiţii oricând, accesând aceasta pagină.':
			'Dacă vreo prevedere din acest capitol "Termeni şi condiţii" este considerată invalidă de către forurile legale competente, invaliditatea unei asemenea prevederi nu va afecta valabilitatea celorlalte prevederi ale "Termeni şi condiţii", acestea rămânând în vigoare. "Termeni şi condiţii" constituie în întregime un acord încheiat între dumneavoastră şi companie în privinţa utilizării site-ului www.cv30.co.Aceste condiţii pot fi modificate de noi la anumite intervale, fără o anunţare sau o acceptare prealabilă a utilizatorilor. Puteţi citi cea mai recentă versiune a acestor condiţii oricând, accesând aceasta pagină.',
		'Prezentul acord constituie unică convenţie dintre dumneavoastră şi companie şi reglementează folosirea de către dumneavoastră a serviciilor site-ului www.cv30.co , impunându-se în faţa oricărui acord precedent dintre dumneavoastră şi companie sau site-urile sale (inclusiv, dar nu limitat la o versiune precedentă a Regulamentului). Puteţi de asemenea fi supuşi unei alte versiuni a Regulamentului care se poate aplica atunci când folosiţi alte servicii ale companiei sau ale partenerilor săi, conţinut al oricărei terţe părţi, sau software a oricărui terţ. Regulamentul şi relaţiile dintre utilizatori şi companie vor fi guvernate de legile aplicabile în România.':
			'Prezentul acord constituie unică convenţie dintre dumneavoastră şi companie şi reglementează folosirea de către dumneavoastră a serviciilor site-ului www.cv30.co , impunându-se în faţa oricărui acord precedent dintre dumneavoastră şi companie sau site-urile sale (inclusiv, dar nu limitat la o versiune precedentă a Regulamentului). Puteţi de asemenea fi supuşi unei alte versiuni a Regulamentului care se poate aplica atunci când folosiţi alte servicii ale companiei sau ale partenerilor săi, conţinut al oricărei terţe părţi, sau software a oricărui terţ. Regulamentul şi relaţiile dintre utilizatori şi companie vor fi guvernate de legile aplicabile în România.',
		'Actualizarea acordului de utilizare a fost realizata la data de 15 mai 2020.':
			'Actualizarea acordului de utilizare a fost realizata la data de 15 mai 2020.',
		'We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.':
			'Folosim cookie-uri pentru a vă asigura că vă oferim cea mai bună experiență pe site-ul nostru web. Dacă continuați să utilizați acest site, va exprimati acordul pentru folosirea acestora si modul lor de utilizare.',
		'Works at': 'Lucreaza la',
		'Are you sure you want to delete this post?':
			'Sigur doriți să ștergeți această postare?',
		Yes: 'Da',
		No: 'Nu',
		Delete: 'Sterge',
		'Long Article': 'Articol lung',
		'Are you sure you want to delete this article?':
			'Sigur doriți să ștergeți acest articol?',
		'choose up to 5 tags for your post':
			'alege pana la 5 etichete pentru postarea ta',
		'choose up to 5 tags for your article':
			'alege pana la 5 etichete pentru articolul tau',
		'Description goes here': 'Descriere',
		'Add new experience': 'Adauga o noua experienta',
		Title: 'Titlu',
		Description: 'Descriere',
		Start: 'Start',
		End: 'Sfarsit',
		Region: 'Judet',
		Location: 'Oras',
		Company: 'Companie',
		Position: 'Positie',
		'Use this as my current position': 'Lucrez in prezent',
		Cancel: 'Anuleaza',
		Save: 'Salveaza',
		'Are you sure you want to delete this experience?':
			'Sigur doriti să stergeti aceasta experienta?',
		'search and select desired skills':
			'cauta si selecteaza abilitatile dorite',
		cancel: 'anuleaza',
		save: 'salveaza',
		'Edit experience': 'Modifica experienta',
		'your values, go here': 'descriere personala',
		'First Name': 'Prenume',
		'Last Name': 'Nume',
		Username: 'Username',
		'letters, numbers, including .-_': 'litere, numere, inclusiv .-_',
		'Check Availability': 'Verifica disponibilitatea',
		'New Password': 'Parola noua',
		'only fill in to change the password':
			'completati numai pentru a schimba parola',
		'Confirm New Password': 'Confirma noua parola',
		'must match the new password': 'trebuie sa corespunda cu noua parola',
		'Your current password is missing or incorrect':
			'Parola curenta lipseste sau este incorecta',
		Update: 'Actualizati',
		Name: 'Nume',
		'Date founded': 'Data fondarii',
		'Industry search': 'Cauta industria dorita',
		Website: 'Site web',
		Phone: 'Telefon',
		Email: 'E-mail',
		Addresses: 'Adrese',
		'saved!': 'salvat!',
		"We couldn't find anything\nTry to refine your search term":
			'Nu s-a gasit niciun rezultat',
		'Search for Employers, Teams, Jobs or Articles':
			'Cauta Angajatori, Echipe, Job-uri sau Articole',
		'No account associated with this email address':
			'Nu am gasit nici un cont cu aceasta adresa de email',
		'If the address exists in our database, you will receive an email with the password recovery instructions.':
			'Daca adresa exista in baza noastra de date, veti primi un e-mail cu instructiunile de recuperare a parolei.',
		'Sorry, this mail could not be sent at this time. Please try again later.':
			'Ne pare rau, e-mailul nu s-a putut trimite la aceasta ora. Va rugam, incercati mai tarziu.',
		'Your email address or password is incorrect. Please try again':
			'Adresa de email sau parola sunt incorecte. Incearca din nou.',
		'No tags found': 'Nu s-au gasit etichete',
		'Uploading ... ': 'Se incarca ...',
		'Preparing ...': 'Se pregateste ...',
		'Type a location...': 'Scrie o locatie...',
		"I'll will do it later": 'O voi face mai tarziu',
		'Describe your company': 'Descrierea companiei',
		// 'Discover our team': 'Descopera echipa noastra',
		'Meet our teams': 'Descopera echipele noastre',
		'Create page': 'Creaza <br /> pagina',
		'Upload profile image': 'Incarcati <br /> imaginea de profil',
		'Upload cover photo/video':
			'Incarcati <br /> fotografia/video-ul de coperta',
		'Create teams': 'Creati <br /> echipe',
		'Create Roles': 'Creati <br /> roluri',
		'Write a story': 'Scrie <br /> o poveste',
		'Create jobs': 'Creati <br /> job-uri',
		'Your new company page is ready!': 'Noua ta companie este gata!',
		'Tell visitor more about your company!':
			'Spune vizitatorilor mai multe despre compania ta!',
		'Welcome, CompanyName!': 'Bine ati venit, {{companyName}}!',
		'Upload Profile Picture': 'Incarcati poza de profil',
		Industry: 'Industrie',
		'Company type': 'Tipul companiei',
		'Company size': 'Numar angajati',
		'Location of office(s)': 'Amplasarea biroului/birourilor',
		'Describe your company here...': 'Describe your company here...',
		'Add the e-mail addresses': 'Adauga adresele de e-mail...',
		'Add the e-mail addresses of your team members':
			'Adauga adresele de e-mail ale membrilor echipei',
		teams: 'echipe',
		companySettings: '<strong>Setari</strong>',
		Administrator: 'Administrator',
		Add: 'Add',
		'Content managers': 'Manageri de continut',
		'User accounts': 'Conturi de utilizator',
		'Business ID': 'Cod de afaceri',
		Notifications: 'Notificari',
		Jobs: 'Joburi',
		'Meet the': null,
		'Team members': 'Membrii echipei',
		'average age': 'Varsta medie:',
		employees: 'angajati',
		female: 'femei',
		male: 'barbati',
		'with disability': 'cu dezabilitati',
		'Add members': 'Adauga membrii',
		'Offices in': 'Locatii in',
		'Add stories that represents the life at your office':
			'Adaugă povești care reprezintă viața la biroul tău',
		'Add story': 'Adauga poveste',
		'Publish story': 'Publica povestea',
		'My Company': 'Compania mea',
		'Company profile': 'Profilul companiei',
		'Jobs (coming soon)': 'Joburi (in curand)',
		'Name not available': 'Numele nu este disponibil',
		Search: 'Cauta',
		All: 'Toate',
		Locations: 'Locatii',
		'Add email addresses...': 'Adăugați adrese de e-mail ...',
		"I'll  do it later": 'O voi face mai târziu',
		'Team Name': 'Nume Echipa',
		'Learn more': 'Aflati mai multe',
		'The team': 'Echipa',
		'Team posts': 'Postarile echipei',
		'No of members': 'Nr. de membri',
		'Female ratio': 'Raport femei',
		'Male ratio': 'Raport barbati',
		'Average age': 'Media de varsta',
		'Disability ratio': 'Raport pers. cu dizabilitati',
		Share: 'Distribuie',
		Apply: 'Aplica',
		'Salary Range': 'Salarizare',
		'Contract Type': 'Tip contract',
		Hours: 'Numar ore',
		Benefits: 'Beneficii',
		'Skills required': 'Aptitudini necesare',
		'Job description': 'Descrierea postului',
		'Create a new Job opening!': 'Creati un nou post liber!',
		'Name of your Job': 'Numele postului',
		'Name of your Job opening ...': 'Numele postului liber ...',
		'Describe your job': 'Descrierea postului',
		'Expiration date of the opening': 'Data de expirare postului',
		'Expiration date': 'Data de expirare',
		'Describe your new Job opening here. Talk about your requirements and benefits ...':
			'Descrieti noul post aici. Descrieti cerintele si beneficiile ...',
		Next: 'Continua',
		'Name of your Job is required': 'Numele postului este obligatoriu',
		'Define the salary range': 'Definiti nivelul de salarizare',
		'Starting from': 'Incepand de la',
		To: 'La',
		Currency: 'Moneda',
		Rate: 'Rata',
		'Add Required Skills. Search from our database ...':
			'Adaugati competentele necesare. Cautati in baza noastra de date ...',
		'What is the Contract type?': 'Tipul contractului',
		'What kind of job is this?': 'Tipul postului',
		'Is this job On site or Remote?': 'Acest post este  la distanta?',
		'What Seniority level are you looking for?':
			'Nivelul de experienta al candidatului',
		'Finally, introduce the team!': 'Introduceti echipa!',
		'What team is this part of?': 'Care este echipa dorita?',
		'And who will be the boss?': 'Cine va fi liderul echipei?',
		'Search among your teams ...': 'Cautati printre echipele dvs....',
		'Search for a team member ...': 'Cauta un membru ale echipei ...',
		None: 'Nici unul',
		'Search for required skills...': 'Căutați abilitățile necesare ...',
		'Benefits.': 'Beneficii.',
		'Add locations': 'Adauga locatii',
		'Search for locations': 'Cautati locatii',
		'Talk about your benefits ...': 'Descrie beneficiile oferite ...',
		'Edit Job Details': 'Modifica detaliile job-ului',
		'Creating job progress': 'Creare job, grad de completare',
		Back: 'Inapoi',
		Skip: 'Sari',
		'Choose the step you want to change from the ones below.':
			'Alege pasul pe care vrei sa il modifici dintre cei de mai jos.',
		Required: 'Obligatoriu',
		Optional: 'Optional',
		Department: 'Specializare',
		'Team Size': 'Marimea echipei',
		'Average Age': 'Media de varsta',
		'Allocated to the whole company': 'Alocat intregii companii',
		'People all around the world from different teams and domains share their knowhow, work experience and their team culture.':
			'Oameni din întreaga lume din diferite echipe si domenii isi impartasesc cunostintele, experienta de munca si cultura echipei lor.',
		exploreCompanies: 'Explore <2>Companies</2>',
		'Back to CompanyName': 'Inapoi la {{CompanyName}}',
		searchPlaceholder: 'Cauta: Angajatori, Echipe, Articole, Job-uri',
		searchEmployersAndTeamsPlaceholder: 'Cauta: Angajatori, Echipe',
		discoverOurCompany: 'Descopera compania',
		meetOurTeams: 'Descopera echipele',
		'My Settings': 'Setarile mele',
		'Teams of': 'Echipele de',
		advancedSearchTitle: 'Cautare <1>Avansata</1>',
		checkBackLaterForPosts:
			'Revino mai tarziu pentru a vedea postarile lui {{data}}',
		checkBackLaterForTeams:
			'Revino mai tarziu pentru a vedea echipele {{data}}',
		'No teams created yet': 'Nu exista echipe',
		'No posts created yet': 'Nu exista postari.',
		'Add a new job': 'Adauga un nou job',
		'Please, go in your profile page and upload your CV before applying.':
			'Inainte de a aplica pentu un job, va rugam sa mergeti in profilul dumneavoastra si sa va incarcati CV-ul',
		'In order to apply for this job you need to be logged in and have a CV file uploaded.':
			'Pentru a aplica pentru acest job, trebuie să fiti autentificat si să aveti incarcat CV-ul dumneavoastra.',
		'Optional: If you fill in a field, it is necessary to fill in all the fields':
			'Optional: In cazul in care completati un camp, este necesara completarea tuturor campurilor',
		University: 'Universitate',
		Specialization: 'Specializarea',
		'Please select the specialization': 'Te rog, selecteaza specializarea',
		Year: 'Anul',
		'Please select the year': 'Te rog, selecteaza anul',
		'City of residence': 'Orasul de domiciliu',
		Submit: 'Salveaza',
		Faculty: 'Facultate',
		bannerMessages: `Make Mondays Happy!<1/> Don’t just find a job, find a team where
					you belong.<3/> Meet the teams of top employers, see how they
					work daily, engage with people alike through content of
					interest, all before you apply for a position.`,
		'See more': 'Vezi mai mult',
		successfullyRegisteredForStudentsKit:
			'Pentru a finaliza procesul de inregistrare te rugam sa deschizi email-ul pe care ti l-am trimis, si sa dai click pe link-ul din email pentru a confirma email-ul. Cauta si in folderele SPAM, sau Junk daca nu il gasesti in folderul principal din clientul tau de email. Dupa confirmare vei primi un email cu codul QR cu care vei primi Students Kit.',
		closedStudentsKit:
			'Procesul de inregistrare la Students Kit s-a incheiat.',
		'You will receive the email with the QR code for StudentsKit':
			'Vei primi email-ul cu QR codul de participare la Students Kit',
		'Registration failed': 'Inregistrarea nu a reusit',
		'Start typing to see the list':
			'Incepe sa tastezi pentru a vedea lista',
		'The two passwords that you entered do not match!':
			'Parolele nu sunt la fel!',
		'Email verified successfully, you will receive the email with the QR code for StudentsKit':
			'E-mailul a fost validat. Vei primi pe email QR codul pentru StudentsKit',
		'Your email has already been confirmed':
			'Aceasta adresa de email a fost deja confirmata',
		'Sorry ... something went wrong':
			'Sorry ... ceva nu a functionat, ne poti contacta la contact@cv30.co pentru support',
		'Go to home page': 'La pagina principala',
		Agree: 'De acord',
		Disagree: 'Nu sunt de acord',
		'Recover code': 'Recupereaza codul',
		Register: 'Inregistreaza-te',
		'Please enter your first name': 'Prenume',
		'Please enter your last name': 'Nume',
		'Please enter your email': 'Adresa de e-mail',
		'Please select your university': 'Universitatea',
		'Please enter your faculty': 'Facultatea',
		'Please enter your specialization': 'Specializarea',
		'Please select your university level': 'An universitar',
		'Please enter the location': 'Orasul unde studiezi',
		'Please enter your password': 'Introdu parola',
		'Please confirm your password': 'Confirma parola',
		Password: 'Parola',
		'Confirm Password': 'Confirma parola',
		TermsAndConditionsLabel: `<0>Am citit si sunt de acord cu</0><1>Termenii si conditiile de utilizare</1>`,
		Send: 'Trimite',
		'There is another team with the same name.':
			'In cadrul companiei exista deja o echipa cu acest nume.',
		'Are you sure you want to leave? You will lose your data if you continue!':
			'Esti sigur ca vrei sa parasesti pagina? Datele tale se vor pierde daca continui!',
		TheNameIsAlreadyTaken: 'Numele nu este disponibil!',
		'Students Kit 2021 is available only in the universities listed.':
			'Students Kit 2021 mai este disponibil doar in universitatile afisate in lista.',
		'Delete Account': 'Sterge contul',
		'Are you sure you want to delete the account?':
			'Sigur doriti stergerea contului?',
		'Account deleted successfully!': 'Contul a fost sters cu success!',
	},
}
