import gql from 'graphql-tag'

export const GetSkills = gql`
	query searchSkills($key: String!) {
		searchSkills(key: $key) {
			id
			title
		}
	}
`
