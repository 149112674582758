import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		container: {
			overflowY: 'visible',
			maxWidth: 600,
			maxHeight: 630,
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			'& > p': {
				fontSize: '1rem',
			},
			'& > em': {
				fontSize: '.8rem',
			},
			'& .dropzone': {
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: 20,
				margin: 20,
				borderWidth: 1,
				borderRadius: 2,
				borderColor: '#eeeeee',
				borderStyle: 'dashed',
				color: '#bdbdbd',
				outline: 'none',
				transition: 'border .24s ease -in -out',
				'&:focus': {
					borderColor: '#2196f3',
				},
				'&.disabled': {
					opacity: 0.6,
				},
			},
		},
		cropContainer: {
			position: 'relative !important' as any,
			height: '100%',
			// minHeight: 200,
			// top: 0,
			// left: 0,
			// right: 0,
			// bottom: 80,
			// paddingBottom: '100%',
		},
		cropImage: {
			// position: 'relative !important' as any,
		},
		controls: {
			// position: 'absolute',
			// bottom: 0,
			// left: '50%',
			// width: '50%',
			// transform: 'translateX(-50%)',
			height: 30,
			padding: '0 15px',
			display: 'flex',
			alignItems: 'center',
		},
		slider: {
			padding: '22px 0px',
		},
		clearButton: {
			paddingLeft: 8,
			position: 'absolute',
			bottom: -40,
			left: 8,
			borderColor: 'rgba(255, 255, 255, 0.8)',
			color: 'rgba(255, 255, 255, 0.8)',
			'& > svg': {
				color: 'rgba(255, 255, 255, 0.8)',
			},
			'&:hover': {
				borderColor: 'rgba(255, 255, 255, 1)',
				color: 'rgba(255, 255, 255, 1)',
				'& > svg': {
					color: 'rgba(255, 255, 255, 1)',
				},
			},
		},
		saveButton: {
			paddingLeft: 8,
			position: 'absolute',
			bottom: -40,
			right: 8,
			borderColor: 'rgba(255, 255, 255, 0.8)',
			color: 'rgba(255, 255, 255, 0.8)',
			'& > svg': {
				color: 'rgba(255, 255, 255, 0.8)',
			},
			'&:hover': {
				borderColor: 'rgba(255, 255, 255, 1)',
				color: 'rgba(255, 255, 255, 1)',
				'& > svg': {
					color: 'rgba(255, 255, 255, 1)',
				},
			},
		},
	})
