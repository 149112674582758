import gql from 'graphql-tag'
import {
	standardCompanyResult,
	standardArticleResult,
	companyOfficesResult,
	companyConnectionData,
} from 'store/fragments'

export const IndustriesQuery = gql`
	query {
		industries {
			id
			key
		}
	}
`
export const CompaniesTypesQuery = gql`
	query {
		companyTypes {
			id
			title
		}
	}
`
export const CompaniesQuery = gql`
	query companies($data: CompaniesFilterInput) {
		companies(data: $data) {
			id
			name
			slug
		}
	}
`

export const CompanyQuery = gql`
	query company($id: String, $name: String, $slug: String) {
		company(id: $id, name: $name, slug: $slug) {
			...standardCompanyResult
		}
	}
	${standardCompanyResult}
`

export const CompanyOffice = gql`
	query companyOffice($id: String!) {
		companyOffice(id: $id) {
			...companyOfficesResult
		}
	}
	${companyOfficesResult}
`

export const CompanyOffices = gql`
	query companyOffices($companySlug: String, $companyId: String) {
		companyOffices(companySlug: $companySlug, companyId: $companyId) {
			...companyOfficesResult
		}
	}
	${companyOfficesResult}
`

export const FeedCompaniesQuery = gql`
	query feedCompanies($data: feedCompaniesInput) {
		feedCompanies(data: $data) {
			...companyConnectionData
		}
	}
	${companyConnectionData}
`

// MUATTIONS

export const AddCompany = gql`
	mutation addCompany($details: CompanyInput!) {
		addCompany(details: $details) {
			...standardCompanyResult
		}
	}
	${standardCompanyResult}
`

export const UpdateCompanyDetails = gql`
	mutation handleCompany($details: CompanyInput!) {
		handleCompany(details: $details) {
			...standardCompanyResult
		}
	}
	${standardCompanyResult}
`

export const HandleCompanyStory = gql`
	mutation handleCompanyStory($story: CompanyStoryInput!) {
		handleCompanyStory(story: $story) {
			...standardArticleResult
		}
	}
	${standardArticleResult}
`

export const DeleteCompanyStory = gql`
	mutation deleteCompanyStory($storyId: String!) {
		deleteCompanyStory(storyId: $storyId) {
			...standardArticleResult
		}
	}
	${standardArticleResult}
`

export const AddCompanyOffice = gql`
	mutation addCompanyOffice($data: CompanyOfficesInput!) {
		addCompanyOffice(data: $data) {
			...standardCompanyResult
		}
	}
	${standardCompanyResult}
`

export const HandleCompanyOffices = gql`
	mutation handleCompanyOffice($data: CompanyOfficesInput!) {
		handleCompanyOffice(data: $data) {
			...companyOfficesResult
		}
	}
	${companyOfficesResult}
`
export const DeleteCompanyOffice = gql`
	mutation deleteCompanyOffice($id: String!) {
		deleteCompanyOffice(id: $id) {
			...standardCompanyResult
		}
	}
	${standardCompanyResult}
`
export const ChangeCompanyHq = gql`
	mutation changeCompanyHq($id: String!, $companySlug: String!) {
		changeCompanyHq(id: $id, companySlug: $companySlug) {
			...standardCompanyResult
		}
	}
	${standardCompanyResult}
`

export const DeleteCompanyMutation = gql`
	mutation deleteCompany($id: String!, $checkText: String!) {
		deleteCompany(id: $id, checkText: $checkText) {
			...standardCompanyResult
		}
	}
	${standardCompanyResult}
`
