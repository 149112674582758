import React, { memo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Card, CardContent, IconButton } from '@material-ui/core'
import { useTranslation, Trans } from 'react-i18next'
import { KeyboardArrowRight } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import styles from './styles'

const useStyles = makeStyles(styles)
// const CompanyTeam = require('../../assets/images/company-team-image.png')

const EntityCompanyLink: React.FunctionComponent<any> = props => {
	const { toggle, companyName, companySlug } = props
	const { t, i18n } = useTranslation()
	const classes = useStyles()
	const history = useHistory()

	const setTab = useCallback(
		(tabIndex: 'teams' | 'posts') => () => {
			toggle(tabIndex)
		},
		[toggle],
	)

	const handleSeeCompanyJobs = useCallback(() => {
		let pathname = `/${i18n.language}/employers/${companySlug}/active-jobs`
		history.push(pathname, { companyName })
	}, [i18n, history, companyName, companySlug])

	return (
		<React.Fragment>
			<Grid
				container
				style={{ maxWidth: '670px', marginTop: '20px' }}
				spacing={2}
			>
				<Grid item xs={12}>
					<Card classes={{ root: classes.cardRoot }}>
						<CardContent
							classes={{ root: classes.cardContentRoot }}
						>
							<div className={classes.imageContent}>
								<div
									onClick={setTab('teams')}
									className={classes.details}
								>
									<span className={classes.imageTitle}>
										<span className={classes.mainTitle}>
											{t('Meet our teams')}
										</span>
										{/* <span className={classes.subTitle}>
											{t('Discover our team')}
										</span> */}
									</span>
									<IconButton
										className={classes.arrowRightIcon}
									>
										<KeyboardArrowRight
											className={classes.arrowWhite}
											style={{ fontSize: 30 }}
										/>
									</IconButton>
								</div>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Card classes={{ root: classes.boxRoot }}>
						<CardContent
							classes={{ root: classes.cardGray }}
							onClick={handleSeeCompanyJobs}
						>
							<div className={classes.details}>
								<span className={classes.detailsTitle}>
									<span className={classes.mainTitle}>
										{
											<Trans key="discoverOurJobs">
												{t('Discover our')}
												<br />
												{t('jobs')}
											</Trans>
										}
									</span>
								</span>
								<IconButton className={classes.arrowRightIcon}>
									<KeyboardArrowRight
										className={classes.arrowBlue}
										style={{ fontSize: 30 }}
									/>
								</IconButton>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Card classes={{ root: classes.boxRoot }}>
						<CardContent
							classes={{ root: classes.cardContentRoot }}
						>
							<div
								onClick={setTab('posts')}
								className={classes.details}
							>
								<span className={classes.detailsTitle}>
									<span className={classes.mainTitle}>
										{t('Our')}
									</span>
									<span className={classes.mainTitle}>
										{t('latest posts')}
									</span>
								</span>
								<IconButton className={classes.arrowRightIcon}>
									<KeyboardArrowRight
										className={classes.arrowBlue}
										style={{ fontSize: 30 }}
									/>
								</IconButton>
							</div>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}
export default memo(EntityCompanyLink)
