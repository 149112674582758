import React, { memo, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Fab, LinearProgress } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useTranslation, Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import styles from './styles'

const useStyles = makeStyles(styles)

const CompanyPageProgress: React.FunctionComponent<any> = props => {
	const {
		hasLogo,
		hasCover,
		hasCompanyArticles,
		hasCompanyTeams,
		handleShowUploadCrop,
		triggerFileSelect,
		handleGoToCreateStory,
		toggleAddTeamModal,
		hasCompanyJobs,
		featuredVideo,
	} = props.companyData
	const history = useHistory()
	const { t, i18n } = useTranslation()
	const classes = useStyles()
	const [steps, setSteps] = useState([
		{
			title: 'Create page',
			value: true,
		},
		{
			title: 'Upload profile image',
			value: hasLogo,
			onClick: () => {
				handleShowUploadCrop()
			},
		},
		{
			title: 'Upload cover photo/video',
			value: hasCover || featuredVideo,
			onClick: () => {
				triggerFileSelect()
			},
		},
		{
			title: 'Create teams',
			value: hasCompanyTeams,
			onClick: () => {
				toggleAddTeamModal()
			},
		},
		{
			title: 'Create jobs',
			value: hasCompanyJobs,
			onClick: () => {
				history.push({
					pathname: `/${i18n.language}/create-job`,
				})
			},
		},
		{
			title: 'Write a story',
			value: hasCompanyArticles,
			onClick: () => {
				handleGoToCreateStory()
			},
		},
	])

	useEffect(() => {
		setSteps([
			{
				title: 'Create page',
				value: true,
			},
			{
				title: 'Upload profile image',
				value: hasLogo,
				onClick: () => {
					handleShowUploadCrop()
				},
			},
			{
				title: 'Upload cover photo/video',
				value: hasCover || featuredVideo,
				onClick: () => {
					triggerFileSelect()
				},
			},
			{
				title: 'Create teams',
				value: hasCompanyTeams,
				onClick: () => {
					toggleAddTeamModal()
				},
			},
			{
				title: 'Create jobs',
				value: hasCompanyJobs,
				onClick: () => {
					history.push({
						pathname: `/${i18n.language}/create-job`,
					})
				},
			},
			{
				title: 'Write a story',
				value: hasCompanyArticles,
				onClick: () => {
					handleGoToCreateStory()
				},
			},
		])
	}, [
		hasLogo,
		hasCover,
		hasCompanyTeams,
		hasCompanyArticles,
		handleGoToCreateStory,
		handleShowUploadCrop,
		toggleAddTeamModal,
		triggerFileSelect,
		hasCompanyJobs,
		history,
		i18n.language,
		featuredVideo,
	])

	let stepsChecked = 0
	let completed1 = 0

	steps.forEach(item => {
		if (item.value) stepsChecked++
	})
	completed1 = Math.ceil((stepsChecked / 6) * 100)

	return (
		<Card
			style={{ width: '650px' }}
			classes={{
				root: classes.cardRoot,
			}}
		>
			<CardContent
				classes={{
					root: classes.cardContent,
				}}
			>
				<div className={classes.cardContentTitle}>
					{t('Your new company page is ready!')}
				</div>
				<div>{t('Tell visitor more about your company!')}</div>
				<div style={{ marginTop: '10px' }}>
					<LinearProgress
						variant="determinate"
						color="primary"
						value={completed1}
						classes={{
							barColorPrimary: classes.colorPrimary,
							root: classes.progressRoot,
							bar1Determinate: classes.bar1Determinate,
						}}
					/>
				</div>
				<div className={classes.progressSteps}>
					{steps.map((item: any, index: any) => {
						let cursorStyle =
							index !== 0 ? { cursor: 'pointer' } : undefined
						return (
							<div
								key={`key-${index}`}
								className={classes.progressStepsItem}
							>
								<Fab
									disabled={!item.value}
									className={classes.stepItem}
									classes={{
										disabled: classes.fabDisabled,
									}}
								>
									{item.value ? <CheckIcon /> : ''}
								</Fab>
								<span
									style={cursorStyle}
									onClick={
										item.onClick ? item.onClick : undefined
									}
									className={
										item.value
											? classes.stepItemTitle
											: classes.stepTitleDisabled
									}
								>
									<Trans i18nKey={item.title} />
								</span>
							</div>
						)
					})}
				</div>
			</CardContent>
		</Card>
	)
}
export default memo(CompanyPageProgress)
