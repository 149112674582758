import gql from 'graphql-tag'
import {
	AuthenticationUserFragment,
	UserProfileFragment,
	UserProfileArticlesFragment,
} from 'store/fragments'

// Queries
export const UserQuery = gql`
	query user($id: String, $uid: String) {
		user(id: $id, uid: $uid) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`

export const UserProfileQuery = gql`
	query userProfile($uid: String!) {
		userProfile(data: { uid: $uid }) {
			...UserProfile
		}
	}
	${UserProfileFragment}
`

export const UsersProfileQuery = gql`
	query usersProfile($data: UsersProfileInput) {
		usersProfile(data: $data) {
			...UserProfile
		}
	}
	${UserProfileFragment}
`

export const UserProfilesByNameQuery = gql`
	query userProfilesByName($data: UserProfilesByNameInput) {
		userProfilesByName(data: $data) {
			...UserProfile
		}
	}
	${UserProfileFragment}
`

export const UserProfilesByEmailQuery = gql`
	query userProfilesByEmail($data: UserProfilesByEmailInput) {
		userProfilesByEmail(data: $data) {
			...UserProfile
		}
	}
	${UserProfileFragment}
`

export const UserProfileArticlesQuery = gql`
	query userProfile($uid: String!) {
		userProfile(data: { uid: $uid }) {
			...UserProfileArticles
		}
	}
	${UserProfileArticlesFragment}
`

export const CheckUsername = gql`
	query checkUsername($uid: String!) {
		checkUsername(uid: $uid) {
			status
		}
	}
`

export const CheckEmail = gql`
	query checkEmail($email: String!) {
		checkEmail(email: $email) {
			status
		}
	}
`

// MUATTIONS

export const UpdateProfileDetails = gql`
	mutation handleProfile($details: ProfileInput!) {
		handleProfile(data: $details) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`

export const UpdateUserValues = gql`
	mutation setValues($values: [String!]!, $userId: String) {
		setValues(values: $values, userId: $userId) {
			...UserProfile
		}
	}
	${UserProfileFragment}
`

export const UpdateUserSkills = gql`
	mutation setSkills($skills: [String!]!) {
		setSkills(skills: $skills) {
			id
			title
		}
	}
`

export const UpdateUsername = gql`
	mutation updateUsername($userId: String, $username: String!) {
		updateUsername(userId: $userId, username: $username) {
			status
			error
		}
	}
`

export const SendUpdateEmail = gql`
	mutation sendUpdateEmailLink(
		$language: LanguageCodeType!
		$newEmail: String!
	) {
		sendUpdateEmailLink(language: $language, newEmail: $newEmail) {
			status
			error
		}
	}
`

export const ConfirmUpdateEmail = gql`
	mutation updateEmail($token: String!) {
		updateEmail(token: $token) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`

export const UpdateUserSettings = gql`
	mutation updateUserSettings($userSettings: UserSettingsInput) {
		updateUserSettings(userSettings: $userSettings) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`

export const SetUpProfile = gql`
	mutation setUpProfile($data: SetUpProfileInput) {
		setUpProfile(data: $data) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`
export const SetCVFile = gql`
	mutation setCVFile($cvFilePath: [String], $cvFileOriginalName: [String]) {
		setCVFile(
			cvFilePath: $cvFilePath
			cvFileOriginalName: $cvFileOriginalName
		) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`
export const SetUserOnBoarding = gql`
	mutation setUserOnBoarding($language: LanguageCodeType!) {
		setUserOnBoarding(language: $language) {
			status
			error
		}
	}
`
