import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		teamItem: {
			border: '1px solid #ebebeb',
			borderRadius: '7px',
			margin: '35px 0px',
			position: 'relative',
			'&:hover': {
				cursor: 'pointer',
			},
			backgroundColor: '#ffffff',
		},
		teamResourceContainer: {
			maxHeight: '260px',
			overflow: 'hidden',
			borderRadius: '8px 0px 0px 8px',
			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
		},
		video: {
			display: 'flex',
			'&:hover': {
				cursor: 'pointer',
			},
			position: 'relative',
			paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
		},
		player: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			'& iframe': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},
		},
		teamItemInformation: {
			padding: '35px 10px 10px 10px',
			position: 'relative',
			maxHeight: '260px',
			wordBreak: 'break-word',
		},
		teamItemTitle: {
			fontSize: '16px',
			color: '#424242',
			marginBottom: '0px',
			fontWeight: 700
		},
		teamItemDescription: {
			fontSize: '14px',
			color: '#424242',
		},
		badgeExtra: {
			position: 'absolute',
			left: theme.spacing(2),
			top: -1 * theme.spacing(4),
			[theme.breakpoints.down('sm')]: {
				left: theme.spacing(1),
				maxWidth: theme.spacing(30),
				[theme.breakpoints.down('xs')]: {
					maxWidth: theme.spacing(27),
				}

			},
		},
		locationIcon: {
			fill: '#7c7c7c',
			fontSize: '1.3rem',
			marginRight: 2,
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.1rem',
			},
		},
		location: {
			position: 'absolute',
			right: theme.spacing(2),
			top: '-14px',
			fontWeight: 300,
			color: '#989898',
			fontSize: 12,
			lineHeight: '18px',
			border: `1px solid #ebebeb`,
			borderRadius: 12,
			padding: `${theme.spacing(0.5)}px 10px`,
			backgroundColor: '#ffffff',
			display: 'flex',
			alignItems: 'center',
			'&>span': {
				// verticalAlign: 'super',
				color: '#595959',
				fontSize: '13px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: theme.spacing(1.2),
				right: theme.spacing(1),
			}
		},
	})
