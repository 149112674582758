export const s3BucketURL = `https://s3.eu-west-1.amazonaws.com/assets${
	process.env.REACT_APP_ENVIRONMENT === 'production' ? '' : '-dev'
}.cv30.co`
export const articlesFolder = 'articles'
export const profilesFolder = 'profiles'
export const storiesFolder = 'stories'
export const teamsFolder = 'teams'
export const companiesFolder = 'companies'
export const jobsFolder = 'jobs'
export const adsFolder = 'promo'
