import React, { memo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styles from '../styles'
import { buildUserAvatar } from 'utils'

const useStyles = makeStyles(styles)

const TeamMemberRow: React.FunctionComponent<any> = ({
	teamMember,
	handleDeleteTeamMember,
	bCanEdit,
}) => {
	const history = useHistory()
	const { i18n } = useTranslation()
	const { firstName, lastName, avatarPath } = teamMember.user.profile
	const { company, position } = teamMember.user.profile.worksAt
	const classes = useStyles()

	const goToMemberProfile = useCallback(
		(memberUid: string) => () => {
			history.push({
				pathname: `/${i18n.language}/people/${memberUid}`,
			})
		},
		[history, i18n],
	)

	return (
		<Grid item className={classes.cardItemWrap}>
			<div className={classes.cardItem}>
				<div
					className={classes.avatarContent}
					onClick={goToMemberProfile(teamMember.user.uid)}
				>
					<div>
						<Avatar
							classes={{ root: classes.profilePicture }}
							src={buildUserAvatar(
								teamMember.user.id,
								avatarPath,
							)}
						/>

						<div className={classes.details}>
							<div className={classes.name}>
								{`${firstName} ${lastName}`}
							</div>
							<span
								className={classes.role}
							>{`${company} ${position}`}</span>
						</div>
					</div>
					{bCanEdit && (
						<div>
							<span
								className={'removeCardIcon'}
								onClick={event =>
									handleDeleteTeamMember(event, teamMember.id)
								}
							>
								X
							</span>
						</div>
					)}
				</div>
			</div>
		</Grid>
	)
}
export default memo(TeamMemberRow)
