import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		companyContainer: {
			alignItems: 'center',
			justifyContent: 'center',
		},
		companyMainContent: {
			maxWidth: '670px',
			width: '100%',
			marginBottom: '30px',
			padding: '0px 10px',
			position: 'relative',
		},
		mainInfoWrap: {
			marginTop: '10px',
		},
		companySection: {
			marginTop: '30px',
		},
		tabLabelContainer: {
			color: theme.palette.grey[900],
			fontSize: '16px',
			fontWeight: 500,
			cursor: 'pointer',
			marginRight: '28px',
			paddingBottom: '10px',
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
			},
		},
		selectedTabLabelContainer: {
			color: theme.palette.primary.main,
			borderBottom: '3px solid',
			cursor: 'initial',
		},
		sectionTitle: {
			fontSize: '32px',
			lineHeight: '1.2em',
			fontWeight: 300,
			marginTop: '0px',
			marginBottom: '10px',
			color: theme.palette.primary.main,
			[theme.breakpoints.down('sm')]: {
				fontSize: '20px',
			},
		},
		emphasizedText: {
			fontWeight: 700,
			display: 'block',
			color: theme.palette.primary.main,
		},
		sectionContent: {
			fontSize: '16px',
			color: theme.palette.grey[600],
			marginTop: 10,
			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
			},
		},
		teamLifeSection: {
			width: '100%',
		},
		teamBadgesContainer: {
			margin: '20px 0px',
		},
		teamBadge: {
			margin: '5px 5px 5px 0px',
		},
		companyDetailsIcon: {
			height: '20px',
			marginRight: '2px',
		},
		companyMainInformationItem: {
			color: theme.palette.grey[300],
			fontSize: '14px',
			fontWeight: 400,
			borderLeft: '2px solid #989898',
			height: '12px',
			lineHeight: '12px',
			padding: '0px 20px 0px 10px',
			margin: '5px 0px',
		},
		companyDetailsAddressWrap: {
			display: 'flex',
		},
		companyDetailsInformation: {
			marginTop: '20px',
			marginBottom: '10px',
		},
		companyDetailsInformationItemContainer: {
			display: 'flex',
			margin: '5px 20px 0px 0px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		companyDetailsInformationLink: {
			display: 'flex',
			textDecoration: 'none',
		},
		companyDetailsInformationItem: {
			color: theme.palette.grey[300],
			fontSize: '14px',
			fontWeight: 700,
		},
		additionalLocations: {
			color: '#1b1a7c',
			fontWeight: 700,
			marginLeft: '5px',
		},
		qaIcon: {
			width: '18px',
			height: '18px',
			minWidth: '18px',
			minHeight: '18px',
			borderRadius: '50%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: '18px',
			fontWeight: 700,
			cursor: 'pointer',
			marginTop: '3px',
			marginRight: '10px',
			lineHeight: '18px',
		},
		expandIcon: {
			backgroundColor: '#e4e4f2',
			color: '#1b1a7c',
		},
		collapseIcon: {
			backgroundColor: '#1b1a7c',
			color: '#FFF',
		},
		teamLifeItem: {
			border: '1px solid #ebebeb',
			borderRadius: '7px',
			margin: '15px 0px',
			overflow: 'hidden',
		},
		teamLifeResourceContainer: {
			maxHeight: '260px',
			overflow: 'hidden',
			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
		},
		teamLifeItemInformation: {
			padding: '10px',
			maxHeight: '260px',
		},
		teamLifeItemTitle: {
			fontSize: '16px',
			color: '#424242',
			marginBottom: '0px',
		},
		teamLifeItemDescription: {
			fontSize: '12px',
		},
		editIconLabel: {
			paddingLeft: 6,
			paddingRight: 6,
		},
		addTeamElement: {
			position: 'absolute',
			top: 5,
			right: 10,
			[theme.breakpoints.down('md')]: {
				top: -10,
				right: 5,
			},
		},
		editRootElement: {
			position: 'absolute',
			top: 10,
			right: 10,
			[theme.breakpoints.down('md')]: {
				top: 0,
				right: 5,
				[theme.breakpoints.only('xs')]: {
					width: 30,
					height: 30,
					'& svg': {
						fontSize: '1.2rem',
					},
				},
			},
		},
		textInputRoot: {
			width: '100%',
		},
		paperWidthMd: {
			width: '670px',
			marginLeft: '10px',
			marginRight: '10px',
		},
		titleSection: {
			display: 'flex',
			flexFlow: 'column',
		},
		inviteModalTitle: {
			color: '#1b1a7c',
			fontSize: 20,
			fontWeight: 'bold',
		},
		inviteModalSubTitle: {
			fontSize: 18,
			fontWeight: 300,
		},
		submitSection: {
			display: 'flex',
			alignItems: 'center',
			flexFlow: 'row',
			marginTop: '10px',
			marginBottom: '10px',
		},
		submitBtn: {
			outline: 'none',
			cursor: 'pointer',
			background: '#1B1A7C',
			fontSize: '14px',
			fontWeight: 'bold',
			color: '#fff',
			borderRadius: '12px',
			marginLeft: 'auto',
			padding: '4px 10px',
			// width: '50px',
		},
		infoText: {
			color: '#1B1A7C',
			fontWeight: 'bold',
		},
		teamResourceContainer: {
			maxHeight: '260px',
			overflow: 'hidden',
			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
		},
		teamItem: {
			border: '1px solid #ebebeb',
			borderRadius: '7px',
			margin: '15px 0px',
			overflow: 'hidden',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		teamItemInformation: {
			position: 'relative',
			padding: '10px',
			maxHeight: '260px',
			backgroundColor: '#ffffff',
		},
		teamItemTitle: {
			width: '100%',
			overflowWrap: 'break-word',
			fontSize: '16px',
			color: '#424242',
			marginBottom: '0px',
			fontWeight: 700
		},
		teamItemDescription: {
			width: '100%',
			overflowWrap: 'break-word',
			fontSize: '14px',
			color: '#424242',
		},
		video: {
			display: 'flex',
			'&:hover': {
				cursor: 'pointer',
			},
			position: 'relative',
			paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
		},
		player: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			'& iframe': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},
		},
		addStoryRoot: {
			borderRadius: '5px',
			backgroundColor: '#ebebeb',
			display: 'flex',
			flex: 1,
			flexFlow: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		storyPlaceholder: {
			color: theme.palette.grey[500],
			fontSize: 16,
			marginBottom: '2px',
			marginTop: '12px',
			marginLeft: '8px',
		},
		addStoryBtn: {
			textTransform: 'none',
			height: '35px !important',
			marginBottom: '15px',
		},
		formControl: {
			marginTop: '13px',
			width: '100%',
		},
		actionTitle: {
			paddingTop: 9,
			paddingRight: 5,
			marginLeft: 'auto',
			cursor: 'pointer',
		},
		textField: {
			width: '100%',
		},
		labelTextField: {
			color: '#727272',
		},
		input: {
			color: '#424242',
			fontSize: '14px',
		},
		labelText: {
			color: '#727272',
			fontSize: '12px',
			marginBottom: '3px',
		},
		tooltipCustomWidth: {
			maxWidth: 500,
			fontSize: 14,
		},
		required: {
			marginLeft: 4,
			fontSize: 10,
			fontStyle: 'italic',
		},
	})
