import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import styles from './styles'

interface IPrimaryButton {
	label: string
	onClick:
		| ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
		| undefined
}

const useStyles = makeStyles(styles)

const PrimaryButton: React.FunctionComponent<IPrimaryButton> = ({
	label,
	onClick,
}) => {
	const classes = useStyles()
	return (
		<Button
			variant="contained"
			color="primary"
			classes={{ root: classes.button }}
			onClick={onClick}
		>
			{label}
		</Button>
	)
}
export default memo(PrimaryButton)
