export * from './s3'
export * from './functions'
export * from './useOnScreen'
export * from './uris'
export * from './teamData'
export * from './globalEnums'
export * from './truncateHTML'
export * from './useCheckUserPermission'
export * from './parseQuery'
export { default as PlaceholderContent } from './placeholderContent'

const keys: any = {
	local: 'vYA6mB5F5C4H4C2C7dzlF5A3C2A-7pC-22C4A3C3B2D4F2D2C3A2D3==',
	development: 'vYA6mB5F5C4H4C2C7dzlF5A3C2A-7pC-22C4A3C3B2D4F2D2C3A2D3==',
	production: 'vYA6mB5F5C4H4C2C7dzlF5A3C2A-7pC-22C4A3C3B2D4F2D2C3A2D3==',
}

// @ts-ignore
export const FROALA_KEY = keys[process.env.REACT_APP_ENVIRONMENT]

export const TOKEN_HEADER =
	'$2y$12$iL6jraxFdRgCTGc9L7q5COQIIVMgIgEopV7WT52XDj3.OxLUhMwEK'
export const REFRESH_TOKEN_HEADER =
	'$2y$12$jyCIIA8HOWqIby7C9qUNWOo7SDJa/XBsP3oIdKJtvmLjvOwouLUJa'
