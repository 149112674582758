import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		button: {
			[theme.breakpoints.down('sm')]: {
				padding: '2px',
			},
			height: theme.spacing(3),
		},
	})
