import React, { memo, useCallback } from 'react'
import {
	DialogTitle,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import styles from './styles'
import { buildUserAvatar } from 'utils'
import { useHistory } from 'react-router'
import { ArticleTagVotersQuery } from 'store'

const useStyles = makeStyles(styles)

export const ArticleTagVoters = memo(({ articleId }: { articleId: string }) => {
	const classes = useStyles()

	const { data } = useQuery(ArticleTagVotersQuery, {
		variables: { articleId },
	})

	return (
		data?.getArticleTagVoters?.length > 0 &&
		data?.getArticleTagVoters
			?.filter(
				(articleTagVoter: any) => articleTagVoter.voters.length > 0,
			)
			.map((articleTagVoter: any) => (
				<div key={articleTagVoter?.tag?.id}>
					<DialogTitle
						id="responsive-dialog-title"
						className={classes.dialogTitle}
					>
						{articleTagVoter.tag.title}
					</DialogTitle>
					<Divider
						className={classes.dividerTitle}
						light
						variant="middle"
					/>
					<List className={classes.cardItemWrap}>
						<div className={classes.cardItem}>
							<div className={classes.avatarContent}>
								{articleTagVoter?.voters?.map((voter: any) => (
									<ArticleTagVoter
										key={voter.id}
										voter={voter}
									/>
								))}
							</div>
						</div>
						<Divider className={classes.dividerTag} flexItem />
					</List>
				</div>
			))
	)
})

const ArticleTagVoter = memo(({ voter }: any) => {
	const classes = useStyles()
	const history = useHistory()
	const { i18n } = useTranslation()

	const handleListItemClick = useCallback(() => {
		history.push({
			pathname: `/${i18n.language}/people/${voter?.user?.uid}`,
		})
	}, [history, i18n.language, voter])

	return (
		<ListItem
			button
			onClick={handleListItemClick}
			key={voter?.user?.uid + voter?.tag?.id}
		>
			<ListItemAvatar key={voter?.id + voter?.tag?.id}>
				{voter?.avatarPath ? (
					<div>
						<Avatar
							key={voter?.id + voter?.tag?.id}
							classes={{
								root: classes.profilePicture,
							}}
							src={buildUserAvatar(
								voter?.user?.id,
								voter?.avatarPath,
							)}
						/>
					</div>
				) : (
					<Avatar
						classes={{
							root: classes.profilePicture,
						}}
					/>
				)}
			</ListItemAvatar>
			<div className={classes.details}>
				<ListItemText
					key={voter?.user?.uid + voter?.tag?.id}
					className={classes.name}
					primary={`${voter?.firstName} ${voter?.lastName}`}
				/>
				<span className={classes.role}>
					{voter?.worksAt?.company
						? `${voter?.worksAt?.company}`
						: ' '}
					{'  '}
					{voter?.worksAt?.position
						? `${voter?.worksAt?.position}`
						: ` `}
				</span>
			</div>
		</ListItem>
	)
})
