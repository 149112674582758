import React, { useContext, useReducer, createContext } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'

import initialState from './initial'
import reducer from './reducers'
import client from './apollo'
import { IAppState } from 'models'

// interface AppInterface {
// initialState: AppState
// reducer: React.Reducer<AppState, React.ReducerAction<any>>
// }

// export const firebaseApp = firebase.initializeApp({
// 	apiKey: 'AIzaSyD3BQ3mNupKYAYNyVMAIUEkvIIWuTMUxMM',
// 	authDomain: 'cv30dev-feda5.firebaseapp.com',
// 	databaseURL: 'https://cv30dev-feda5.firebaseio.com',
// 	projectId: 'cv30dev-feda5',
// 	storageBucket: 'cv30dev-feda5.appspot.com',
// 	messagingSenderId: '956898530395',
// 	appId: '1:956898530395:web:04a62746a7d8cbf9',
// })

const AppContext: React.Context<[
	IAppState,
	React.Dispatch<any>,
]> = createContext<any>(null)

export const AppProvider: React.FunctionComponent<{}> = ({ children }) => {
	return (
		<ApolloProvider client={client}>
			<AppContext.Provider value={useReducer(reducer, initialState)}>
				{children}
			</AppContext.Provider>
		</ApolloProvider>
	)
}

// new version of store
interface IStoreValue {
	children: any
	keys: any
	// TODO: Update the interface with proper types
}

export const StoreValue = ({ children, keys = [] }: IStoreValue) => {
	let [state, storeDispatch] = useContext(AppContext)
	const keysMap: any = {}
	keys.map((item: any) => {
		// const temp =
		// 	item.indexOf("|") === -1
		// 		? item.split(".")
		// 		: item.split("|")[0].split(".");
		// if (temp.length === 1)
		// 	keysMap[item] = item;
		// else
		keysMap[item] =
			item.indexOf('|') === -1
				? item.split('.')
				: item.split('|')[0].split('.')
		return undefined
	})
	let result: { [key: string]: any } = []
	keys.map((item: any) => {
		const last =
			item.indexOf('|') === -1
				? [...keysMap[item]].reverse()[0]
				: item.split('|')[1]
		if (typeof keysMap[item] === 'object') {
			result[last] = keysMap[item].reduce(
				(a: any, c: any) => (a !== null ? a[c] : null),
				state,
			)
		} else {
			result[last] = state[keysMap[item]]
		}
		return undefined
	})

	if (keys.indexOf('storeDispatch') !== -1)
		result['storeDispatch'] = storeDispatch

	// TODO: update with a proper interface.
	let elements: any = React.Children.toArray(children)
	if (elements.length === 1) {
		elements = React.cloneElement(elements[0], result)
	} else if (elements.length > 0) {
		for (let i in elements) {
			elements[i] = React.cloneElement(elements[i], result)
		}
	}
	return <>{elements}</>
}

export const useStoreValue = () => useContext(AppContext)
