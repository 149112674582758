import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		section: {
			marginTop: '30px',
			width: '100%',
		},
		sectionTitle: {
			fontSize: '32px',
			lineHeight: '1.2em',
			fontWeight: 300,
			marginTop: '0px',
			marginBottom: '10px',
			position: 'relative',
			color: '#1b1a7c',
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				fontSize: '22px',
				[theme.breakpoints.only('xs')]: {
					fontSize: '20px',
				},
			},
		},
		sectionMainTitle: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '20px',
				[theme.breakpoints.only('xs')]: {
					fontSize: '16px',
				},
			},
		},
		inlineEmphasizedText: {
			fontWeight: 700,
			display: 'inline',
			color: '#1b1a7c',
		},
		blockEmphasizedText: {
			fontWeight: 700,
			display: 'block',
			color: '#1b1a7c',
			[theme.breakpoints.down('sm')]: {
				height: '20px',
				[theme.breakpoints.only('xs')]: {
					height: '16px',
				},
			},
		},
		sectionContent: {
			width: '100%',
			fontSize: '16px',
			lineHeight: '21px',
			fontWeight: 300,
			color: theme.palette.grey[600],
			marginTop: 10,
			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',

			},
		},
		editIconLabel: {
			paddingLeft: 6,
			paddingRight: 6,
		},
		editRootElement: {
			position: 'absolute',
			top: 0,
			right: 0,
			[theme.breakpoints.down('md')]: {
				top: 0,
				right: 5,
				[theme.breakpoints.only('xs')]: {
					width: 30,
					height: 30,
					'& svg': {
						fontSize: '1.2rem'
					}
				}
			},
		},
		saveEdit: {
			position: 'absolute',
			right: 0,
			top: 6,
			fontSize: 16,
			border: '1px solid #FC2652',
			padding: '0 8px',
			borderRadius: 12,
			color: '#FC2652',
			cursor: 'pointer',
		},
		saveSuccess: {
			position: 'absolute',
			right: 0,
			top: 6,
			fontSize: 13,
			color: '#FC2652',
		},
	})
