import React, { memo, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/styles'
import { matchPath, useLocation } from 'react-router-dom'

import styles from './styles'
import { SearchInput, LeftMenu, RightMenu } from './components'

const useStyles = makeStyles(styles)

const Header: React.FunctionComponent<any> = ({ section, data }) => {
	const classes = useStyles()
	const location = useLocation()
	if (
		matchPath(location.pathname, {
			path: '/:language(en|ro)/auth',
			exact: false,
		}) ||
		matchPath(location.pathname, {
			path: '/oauth',
			exact: false,
		})
	)
		return null
	if (
		matchPath(location.pathname, {
			path: '/:language(en|ro)/(create|edit|create-company|create-story)',
			exact: true,
		})
	)
		return null
	if (
		matchPath(location.pathname, {
			path: '/:language(en|ro)/employers/:companySlug(.*)/create-story',
			exact: true,
		})
	)
		return null
	if (
		matchPath(location.pathname, {
			path: '/:language(en|ro)/terms_and_conditions',
			exact: true,
		})
	)
		return null
	if (
		matchPath(location.pathname, {
			path: '/:language(en|ro)/privacy',
			exact: true,
		})
	)
		return null
	if (
		matchPath(location.pathname, {
			path: '/:language(en|ro)/:companySlug/jobs/:action(edit|create)/:jobSlug?/:jobId?',
			exact: true,
		})
	)
		return null
	if (
		matchPath(location.pathname, {
			path: '/studentskit2023',
			exact: true,
		})
	)
		return null
	if (
		matchPath(location.pathname, {
			path: '/cp2022',
			exact: true,
		})
	)
		return null

	return (
		<div className={classes.root}>
			<AppBar
				position="static"
				color="default"
				elevation={2}
				className={classes.colorDefault}
			>
				<Toolbar className={classes.toolbar} disableGutters>
					<LeftMenu />
					<SearchInput />
					<RightMenu />
				</Toolbar>
			</AppBar>
		</div>
	)
}

export const SearchInputContext: React.Context<any> =
	React.createContext<any>(null)

const HeaderHOC: React.FunctionComponent<any> = props => {
	const isFocusedState = useState(false)

	return (
		<SearchInputContext.Provider value={isFocusedState}>
			<Header {...props} />
		</SearchInputContext.Provider>
	)
}

export default memo(HeaderHOC)
