import gql from 'graphql-tag'

import { pageInfoData } from './common'
import { AuthenticationUserFragment } from './user'

export const standardJobResult = gql`
	fragment standardJobResult on Job {
		__typename
		id
		slug
		description
		title
		expiryDate
		contractType
		seniorityLevel
		jobType
		locationType
		featuredImage
		videoUrl
		applyJobExternalUrl
		company {
			__typename
			id
			logoPath
			hasCover
			hasLogo
			name
			slug
			description
			coverPath
			featuredVideo
			industry {
				__typename
				id
				key
			}
		}
		teams {
			__typename
			id
			name
			slug
			description
			hasCover
			coverPath
			members {
				id
				user {
					id
					profile {
						id
						firstName
						lastName
					}
				}
			}
		}
		teamLeader
		salary {
			id
			__typename
			currency
			amountMin
			amountMax
			rate
		}
		benefits
		skills {
			__typename
			id
			key
			title
		}
		locations {
			__typename
			id
			location {
				__typename
				id
				name
				region2
			}
		}
		applicants {
			__typename
			id
		}
		checkLoggedUserApplication
	}
`

export const jobConnectionData = gql`
	fragment jobConnectionData on JobsConnection {
		edges {
			node {
				...standardJobResult
			}
			cursor
		}
		pageInfo {
			...pageInfoData
		}
	}
	${standardJobResult}
	${pageInfoData}
`
export const jobApplicantsConnectionData = gql`
	fragment jobApplicantsConnectionData on JobApplicantsConnection {
		edges {
			node {
				user {
					...AuthenticationUser
				}
				createdAt
			}
			cursor
		}
		pageInfo {
			...pageInfoData
		}
	}
	${AuthenticationUserFragment}
	${pageInfoData}
`
