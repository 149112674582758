import React, { memo, useContext, useCallback, useEffect } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'

import styles from './styles'
import { SearchInputContext } from '../component'
import Cancel from './cancelButton'
import { EAppStateActionTypes, THeaderSearchInputProps } from 'models'
import { StoreValue } from 'store'
import { useDebounce } from 'utils'

const useStyles = makeStyles(styles)

const SearchInput: React.FunctionComponent<THeaderSearchInputProps> = ({
	storeDispatch,
	storeSearchQuery,
	storeSearchSection,
}) => {
	const {
		state,
	}: { state: { seeEmployersAndTeamsSection?: boolean } } = useLocation()
	const history = useHistory()
	const classes = useStyles()
	const { t, i18n } = useTranslation()
	const theme: Theme = useTheme()
	const debouncedSearchQuery = useDebounce(storeSearchQuery, 500)
	const [isFocused, setIsFocused] = useContext(SearchInputContext)
	const shouldShowClose =
		(useMediaQuery(theme.breakpoints.up('md')) && isFocused) || false
	const updateQueryHandle = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	) => {
		storeDispatch({
			type: EAppStateActionTypes.updateSearchLoading,
			loading: e.target.value.trim() ? true : false,
		})
		storeDispatch({
			type: EAppStateActionTypes.updateQuery,
			query: e.target.value,
		})
	}

	const onFocus = (e: React.FocusEvent<any>) => {
		setIsFocused(true)
		if (
			(state && state.seeEmployersAndTeamsSection) ||
			storeSearchSection !== ''
		) {
			return
		}
		history.push(`/${i18n.language}/search`)
	}
	const onClear = useCallback(() => {
		storeDispatch({
			type: EAppStateActionTypes.updateQuery,
			query: '',
		})
	}, [storeDispatch])

	useEffect(() => {
		//if (debouncedSearchQuery) {
		storeDispatch({
			type: EAppStateActionTypes.updateDebounceQuery,
			debounceQuery: debouncedSearchQuery,
		})
		//}
	}, [debouncedSearchQuery, storeDispatch])

	return (
		<>
			<div
				className={classNames(classes.container, classes.search, {
					[classes.marginLeftRight]: isFocused,
				})}
			>
				<div
					className={classNames(classes.searchIcon, {
						[classes.closeIcon]:
							storeSearchQuery && storeSearchQuery.length > 0,
					})}
					onClick={onClear}
				>
					{!storeSearchQuery || storeSearchQuery.length === 0 ? (
						<SearchIcon />
					) : (
						<CloseIcon />
					)}
				</div>
				<InputBase
					onFocus={onFocus}
					type="text"
					value={storeSearchQuery}
					onChange={updateQueryHandle}
					placeholder={
						state && state.seeEmployersAndTeamsSection
							? t('searchEmployersAndTeamsPlaceholder')
							: t('searchPlaceholder')
					}
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput,
					}}
				/>
			</div>
			{shouldShowClose && <Cancel />}
		</>
	)
}

export default memo((props: THeaderSearchInputProps) => (
	<StoreValue
		keys={[
			'storeDispatch',
			'search.query|storeSearchQuery',
			'search.section|storeSearchSection',
			'search.loading|storeSearchLoading',
		]}
	>
		<SearchInput {...props} />
	</StoreValue>
))
