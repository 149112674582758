import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		entityHeader: {
			alignItems: 'center',
			height: 'auto',
			// maxHeight:'500px',
			paddingTop: '10px',
			backgroundColor: '#ebebeb',
			'-webkit-box-shadow': '0px 1px 5px 0px rgba(235,235,235,1)',
			'-moz-box-shadow': '0px 1px 5px 0px rgba(235, 235, 235, 1)',
			boxShadow: '0px 1px 5px 0px rgba(235, 235, 235, 1)',
			flexFlow: 'column',
		},
		entityHeaderContent: {
			justifyContent: 'space-between',
			maxWidth: '670px',
			paddingLeft: '10px',
			paddingRight: '10px',
			width: '100%',
			height: '100%',
			position: 'relative',
			marginTop: '48px',
		},
		entityCompanyProgress: {
			maxWidth: '670px',
			paddingLeft: '10px',
			paddingRight: '10px',
		},
		headerImageContainer: {
			height: '260px',
			overflow: 'hidden',
			borderRadius: '5px',
			'-webkit-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.2)',
			'-moz-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.2)',
			boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.2)',
			marginBottom: '20px',
		},
		menuHeaderWrap: {
			// paddingTop: '5px'
		},
		headerBadge: {
			zIndex: 1,
			position: 'absolute',
			top: '-31px',
			left: '20px',
			'-webkit-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.2)',
			'-moz-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.2)',
			boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.2)',
		},
		headerImage: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
		tabLabelContainer: {
			color: '#424242',
			fontFamily: 'Roboto',
			fontSize: '16px',
			fontWeight: 500,
			cursor: 'pointer',
			marginRight: '28px',
			paddingBottom: '10px',
			paddingTop: '5px',
		},
		selectedTabLabelContainer: {
			color: '#1b1a7c',
			borderBottom: '3px solid',
			cursor: 'initial',
		},
		addCoverPhoto: {
			position: 'absolute',
			top: 0,
			right: 56,
			'& svg': {
				'-webkit-filter': 'drop-shadow(3px 3px 2px rgba(0, 0, 0, .7))',
				filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, .7))',
			},
		},
		teamAddCoverPhoto: {
			right: 265,
			'@media (max-width: 500px) and (min-width: 350px)': {
				right: 190,
			},
			'@media (max-width: 349px)': {
				right: 150,
			},
		},
		addCoverVideo: {
			position: 'absolute',
			top: 1,
			right: 16,
			'& svg': {
				'-webkit-filter': 'drop-shadow(3px 3px 2px rgba(0, 0, 0, .7))',
				filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, .7))',
			},
		},
		teamAddCoverVideo: {
			right: 230,
			'@media (max-width: 500px) and (min-width: 350px)': {
				right: 150,
			},
			'@media (max-width: 349px)': {
				right: 120,
			},
		},
		textInputRoot: {
			width: '100%',
		},
		video: {
			display: 'flex',
			position: 'relative',
			paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
		},
		player: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			'& iframe': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},
		},
		loader: {
			alignSelf: 'center',
			margin: 'auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			'& > span': {
				fontSize: 12,
				fontWeight: 'bold',
				color: '#888',
				marginRight: 8,
			},
		},
		alignRight: {
			marginRight: 0,
			marginLeft: 'auto',
			'& > svg': {
				width: 18,
				marginRight: 4,
				marginTop: -4,
				verticalAlign: 'middle',
			},
		},
		viewAsMember: {
			// '-webkit-box-shadow': '0px 2px 4px 0px rgba(0,0,0,0.4)',
			// '-moz-box-shadow': '0px 2px 4px 0px rgba(0, 0, 0, 0.4)',
			// boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.4)',
			zIndex: 1,
			background: 'transparent',
			border: '1px solid #ffffff',
			color: '#ffffff',
			'-webkit-filter': 'drop-shadow(3px 3px 2px rgba(0, 0, 0, .7))',
			filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, .7))',
			// '> span': {
			// 	textShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.4)',
			// },
		},
		teamHeaderRoot: {
			'-webkit-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.2)',
			'-moz-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.2)',
			boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.2)',
			borderRadius: '5px 0 0 5px',
			overflow: 'hidden',
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
		teamHeadCont: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
		teamResourceContainer: {
			position: 'relative',
			height: '100%',
			overflow: 'hidden',

			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
		},
		teamImgPlaceholder: {
			backgroundColor: 'rgba(27, 26, 124, 0.1)',
			width: '100%',
			height: '100%',
			borderRadius: '5px 0 0 5px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		teamHeadDescriptionRoot: {
			width: '100%',
			height: '100%',
			backgroundColor: '#fff',
			display: 'flex',
			flexDirection: 'column',
		},
		teamHeadTitle: {
			margin: '10px',
			textTransform: 'capitalize',
			color: theme.palette.grey[800],
			fontWeight: 700,
		},
		teamHeadDesc: {
			color: theme.palette.grey[800],
			fontWeight: 500,
			margin: '0 10px',
			overflowWrap: 'break-word',
		},
		backBtn: {
			position: 'absolute',
			top: '15px',
			left: '20px',
			background: 'rgba(0, 0, 0, 0.2)',
			color: '#fff',
			border: '1px solid #fff',
			borderRadius: '20px',
			padding: '2px 0px',
			paddingRight: '10px',
			display: 'flex',
			flexFlow: 'row',
			alignItems: 'center',
			textTransform: 'none',
			[theme.breakpoints.only('xs')]: {
				left: 5,
			},
		},
		backBtnText: {
			paddingRight: '2px',
		},
		stickyHeader: {
			position: 'fixed',
			zIndex: 2,
		},

		stickyHeaderContainer: {
			paddingTop: '340px',
		},
		breadcrumbsContainer: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			'& .MuiTypography-body1': {
				lineHeight: '30px',
				marginLeft: '10px',
			},
		},
	})
