export const teamSize: {
	OneFive: string
	SixTen: string
	ElevenFifteen: string
	SixteenTwentyFive: string
	TwentySixFifty: string
	OverFifty: string
	[key: string]: any
} = {
	OneFive: '1-5',
	SixTen: '6-10',
	ElevenFifteen: '11-15',
	SixteenTwentyFive: '16-25',
	TwentySixFifty: '26-50',
	OverFifty: '50+',
}
