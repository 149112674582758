import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		container: {
			paddingTop: theme.spacing(1),
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
			maxHeight: '100%',
			'& > .rcs-custom-scroll': {
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
				'& > .rcs-outer-container': {
					flexGrow: 1,
					display: 'flex',
					flexDirection: 'column',
					'& > .rcs-inner-container': {
						flexGrow: 1,
						display: 'flex',
						flexDirection: 'column',
						'& > div': {
							flexGrow: 1,
							display: 'flex',
							flexDirection: 'column',
						},
					},
				},
			},
			'& .rcs-custom-scroll .rcs-inner-handle': {
				height: 'calc(100% - 12px)',
				marginTop: '6px',
				backgroundColor: `${theme.palette.secondary.light} !important`,
				borderRadius: '3px',
			},
		},
		commnetContainer: {
			position: 'relative',
		},
		avatar: {
			minWidth: '40px!important',
			width: '40px!important',
			height: '40px!important',
			alignSelf: 'center',
			margin: '10px 10px 10px 0',
			borderRadius: 20,
			border: '1px solid #fff',
			background: 'rgba(0, 0, 0, 0.4)',
			padding: 2,
			display: 'flex',
			backgroundSize: 'contain',
			backgroundPosition: 'center',
		},
		commentHeader: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},
		userInfo: {
			'& > div:first-child': {
				fontSize: 16,
				fontWeight: 700,
				[theme.breakpoints.down('sm')]: {
					fontSize: 14,
				},
			},
		},
		extraInfo: {
			display: 'flex',
			flexDirection: 'row',
			'& > span': {
				fontSize: 14,
				[theme.breakpoints.down('sm')]: {
					fontSize: 12,
				},
			},
			'& > span:first-child': {
				fontSize: 14,
				fontWeight: 300,
				color: theme.palette.grey[800],
				[theme.breakpoints.down('sm')]: {
					fontSize: 12,
				},
			},
			'& > span:nth-child(2)': {
				margin: '0px 4px',
				fontWeight: 700,
				[theme.breakpoints.down('sm')]: {
					fontSize: 12,
				},
			},
			'& > span:last-child': {
				fontSize: 14,
				fontWeight: 300,
				color: theme.palette.grey[500],
				[theme.breakpoints.down('sm')]: {
					fontSize: 12,
				},
			},
		},
		text: {
			display: 'inline-flex',
			padding: 10,
			background: '#ebebeb',
			borderRadius: 10,
			marginLeft: 50,
			marginRight: 20,
			wordBreak: 'break-word',
			whiteSpace: 'pre-wrap',
		},
		input: {
			border: '1px solid #ebebeb',
			// margin: '12px 0 0 0',
			margin: 0,
			// padding: 8,
			borderRadius: 16,
			flex: 1,
			alignSelf: 'flex-start',
			color: '#414141',
			'& > textarea': {
				padding: theme.spacing(1),
				resize: 'none',
				maxHeight: '170px !important',
			},
			'&::placeholder': {
				color: '#989898',
			},
		},
		newComment: {
			display: 'flex',
			flexDirection: 'row',
			marginTop: theme.spacing(1),
			marginBottom: 0,
			alignItems: 'flex-end',
			'& div:first-child': {},
		},
		send: {
			background: '#fff',
			right: 4,
			color: theme.palette.grey[300],
			marginLeft: theme.spacing(1),
			textTransform: 'capitalize',
			boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.1)',
			'&:hover': {
				background: '#fff',
			},
			'&[disabled]': {
				border: `1px solid ${theme.palette.grey[300]}`,
				background: '#fff',
				color: theme.palette.grey[300],
			},
			'&:not([disabled])': {
				border: `1px solid ${theme.palette.secondary.main}`,
				color: theme.palette.secondary.main,
			},
		},
		loadMore: {
			paddingTop: theme.spacing(1),
			textAlign: 'center',
			marginBottom: 0,
			marginTop: 'auto',
			'&:hover': {
				color: theme.palette.secondary.main,
				cursor: 'pointer',
			},
		},
		unauthenticated: {
			marginTop: 10,
			padding: 10,
			textAlign: 'center',
			border: '1px solid #ebebeb',
			color: '#c9c9c9',
			'&:hover': {
				borderColor: theme.palette.secondary.main,
				color: theme.palette.secondary.main,
				cursor: 'pointer',
			},
		},
		commentMoreButton: {
			position: 'absolute',
			top: 0,
			right: 0,
			color: theme.palette.grey[400],
		},
		commentMoreMenu: {
			'& > ul': {
				padding: 0,
				'& > li': {
					padding: '0 16px',
					fontSize: 12,
					minHeight: 32,
					color: theme.palette.grey[700],
				},
			},
		},
		emoji: {
			position: 'absolute',
			zIndex: 999,
			'@media (max-width: 599px)': {
				bottom: '67px',
			},
			'@media (min-width:600px)': {
				bottom: '75px',
				borderBottomRightRadius: 16,
				overflow: 'hidden',
				right: '75px',
			},
		},
	})
