import gql from 'graphql-tag'

export const GetUniversities = gql`
	query getUniversities {
		getUniversities {
			id
			name
		}
	}
`
export const GetFaculties = gql`
	query getFaculties($universityId: String!, $facultyName: String) {
		getFaculties(universityId: $universityId, facultyName: $facultyName) {
			id
			name
		}
	}
`

export const ExportStudentsKit = gql`
	query exportStudents {
		exportStudents {
			firstName
			lastName
			email
			university
			faculty
			specialization
			hasAccount
			location
			scanDate
			scanningAgentName
			scanningAgentEmail
		}
	}
`

export const GetFacultySpecializations = gql`
	query getFacultySpecializations(
		$facultyId: String!
		$specializationName: String
	) {
		getFacultySpecializations(
			facultyId: $facultyId
			specializationName: $specializationName
		) {
			id
			name
		}
	}
`

// MUATTIONS

export const AddStudentKit = gql`
	mutation addStudentKit($data: StudentKitInput!) {
		addStudentKit(data: $data) {
			id
			firstName
			lastName
			email
		}
	}
`
export const SendQRCode = gql`
	mutation sendQRCode($language: LanguageCodeType!, $email: String!) {
		sendQRCode(language: $language, email: $email) {
			code
		}
	}
`
export const ConfirmEmail = gql`
	mutation confirmEmail(
		$id: String!
		$pin: String!
		$language: LanguageCodeType!
	) {
		confirmEmail(id: $id, pin: $pin, language: $language) {
			id
			firstName
			lastName
			email
		}
	}
`
