// import { setCookie } from '.'
import { EAppStateActionTypes } from 'models'

export default (state: any, action: any): any => {
	let newState = state
	switch (action.type) {
		case EAppStateActionTypes.setCheckCredentials: {
			newState = {
				...state,
				checkCredentials: action.checkCredentials,
			}

			return newState
		}

		default:
			return newState
	}
}
