import React, { useState, useCallback, memo } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import styles from '../styles'
import company from '../mockdata'

const ExpansionPanel = withStyles({
	root: {
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
		marginBottom: '12px',
	},
	expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
	root: {
		paddingLeft: '0px',
		fontSize: '18px',
		fontWeight: 400,
		color: '#1b1a7c',
		cursor: 'pointer',
		minHeight: 'auto !important',
	},
	content: {
		'&$expanded': {
			margin: '0px',
			minHeight: 'auto',
		},
		margin: '0px',
	},
	expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(theme => ({
	root: {
		padding: '10px',
		border: '1px solid #ebebeb',
		borderRadius: '5px',
		margin: '10px 0px 10px 0px',
		fontSize: '12px',
		color: '#989898',
	},
}))(MuiExpansionPanelDetails)

const useStyles = makeStyles(styles)

const CompanyQA: React.FunctionComponent<{}> = () => {
	const classes = useStyles()
	const { t } = useTranslation()
	const [selectedQA, setSelectedQA] = useState()

	return (
		<Grid
			container={true}
			direction="row"
			className={classes.companySection}
		>
			<Grid item={true}>
				<h2 className={classes.sectionTitle}>{t('Q&A')}</h2>
			</Grid>
			<Grid item={true} className={classes.sectionContent}>
				{company.qa.map((item: any, index: number) => (
					<QAItem
						key={index}
						index={index}
						expanded={selectedQA === index}
						setSelectedQA={setSelectedQA}
						classes={classes}
						question={item.question}
						answer={item.answer}
					/>
				))}
			</Grid>
		</Grid>
	)
}

const QAItem: React.FunctionComponent<any> = memo(
	({ index, expanded, setSelectedQA, classes, question, answer }) => {
		const handleQuestionChange = useCallback(
			(event: any, newExpanded: any) => {
				setSelectedQA(newExpanded ? index : false)
			},
			[setSelectedQA, index],
		)

		return (
			<ExpansionPanel
				key={`qa-${index}`}
				expanded={expanded}
				onChange={handleQuestionChange}
			>
				<ExpansionPanelSummary>
					{!expanded && (
						<div
							className={classNames(
								classes.qaIcon,
								classes.expandIcon,
							)}
						>
							<span>+</span>
						</div>
					)}
					{expanded && (
						<div
							className={classNames(
								classes.qaIcon,
								classes.collapseIcon,
							)}
						>
							<span>-</span>
						</div>
					)}
					<span>{question}</span>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<span>{answer}</span>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		)
	},
)

export default memo(CompanyQA)
