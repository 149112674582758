import React, { memo, useState, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import EditIcon from '@material-ui/icons/EditOutlined'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { useMutation, useQuery } from '@apollo/react-hooks'
import FroalaEditor from 'react-froala-wysiwyg'
import { useTranslation } from 'react-i18next'

import { companiesFolder, FROALA_KEY } from 'utils'
import styles from './styles'
import { IEntityAboutProps, EEntityAboutSectionType } from 'models'
import {
	S3GetEditorHashQuery,
	UpdateCompanyDetails,
	UpdateProfileDetails,
	UpdateTeamDetails,
} from 'store'

const useStyles = makeStyles(styles)
const mutations = {
	[EEntityAboutSectionType.companyAbout]: UpdateCompanyDetails,
	[EEntityAboutSectionType.companyTeams]: UpdateCompanyDetails,
	[EEntityAboutSectionType.companyProductsAndServices]: UpdateCompanyDetails,
	[EEntityAboutSectionType.profileAbout]: UpdateProfileDetails,
	[EEntityAboutSectionType.teamAbout]: UpdateTeamDetails,
}

const EntityAboutSection: React.FunctionComponent<IEntityAboutProps> = ({
	type,
	title,
	emphasizedTitle,
	oneRowTitle,
	content,
	canEdit,
	companyId,
	teamId,
	user,
	s3UploadHash,
}) => {
	const { t, i18n } = useTranslation()
	// @ts-ignore
	const [updateMutationHandle] = useMutation(mutations[type])
	const [body, setBody] = useState(content)
	const [success, setSuccess] = useState(false)
	const [edit, setEdit] = useState(false)
	const [loading, setLoading] = useState(false)
	const handleChange = useCallback((value: string) => {
		setBody(value)
	}, [])
	const { data: s3Data } = useQuery(S3GetEditorHashQuery, {
		variables: {
			keyStart: companiesFolder + '/',
		},
	})

	if (s3Data && s3Data.getS3UploadHash && !s3UploadHash) {
		s3UploadHash = {
			bucket: s3Data.getS3UploadHash.bucket,
			keyStart: s3Data.getS3UploadHash.keyStart,
			region: s3Data.getS3UploadHash.region,
			params: {
				acl: s3Data.getS3UploadHash.params.acl,
				policy: s3Data.getS3UploadHash.params.policy,
				'x-amz-algorithm': s3Data.getS3UploadHash.params.XAmzAlgorithm,
				'x-amz-credential':
					s3Data.getS3UploadHash.params.XAmzCredential,
				'x-amz-date': s3Data.getS3UploadHash.params.XAmzDate,
				'x-amz-signature': s3Data.getS3UploadHash.params.XAmzSignature,
			},
		}
	}

	const config = {
		events: {
			'paste.afterCleanup': function (html: string) {
				let div = document.createElement('div')
				div.innerHTML = html
				return div.innerText
			},
		},
		videoUpload: false,
		imageUploadURL: false,
		imageUploadToS3: {
			bucket: s3UploadHash?.bucket,
			keyStart: s3UploadHash?.keyStart,
			region: s3UploadHash?.region,
			params: s3UploadHash?.params,
		},
		placeholderText: t('Description goes here'),
		charCounterCount: true,
		toolbarInline: false,
		toolbarButtons: [
			'bold',
			'italic',
			'underline',
			'strikeThrough',
			'alignLeft',
			'alignCenter',
			'formatOLSimple',
			'alignRight',
			'alignJustify',
			'formatOL',
			'formatUL',
		],
		key: FROALA_KEY,
		attribution: false,
		pastePlain: true,
	}
	const classes = useStyles()

	const toggle = useCallback(() => {
		setEdit(!edit)
	}, [edit])

	const saveEdit = useCallback(() => {
		setEdit(false)
		setLoading(true)
	}, [])

	useEffect(() => {
		setBody(content)
	}, [content])

	useEffect(() => {
		if (loading) {
			const mutate = async () => {
				try {
					let variables: any = {}
					switch (type) {
						case EEntityAboutSectionType.companyAbout:
							variables = {
								language: i18n.language,
								details: {
									id: companyId,
									description: body,
								},
							}
							break
						case EEntityAboutSectionType.companyProductsAndServices:
							variables = {
								language: i18n.language,
								details: {
									id: companyId,
									productsAndServices: body,
								},
							}
							break
						case EEntityAboutSectionType.teamAbout:
							variables = {
								details: {
									id: teamId,
									description: body,
								},
							}
							break
						case EEntityAboutSectionType.profileAbout:
							variables = {
								language: i18n.language,
								details: {
									userId: (user && user.id) || null,
									aboutMe: body,
								},
							}
							break
						case EEntityAboutSectionType.companyTeams:
							variables = {
								language: i18n.language,
								details: {
									id: companyId,
									aboutTeams: body,
								},
							}
							break
						default:
							break
					}
					if (!variables) {
						setLoading(false)
					}
					await updateMutationHandle({
						variables,
					})
					setLoading(false)
					setSuccess(true)
					setTimeout(() => {
						setSuccess(false)
					}, 2000)
				} catch (error) {
					console.error(error)
					setLoading(false)
				}
			}
			mutate()
		}
	}, [
		loading,
		body,
		companyId,
		i18n.language,
		teamId,
		type,
		updateMutationHandle,
		user,
	])

	return (
		<Grid className={classes.section} container={true} direction="column">
			<Grid item={true}>
				<h2 className={classes.sectionTitle}>
					{title}{' '}
					<span
						className={
							oneRowTitle
								? classes.inlineEmphasizedText
								: classes.blockEmphasizedText
						}
					>
						{emphasizedTitle}
					</span>
					{canEdit && !edit && (
						<>
							{!success && (
								<Chip
									onClick={toggle}
									style={{ margin: 2, background: '#fff' }}
									icon={<EditIcon />}
									variant="outlined"
									color={'secondary'}
									clickable={true}
									classes={{
										label: classes.editIconLabel,
										root: classes.editRootElement,
									}}
								/>
							)}
							{success && (
								<div className={classes.saveSuccess}>
									{t('saved!')}
								</div>
							)}
						</>
					)}
					{canEdit && edit && (
						<div className={classes.saveEdit} onClick={saveEdit}>
							save
						</div>
					)}
				</h2>
			</Grid>
			<Grid item={true} className={classes.sectionContent}>
				{!edit && <FroalaEditorView model={body} />}
				{edit && (
					<FroalaEditor
						tag="textarea"
						config={config}
						model={body}
						onModelChange={handleChange}
					/>
				)}
			</Grid>
		</Grid>
	)
}

export default memo(EntityAboutSection)
