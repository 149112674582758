import React, { memo } from 'react'

import { ILoaderProps } from 'models'
import './styles.css'

const Loading: React.FunctionComponent<ILoaderProps> = ({ size, style }) => (
	<div
		style={{
			color: 'rgba(251, 38, 82, 1)',
			margin: 'auto',
			flex: 1,
			width: size || undefined,
			height: size || undefined,
			...style,
		}}
		className="la-ball-clip-rotate-pulse la-3x"
	>
		<div
			style={{
				flex: 1,
				maxWidth: size || undefined,
				maxHeight: size || undefined,
				borderWidth: (size && size / 16) || undefined,
			}}
		/>
		<div
			style={{
				flex: 1,
				maxWidth: size || undefined,
				maxHeight: size || undefined,
				width: size && size / 2,
				height: size && size / 2,
			}}
		/>
	</div>
)

export default memo(Loading)
