import React, { memo } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { StoreValue } from 'store'

interface IModalTermsAndConditionsView {
	termsAndConditionsModal: boolean
	handleAgreement: Function
}

const ModalTermsAndConditionsView: React.FunctionComponent<IModalTermsAndConditionsView> = memo(
	({ termsAndConditionsModal, handleAgreement }) => {
		const { t } = useTranslation()
		const theme = useTheme()
		const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

		return (
			<Dialog
				fullScreen={isSmDown}
				open={termsAndConditionsModal}
				// onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				maxWidth="md"
				fullWidth={true}
			>
				<DialogContent>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 25 }}>
							{t('Termeni și condiții')}
						</p>
						<p style={{ fontSize: 15.4 }}>
							{t('Acceptarea acordului de utilizare')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Acest acord se referă la paginile web operate de S.C. cv30 WORLDWIDE GROUP S.R.L. cu sediul social în București, Strada General Barbu Vlădoianu, nr. 26, sector 1, (denumită în continuare cv30). Site-ul, serviciile şi conţinutul www.cv30.co, denumit în continuare şi “Site-ul” vă sunt oferite condiţionat de acceptarea dumneavoastră fără nici o modificare a condiţiilor din prezentul acord. Dacă nu acceptaţi termenii şi condiţiile statuate în acest document, nu folosiţi site-ul şi serviciile oferite prin intermediul lui. Prin utilizarea site-ului, indicaţi că acceptaţi să respectaţi "Termenii şi Condiţiile". ',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Descrierea serviciilor')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Site-ul www.cv30.co oferă utilizatorilor acces la o colecţie bogată de resurse şi informaţii cu privire la educaţie şi carieră. Dumneavoastră înţelegeţi şi sunteţi de acord că aceste servicii includ şi publicitate, iar publicitatea este necesară site-ului pentru furnizarea serviciilor sale. Dumneavoastră înţelegeţi şi sunteţi de acord că serviciile site-ului includ diverse anunţuri şi informaţii ce sunt actualizate constant şi au ca subiect educaţia şi cariera. Dumneavoastră înţelegeţi şi sunteţi de acord că serviciile sunt oferite "aşa cum sunt", iar site-ul nu îşi asumă nicio responsabilitate pentru ştergerea, transmiterea sau salvarea greşită a setărilor personalizate. Dumneavoastră înţelegeţi şi sunteţi de acord cu faptul că informațiile prezente pe site sunt cu titlu orientativ.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Obligaţii la înregistrare')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Pentru a folosi serviciile oferite de site, vă obligaţi să',
						)}
						<br />
						{t(
							'1. furnizaţi date adevărate, exacte şi complete despre dumneavoastră, aşa cum este cerut de formularul de înregistrare al site-ului ',
						)}
						<br />
						{t(
							'2. menţineţi şi înnoiţi, atunci când situaţia o cere, datele de înregistrare pentru a fi adevărate, exacte şi complete.',
						)}
						<br />
						{t(
							'Dacă veţi furniza informaţii care nu sunt conforme cu realitatea, inexacte sau incomplete, compania are dreptul să suspende sau să desfiinţeze contul dumneavoastră şi să refuze toate încercările curente sau viitoare de folosire a site-ului.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Responsabilităţile utilizatorului')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Ca utilizator sunteţi responsabil de propriile acţiuni, precum şi de consecinţele pe care acestea le pot avea, în urma publicării materialelor pe care le încărcaţi, puneţi la dispoziţie în mod public pe contul pus la dispoziţie de companie.',
						)}
						<br />
						<br />
						{t('Vă obligaţi să nu faceţi următoarele lucruri')}
						<br />
						{t(
							'1. să publicaţi material cu drept de autor, dacă nu sunteţi autorul sau dacă nu aveţi permisiunea autorului de a publica materialul respectiv;',
						)}{' '}
						<br />
						{t(
							'2. să publicaţi materiale obscene, defăimătoare, de ameninţare, discriminorii sau răuvoitoare faţă de un alt utilizator, persoană fizică sau juridică sau faţă de orice alt terţ;',
						)}{' '}
						<br />
						{t(
							'3. să publicaţi o imagine sau o afirmaţie explicit sexuală;',
						)}
						<br />
						{t(
							'4. să publicaţi materiale care conţin viruşi, viermi sau alte programe cu intenţia de a distruge orice sistem sau informaţie;',
						)}
						<br />
						{t(
							'5. încarca, difuza sau transmite în alt mod orice conţinut pentru care nu aveţi dreptul legal de transmitere sau difuzare în orice condiţii, sub orice sistem juridic, român sau străin, relaţii contractuale sau de încredere (cum sunt informaţiile confidenţiale, cele aflate sub drept de proprietate, aflate sau dezvăluite ca parte a relaţiilor de serviciu sau aflate sub incidenţa acordurilor de confidenţialitate);',
						)}
						<br />
						{t(
							'6. încarca, difuza sau transmite în alt mod orice tip de publicitate, materiale promoţionale, "junk mail", "spam", scrisori în lanţ, scheme piramidale, sau orice altă formă de solicitări, cu excepţia porţiunilor din serviciu care sunt formate cu acest scop;',
						)}
						<br />
						{t(
							'7. avea adrese multiple în cadrul site-ului care se aseamănă sau care sunt create pe aceeaşi temă;',
						)}
						<br />
						{t(
							'8. promova sau furniza informaţii despre modul de derulare a activităţilor ilegale, promova rănirea fizică sau verbală împotriva oricărui grup sau oricărei persoane, sau pentru a promova orice act de cruzime faţă de animale. Aceasta poate include, dar nu este limitat, a furniza informaţii despre modul de a fabrica, achiziţiona sau asambla bombe, grenade sau alte tipuri de arme şi crearea de site-uri "crush";',
						)}
						<br />
						{t(
							'9. difuza sau transmite în alt mod date personale (nume, adresa, numere de telefon) sau înregistrarea acestora în rubricile care pot fi vizualizate de către ceilalţi utilizatori (descrieri, pseudonim etc.);',
						)}
						<br />
						{t(
							'10. difuza sau transmite orice alt material care contravine, în orice fel, normelor legale în vigoare.',
						)}
						<br />
						<br />
						{t(
							'Compania nu garantează credibilitatea, acurateţea informaţiilor publicate de către utilizatori sau nu girează vreo părere exprimată de către utilizatori.',
						)}
						<br />
						<br />
						{t(
							'Compania acţionează ca un factor pasiv în distribuţia online şi publicarea informaţiilor furnizate de către utilizatori şi nu are obligaţia de a verifica în avans materialul publicat de către utilizatori. De aceea, compania nu răspunde în niciun mod cu privire la informaţiile şi datele postate, difuzate sau transmise de către utilizatorii săi. Dacă i se solicită de către un utilizator, compania poate investiga şi verifica afirmaţiile şi poate hotărî dacă informaţiile respective trebuie îndepărtate. De asemenea, permiteţi oricărei persoane care este abonată să acceseze, să vizualizeze, să stocheze sau să reproducă asemenea materiale în scopuri personale. Supus celor menţionate înainte, posesorul unui astfel de material publicat pe site îşi menţine orice drepturi care ar putea deriva de aici.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Informaţii pentru utilizatori')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'În momentul în care vă înregistraţi pe site, vi se vor cere anumite informaţii incluzând o adresă de e-mail validă. Pe lângă termenii şi condiţiile care vor fi reglementate în politica site-ului, dumneavoastră înţelegeţi şi acceptaţi faptul că această companie poate divulga unei terţe persoane anumite informaţii conţinute în cererea dumneavoastră de înregistrare.',
						)}
						<br />
						<br />
						{t(
							'Compania nu va divulga unei terţe persoane numele, adresa de e-mail sau numărul dumneavoastră de telefon fără consimţământul dumneavoastră, cu excepţia cazurilor prevăzute de lege când comunicarea acestor informaţii este necesară şi în conformitate cu politica de confidenţialitate.',
						)}
						<br />
						<br />
						{t(
							'Compania îşi rezervă dreptul de a oferi serviciile şi produsele unui terţ, bazându-se pe datele menţionate în înregistrarea dumneavoastră în orice moment după ce aceasta a avut loc; asemenea oferte pot fi făcute de către companie sau de către un terţ implicat.',
						)}
						<br />
						<br />
						{t(
							'Pentru mai multe detalii despre divulgarea datelor dumneavoastră, vedeți “Nota de informare privind confidențialitatea datelor”. Aceste operațiuni respectă Regulamentul General privind Protecția Datelor 2016/679.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Contul, parola şi securitatea datelor')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Sunteţi răspunzători de păstrarea confidenţialităţii asupra informaţiilor şi a parolei dumneavoastră. Veţi fi răspunzători de utilizarea înregistrării dumneavoastră, indiferent dacă utilizarea se face cu sau fără voia dumneavoastră. Sunteţi de acord să sesizaţi compania în legătură cu orice utilizare neautorizată a parolei şi a înregistrării dumneavoastră. Sunteţi de asemenea de acord să folosiţi butonul de ieşire din contul dumneavoastră când doriţi să părăsiţi site-ul. Compania nu va fi răspunzătoare pentru nici o pagubă morală sau materială provocată prin nerespectarea acestei prevederi de către dumneavoastră.',
						)}
						<br />
						<br />
						{t(
							'Dacă consideră de cuviinţă, compania vă poate bloca parola, utilizarea serviciilor sau folosirea oricăror altor servicii ale sale de care beneficiaţi, sau să scoată şi să şteargă orice material din cadrul serviciilor, pentru orice motiv sau fără motiv. Compania poate de asemenea, oricând doreşte şi fără a da socoteală, întrerupe furnizarea serviciilor, sau a unei părţi a lor, cu sau fără nici o notificare prealabilă.',
						)}
						<br />
						<br />
						{t(
							'Orice încetare a accesului dumneavoastră la serviciile site-ului, conform oricărei reguli din prezentul Regulament, poate fi efectuată fără o avertizare anticipată, şi luaţi la cunoştinţă şi sunteţi de acord că firma poate dezactiva sau şterge imediat contul dumneavoastră din cadrul site-ului şi toate informaţiile în legătură cu acesta şi/sau să interzică orice acces ulterior la serviciile site-ului. Mai mult, compania nu va fi răspunzătoare faţă de dumneavoastră sau orice terţă parte în orice mod, pentru interzicerea şi/sau ştergerea contului şi a accesului la serviciile puse la dispoziţie prin intermediul site-ului.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Utilizarea materialelor')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Compania vă autorizează să vizualizaţi informaţia de pe site doar în scopuri personale şi pentru uz necomercial. Conţinutul acestui site, adică textele, grafica, fotografiile, precum şi alte materiale sunt protejate de legea dreptului de autor. Întregul material este proprietatea companiei. Compilaţia (adică adunarea şi rearanjarea) conţinutului acestui site este dreptul exclusiv al companiei, drept protejat de legile în vigoare. Folosirea neautorizată a materialului reprezintă o încălcare a legii dreptului de autor sau a altor legi. Nu aveţi permisiunea de a vinde sau a modifica materialul, a-l expune public, a-l distribui sau a-l folosi în orice alt mod, în scopuri publice sau comerciale.',
						)}
						<br />
						<br />

						{t(
							'Pe serverele care aparţin sau care sunt închiriate de către companie accesul oricărui utilizator neautorizat este interzis. În caz de încercare şi/sau accesare, fără drept, a acestor servere, fapta constituie infracţiune şi se pedepseşte conform legislaţiei române în vigoare.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t(
								'Proprietatea materialelor şi informaţiilor introduse pe site',
							)}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Compania nu va pretinde un drept de proprietate asupra materialelor pe care le furnizaţi (inclusiv observaţiile şi sugestiile) sau pe care le postaţi, încărcaţi sau trimiteţi către site. Totuşi, transmiţând aceste materiale, dumneavoastră sunteţi de acord să acordaţi companiei permisiunea de a utiliza materialele inclusiv să le copieze, distribuie, transmită, publice, reproducă, modifice şi să traducă; de a menţiona numele dumneavoastră în legătură cu materialul pe care l-aţi transmis; şi dreptul de a cesiona la rândul ei toate aceste drepturi oricărui partener al companiei.',
						)}
						<br />
						<br />
						{t(
							'Nu se vor pretinde şi nici acorda niciun fel de compensaţii pentru utilizarea materialului în felul arătat mai sus. Compania nu are obligaţia de a transmite pe site sau folosi în orice mod materialul furnizat de dumneavoastră; compania are de asemenea dreptul să şteargă materialul dumneavoastră în orice moment şi fără a avea nevoie de un motiv în acest sens.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t(
								'Relaţia cu companiile care fac publicitate pe site-ul www.cv30.co',
							)}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Compania rulează campanii publicitare şi promoţii în cadrul site-ului său. Corespondenţa sau afacerile sau participarea în promoţii ale companiilor de publicitate găsite pe sau prin intermediul site-urilor, inclusiv plăți sau livrări ale unor bunuri sau servicii şi orice alte condiţii, termene, garanţii sau reprezentări asociate cu astfel de relaţii sunt numai în responsabilitatea dumneavoastră şi a acestor companii. Compania nu este răspunzătoare şi nu va fi trasă la răspundere pentru orice pierdere sau prejudiciu de orice fel, rezultat din astfel de relaţii sau rezultat din prezenţa unor astfel de companii în cadrul campaniilor publicitare ale site-ului. Prin agrearea termenilor prezentului Contract, ne acordaţi dreptul de a vă trimite periodic e-mail-uri cu informaţii sau oferte speciale.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t(
								'Legături către alţi furnizori de informaţii sau servicii',
							)}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Site-ul poate furniza, sau terţe părţi pot furniza legături (link-uri) către alte site-uri World Wide Web sau alte resurse. Deoarece compania nu are niciun control asupra acestor site-uri sau resurse, luaţi cunoştinţă şi sunteţi de acord că nu suntem responsabili de disponibilitatea acestora şi nu garantăm şi nu suntem responsabili pentru niciun conţinut, publicitate, produse sau alte materiale de pe sau disponibile pe acest site sau resurse.',
						)}
						<br />
						<br />
						{t(
							'Mai mult, recunoaşteţi şi sunteţi de acord că nu vom fi responsabili sau pasibili de a plăti despăgubiri, direct sau indirect pentru orice pagubă sau pierdere cauzată sau presupusă a fi fost cauzată de sau în legătură cu folosirea sau încrederea în informaţiile furnizate de un asemenea conţinut, bunuri sau servicii disponibile pe sau prin intermediul unor astfel de site-uri sau resurse.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Responsabilităţile companiei')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Deoarece identificarea utilizatorilor pe Internet este dificilă, site-ul nu confirmă faptul că fiecare utilizator este ceea ce pretinde. Deoarece nu suntem şi nu putem fi implicaţi în relaţiile user-to-user sau nu putem controla comportamentul utilizatorilor www.cv30.co, în eventualitatea unei dispute cu unul sau mai mulţi utilizatori, compania (prin agenţii, administratorii şi angajaţii săi) este absolvită de orice răspundere sau despăgubire (directă sau indirectă) de orice fel şi de orice natură, cunoscută sau necunoscută, suspectată sau nu, dezvăluită sau nu, ivită în vreun mod care are legătură cu disputele.',
						)}
						<br />
						<br />
						{t(
							'Compania nu are obligaţia legală de a controla informaţiile oferite de alţi utilizatori, disponibile pe site. Informaţiile altor persoane pot fi neplăcute, vătămătoare sau inexacte.',
						)}
						<br />
						<br />
						{t(
							'Materialul poate conţine inexactităţi sau greşeli de scriere. Compania nu îşi asumă veridicitatea şi exactitatea materialului de pe site. Dumneavoastră luaţi la cunoştinţă şi sunteţi de acord cu faptul că sunteţi singurul responsabil pentru forma, conţinutul sau exactitatea materialului conţinut sau plasat de dumneavoastră pe site.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Acord de confidenţialitate')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Echipa companiei încearcă să protejeze cât mai mult dreptul la intimitate şi la imagine al utilizatorilor site-ului. Ne propunem să vă oferim dumneavoastră, utilizatorilor site-ului, o experienţă online sigură, care să nu vă afecteze în mod negativ viaţa personală. În acest sens depunem toate eforturile pentru a ne asigura că informaţiile pe care dumneavoastră le introduceţi în baza noastră de date sunt folosite numai în scopurile pe care dumneavoastră le aveţi în vedere.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t(
								'Afișarea datelor personale în spatiile/domeniile publice ale www.cv30.co',
							)}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Informațiile cu caracter personal postate de către utilizatori în locurile/spatiile publice aparținând www.cv30.co, cum ar fi forum-uri, chat-uri, liste de discutii, baza de date cu CV-urile căutabile, pot fi copiate și stocate de către terți asupra cărora www.cv30.co nu are nici un control. Noi nu suntem responsabili pentru faptul că terțe părți utilizează informații pe care le-ați postat sau le-ați făcut disponibile în spatiile publice aparținând www.cv30.co.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>{t('Profil ')}</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Din moment ce www.cv30.co este un site de dezvoltare personala si profesionala online vă acordăm posibilitatea de înregistrare a propriului profil în baza noastră de date. Există două modalități 1. Vă puteți înregistra cv-ul în baza noastră de date. 2. Puteți crea profilul de cariera, iar angajatorii vor avea acces la datele dvs. de contact. Puteți să ștergeți profilul din baza noastră de date oricând (dar nu mai devreme de 6 luni de la cea mai recentă aplicare făcută de dvs. pentru un loc de munca). Dacă postați datele altei persoane este necesar ca persoana respectivă să își fi dat în prealabil acordul pentru acest act.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>{t('Evenimente ')}</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Compania organizează periodic evenimente la care vă înscrieți prin intermediul website-ului. Imaginile, video-urile și textele din cadrul evenimentelor sunt proprietatea companiei și dumneavoastră sunteţi de acord să acordaţi companiei permisiunea de a utiliza materialele inclusiv să le copieze, distribuie, transmită, publice, reproducă, modifice şi să traducă; de a menţiona numele dumneavoastră în legătură cu materialul pe care l-aţi transmis; şi dreptul de a cesiona la rândul ei toate aceste drepturi oricărui partener al companiei.',
						)}
						<br />
						<br />
						{t(
							'Nu se vor pretinde şi nici acorda niciun fel de compensaţii pentru utilizarea materialului în felul arătat mai sus. Compania nu are obligaţia de a transmite pe site sau folosi în orice mod materialul din cadrul evenimentelor; compania are de asemenea dreptul să şteargă materialul în orice moment şi fără a avea nevoie de un motiv în acest sens.',
						)}
						<br />
						<br />
						{t(
							'Dreptul de a modifica și șterge datele dvs. deținute de operator www.cv30.co conferă dreptul utilizatorului de a modifica datele de contact pe care le-ați înregistrat, unele aspecte de care sunteți interesat, incluzând noile informații despre produse și servicii lansate. De asemenea, puteți solicita operatorului ștergerea datelor dvs. oricând.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Securitatea datelor')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Pentru confidențialitatea și securitatea datelor, contul dumneavoastra pe www.cv30.co este protejat de o parolă.',
						)}
					</Typography>

					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>{t('Cookies ')}</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							"Pentru a vă oferi o navigare cât mai fluidă pe site-ul www.cv30.co, folosim o facilitate a browserului Internet numita 'cookie'. Cookies sunt fișiere de dimensiuni reduse pe care browserul le plasează pe hard-diskul utilizatorului. Aceste cookies sunt folosite pentru a memora numele utilizatorilor, parolele și preferințele, pentru a monitoriza parcursuri pe site, și pentru a personaliza paginile în funcție de vizitator. Aveți opțiunea de a seta browserul astfel încât să respingă cookies. În acest caz însă, va exista un impact negativ asupra navigării pe site-ul nostru.",
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>{t('Notificari SMS')}</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'www.cv30.co poate trimite ocazional alerte de tip SMS utilizatorilor înregistrați cu cont creat pe site pentru a-i informa despre evenimente de carieră, job-uri, programe de recrutare ale companiilor prezente pe cv30.ro, produse și servicii de care ar putea fi interesați sau alte aspecte importante legate de contul lor.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>{t('Feedback ')}</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'www.cv30.co oferă posibilitatea utilizatorilor site-ului de a trimite comentarii, întrebări și sugestii. Orice informație care este trimisă prin intermediul formularelor de contact va fi folosită ținând cont de dreptul la confidențialitate și la imagine al persoanelor.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Modificări ale politicii de confidențialitate')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Dacă vom considera că este necesară o schimbare a regulilor de confidențialitate, vom publica respectivele modificări în această pagină pentru a vă informa cu privire la tipurile de informații pe care le colectam și modul în care le utilizam. Dacă aveți întrebări cu privire la politica noastră de confidențialitate vă rugăm sa ne scrieți la contact@cv30.co.',
						)}
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t(
								'Notă de informare privind protecția datelor personale',
							)}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Începând cu data de 25 mai 2018, va intra în vigoare Regulamentul (UE) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (Regulamentul general privind protecția datelor).',
						)}
						<br />
						<br />
						{t(
							'cv30 Worldwide Group SRL - cu sediul în Str. Gral Barbu Vladoianu, nr 26, sector 1, Bucuresti, este operator de date cu caracter personal în scopuri de marketing în baza notificării 35367.',
						)}
						<br />
						<br />
						{t(
							'Conformitatea cv30 în legătură cu Regulamentul general privind protecția datelor',
						)}
						<br />
						{t('1. Cum ne contactați?')}
						<br />
						{t(
							'CV30 WORLDWIDE GROUP SRL - Strada General Barbu Vlădoianu',
						)}
						<br />
						{t('contact@cv30.co')}
						<br />
						<br />
						{t('2. Ce acoperă Nota noastră de informare?')}
						<br />
						{t('2.1. Nota noastră de informare')}
						<br />
						{t('2.1.1. produce efecte de la 25.05.2018')}
						<br />
						{t('2.1.2. este publicată la 15.05.2018')}
						<br />
						{t('2.2. Nota noastră de informare se aplică')}
						<br />
						{t('2.2.1. websiteului nostru cv30.co')}
						<br />
						{t('2.2.2. newsletter-ului nostru.')}
						<br />
						<br />
						{t(
							'3. De ce și cum prelucrăm datele dumneavoastră personale?',
						)}
						<br />
						{t(
							'Colectăm câteva informații personale despre vizitatori și userii site-ului.',
						)}
						<br />
						{t(
							'Colectăm informații precum nume, prenume, email, telefon, oraș, comentarii și date din serviciile de web analytics. De asemenea, prelucrăm date când aplicați la job-uri (datele din cv) și datele pe care le furnizați când vă înscrieți la un eveniment cv30.',
						)}
						<br />
						{t(
							'Suntem siguri că primești zilnic o multitudine de mailuri, motiv pentru care vrem sa ne limităm la ceea ce este esențial și relevant. Pe scurt, fără spam.',
						)}
						<br />
						<br />
						{t(
							'4. Cine este responsabil pentru prelucrarea datelor dumneavoastră personale?',
						)}
						<br />
						{t('4.2. cv30')}
						<br />
						{t(
							'- decide de ce sunt prelucrate datele dumneavoastră personale,',
						)}
						<br />
						{t(
							'- decide cum sunt prelucrate datele dumneavoastră personale, și',
						)}
						<br />
						{t(
							'- este responsabila pentru prelucrarea datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'4.2.1. cv30 are un responsabil cu protecția datelor personale.',
						)}
						<br />
						{t(
							'- Datele de contact sunt Codrin-Andrei Pascariu, codrin.pascariu@cv30.co, 0311096740, Strada General Barbu Vladoianu, nr. 26',
						)}
						<br />
						<br />
						{t(
							'5. De la cine și cum colectăm datele dumneavoastră personale?',
						)}
						<br />
						{t('5.1. Colectăm datele dumneavoastră personale')}
						<br />
						{t('5.1.1. direct de la dumneavoastră.')}
						<br />
						{t('5.2. Colectăm datele dumneavoastră personale')}
						<br />
						{t('5.2.1. electronic, folosind un formular web.')}
						<br />
						{t('5.2.2. electronic')}
						<br />
						{t(
							'- prin stocarea de informație pe echipamentele dumneavoastră, sau',
						)}
						<br />
						{t(
							'- accesând informație stocată pe echipamentele dumneavoastră, ori',
						)}
						<br />
						{t(
							'- citind informație emisă de echipamentele dumnevoastră.',
						)}
						<br />
						{t(
							'Când ne furnizați datele dumneavoastră personale, furnizarea dumneavoastră este',
						)}
						<br />
						{t(
							'5.3.1. permisă și voluntară. Ni le puteți furniza liber nume, email, telefon, oraș, studii',
						)}
						<br />
						{t(
							'5.4. Dacă nu reușiți să ne furnizați datele dumneavoastră personale și această furnizare este voluntară, atunci acest lucru nu vă poate afecta.',
						)}
						<br />
						<br />
						{t(
							'6. Care sunt temeiurile noastre legale pentru prelucrarea datelor dumneavoastră personale?',
						)}
						<br />
						{t('6.1. Noi procesăm date personale "nesensibile".')}
						<br />
						{t(
							'6.2. Temeiul legal pentru prelucrarea datelor dumneavoastră personale "nesensibile" este',
						)}
						<br />
						{t('6.2.1. consimțământul dumneavoastră.')}
						<br />
						{t('6.2.2. Interesul')}
						<br />
						{t('- nostru legitim, sau')}
						<br />
						{t('- legitim al unei terțe părți.')}
						<br />
						{t(
							'Prelucrăm datele dumneavoastră personale pe baza unor interese care sunt',
						)}
						<br />
						{t('- legitime,')}
						<br />
						{t('- reale, și')}
						<br />
						{t('- prezente.')}
						<br />
						{t(
							'Prelucrarea datelor dumneavoastră personale este necesară pentru interesele legitime pe care le urmărim.',
						)}
						<br />
						{t('Interesele noastre legitime pot fi înlăturate de')}
						<br />
						{t('- interesele dumneavoastră și')}
						<br />
						{t('- drepturi dumneavoastră fundamentale.')}
						<br />
						{t('Vă protejăm în mod adecvat')}
						<br />
						{t('- interesele și')}
						<br />
						{t('- drepturile și libertățile.')}
						<br />
						<br />
						{t(
							'7. În ce situații prelucrăm datele dumneavoastră personale?',
						)}
						<br />
						{t('7.1. Colectăm date personale')}
						<br />
						{t(
							'7.1.1. ale cititorilor, utilizatori ai siteului, membrii ai comunității, participanți la evenimente',
						)}
						<br />
						{t('7.2.Suntem')}
						<br />
						{t('7.2.1. o întreprindere.')}
						<br />
						{t('7.3. Procesăm datele dumneavoastră personale din')}
						<br />
						{t('7.3.1. sectorul privat.')}
						<br />
						{t(
							'7.4. Procesăm datele dumneavoastră personale într-o situație care implică',
						)}
						<br />
						{t('7.4.1. o activitate online.')}
						<br />
						<br />
						{t(
							'8. Folosim procese de profilare automată și decizii automatizate?',
						)}
						<br />
						{t(
							'8.1. Folosim datele dumneavoastră personale pentru a evalua automat aspecte ale personalității dumneavoastră.',
						)}
						<br />
						{t('Evaluarea automată')}
						<br />
						{t(
							'- poate include o analiză a caracteristicilor dumneavoastră.',
						)}
						<br />
						{t(
							'- poate include predicții cu privire la comportamentul dumneavoastră.',
						)}
						<br />
						{t(
							'- este realizată exclusiv cu mijloace informatice.',
						)}
						<br />
						{t('- este realizată fără implicare umană.')}
						<br />
						{t(
							'- va fi menționată în continuare prin referire la termenul “profilare complet automatizată”.',
						)}
						<br />
						{t(
							'8.2. Folosim datele dumneavoastră personale pentru a lua decizii automate despre dumneavoastră.',
						)}
						<br />
						{t('Deciziile automate')}
						<br />
						{t(
							'- pot include "profilarea automată în totalitate" a dumneavoastră',
						)}
						<br />
						{t('- sunt realizate numai de un calculator.')}
						<br />
						{t('- sunt făcute fără intervenție umană.')}
						<br />
						{t(
							'- sunt denumite în continuare "procese decizionale complet automatizate".',
						)}
						<br />
						{t(
							'8.3. Deciziile noastre "complet automate" despre dumneavoastră se bazează pe "profilarea automată" a dumneavoastră.',
						)}
						<br />
						{t(
							'8.4. Ne fundamentăm procesul de “decizie complet automată” despre dumneavoastră pe următoarea logică de prelucrare',
						)}
						<br />
						{t(
							'8.4.1. Daca un cititor accesează articole dintr-o anumită categorie, vom considera că aria sa de interes este legată de acea categorie de articole și vom încerca să personalizăm, în viitor, conținutul pe baza acelei analize.',
						)}
						<br />
						{t(
							'8.5. Deciziile noastre "complet automate" nu au un impact asupra',
						)}
						<br />
						{t('- drepturilor dumneavoastră, sau')}
						<br />
						{t('- statutului dumneavoastră juridic.')}
						<br />
						{t(
							'Deciziile noastre "complet automate" nu au un impact real semnificativ asupra',
						)}
						<br />
						{t(
							'- circumstanțelor aferente situației dumneavoastră,',
						)}
						<br />
						{t('- comportamentului dumneavoastră, sau')}
						<br />
						{t(' - alegerilor dumneavoastră.')}
						<br />
						{t(
							'8.6. Temeiul legal pentru prelucrarea noastră referitoare la deciziile "complet automate" referitoare la dumneavoastră este',
						)}
						<br />
						{t('8.6.1. o prelucrare în scopul')}
						<br />
						{t('- intereselor noastre legitime, sau')}
						<br />
						{t('- intereselor legitime ale unei terțe părți.')}
						<br />
						{t(
							'8.7. Datele dumneavoastră personale pe care sistemul nostru se bazează atunci când ia "decizii complet automate" sunt',
						)}
						<br />
						{t('8.7.1. date personale “nesensibile”.')}
						<br />
						{t(
							'8.8. Deciziile noastre “complet automate” nu vă pot afecta.',
						)}
						<br />
						<br />
						{t(
							'9. Despre scopurile pentru care prelucrăm date personale',
						)}
						<br />
						{t(
							'9.1. Prelucrăm datele dumneavoastră personale pentru scopurile descrise în Secțiunea 3.',
						)}
						<br />
						{t(
							'Scopurile noastre, pentru care prelucrăm date personale, sunt',
						)}
						<br />
						{t('- reale,')}
						<br />
						{t('- prezente, și')}
						<br />
						{t('- legitime.')}
						<br />
						{t(
							'9.2. Nu vă prelucrăm datele dumneavoastră personale în scopuri secundare care sunt incompatibile cu scopurile principale pentru care datele dumneavoastră personale sunt colectate inițial,',
						)}
						<br />
						{t('- fără consimțământul dumneavoastră prealabil,')}
						<br />
						{t(
							'- fără să existe un interes legitim în acest sens, și',
						)}
						<br />
						{t('- fără un temei juridic.')}
						<br />
						{t(
							'9.3. Vă informăm înainte de prelucrarea datelor dumneavoastră personale în scopuri secundare',
						)}
						<br />
						{t(
							'- în primul rând, dacă colectăm inițial datele dumneavoastră personale pentru un scop principal și',
						)}
						<br />
						{t(
							'- dacă scopul nostru secundar este incompatibil cu scopul principal.',
						)}
						<br />
						<br />
						{t(
							'10. Cât timp păstrăm datele dumneavoastră personale?',
						)}
						<br />
						{t(
							'10.1. Limităm durata de stocare a datelor dumneavoastră personale la ceea ce este necesar pentru scopurile noastre de procesare.',
						)}
						<br />
						{t(
							'10.2. Revizuim necesitatea păstrării în continuare a datelor dumneavoastră personale În fiecare an analizăm datele colectate și prelucrate, în vederea filtrării, sortării și menținerii prelucrării doar pentru datele în cazul cărora scopul prelucrării este actual.',
						)}
						<br />
						{t(
							'10.3. Ștergem datele dumneavoastră personale într-un interval de timp specificat Ștergem datele dumneavoastră la un an de la data la care relația dumneavoastră cu noi se încheie (clauza aplicabilă în cazul newsletterelor, de la momentul la care cititorul nu mai accesează conținutul newsletterelor noastre).',
						)}
						<br />
						{t(
							'10.4. Ștergem datele dumneavoastră în momentul în care ne solicitați acest lucru, cu excepția datelor a căror furnizare și prelucrare este impusă de o prevedere legală, pe care le ștergem în termenul prevăzut de lege pentru aceasta.',
						)}
						<br />
						{t(
							'10.4.1. În cazul în care păstrarea datelor dumneavoastră personale este necesară în scopurile specificate de lege, putem păstra în continuare datele dumneavoastră personale.',
						)}
						<br />
						<br />
						{t('11. Dezvăluim datele dumneavoastră personale?')}
						<br />
						{t(
							'11.1. Dezvăluim datele dumneavoastră personale unor destinatari.',
						)}
						<br />
						{t(
							'11.2. Încadrarea legală pe care se bazează divulgarea datelor dumneavoastră personale către destinatari este',
						)}
						<br />
						{t('11.2.1. consimțământul dumneavoastră.')}
						<br />
						{t(
							'11.3. Dacă în viitor vom dezvălui datele dumneavoastră personale unui destinatar, atunci vă vom informa asupra',
						)}
						<br />
						{t('- momentului dezvăluirii și')}
						<br />
						{t('- numelui destinatarilor.')}
						<br />
						<br />
						{t(
							'12. Transferăm datele dumneavoastră personale în afara UE sau SEE?',
						)}
						<br />
						{t('12.1. Nu vă transferăm datele personale')}
						<br />
						{t('- în țări din afara UE sau SEE, sau')}
						<br />
						{t('- către organizații internaționale.')}
						<br />
						<br />
						{t(
							'13. Sunt datele dumneavoastră personale în siguranță?',
						)}
						<br />
						{t('13.1. Îți ținem în siguranță datele personale')}
						<br />
						{t('- cu măsuri tehnice corespunzătoare,')}
						<br />
						{t('- cu măsuri organizatorice adecvate,')}
						<br />
						{t('- cu un nivel adecvat de securitate,')}
						<br />
						{t('- împotriva prelucrării neautorizate,')}
						<br />
						{t('- împotriva prelucrării ilegale,')}
						<br />
						{t('- împotriva pierderii accidentale sau ilegale,')}
						<br />
						{t('- împotriva distrugerii accidentale sau ilegale,')}
						<br />
						{t('- împotriva daunelor accidentale sau ilegale.')}
						<br />
						{t('13.2. Am implementat măsuri pentru a')}
						<br />
						{t('13.2.1. descoperi breșe de securitate.')}
						<br />
						{t(
							'13.2.2. documenta cauzele incidentului de securitate.',
						)}
						<br />
						{t(
							'13.2.3. documenta care date personale sunt afectate de incidentul de securitate.',
						)}
						<br />
						{t(
							'13.2.4. documenta acțiunile (și motivele acțiunilor) pentru a remedia încălcarea securității.',
						)}
						<br />
						{t(
							'13.2.5. limita consecințele incidentului de securitate.',
						)}
						<br />
						{t('13.2.6. recupera date personale.')}
						<br />
						{t(
							'13.2.7. reveni la o stare normală de prelucrare a datelor cu caracter personal.',
						)}
						<br />
						{t(
							'13.3. Dacă avem un grad de certitudine rezonabil că s-a produs o încălcare a securității prelucrării datelor dumneavoastră personale, atunci',
						)}
						<br />
						{t(
							'13.3.1. raportăm incidentul de securitate catre managementul companiei noastre.',
						)}
						<br />
						{t('13.3.2. desemnăm o persoana responsabilă pentru')}
						<br />
						{t(
							'- a analiza dacă încălcarea securității poate avea efecte nefavorabile pentru dumneavoastră,',
						)}
						<br />
						{t(
							'- a informa personalul relevant din organizația noastră,',
						)}
						<br />
						{t(
							'- a determina în ce măsură este necesară notificarea Autorității de Supraveghere cu privire la incidentul de securitate, și',
						)}
						<br />
						{t(
							'- a stabili dacă este necesar să vă comunicăm informații despre incidentul de securitate.',
						)}
						<br />
						{t('13.3.3. investigăm incidentul de securitate.')}
						<br />
						{t(
							'13.3.4. încercăm să împiedicăm incidentul de securitate să ducă la',
						)}
						<br />
						{t(
							'- distrugerea accidentală sau ilegală a datelor personale,',
						)}
						<br />
						{t(
							'- pierderea accidentală sau ilegală a controlului datelor cu caracter personal,',
						)}
						<br />
						{t(
							'- pierderea accidentală sau ilegală a accesului la datele cu caracter personal,',
						)}
						<br />
						{t(
							'- alterarea accidentală sau ilegală a datelor cu caracter personal,',
						)}
						<br />
						{t(
							'- divulgarea neautorizată a datelor cu caracter personal, sau',
						)}
						<br />
						{t(
							'- acces neautorizat la datele cu caracter personal.',
						)}
						<br />
						{t(
							'13.3.5. depunem toate eforturile pentru atenuarea riscului imediat de producere a unui prejudiciu.',
						)}
						<br />
						{t(
							'13.3.6. notificăm Autoritatea de Supraveghere cu privire la incidentul de securitate, daca încălcarea este susceptibilă să ducă la un risc ridicat pentru drepturile și libertățile dumneavoastră.',
						)}
						<br />
						{t('13.3.7. vă informăm despre încălcarea securității')}
						<br />
						{t(
							'- dacă încălcarea este susceptibilă să ducă la un risc ridicat pentru drepturile și libertățile dumneavoastră,',
						)}
						<br />
						{t('- cât mai repede posibil,')}
						<br />
						{t(
							'- prin canale de contact adecvate, de ex. prin e-mail, SMS, bannere proeminente pe site-ul nostru, comunicări poștale, reclame proeminente în mass-media etc.',
						)}
						<br />
						{t('- Nu suntem obligați să vă informăm direct dacă')}
						<br />
						{t(
							'- am luat măsuri pentru a face ca datele dvs. personale să fie incomprehensibile oricărei persoane care nu este autorizată să le acceseze,',
						)}
						<br />
						{t(
							'- imediat după incidentul de securitate, am luat măsuri pentru a ne asigura că riscul ridicat pentru drepturile și libertățile dumneavoastră nu mai este posibil să se producă sau',
						)}
						<br />
						{t(
							'- ar implica eforturi disproporționate. Într-un astfel de caz, vă vom informa prin intermediul rețelelor publice.',
						)}
						<br />
						<br />
						{t('14. Care sunt drepturile dumneavoastră?')}
						<br />
						{t(
							'14.1. Vă respectăm drepturile care privesc protecția datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'14.2. Aveți dreptul de acces la datele dumneavoastră personale.',
						)}
						<br />
						{t(
							'În cazul în care solicitați să confirmăm dacă prelucrăm sau nu datele dumneavoastră personale, atunci aveți un drept care ne obligă să confirmăm că noi',
						)}
						<br />
						{t('- prelucrăm datele dumneavoastră personale sau')}
						<br />
						{t('- nu prelucrăm datele dumneavoastră personale.')}
						<br />
						{t(
							'Dreptul dumneavoastră de a obține confirmarea de la noi că prelucrăm (sau nu prelucrăm) datele dumneavoastră personale',
						)}
						<br />
						{t('- nu include date anonime.')}
						<br />
						{t('- include doar datele personale care vă privesc.')}
						<br />
						{t(
							'- include date pseudonime care pot fi în mod clar legate de dumneavoastră.',
						)}
						<br />
						{t(
							'Trebuie să vă dăm acces la datele dumneavoastră personale dacă',
						)}
						<br />
						{t(
							'- solicitați să confirmăm dacă prelucrăm sau nu datele dumneavoastră personale și',
						)}
						<br />
						{t('- prelucrăm datele dumneavoastră personale și')}
						<br />
						{t(
							'- solicitați accesul la datele dumneavoastră personale.',
						)}
						<br />
						{t(
							'Trebuie să vă furnizăm o copie a datelor dumneavoastră personale dacă',
						)}
						<br />
						{t(
							'- solicitați să confirmăm dacă prelucrăm sau nu datele dumneavoastră personale și',
						)}
						<br />
						{t('- prelucrăm datele dumneavoastră personale și')}
						<br />
						{t(
							'- solicitați o copie a datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'Dacă solicitați copii suplimentare ale datelor dumneavoastră personale, atunci vă putem percepe o taxă rezonabilă, care se bazează pe costurile administrative necesare pentru îndeplinirea cererii.',
						)}
						<br />
						{t(
							'Aveți dreptul la informațiile privind garanțiile pe care le-am implementat pentru transferul datelor dumneavoastră personale către o țară care este în afara UE și SEE dacă',
						)}
						<br />
						{t(
							'- solicitați să confirmăm dacă prelucrăm sau nu datele dumneavoastră personale și',
						)}
						<br />
						{t(
							'- vă transferăm datele dumneavoastră personale într-o țară care este în afara UE și SEE.',
						)}
						<br />
						{t(
							'14.3. Aveți dreptul la rectificarea datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'Dreptul de a obține rectificarea datelor dumneavoastră cu caracter personal care sunt inexacte',
						)}
						<br />
						{t('- nu include date anonime.')}
						<br />
						{t('- include doar datele personale care vă privesc.')}
						<br />
						{t(
							'- include date pseudonime care pot fi în mod clar legate de dumneavoastră',
						)}
						<br />
						{t(
							'Trebuie să rectificăm datele dumneavoastră personale dacă',
						)}
						<br />
						{t('- prelucrăm datele dumneavoastră personale, și')}
						<br />
						{t(
							'- datele dumneavoastră personale sunt inexacte, și',
						)}
						<br />
						{t(
							'- solicitați să obțineți rectificarea datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'Trebuie să completăm datele dumneavoastră personale dacă',
						)}
						<br />
						{t('- prelucrăm datele dumneavoastră personale, și')}
						<br />
						{t(
							'- datele dumneavoastră personale sunt incomplete, și',
						)}
						<br />
						{t(
							'- solicitați să obțineți completarea datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'Aveți dreptul să ne furnizați o declarație suplimentară.',
						)}
						<br />
						{t(
							'Trebuie să comunicăm rectificarea datelor dumneavoastră personale destinatarilor datelor dumneavoastră personale (dacă există).',
						)}
						<br />
						{t(
							'Nu comunicăm rectificarea datelor dumneavoastră personale destinatarilor datelor dumneavoastră personale în cazul în care comunicarea către destinatar',
						)}
						<br />
						{t('- este imposibilă, sau')}
						<br />
						{t('- implică un efort disproporționat.')}
						<br />
						{t(
							'14.4. Aveți dreptul la ștergerea datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate, dacă',
						)}
						<br />
						{t(
							'- solicitați ștergerea datelor dumneavoastră personale, și',
						)}
						<br />
						{t('- prelucrăm datele dumneavoastră personale, și')}
						<br />
						{t(
							'- datele dumneavoastră personale nu sunt necesare scopurilor noastre de prelucrare a datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate dacă',
						)}
						<br />
						{t(
							'- solicitați ștergerea datelor dumneavoastră personale, și',
						)}
						<br />
						{t('- prelucrăm datele dumneavoastră personale, și')}
						<br />
						{t(
							'- vă retrageți consimțământul pe care se bazează prelucrarea datelor dumneavoastră personale, și',
						)}
						<br />
						{t(
							'- nu există un alt temei juridic pentru prelucrarea datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate dacă',
						)}
						<br />
						{t(
							'- solicitați să obțineți ștergerea datelor dumneavoastră personale, și',
						)}
						<br />
						{t('- prelucrăm datele dumneavoastră personale, și')}
						<br />
						{t(
							'- prelucrarea datelor dumneavoastră personale este necesară pentru îndeplinirea unei sarcini pe care o îndeplinim în interes public, sau',
						)}
						<br />
						{t(
							'- prelucrarea datelor dumneavoastră personale este necesară în cadrul exercitării unei autorități oficiale cu care este învestit, și',
						)}
						<br />
						{t(
							'- prelucrarea este necesară în scopul intereselor legitime pe care le urmărim, sau',
						)}
						<br />
						{t(
							'- prelucrarea este necesară în scopul intereselor legitime pe care o terță parte le urmărește, și',
						)}
						<br />
						{t(
							'- obiectați la prelucrarea de către noi a datelor dumneavoastră personale, și',
						)}
						<br />
						{t(
							'- prelucrarea datelor dumneavoastră cu caracter personal are un motiv legitim care nu prevalează asupra obiecției dumneavoastră',
						)}
						<br />
						{t(
							'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate dacă',
						)}
						<br />
						{t(
							'- solicitați ștergerea datelor dumneavoastră personale și',
						)}
						<br />
						{t('- prelucrăm datele dumneavoastră personale, și')}
						<br />
						{t(
							'- contestați prelucrarea de către noi a datelor dumneavoastră personale în scop de marketing direct, și',
						)}
						<br />
						{t(
							'- prelucrarea datelor dumneavoastră cu caracter personal are un motiv legitim care nu prevalează asupra obiecției dumneavoastră',
						)}
						<br />
						{t(
							'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate dacă',
						)}
						<br />
						{t(
							'- solicitați ștergerea datelor dumneavoastră personale, și',
						)}
						<br />
						{t(
							'- prelucrarea datelor dumneavoastră personale este ilegală.',
						)}
						<br />
						{t(
							'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate dacă',
						)}
						<br />
						{t(
							'- solicitați ștergerea datelor dumneavoastră personale, și',
						)}
						<br />
						{t(
							'- datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale care ne revine în temeiul dreptului Uniunii sau al dreptului intern al unui stat membru.',
						)}
						<br />
						{t(
							'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate dacă',
						)}
						<br />
						{t(
							'- solicitați ștergerea datelor dumneavoastră personale, și',
						)}
						<br />
						{t(
							'- datele dumneavoastră personale au fost colectate în legătură cu oferirea de servicii ale societății informaționale.',
						)}
						<br />
						{t(
							'Trebuie să comunicăm ștergerea datelor dumneavoastră personale destinatarilor către care le dezvăluim (dacă există).',
						)}
						<br />
						{t(
							'Nu comunicăm ștergerea datelor dumneavoastră personale destinatarilor către care le dezvăluim, în cazul în care comunicarea către destinatar',
						)}
						<br />
						{t('- este imposibilă, sau')}
						<br />
						{t('- implică un efort disproporționat.')}
						<br />
						{t(
							'14.5. Aveți dreptul să obțineți de la noi restricționarea prelucrării datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'Dreptul dumneavoastră de a obține restricții privind prelucrarea datelor dumneavoastră personale',
						)}
						<br />
						{t('- nu include date anonime.')}
						<br />
						{t('- include datele personale care vă privesc.')}
						<br />
						{t(
							'- include date pseudonime care pot fi în mod clar legate de dumneavoastră.',
						)}
						<br />
						{t(
							'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale pentru o perioadă, în scopul de a verifica exactitatea datelor dumneavoastră personale dacă',
						)}
						<br />
						{t(
							'- solicitați să obțineți restricționarea prelucrării datelor dumneavoastră personale și',
						)}
						<br />
						{t(
							'- contestați exactitatea datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale dacă',
						)}
						<br />
						{t(
							'- solicitați să obțineți restricționarea prelucrării datelor dumneavoastră personale și',
						)}
						<br />
						{t(
							'- prelucrarea datelor dumneavoastră personale este ilegală și',
						)}
						<br />
						{t(
							'- vă opuneți ștergerii datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale dacă',
						)}
						<br />
						{t(
							'- solicitați să obțineți restricționarea prelucrării datelor dumneavoastră personale și',
						)}
						<br />
						{t(
							'- nu avem nevoie de datele dumneavoastră personale în scopul prelucrării noastre și',
						)}
						<br />
						{t(
							'- solicitați datele dumneavoastră personale pentru a stabili o reclamație legală sau',
						)}
						<br />
						{t(
							'- solicitați datele dumneavoastră personale pentru a vă exercita o plângere legală sau',
						)}
						<br />
						{t(
							'- aveți nevoie de datele dumneavoastră personale pentru a vă apăra împotriva unei plângeri legale.',
						)}
						<br />
						{t(
							'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale dacă solicitați să obțineți restricționarea',
						)}
						<br />
						{t('- prelucrării datelor dumneavoastră personale și')}
						<br />
						{t(
							'- obiectați de prelucrarea datelor dumneavoastră personale care sunt necesare pentru îndeplinirea unei sarcini pe care o îndeplinim în interes public, sau',
						)}
						<br />
						{t(
							'- vă opuneți prelucrării datelor dumneavoastră personale care sunt necesare în exercitarea unei autorități oficiale care ne este încredințată și',
						)}
						<br />
						{t(
							'- vă opuneți prelucrării datelor dumneavoastră personale care sunt necesare în scopul intereselor legitime pe care le urmărim și',
						)}
						<br />
						{t(
							'- așteptați să verificați dacă prelucrarea datelor dumneavoastră personale are un motiv legitim care nu depășește obiecția dumneavoastră',
						)}
						<br />
						{t(
							'Trebuie să comunicăm restricționarea prelucrării datelor dumneavoastră personale către destinatarii datelor dumneavoastră personale (dacă există).',
						)}
						<br />
						{t(
							'Nu comunicăm restricționarea prelucrării datelor dumneavoastră personale către destinatarii datelor dumneavoastră personale în cazul în care comunicarea către destinatar',
						)}
						<br />
						{t('- este imposibilă sau')}
						<br />
						{t('- implică un efort disproporționat.')}
						<br />
						{t(
							'Dacă restricționăm prelucrarea datelor dumneavoastră personale, atunci putem',
						)}
						<br />
						{t('- stoca datele dumneavoastră personale,')}
						<br />
						{t(
							'- prelucra datele dumneavoastră personale pe baza consimțământului dumneavoastră,',
						)}
						<br />
						{t(
							'- prelucra datele dumneavoastră personale pentru a întemeia o reclamație legală,',
						)}
						<br />
						{t(
							'- prelucra datele dumneavoastră personale pentru a exercita o plângere legală,',
						)}
						<br />
						{t(
							'- prelucra datele dumneavoastră personale pentru a ne apăra împotriva unei plângeri legale,',
						)}
						<br />
						{t(
							'- prelucra datele dumneavoastră personale pentru a proteja drepturile unei persoane,',
						)}
						<br />
						{t(
							'- prelucra datele dumneavoastră personale din motive de interes public ale Uniunii sau ale unui stat membru.',
						)}
						<br />
						{t(
							'Dacă obțineți o restricționare privind prelucrarea datelor dumneavoastră personale, trebuie să vă informăm înainte de ridicarea restricționării.',
						)}
						<br />
						{t(
							'14.6. Dacă prelucrăm datele dumneavoastră personale în scopuri de marketing direct, inclusiv profilarea (în măsura în care este legată de un astfel de marketing direct), aveți dreptul să obiectați cu privire la prelucrarea datelor dumneavoastră personale în acest scop.',
						)}
						<br />
						{t(
							'Dreptul dumneavoastră de a obiecta la prelucrarea datelor dumneavoastră personale în scopuri de marketing direct',
						)}
						<br />
						{t('- este un drept pe care îl aveți în orice moment.')}
						<br />
						{t('- nu include date anonime.')}
						<br />
						{t('- include datele personale care vă privesc.')}
						<br />
						{t('- nu include date personale care nu vă privesc.')}
						<br />
						{t(
							'- include date pseudonime care pot fi în mod clar legate de dumneavoastră',
						)}
						<br />
						{t(
							'Dacă obiectați la prelucrarea datelor dumneavoastră personale în scopuri de marketing direct, atunci trebuie să omitem prelucrarea datelor dumneavoastră personale în acest scop.',
						)}
						<br />
						{t(
							'Dacă vă procesăm datele dumneavoastră personale în scopuri de marketing direct, inclusiv profilarea (în măsura în care este legată de un astfel de marketing direct), atunci',
						)}
						<br />
						{t(
							'- trebuie să vă aducem la cunoștință, în mod explicit, acest drept, cel mai târziu la momentul primei comunicări cu dumneavoastră și',
						)}
						<br />
						{t(
							'- trebuie să vă prezentăm acest drept în mod clar și separat de orice altă informație.',
						)}
						<br />
						<br />
						{t('15. Cum vă puteți exercita drepturile?')}
						<br />
						{t(
							'15.1. Vă invităm să comunicați cu noi despre exercitarea drepturilor dumneavoastră privind protecția datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'15.2. Acceptăm numai solicitări scrise, deoarece nu putem face față solicitărilor verbale imediat, fără să',
						)}
						<br />
						{t(
							'- analizăm mai întâi conținutului cererii și fără să',
						)}
						<br />
						{t('- vă identificăm mai întâi.')}
						<br />
						{t(
							'Cererea dumneavoastră trebuie să conțină o descriere detaliată și precisă a dreptului pe care doriți să-l exercitați.',
						)}
						<br />
						{t(
							'Trebuie să ne furnizați o copie a unui document de identificare pentru a vă confirma identitatea ca, de exemplu',
						)}
						<br />
						{t('- un carnet de identitate sau')}
						<br />
						{t('- un pașaport.')}
						<br />
						{t('Documentul trebuie să conțină')}
						<br />
						{t('- un număr de identificare,')}
						<br />
						{t('- țara de emisiune,')}
						<br />
						{t('- perioada de valabilitate,')}
						<br />
						{t('- numele dumneavoastră,')}
						<br />
						{t('- adresa dumneavoastră, și')}
						<br />
						{t('- data dumneavoastră de naștere.')}
						<br />
						{t(
							'Orice alte date conținute în copia documentului de identificare, cum ar fi o fotografie sau orice alte caracteristici personale, pot fi mascate.',
						)}
						<br />
						{t(
							'Nu vom accepta alte mijloace de a vă asigura identitatea.',
						)}
						<br />
						{t(
							'Dacă doriți să propuneți alternative, le vom evalua de la caz la caz.',
						)}
						<br />
						{t(
							'Folosirea informațiilor din documentul dumneavoastră de identificare',
						)}
						<br />
						{t(
							'- este limitată la activitatea de confirmare a identității dumneavoastră și',
						)}
						<br />
						{t(
							'- nu va genera o stocare a datelor dumneavoastră personale mai mult decât este necesar în acest scop.',
						)}
						<br />
						{t(
							'Puteți trimite solicitarea dumneavoastră referitoare la protecția datelor dumneavoastră personale la',
						)}
						<br />
						{t('15.2.1 email contact@cv30.co')}
						<br />
						{t(
							'15.3. Veți primi răspunsul nostru la cererile dumneavoastră care vizează protecția datelor dumneavoastră personale la adresa de pe care am primit solicitarea.',
						)}
						<br />
						{t(
							'15.4. Am desemnat o persoană responsabilă de tratarea cererilor dumneavoastră referitoare la protecția datelor dumneavoastră personale.',
						)}
						<br />
						{t(
							'15.5. Am implementat politici care ne asigură faptul că o cerere privind protecția datelor dumneavoastră personale este',
						)}
						<br />
						{t('- recunoscută, și')}
						<br />
						{t('- soluționată în termenele prevăzute de lege.')}
						<br />
						{t(
							'15.6. Vă informăm despre modul în care ne ocupăm de cererea dumneavoastră (prin care vă exercitați drepturile) cu privire la protecția datelor dumneavoastră personale',
						)}
						<br />
						{t('15.6.1 intr-o lună de la data primirii cererii.')}
						<br />
						<br />
						{t('16. Aveți dreptul de a depune o plângere?')}
						<br />
						{t(
							'16.1. Puteți depune o plângere la o autoritate de supraveghere',
						)}
						<br />
						{t(
							'- la domiciliul dumneavoastră obișnuit în UE și SEE.',
						)}
						<br />
						{t('- la locul dumneavoastră de muncă în UE și SEE.')}
						<br />
						{t('- la locul presupusei încălcări în UE și în SEE.')}
						<br />
						{t(
							'Autoritatea de supraveghere trebuie să vă informeze într-un termen rezonabil cu privire la',
						)}
						<br />
						{t('- progresul plângerii și')}
						<br />
						{t('- rezultatul plângerii.')}
						<br />
						{t(
							'16.2. Puteți mandata o organizație să depună o plângere în numele dumneavoastră la o autoritate de supraveghere.',
						)}
						<br />
						{t(
							'Autoritatea de supraveghere trebuie să vă informeze într-un termen rezonabil cu privre la',
						)}
						<br />
						{t('- progresul plângerii și')}
						<br />
						{t('- rezultatul plângerii.')}
						<br />
						{t(
							'16.3. Aveți dreptul de a exercita o cale de atac judiciară în UE și în SEE împotriva',
						)}
						<br />
						{t('- unui operator,')}
						<br />
						{t('- unui împuternicit și')}
						<br />
						{t('- unei Autorități de Supraveghere.')}
						<br />
						{t(
							'16.4. Puteti mandata o organizație să exercite, în numele dumneavoastră, dreptul',
						)}
						<br />
						{t('- la o cale de atac judiciară.')}
						<br />
						{t(
							'- la o despăgubire pentru un prejudiciu rezultat ca urmare a unei încălcări a legii privind protecția datelor cu caracter personal.',
						)}
						<br />
						<br />
						{t('17. Puteți alege setările de confidențialitate?')}
						<br />
						{t(
							'17.1. În prezent, nu ne puteți declara alegerea dumneavoastră privind prelucrarea datelor dumneavoastră personale.',
						)}
						<br />
						<br />
						{t(
							'18. Veți fi informat despre schimbările politicii de confidențialitate?',
						)}
						<br />
						{t(
							'18.1. Dacă ne schimbăm politica de confidențialitate, vom publica o nouă versiune a acesteia.',
						)}
						<br />
						{t(
							'18.2. Nu punem la dispoziție versiunile anterioare ale politicii noastre de confidențialitate.',
						)}
						<br />
						<br />
						{t(
							'19. Explicații cu privire la termenii și expresiile utilizați în această notă de informare',
						)}
						<br />
						{t(
							'19.1. Toţi termenii şi expresiile folosiți în această Notă de Informare vor avea înţelesul dat mai jos, cu excepţia cazului în care se prevede altfel în cuprinsul Notei de Informare.',
						)}
						<br />
						{t(
							'19.1.1. Date cu caracter personal înseamnă orice informații privind o persoană fizică identificată sau identificabilă („persoana vizată”).',
						)}
						<br />
						{t(
							'O persoană fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, în special prin referire la un element de identificare, cum ar fi',
						)}
						<br />
						{t('- un nume')}
						<br />
						{t('- un număr de identificare')}
						<br />
						{t('- date de localizare')}
						<br />
						{t('- un identificator online')}
						<br />
						{t(
							'- identitatea fizică a unei persoane persoane fizice',
						)}
						<br />
						{t(
							'- identitatea fiziologică a unei persoane persoane fizice',
						)}
						<br />
						{t(
							'- identitatea genetică a unei persoane persoane fizice',
						)}
						<br />
						{t(
							'- identitatea psihică a unei persoane persoane fizice',
						)}
						<br />
						{t(
							'- identitatea economică a unei persoane persoane fizice',
						)}
						<br />
						{t(
							'- identitatea culturală a unei persoane persoane fizice',
						)}
						<br />
						{t(
							'- identitatea socială a unei persoane persoane fizice',
						)}
						<br />
						{t(
							'19.1.2. Datele personale sensibile sunt - conform GDPR - numite categorii speciale de date personale.',
						)}
						<br />
						{t(
							'Datele personale sunt sensibile dacă prelucrarea acestor date personale dezvăluie',
						)}
						<br />
						{t('- originea rasială,')}
						<br />
						{t('- originea etnică,')}
						<br />
						{t('- opiniile politice,')}
						<br />
						{t('- credințele religioase,')}
						<br />
						{t('- credințele filosofice,')}
						<br />
						{t('- apartenența la un sindicat.')}
						<br />
						{t('De asemenea, datele personale sunt sensibile dacă')}
						<br />
						{t(
							'- datele genetice sunt prelucrate pentru scopul de a identifica unic o persoană fizică,',
						)}
						<br />
						{t(
							'- datele biometrice sunt prelucrate pentru scopul de a identifica unic o persoană fizică.',
						)}
						<br />
						{t('Datele personale sensibile mai includ')}
						<br />
						{t('- date care vizează starea de sănătate,')}
						<br />
						{t(
							'- date care vizează viața sexuală a unei persoane fizice,',
						)}
						<br />
						{t(
							'- date care vizează orientarea sexuală a unei persoane fizice.',
						)}
						<br />
						{t(
							'19.1.3. Nu există o listă exhaustivă a acestor date cu caracter personal.',
						)}
						<br />
						{t(
							'19.1.4. Pseudonimizarea Datelor Personale înseamnă prelucrarea datelor cu caracter personal într-un asemenea mod încât acestea să nu mai poată fi atribuite unei anume persoane vizate fără a se utiliza informații suplimentare, cu condiția ca aceste informații suplimentare să fie stocate separat și să facă obiectul unor măsuri de natură tehnică și organizatorică care să asigure neatribuirea respectivelor date cu caracter personal unei persoane fizice identificate sau identificabile.',
						)}
						<br />
						{t(
							'19.1.5. Prelucrare semnifică orice operațiune sau set de operațiuni efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi',
						)}
						<br />
						{t('- colectarea,')}
						<br />
						{t('- înregistrarea,')}
						<br />
						{t('- organizarea,')}
						<br />
						{t('- structurarea,')}
						<br />
						{t('- stocarea,')}
						<br />
						{t('- adaptarea,')}
						<br />
						{t('- alterarea,')}
						<br />
						{t('- extragerea,')}
						<br />
						{t('- consultarea,')}
						<br />
						{t('- utilizarea,')}
						<br />
						{t('- ștergerea sau distrugerea')}
						<br />
						{t('- etc.')}
						<br />
						{t(
							'19.1.6. Restricționarea prelucrării înseamnă marcarea datelor personale stocate cu scopul de a limita prelucrarea acestora în viitor.',
						)}
						<br />
						{t(
							'19.1.7. Scopul prelucrării înseamnă motivul pentru care este realizată prelucrarea datelor personale.',
						)}
						<br />
						{t('19.1.8. Profilarea')}
						<br />
						{t(
							'- (1) trebuie să fie o formă automată de prelucrare, ce include',
						)}
						<br />
						{t(
							'- Prelucrare exclusiv automată (la care face referire Art. 22 din GDPR) și',
						)}
						<br />
						{t(
							'- Prelucrare parțial automată (dacă o persoană fizică este implicată în prelucrarea datelor personale nu înseamnă neapărat ca prelucrarea nu constituie profilare)',
						)}
						<br />
						{t(
							'- (2) trebuie să fie efectuată cu privire la datele cu caracter personal; și',
						)}
						<br />
						{t(
							'- (3) obiectivul profilării trebuie să fie acela de a evalua aspectele personale legate de o persoană fizică, în special de a analiza sau de a face previziuni despre persoane.',
						)}
						<br />
						{t(
							'Rețineți că simpla evaluare sau clasificare a persoanelor în mod automat pe baza unor caracteristici cum ar fi vârsta, sexul și înălțimea acestora ar putea fi considerate profilare automată, indiferent de scopul predictiv.',
						)}
						<br />
						{t(
							'19.1.9. Decizii bazate exclusiv pe prelucrarea automată',
						)}
						<br />
						{t(
							'- (1) înseamnă luarea unor decizii prin mijloace tehnologice fără implicare umană; și care',
						)}
						<br />
						{t('- (2) se bazează pe datele cu caracter personal')}
						<br />
						{t(
							'- furnizate direct de persoanele în cauză (cum ar fi răspunsurile la un chestionar); sau',
						)}
						<br />
						{t(
							'- observate cu privire la persoane (cum ar fi datele privind locația, colectate prin intermediul unei aplicații) sau',
						)}
						<br />
						{t(
							'- derivate sau deduse, cum ar fi profilul persoanei care a fost deja creat (de ex. un scor de credit) pot fi realizate cu sau fără profilare; profilarea poate avea loc fără a lua decizii automate.',
						)}
						<br />
						{t(
							'19.1.10. Operator înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care, singur sau împreună cu altele, stabilește scopurile și mijloacele de prelucrare a datelor cu caracter personal; atunci când scopurile și mijloacele prelucrării sunt stabilite prin dreptul Uniunii sau dreptul intern, operatorul sau criteriile specifice pentru desemnarea acestuia pot fi prevăzute în dreptul Uniunii sau în dreptul intern.',
						)}
						<br />
						{t(
							'19.1.11. Împuternicit înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care prelucrează datele cu caracter personal în numele și pe seama operatorului.',
						)}
						<br />
						{t(
							'19.1.12. Destinatar înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism căreia (căruia) îi sunt divulgate datele cu caracter personal, indiferent dacă este sau nu o parte terță. Cu toate acestea, autoritățile publice cărora li se pot comunica date cu caracter personal în cadrul unei anumite anchete în conformitate cu dreptul Uniunii sau cu dreptul intern nu sunt considerate destinatari; prelucrarea acestor date de către autoritățile publice respective respectă normele aplicabile în materie de protecție a datelor, în conformitate cu scopurile prelucrării.',
						)}
						<br />
						{t(
							'19.1.13. Parte Terță înseamnă o persoană fizică sau juridică, autoritate publică, agenție sau organism altul decât persoana vizată, operatorul, persoana împuternicită de operator și persoanele care, sub directa autoritate a operatorului sau a persoanei împuternicite de operator, sunt autorizate să prelucreze date cu caracter personal.',
						)}
						<br />
						{t(
							'19.1.14. Reprezentant înseamnă o persoană fizică sau juridică stabilită în Uniune, desemnată în scris de către operator sau persoana împuternicită de operator în temeiul articolului 27, care reprezintă operatorul sau persoana împuternicită în ceea ce privește obligațiile lor respective care le revin în temeiul prezentului regulament.',
						)}
						<br />
						{t(
							'19.1.15. Autoritate de supraveghere înseamnă o autoritate publică independentă instituită de un stat membru în temeiul articolului 51 GDPR.',
						)}
						<br />
						{t(
							'19.1.16. Reguli corporatiste obligatorii înseamnă politicile în materie de protecție a datelor cu caracter personal care trebuie respectate de un operator sau de o persoană împuternicită de operator stabilită pe teritoriul unui stat membru, în ceea ce privește transferurile sau seturile de transferuri de date cu caracter personal către un operator sau o persoană împuternicită de operator în una sau mai multe țări terțe în cadrul unui grup de întreprinderi sau al unui grup de întreprinderi implicate într-o activitate economică comună.',
						)}
						<br />
						{t(
							'19.1.17. Scutul de confidențialitate UE-SUA (Privacy Shield)',
						)}
						<br />
						{t(
							'- Cadrul UE - SUA Privacy Shield a fost pus la punct de către Departamentul de Comerț al SUA și Comisia Europeană pentru a oferi companiilor de pe ambele părți ale Atlanticului un mecanism pentru a se conforma cerințelor de protecție a datelor atunci când transferă date cu caracter personal din Uniunea Europeană către Statele Unite în sprijinul comerțului transatlantic. La 12 iulie 2016 Comisia Europeană a aprobat cadrul UE-SUA Privacy Shield ca fiind adecvat pentru a permite transferul de date în conformitate cu legislația UE.',
						)}
						<br />
						{t('19.1.18. Deciziile de Adecvare ale Comisiei')}
						<br />
						{t(
							'- Comisia Europeană are competența de a determina, pe baza articolului 45 GDPR, dacă o țară din afara UE oferă un nivel adecvat de protecție a datelor, fie prin legislația internă, fie prin angajamentele internaționale pe care le-a încheiat.',
						)}
						<br />
						{t(
							'- Efectul unei astfel de decizii este că datele cu caracter personal pot proveni din SEE (UE și Norvegia, Liechtenstein și Islanda) în acea țară terță, fără a fi necesară nicio altă măsură de protecție.',
						)}
						<br />
						{t(
							'- Comisia Europeană a recunoscut, până în prezent, un nivel de protecție adecvat, pentru Andorra, Argentina, Canada (organizații comerciale - PIPEDA), Insulele Feroe, Guernsey, Israel, Insula Man, Jersey, Noua Zeelandă, Elveția, Uruguay și SUA (limitată la cadrul Privacy Shield).',
						)}
						<br />
						{t(
							'19.1.19. Încălcarea securității datelor cu caracter personal înseamnă o încălcare a securității care duce, în mod accidental sau ilegal, la distrugerea, pierderea, modificarea, sau divulgarea neautorizată a datelor cu caracter personal transmise, stocate sau prelucrate într-un alt mod, sau la accesul neautorizat la acestea.',
						)}
						<br />
						{t(
							'19.1.20. Întreprindere înseamnă o persoană fizică sau juridică ce desfășoară o activitate economică, indiferent de forma juridică a acesteia, inclusiv parteneriate sau asociații care desfășoară în mod regulat o activitate economică.',
						)}
						<br />
						{t(
							'19.1.21. Grup de întreprinderi înseamnă o întreprindere care exercită controlul și întreprinderile controlate de aceasta;',
						)}
						<br />
						{t(
							'19.1.22. Organizație internațională înseamnă o organizație și organismele sale subordonate reglementate de dreptul internațional public sau orice alt organism care este instituit printr-un acord încheiat între două sau mai multe țări sau în temeiul unui astfel de acord.',
						)}
						<br />
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>
							{t('Limitarea responsabilității')}
						</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Afirmaţi în mod expres că aţi luat cunoştinţă şi sunteţi de acord că această companie nu va fi răspunzătoare pentru nici un fel de pagube directe, indirecte, accidentale, speciale, inclusiv, dar nu limitat la nerealizare de profit, posibilităţii de folosire, date sau alte pagube (chiar dacă compania a fost înainte informată de posibilitatea apariţiei unor asemenea pierderi), rezultând din',
						)}
						<br />
						{t(
							'1. utilizarea sau imposibilitatea utilizării serviciilor site-ului;',
						)}
						<br />
						{t(
							'2. acces neautorizat la sau deteriorarea transmisiilor sau datelor dumneavoastră;',
						)}
						<br />
						{t(
							'3. declaraţii sau acţiuni a oricărei terţe părţi asupra serviciilor site-ului;',
						)}
						<br />
						{t(
							'4. orice altă problemă legată de serviciile site-ului.',
						)}
						<br />
						<br />
						{t(
							'Declaraţi în mod expres că înţelegeţi şi că sunteţi de acord cu următoarele afirmaţii',
						)}
						<br />
						{t(
							'1. utilizarea serviciilor site-ului se face pe propria răspundere. Serviciile sunt furnizate pe principiul "aşa cum sunt" sau "aşa cum sunt disponibile".',
						)}
						<br />
						{t('2. Compania nu oferă nici o garanţie că')}
						<br />
						{t(
							'serviciile vor împlini toate cerinţele dumneavoastră;',
						)}
						<br />
						{t(
							'serviciile vor fi neîntrerupte, la timp, sigure sau fără erori;',
						)}
						<br />
						{t(
							'rezultatele ce se vor putea obţine din folosirea site-ului şi a serviciilor puse la dispoziţie vor fi corecte sau de încredere;',
						)}
						<br />
						{t('orice eroare de program va fi corectată;')}
						<br />
						{t(
							'3. Orice material descărcat sau obţinut în alt fel prin utilizarea serviciilor puse la dispoziţie de companie se află astfel la discreţia şi poate fi folosit doar pe riscul propriu şi veţi singura persoană responsabilă de eventualele distrugeri cauzate computerului de pe care accesaţi site-ul sau alte pierderi de date ce pot rezulta din descărcarea acestui tip de conţinut.',
						)}
						<br />
					</Typography>
					<DialogTitle id="responsive-dialog-title">
						<p style={{ fontSize: 15.4 }}>{t('Generalităţi ')}</p>
					</DialogTitle>
					<Typography
						variant="body2"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						{t(
							'Dacă vreo prevedere din acest capitol "Termeni şi condiţii" este considerată invalidă de către forurile legale competente, invaliditatea unei asemenea prevederi nu va afecta valabilitatea celorlalte prevederi ale "Termeni şi condiţii", acestea rămânând în vigoare. "Termeni şi condiţii" constituie în întregime un acord încheiat între dumneavoastră şi companie în privinţa utilizării site-ului www.cv30.co.Aceste condiţii pot fi modificate de noi la anumite intervale, fără o anunţare sau o acceptare prealabilă a utilizatorilor. Puteţi citi cea mai recentă versiune a acestor condiţii oricând, accesând aceasta pagină.',
						)}
						<br />
						<br />
						{t(
							'Prezentul acord constituie unică convenţie dintre dumneavoastră şi companie şi reglementează folosirea de către dumneavoastră a serviciilor site-ului www.cv30.co , impunându-se în faţa oricărui acord precedent dintre dumneavoastră şi companie sau site-urile sale (inclusiv, dar nu limitat la o versiune precedentă a Regulamentului). Puteţi de asemenea fi supuşi unei alte versiuni a Regulamentului care se poate aplica atunci când folosiţi alte servicii ale companiei sau ale partenerilor săi, conţinut al oricărei terţe părţi, sau software a oricărui terţ. Regulamentul şi relaţiile dintre utilizatori şi companie vor fi guvernate de legile aplicabile în România.',
						)}
						<br />
						<br />
						{t(
							'Actualizarea acordului de utilizare a fost realizata la data de 15 mai 2020.',
						)}
						<br />
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleAgreement(false)}
						color="primary"
					>
						{t('Disagree')}
					</Button>
					<Button
						onClick={() => handleAgreement(true)}
						color="primary"
						autoFocus
					>
						{t('Agree')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	},
)

const ModalTermsAndConditions = (props: IModalTermsAndConditionsView) => (
	<StoreValue keys={['user.id|storeUserId']}>
		<ModalTermsAndConditionsView {...props} />
	</StoreValue>
)

export default memo(ModalTermsAndConditions)
