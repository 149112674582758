import gql from 'graphql-tag'

import {
	standardJobResult,
	jobApplicantsConnectionData,
	jobConnectionData,
} from 'store/fragments'

// QUERIES
export const JobQuery = gql`
	query job($id: String, $jobSlug: String, $companySlug: String) {
		job(id: $id, jobSlug: $jobSlug, companySlug: $companySlug) {
			...standardJobResult
		}
	}
	${standardJobResult}
`

export const FeedJobsQuery = gql`
	query feedJobs($data: FeedJobsInput) {
		feedJobs(data: $data) {
			...jobConnectionData
		}
	}
	${jobConnectionData}
`

export const FeedJobApplicantsQuery = gql`
	query feedJobApplicants($data: FeedJobApplicantsInput) {
		feedJobApplicants(data: $data) {
			...jobApplicantsConnectionData
		}
	}
	${jobApplicantsConnectionData}
`

// MUTATIONS
export const ApplyJob = gql`
	mutation applyJob(
		$language: LanguageCodeType!
		$id: String!
		$isApplying: Boolean!
	) {
		applyJob(language: $language, id: $id, isApplying: $isApplying) {
			error
			status
		}
	}
`

export const PostJob = gql`
	mutation createJob($data: JobInput!) {
		createJob(data: $data) {
			...standardJobResult
		}
	}
	${standardJobResult}
`
export const EditJob = gql`
	mutation editJob($data: UpdateJobInput!) {
		editJob(data: $data) {
			...standardJobResult
		}
	}
	${standardJobResult}
`

export const DeleteJob = gql`
	mutation deleteJob($data: DeleteJobInput!) {
		deleteJob(data: $data) {
			...standardJobResult
		}
	}
	${standardJobResult}
`
