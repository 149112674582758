import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		header: {
			marginTop: '45px',
		},
		relativeContainer: {
			position: 'relative',
		},
		inputBase: {
			color: theme.palette.grey[600],
		},
		outlinedLabel: {
			transform: 'translate(11px, 12px) scale(1)',
		},
		notchedOutlineLabel: {
			color: theme.palette.grey[500],
		},
		outlinedInputRoot: {
			borderRadius: 2,
		},
		notchedOutline: {
			border: '1px solid #ebebeb',
		},
		formControl: {
			width: '100%',
			marginBottom: theme.spacing(1),
		},
		salaryMinTextField: {
			width: '49%',
			marginRight: 4,
			marginTop: theme.spacing(1),
		},
		salaryMaxTextField: {
			width: '49%',
			marginTop: theme.spacing(1),
		},
		labelTeams: {
			color: theme.palette.grey[500],
			paddingLeft: 8,
			'&.MuiInputLabel-shrink': {
				marginTop: '-8px',
				paddingLeft: 0,
				fontSize: 20,
			},
		},
		selectedLocation: {
			display: 'flex',
			alignItems: 'flex-start',
			flexDirection: 'row',
		},
		labelText: {
			// marginTop: '8px',
			marginBottom: '5px',
			fontSize: 16,
			color: theme.palette.grey[800],
		},
	})
