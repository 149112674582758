import { createStyles, Theme, emphasize } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		details: {
			alignSelf: 'center',
		},
		name: {
			color: '#424242',
			fontSize: '15px',
			fontWeight: 500,
		},
		asyncSelect: {
			flexGrow: 1,
			marginTop: 8,
			marginBottom: 12,
		},
		input: {
			display: 'flex',
			padding: 0,
			height: 'auto',
			cursor: 'default',
		},
		placeholder: {
			position: 'absolute',
			left: 2,
			bottom: 6,
			fontSize: 16,
		},
		valueContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			flex: 1,
			alignItems: 'center',
			overflow: 'hidden',
		},
		chip: {
			margin: theme.spacing(0.5, 0.25),
		},
		chipFocused: {
			backgroundColor: emphasize(
				theme.palette.type === 'light'
					? theme.palette.grey[300]
					: theme.palette.grey[700],
				0.08,
			),
		},
		noOptionsMessage: {
			padding: theme.spacing(1, 2),
		},
		actionButtons: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			'&>div': {
				cursor: 'pointer',
			},
			'&>div:first-child': {
				marginRight: '10px',
			},
		},
		disabledSelect: {
			pointerEvents: 'none',
			color: 'grey',
		},
	})
