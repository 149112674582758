import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		avatarContainer: {
			width: theme.spacing(8) - theme.spacing(1),
			minWidth: theme.spacing(8) - theme.spacing(1),
			height: theme.spacing(8) - theme.spacing(1),
			position: 'relative',
			background: '#fff',
			alignSelf: 'center',
			'& span': {
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				margin: 'auto',
				alignItems: 'center',
				justifyContent: 'center',
				display: 'flex',
				width: '100%',
				height: '100%',
			},
			'& svg': {
				fill: theme.palette.grey[500],
				width: '100%',
				height: '100%',
			},
			[theme.breakpoints.down('sm')]: {
				width: theme.spacing(6),
				height: theme.spacing(6),
				minWidth: theme.spacing(5),
			},
		},

		avatarFullViewContainer: {
			width: theme.spacing(10) - theme.spacing(1),
			minWidth: theme.spacing(10) - theme.spacing(1),
			height: theme.spacing(10) - theme.spacing(1),
			position: 'relative',
			background: '#fff',
			'& span': {
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				margin: 'auto',
				alignItems: 'center',
				justifyContent: 'center',
				display: 'flex',
				width: '100%',
				height: '100%',
			},
			'& svg': {
				fill: theme.palette.grey[500],
				width: '100%',
				height: '100%',
			},
		},
		avatar: {
			background: '#fff',
			width: '100%',
			height: '100%',
			// width: theme.spacing(8) - theme.spacing(1),
			// height: theme.spacing(8) - theme.spacing(1),
			'& svg': {
				fill: theme.palette.grey[500],
				width: '100%',
				height: '100%',
			},
		},
		avatarFullView: {
			background: '#fff',
			width: '100%',
			height: '100%',
			// width: theme.spacing(10) - theme.spacing(1),
			// height: theme.spacing(10) - theme.spacing(1),
			'& svg': {
				fill: theme.palette.grey[500],
				width: '100%',
				height: '100%',
			},
		},
		loader: {
			position: 'relative',
			width: '100%!important',
			height: '100%!important',
			// top: -8,
			// left: 12,
		},
		uploadBtn: {
			background: '#a9a9a940',
			width: '60px',
			height: '60px',
		},
	})
