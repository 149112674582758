import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignSelf: 'stretch',
			flexDirection: 'row',
			flex: '1',
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
		},
		minWidth: {
			width: '100%',
			maxWidth: 670,
		},
		content: {
			[theme.breakpoints.up('sm')]: {
				minWidth: 670,
			},
		},
		tabs: {
			flexShrink: 0,
			height: 'auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'nowrap',
			margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
			justifyContent: 'space-evenly',
			[theme.breakpoints.only('xs')]: {
				justifyContent: 'flex-start',
			},
			'&> a': {
				margin: theme.spacing(1),
			},
		},
		tabsContent: {
			marginTop: 25,
			flexGrow: 1,
			margin: theme.spacing(1),
			[theme.breakpoints.down('sm')]: {
				marginTop: 0,
				margin: 0,
			},
		},
		tabsContentHeadline: {
			color: theme.palette.grey[800],
			opacity: 0.5,
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 8,
			},
		},
		tabsContentContainer: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		tabsContentContainerCompany: {
			flexDirection: 'column',

			padding: '0px 8px',
		},
		tabsContentContainerTeams: {
			marginTop: '10px',
			flexDirection: 'column',
			padding: '0px 8px',
		},
		fixFlexList: {
			marginLeft: -1 * theme.spacing(1),
			[theme.breakpoints.down('sm')]: {
				marginLeft: 'unset',
			},
		},
		companyBadge: {
			maxWidth: '100%',
			width: 'auto',
			marginTop: theme.spacing(1),
			marginLeft: theme.spacing(1),
		},
		companyBadgeAvatar: {
			border: '1px solid #ebebeb',
		},
		peopleBadgeCurrentPosition: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'nowrap',
			fontWeight: 500,
			fontSize: 16,
			lineHeight: '26px',
		},
		articlesContainer: {
			maxWidth: '100%',
			marginTop: 32,
			flex: 1,
		},
		link: {
			fontFamily: 'Open Sans, sans-serif',
			color: theme.palette.grey[400],
			fontWeight: 700,
			textDecoration: 'none',
			fontSize: 16,
			lineHeight: '26px',
			[theme.breakpoints.up('sm')]: {
				fontSize: 18,
				lineHeight: '20px',
				[theme.breakpoints.up('md')]: {
					fontSize: 20,
					lineHeight: '22px',
					[theme.breakpoints.up('lg')]: {
						fontSize: 22,
						lineHeight: '24px',
						[theme.breakpoints.up('xl')]: {
							fontSize: 24,
							lineHeight: '26px',
						},
					},
				},
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: 15,
			},
		},
		active: {
			color: theme.palette.text.secondary,
			borderBottom: `2px solid ${theme.palette.text.secondary}`,
		},
		delimiter: {
			border: 'none',
			backgroundColor: theme.palette.grey[400],
			opacity: 0.5,
			height: 1,
			margin: `${theme.spacing(2)}px 0`,
		},
		newSearchContainer: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			alignItems: 'center',
			whiteSpace: 'pre',
			textAlign: 'center',
			paddingTop: '10%',
			[theme.breakpoints.down('sm')]: {
				padding: 0,
				justifyContent: 'center',
			},
		},
		searchIcon: {
			width: 124,
			height: 124,
			color: theme.palette.grey[500],
			marginBottom: theme.spacing(2),
		},
		emptySearchIcon: {
			width: 90,
			height: 90,
			marginBottom: theme.spacing(2),
		},
		newSearchText: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			alignItems: 'center',
			height: '100%',
			textAlign: 'center',
			[theme.breakpoints.down('sm')]: {
				padding: 0,
				justifyContent: 'center',
			},
			color: theme.palette.text.secondary,
			fontSize: 24,
			lineHeight: '30px',
			fontWeight: 300,
			whiteSpace: 'pre-wrap',
			[theme.breakpoints.down('sm')]: {
				fontSize: 16,
			},
		},
		companiesContainer: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 0,
			flexBasis: '100%',
			maxWidth: 670,
			// alignSelf: 'center',
			[theme.breakpoints.down('sm')]: {
				maxWidth: 670,
			},
		},
		companiesMainContent: {
			width: '100%',
			marginBottom: '30px',
			// padding: '0px 4px',
			position: 'relative',
			flexWrap: 'unset',
			[theme.breakpoints.down('sm')]: {
				padding: 'unset',
			},
		},
		searchContentCards: {
			padding: `0px ${theme.spacing(1)}px`,
		},
		companiesSearchContainer: {
			position: 'absolute',
			top: '20px',
			right: '40px',
			width: 'auto',
			maxWidth: '350px',
			paddingLeft: '20px',
		},
		jobsContainer: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 0,
			flexBasis: '100%',
			maxWidth: 670,
			// alignSelf: 'center',
			[theme.breakpoints.down('sm')]: {
				maxWidth: 670,
			},
		},
		jobsMainContent: {
			width: '100%',
			marginBottom: '30px',
			// padding: '0px 4px',
			position: 'relative',
			flexWrap: 'unset',
			[theme.breakpoints.down('sm')]: {
				padding: 'unset',
			},
		},
		jobsSearchContainer: {
			position: 'absolute',
			top: '20px',
			right: '40px',
			width: 'auto',
			maxWidth: '350px',
			paddingLeft: '20px',
		},
		pageTitle: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			color: theme.palette.grey[800],
			fontSize: '22px',
			fontWeight: 300,
			// marginBottom: '30px',
			'& div:first-child': {
				alignSelf: 'center',
				display: 'flex',
				flexDirection: 'row',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: theme.spacing(2),
				margin: `15px ${theme.spacing(1)}px`,
			},
		},
		titleWithFilterButton: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		onlyFilterButton: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			marginRight: 10,
		},
		pageTitleMain: {
			fontWeight: 700,
		},
		searchSection: {
			display: 'flex',
			flexDirection: 'row',
			// justifyContent: 'space-between',
			'& svg ': {
				verticalAlign: 'text-top',
				marginRight: theme.spacing(1),
			},
		},
		pageDescription: {
			color: theme.palette.grey[800],
			fontSize: '16px',
			fontWeight: 300,
			marginBottom: '20px',
			maxWidth: '70%',
		},
		searchSectionTitle: {
			color: '#1B1A7C',
			fontSize: '22px',
			fontWeight: 300,
		},
		searchSectionTitleMain: {
			fontWeight: 700,
		},
		searchField: {
			width: '100%',
			border: '1px solid #ebebeb',
			display: 'flex',
			alignItems: 'center',
			borderRadius: 2,
		},
		input: {
			flex: 1,
			padding: '5px 8px',
		},
		industryInput: {
			flex: 1,
			border: '1px solid #ebebeb',
			paddingTop: 11,
			paddingBottom: 12,
			paddingLeft: 5,
			paddingRight: 5,
		},
		selectSearchIcon: {
			fontSize: '20px',
			color: theme.palette.grey[500],
			marginRight: 5,
			marginLeft: 5,
		},
		iconButton: {
			padding: 0,
			marginRight: 5,
			marginLeft: 5,
		},
		chip: {
			margin: 2,
			'&:hover, &:focus': {
				backgroundColor: '#e1e1f4 !important',
				color: '#1B1A7C',
			},
		},

		chipActive: {
			background: '#e1e1f4',
			color: '#1B1A7C',
			cursor: 'pointer',
		},
		chipInactive: {
			color: '#1B1A7C',
		},
		availableLocations: {
			alignSelf: 'stretch',
			background: '#fff',
			borderRadius: '0 0 10px 10px',
		},
		locationsRoot: {
			padding: '2px 0px',
			display: 'flex',
			alignItems: 'flex-start',
			flexDirection: 'column',
		},
		selectedLoc: {
			display: 'flex',
			alignItems: 'flex-start',
			flexDirection: 'row',
			marginTop: theme.spacing(1),
		},
		labelText: {
			color: '#727272',
			fontSize: '16px',
			marginBottom: '3px',
		},
		filterDrawer: {
			width: 350,
		},
		filterSection: {
			position: 'absolute',
			top: '200px',
			right: '20px',
			width: 'auto',
			maxWidth: '350px',
			[theme.breakpoints.down('md')]: {
				top: '145px',
				right: '5px',
			},
			[theme.breakpoints.down('sm')]: {
				top: '110px',
				right: '5px',
			},
			[theme.breakpoints.up('md')]: {
				right: '10px',
			},
			[theme.breakpoints.up('lg')]: {
				right: '20px',
			},
		},
		filterSectionWithSmallerTop: {
			top: 35,
		},
		filterSectionButton: {
			[theme.breakpoints.down('sm')]: {
				padding: '2px',
			},
		},
		filterWrapper: {
			width: '300px',
		},
		placeholderText: {
			color: '#1b1a7c6b',
		},
		noLocations: {
			color: '#999',
			textAlign: 'center',
			fontStyle: 'italic',
			textTransform: 'lowercase',
			fontSize: 11,
		},

		chips: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		multipleSelect: {
			maxHeight: 'none',
		},
		selectIcon: {
			color: theme.palette.grey[400],
			marginRight: 5,
		},
		iconCloseContainer: {
			display: 'inline-block',
			paddingLeft: 10,
		},
		iconClose: {
			color: 'red',
			verticalAlign: 'top',
			'&:hover': {
				color: 'red',
			},
		},
		cardSection: {
			marginBottom: '30px',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			flexWrap: 'wrap',
			[theme.breakpoints.between('xs', 'sm')]: {
				justifyContent: 'space-between',
				marginLeft: '10px',
				marginRight: '10px',
			},
			[theme.breakpoints.between('sm', 'md')]: {
				justifyContent: 'flex-start',
			},
		},
		card: {
			backgroundColor: '#ebebeb',
			marginTop: '15px',
			marginRight: '10px',
			overflowWrap: 'break-word',
			transition: 'background-color 0.2s linear',
			padding: '10px',
			width: '200px',
			'&:hover': {
				backgroundColor: '#cecccc',
				cursor: 'default',
			},
			[theme.breakpoints.only('xs')]: {
				width: 'calc(100% - 50px)',
				margin: '10px auto',
				padding: '0px',
			},
			[theme.breakpoints.only('sm')]: {
				width: 'calc(1/2*100% - (1 - 1/2)*50px)',
				marginRight: '20px !important',
				padding: '0px',
				'&:nth-child(2n+2)': {
					marginRight: '0px',
				},
			},
			[theme.breakpoints.only('md')]: {
				width: 'calc(1/3*100% - (1 - 1/3)*30px)',
				marginRight: '20px',
				padding: '0px',
				'&:nth-child(3n+3)': {
					marginRight: '0px',
				},
			},
		},
		activeCard: {
			backgroundColor: '#cecccc',
		},
		cardTitle: {
			fontSize: '14px',
			fontWeight: 'bold',
		},
		cardSubTitle: {
			fontSize: '12px',
			fontWeight: 'normal',
		},
		salaryRangeItem: {
			paddingTop: '0px !important',
		},
		formControl: {
			margin: theme.spacing(1),
			width: '97%',
		},
		textField: {
			width: '100%',
		},
		labelTextField: {
			color: '#727272',
		},
		labelField: {
			color: theme.palette.grey[800],
		},
		titleField: {
			marginTop: '30px',
			paddingBottom: '0px !important',
		},
		currency: {
			display: 'flex',
			flexDirection: 'row',
		},
		filterButton: {
			display: 'flex',
			justifyContent: 'flex-end',
			marginRight: '10px',
		},
		resultsCount: {
			display: 'flex',
			fontSize: '12px',
			color: '#bababa',
		},
	})
