import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		cardRoot: {
			maxWidth: '670px',
			padding: '10px',
			width: '100%',
			height: '100%',
			boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.2)',
			borderRadius: '5px',
		},
		cardContent: {
			padding: 0,
			paddingBottom: '2px !important',
			paddingLeft: '10px',
		},
		cardContentTitle: {
			color: '#1b1a7c',
			fontSize: 20,
			fontWeight: 'bold',
		},
		colorPrimary: {
			backgroundColor: '#1b1a7c',
		},
		progressRoot: {
			borderRadius: '20px',
			height: '10px',
			backgroundColor: '#ebebeb',
		},
		bar1Determinate: {
			borderRadius: '20px',
		},
		progressSteps: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			flexFlow: 'row',
			marginTop: '10px',
		},
		progressStepsItem: {
			// display: 'flex',
			// alignItems: 'flex-start',
			// flexFlow: 'column',
			// marginRight: '30px',
			width: '80px',
			// [theme.breakpoints.down('xs')]: {
			alignItems: 'center',
			justifyContent: 'center',
			alignSelf: 'flex-start',
			textAlign: 'center',
			marginRight: 0,
			// },
		},
		stepItem: {
			cursor: 'auto',
			width: '25px',
			height: '25px',
			minHeight: '20px',
			backgroundColor: '#e1e1f4',
			boxShadow: 'none',
		},
		fabDisabled: {
			background: 'transparent',
			border: '1px solid #e1e1f4',
			backgroundColor: '#fff !important',
		},
		stepItemTitle: {
			display: 'flex',
			fontSize: '12px',
			flexFlow: 'column',
			color: '#1b1a7c',
			[theme.breakpoints.down('sm')]: {
				fontSize: '10px',
			},
		},
		stepTitleDisabled: {
			display: 'flex',
			fontSize: '12px',
			flexFlow: 'column',
			color: '#989898',
		},
	})
