import { useStoreValue } from '../store'
import { EnumUserRoles } from 'utils'

export const useCheckUserPermission = (
	role: EnumUserRoles,
	entityCompany?: any,
): boolean => {
	const loggedUser = useStoreValue()[0].user
	const userCompanyRoles = loggedUser?.companyRoles
	let result = null
	if (!loggedUser) return false
	if (loggedUser._ig) {
		// check if the logged user is god
		result = true
	} else if (role && entityCompany) {
		let checkIfLoggedUserOwnsCompany =
			loggedUser.profile.ownedCompanies &&
			loggedUser.profile.ownedCompanies.length > 0
				? loggedUser.profile.ownedCompanies.find(
						item => item.id === entityCompany.id,
				  )
				: undefined
		if (checkIfLoggedUserOwnsCompany) {
			// check if the logged user owned the company that is related with the entity (article, job, company, etc)
			result = checkIfLoggedUserOwnsCompany
		} else {
			// check if the user has the access
			result = userCompanyRoles.find(
				(item: any) =>
					item.company.id === entityCompany.id &&
					item.roles.includes(role),
			)
		}
	}

	return result ? true : false
}
