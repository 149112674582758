import React, { memo, useState, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import { useMediaQuery, Fab } from '@material-ui/core'
import ReactPlayer from 'react-player'
import textVersion from 'textversionjs'
import DOMPurify from 'dompurify'

import { AddTeamModal } from '.'
import styles from '../../styles'
import localStyles from './styles'
import { TeamsQuery } from 'store'
import { buildTeamCover, combineStyles, PlaceholderContent } from 'utils'
import CompanyHeaderImage from 'assets/images/company-header-image.jpg'
import { EEntityAboutSectionType } from 'models'
import { EntityAboutSection, Loading } from 'components'
import classNames from 'classnames'
import commonStyles from '../../../../styles/common'

const CompanyTeams: React.FunctionComponent<any> = props => {
	const { companyId, companyName, canEdit, companyTeamsDescription } = props
	const history = useHistory()
	const { companySlug }: { companySlug?: string } = useParams()
	const combinedStyles: any = combineStyles(commonStyles, styles, localStyles)
	const useStyles = makeStyles(combinedStyles)
	const classes = useStyles()
	const { i18n, t } = useTranslation()
	const [openAddTeamModal, setOpenAddTeamModal] = useState(false)
	const isMobile = useMediaQuery('@media (max-width:650px)')

	const toggleAddTeamModal = useCallback(() => {
		setOpenAddTeamModal(state => !state)
	}, [])

	const [
		teamResourceContainerHeight,
		setTeamResourceContainerHeight,
	] = useState(208)

	const { data: teamsData, loading: teamsLoading }: any = useQuery(
		TeamsQuery,
		{
			fetchPolicy: 'network-only',
			variables: {
				companyId,
			},
		},
	)

	const handleGoToTeamProfile = useCallback(
		(teamSlug: string) => () => {
			history.push(
				`/${i18n.language}/employers/${companySlug}/teams/${teamSlug}`,
			)
		},
		[history, i18n.language, companySlug],
	)
	useEffect(() => {
		const handleWindowResize = () =>
			setTeamResourceContainerHeight((260 * window.innerWidth) / 650)
		window.addEventListener('resize', handleWindowResize)

		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	})
	return (
		<Grid
			className={classes.companyMainContent}
			container={true}
			direction="column"
		>
			{canEdit && (
				<Chip
					onClick={toggleAddTeamModal}
					style={{
						margin: 2,
						background: '#fff',
					}}
					label={'Add team'}
					variant="outlined"
					color={'secondary'}
					clickable={true}
					classes={{
						label: classes.editIconLabel,
						root: classes.addTeamElement,
					}}
				/>
			)}

			<AddTeamModal
				companyId={companyId || null}
				openAddTeamModal={openAddTeamModal}
				toggleAddTeamModal={toggleAddTeamModal}
			/>

			<EntityAboutSection
				type={EEntityAboutSectionType.companyTeams}
				title={t('Meet our-1')}
				emphasizedTitle={t('Teams-1')}
				oneRowTitle={false}
				content={companyTeamsDescription}
				canEdit={canEdit}
				companyId={companyId || null}
			/>
			{teamsLoading ? (
				<Loading size={48} style={{ margin: '100px auto' }} />
			) : (
				<Grid container={true} direction="column">
					{teamsData && teamsData.teams.length > 0 ? (
						teamsData.teams.map((item: any, index: number) => {
							const plainTextDescription = textVersion(
								DOMPurify.sanitize(item.description),
							)
							return (
								<Grid
									container={true}
									className={classNames(
										classes.teamItem,
										classes.defaultShadow,
									)}
									key={`team ${index}`}
								>
									<Grid
										item={true}
										xs={isMobile ? 12 : 8}
										md={8}
										className={
											classes.teamResourceContainer
										}
										style={{
											minHeight:
												isMobile && item.featuredVideo
													? `${teamResourceContainerHeight}px`
													: 'undefined',
										}}
									>
										{item.hasCover && (
											<img
												src={buildTeamCover(item)}
												alt={item.name}
												onClick={handleGoToTeamProfile(
													item.slug,
												)}
											/>
										)}
										{!item.hasCover && item.featuredVideo && (
											<div className={classes.video}>
												<ReactPlayer
													url={item.featuredVideo}
													className={classes.player}
													controls={true}
													width="100%"
													height="100%"
													style={{
														flex: 1,
														overflow: 'hidden',
													}}
												/>
											</div>
										)}
										{!item.hasCover &&
											!item.featuredVideo && (
												<img
													alt={item.name}
													src={CompanyHeaderImage}
													onClick={handleGoToTeamProfile(
														item.slug,
													)}
												/>
											)}
									</Grid>
									<Grid
										item={true}
										xs={isMobile ? 12 : 4}
										md={4}
										className={classes.teamItemInformation}
										onClick={handleGoToTeamProfile(
											item.slug,
										)}
									>
										<Grid
											container={true}
											direction="column"
										>
											<h3
												className={
													classes.teamItemTitle
												}
												style={{ marginTop: 0 }}
											>
												{item.name}
											</h3>

											{plainTextDescription.length >
											200 ? (
												<div
													className={
														classes.teamItemDescription
													}
												>
													{`${plainTextDescription.substr(
														0,
														200,
													)}`}
													&nbsp;
													<span
														className={
															classes.seeMore
														}
													>
														{`...`}
													</span>
												</div>
											) : (
												plainTextDescription
											)}
										</Grid>
										<Fab
											variant="extended"
											size="small"
											color="primary"
											aria-label="small"
											style={{
												position: 'absolute',
												bottom: '10px',
												right: '10px',
												textTransform: 'none',
												fontSize: '12px',
												height: '25px',
											}}
											onClick={handleGoToTeamProfile(
												item.slug,
											)}
										>
											{t('Learn more') || ''}
										</Fab>
									</Grid>
								</Grid>
							)
						})
					) : (
						<PlaceholderContent
							data={companyName}
							entity={'teams'}
						/>
					)}
				</Grid>
			)}
		</Grid>
	)
}

export default memo(CompanyTeams)
