import React, { memo, useState, useCallback, useMemo } from 'react'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/styles'
import { capitalize } from 'utils'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Loading } from 'components'
import styles from './styles'
import { AppreciateTagMutation, StoreValue } from 'store'
import { TEndorsementProps, EAppStateActionTypes } from 'models'
import { Zoom, Tooltip } from '@material-ui/core'

const useStyles = makeStyles(styles)

const EndorsementView: React.FunctionComponent<TEndorsementProps> = ({
	id: tagId,
	articleId,
	articleAuthorName,
	title,
	votes = 0,
	canVote,
	setTotal,
	storeUser,
	storeGuidesAppreciate,
	storeDispatch,
}) => {
	const history = useHistory()
	const [hasVoted, setHasVoted] = useState(!canVote)
	const classes = useStyles(styles)
	const [isTooltipOpen, setTooltipOpen] = useState(false)
	const handleTooltipClose = useCallback(() => setTooltipOpen(false), [])
	const handleTooltipOpen = useCallback(() => setTooltipOpen(true), [])
	const [loading, setLoading] = useState(false)
	const { t, i18n } = useTranslation()
	const [appreciateHandle] = useMutation(AppreciateTagMutation, {
		variables: {
			language: i18n.language,
			tagId,
			articleId,
		},
	})

	const toggle = useCallback(async () => {
		setLoading(true)
		try {
			const {
				data: {
					appreciate: { status },
				},
			}: any = await appreciateHandle()
			if (status) {
				if (hasVoted) {
					setTotal(-1)
					setHasVoted(false)
				} else {
					setTotal(1)
					setHasVoted(true)
				}
				if (!storeGuidesAppreciate) {
					storeDispatch({
						type: EAppStateActionTypes.updateGuide,
						guide: 'appreciate',
						value: true,
					})
				}
			} else {
			}
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
		}
	}, [
		appreciateHandle,
		storeGuidesAppreciate,
		storeDispatch,
		hasVoted,
		setTotal,
	])
	const label = (
		<>
			{loading ? (
				<Loading size={16} style={{ marginRight: 5 }} />
			) : typeof votes !== 'undefined' ? (
				votes + (canVote === hasVoted ? (hasVoted ? 1 : -1) : 0)
			) : (
				0
			)}
			{' ' + capitalize(title)}
		</>
	)
	const endorseTitle: string = useMemo(
		() =>
			t('Endorse FirstName for Label', {
				firstName: articleAuthorName
					? capitalize(articleAuthorName)
					: '',
				label: title ? capitalize(title) : '',
			}),
		[t, articleAuthorName, title],
	)

	const goToLogin = () => {
		history.push(`/${i18n.language}/auth/login`)
	}

	return (
		<>
			<Tooltip
				TransitionComponent={Zoom}
				title={endorseTitle}
				placement="top"
				classes={{
					tooltip: classes.tooltip,
				}}
				disableFocusListener={hasVoted}
				disableHoverListener={hasVoted}
				disableTouchListener={hasVoted}
				onClose={handleTooltipClose}
				onOpen={handleTooltipOpen}
				open={isTooltipOpen}
			>
				<Chip
					variant={'outlined'}
					label={label}
					color={'primary'}
					className={
						storeUser
							? hasVoted
								? classes.chipInactive
								: classes.chip
							: classes.chip
					}
					onClick={storeUser ? toggle : goToLogin}
					//{...longPressEvent}
				/>
			</Tooltip>

			{/* <Dialog
				open={false}
				aria-labelledby="responsive-dialog-title"
				fullWidth={true}
				maxWidth="xs"
				onClose={() => {
					handleClose && handleClose(false)
				}}
			>
				<Card className={classes.root}>
					<CardContent>
						<Typography
							className={classes.title}
							color="textSecondary"
							gutterBottom
						>
							Word of the Day
						</Typography>
					</CardContent>
				</Card>
			</Dialog>

			<Dialog
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				aria-labelledby="responsive-dialog-title"
				//className={classes.dialog}
				fullWidth={true}
				maxWidth="xs"
			>
				{' '}
				<DialogTitle
					id="responsive-dialog-title"
					className={classes.dialogTitle}
				>
					<div>{modalTitle}</div>
				</DialogTitle>
				{fullNameArr?.length > 0 ? (
					<IconButton
						aria-label="close"
						className={classes.closeModalButton}
						onClick={() => setModalVisible(false)}
					>
						<CloseIcon />
					</IconButton>
				) : null}
				<List key={storeUser?.id} className={classes.cardItemWrap}>
					<div className={classes.cardItem}>
						<div className={classes.avatarContent}>
							{fullNameArr?.length > 0
								? fullNameArr?.map((user: any) => (
										<ListItem
											button
											onClick={() =>
												handleListItemClick(user.uid)
											}
											key={user?.userId}
										>
											<ListItemAvatar key={user?.userId}>
												{user?.avatarPath ? (
													<div>
														<Avatar
															key={user?.userId}
															classes={{
																root:
																	classes.profilePicture,
															}}
															src={buildUserAvatar(
																user?.userId,
																user?.avatarPath,
															)}
														/>
													</div>
												) : (
													<Avatar
														classes={{
															root:
																classes.profilePicture,
														}}
													/>
												)}
											</ListItemAvatar>
											<div className={classes.details}>
												<ListItemText
													className={classes.name}
													primary={user?.fullName}
												/>
												<span className={classes.role}>
													{user?.voterCompany
														? `${user?.voterCompany}`
														: ' '}
													{'  '}
													{user?.voterPosition
														? `${user?.voterPosition}`
														: ` `}
												</span>
											</div>
										</ListItem>
								  ))
								: null}
						</div>
					</div>
				</List>
			</Dialog>*/}
		</>
	)
}

const Endorsement = (props: TEndorsementProps) => (
	<StoreValue
		keys={[
			'user|storeUser',
			'guides.appreciate|storeGuidesAppreciate',
			'storeDispatch',
		]}
	>
		<EndorsementView {...props} />
	</StoreValue>
)

export default memo(Endorsement)
