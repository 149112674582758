import searchReducer from './search'
import userReducer from './user'
import guidesReducer from './guides'
import companyReducer from './company'
import jobReducer from './job'
import credentialsStateReducer from './credentialsState'
// import firebaseReducer from './firebase'
import firebaseUserReducer from './firebaseUser'
import { IAppState } from 'models'

export const setCookie: Function = (value: any) => {
	// _cv30lc => cv30 local cookies
	document.cookie = '_cv30lc=' + JSON.stringify(value) + '; path=/; secure;'
}

export default (
	{
		credentialsState,
		guides,
		user,
		search,
		firebase,
		firebaseUser,
		company,
		job,
	}: IAppState,
	action: React.ReducerAction<any>,
) => {
	const state = {
		credentialsState: credentialsStateReducer(credentialsState, action),
		guides: guidesReducer(guides, action),
		user: userReducer(user, action),
		search: searchReducer(search, action),
		firebase, //: firebaseReducer(firebase, action),
		firebaseUser: firebaseUserReducer(firebaseUser, action),
		company: companyReducer(company, action),
		job: jobReducer(job, action),
	}
	setCookie({
		guides: state.guides,
	})

	return state
}
