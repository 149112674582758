import React, { memo, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import CommentIcon from '@material-ui/icons/ChatBubbleOutline'
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown'
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp'
import CloseIcon from '@material-ui/icons/CloseRounded'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CustomScroll from 'react-custom-scroll'
import 'react-custom-scroll/dist/customScroll.css'

import {
	useMediaQuery,
	IconButton,
	Dialog,
	Card,
	CardContent,
	SwipeableDrawer,
	Button,
} from '@material-ui/core'

import { useCommentsCount } from '../firebase'
import Endorsement from './endorsement'
import Comments from 'components/comments'
import styles from './styles'
import { ITag, IEndorsementsProps, EEndorsementsSectionTypes } from 'models'
import { EnumUserRoles, useCheckUserPermission } from 'utils'
import { StoreValue } from 'store'
import { Loading } from 'components'
import { ArticleTagVoters } from './articleTagVoters'

const useStyles = makeStyles(styles)

const EndorsementsContainerView: React.FunctionComponent<IEndorsementsProps> = memo(
	({
		data = [],
		articleId,
		articleAuthorName,
		articleAuthorId,
		viewMode,
		postingCompany,
		commentsPerPage,
		handleClose,
		articleVideo,
		containerClassName,
		storeUser,
		firebase,
		firebaseUser,
	}) => {
		const { t } = useTranslation()
		const commentsCount = useCommentsCount(
			articleId,
			firebase,
			firebaseUser,
		)
		const classes = useStyles()
		const theme = useTheme()
		const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
		const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
		const [modalOpen, setModalOpen] = useState(false)
		const [section, setSection] = useState(
			EEndorsementsSectionTypes.endorsement,
		)
		const [total, setTotal] = useState(
			(data &&
				data.reduce((acc: number, cur: ITag) => acc + cur.votes, 0)) ||
				0,
		)
		const setEndorsementsTotal = useCallback(
			step => {
				setTotal(total + step)
			},
			[total],
		)
		const toggleSection = useCallback(() => {
			setSection(
				section === EEndorsementsSectionTypes.endorsement
					? EEndorsementsSectionTypes.comments
					: EEndorsementsSectionTypes.endorsement,
			)
		}, [section])

		const goBack = useCallback(
			event => {
				handleClose && handleClose(event)
			},
			[handleClose],
		)

		let customStyle: any = viewMode
			? { position: 'unset', left: 14, right: 0 }
			: articleVideo
			? { top: -34 }
			: undefined
		let endorsementsViewModeStyle: any = viewMode
			? { position: 'absolute', top: -0 }
			: undefined

		// checking if user is publisher or higher
		const hasUserAccess: boolean = useCheckUserPermission(
			EnumUserRoles.PUBLISHER,
			postingCompany,
		)

		let checkAccess =
			hasUserAccess || articleAuthorId === storeUser?.id ? true : false

		let idTagsArr: any = []
		data.map((item: ITag) => {
			idTagsArr.push(item.id)

			return undefined
		})

		const queryAndOpenModal = () => {
			setModalOpen(true)
		}

		return !firebaseUser ? (
			<Loading size={30} style={{ margin: '10px auto' }} />
		) : (
			<div
				className={classes.endorsements + ' ' + containerClassName}
				style={{ padding: !isUpMd ? '8px' : undefined }}
			>
				{data && data.length > 0 && (
					<>
						<Button
							className={classes.endorsementsHeadline}
							style={endorsementsViewModeStyle}
							onClick={queryAndOpenModal}
							disabled={!total}
						>
							{t(`Endorsed`)} {total} {t(`times`)}
						</Button>
						<div
							className={classes.container}
							style={{ paddingTop: viewMode ? 8 : undefined }}
						>
							{data.map((item: ITag, index: number) => (
								<div key={`${articleId}_${index}`}>
									<Endorsement
										{...item}
										articleId={articleId}
										articleAuthorName={articleAuthorName}
										setTotal={setEndorsementsTotal}
										checkAccess={checkAccess}
									/>
								</div>
							))}
						</div>
					</>
				)}
				{!isSmDown && (
					<Dialog
						open={modalOpen}
						onClose={() => setModalOpen(false)}
						aria-labelledby="responsive-dialog-title"
						//scroll="body"
					>
						<CustomScroll allowOuterScroll={true}>
							<Card className={classes.root}>
								<CardContent>
									<IconButton
										aria-label="close"
										className={classes.closeModalButton}
										onClick={() => setModalOpen(false)}
									>
										<CloseIcon />
									</IconButton>
									<ArticleTagVoters articleId={articleId} />
								</CardContent>
							</Card>
						</CustomScroll>
					</Dialog>
				)}

				{isSmDown && (
					<SwipeableDrawer
						anchor="right"
						open={modalOpen}
						onOpen={() => setModalOpen(true)}
						onClose={() => setModalOpen(false)}
					>
						<CustomScroll allowOuterScroll={true}>
							<>
								<Card className={classes.root}>
									<CardContent>
										<IconButton
											aria-label="close"
											className={classes.closeModalButton}
											onClick={() => setModalOpen(false)}
										>
											<CloseIcon />
										</IconButton>
										<ArticleTagVoters
											articleId={articleId}
										/>
									</CardContent>
								</Card>
							</>
						</CustomScroll>
					</SwipeableDrawer>
				)}
				<div
					className={classes.commentsBadge}
					style={customStyle}
					onClick={toggleSection}
				>
					<div className={classes.commentsBadgeCount}>
						{commentsCount} {t('Comments')}
					</div>
					{!viewMode && (
						<>
							{section ===
								EEndorsementsSectionTypes.endorsement && (
								<DownArrowIcon
									classes={{ root: classes.iconSize }}
									className={classes.commentsBadgeArrow}
								/>
							)}
							{section === EEndorsementsSectionTypes.comments && (
								<UpArrowIcon
									classes={{ root: classes.iconSize }}
									className={classes.commentsBadgeArrow}
								/>
							)}
						</>
					)}
					<CommentIcon
						classes={{ root: classes.iconSize }}
						className={classes.commentsBadgeIcon}
					/>
				</div>
				{viewMode && isUpMd && (
					<>
						<IconButton
							className={classes.closeButton}
							onClick={goBack}
						>
							<CloseIcon />
						</IconButton>
					</>
				)}

				{(section === EEndorsementsSectionTypes.comments ||
					viewMode) && (
					<Comments
						articleId={articleId}
						commentsPerPage={commentsPerPage}
						viewMode={viewMode}
					/>
				)}
			</div>
		)
	},
)

const EndorsementContainer = (props: IEndorsementsProps) => (
	<StoreValue keys={['firebase', 'firebaseUser', 'user|storeUser']}>
		<EndorsementsContainerView {...props} />
	</StoreValue>
)

export default memo(EndorsementContainer)
