import { fade } from '@material-ui/core/styles/colorManipulator'
import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			marginBottom: theme.spacing(1),
			flexGrow: 0,
			position: 'fixed',
			zIndex: 2,
		},
		logoParent: {
			[theme.breakpoints.up('md')]: {
				marginRight: theme.spacing(2),
				[theme.breakpoints.up('xl')]: {
					marginRight: theme.spacing(4),
					marginLeft: theme.spacing(4),
				},
			},
			marginLeft: theme.spacing(1),
			position: 'relative',
		},
		beta: {
			pointerEvents: 'none',
			position: 'absolute',
			bottom: 0,
			right: -16,
			fontWeight: 700,
			color: '#555',
			textShadow:
				'-1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF',
			[theme.breakpoints.down('xs')]: {
				bottom: -4,
				right: 0,
			},
		},
		logo: {
			objectFit: 'contain',
			cursor: 'pointer',
		},
		menuButton: {
			color: fade(theme.palette.common.black, 0.4),
			[theme.breakpoints.up('md')]: {
				marginRight: theme.spacing(1),
			},
		},
		toolbar: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-around',
			flexGrow: 1,
		},
		menuItem: {
			alignSelf: 'stretch',
			display: 'flex',
			alignItems: 'center',
			fontWeight: 600,
			fontSize: 16,
			borderBottomWidth: 3,
			borderBottomStyle: 'solid',
			borderBottomColor: 'transparent',
			[theme.breakpoints.up('md')]: {
				marginLeft: theme.spacing(2),
				marginRight: theme.spacing(2),
			},
			cursor: 'pointer',
		},
		menuActive: {
			borderBottomWidth: 3,
			borderBottomStyle: 'solid',
			borderBottomColor: theme.palette.primary.main,
			color: theme.palette.primary.main,
		},
		colorDefault: {
			backgroundColor: theme.palette.common.white,
			boxShadow: '0px 3px 10px 0px rgba(0,0,0,0.2)',
		},
		logoContainer: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			alignSelf: 'stretch',
			justifyContent: 'space-evenly',
			flex: '0 0 auto',
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'flex-start',
			},
			[theme.breakpoints.up('sm')]: {
				flex: '0.5 0 0',
				[theme.breakpoints.up('md')]: {
					justifyContent: 'flex-start',
					flex: '1 0 0',
				},
			},
		},
		unsetFlex: {
			flex: 'unset!important',
		},
		rightMenu: {
			marginRight: theme.spacing(1),
			flex: '0 0 auto',
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			[theme.breakpoints.up('sm')]: {
				flex: '0.5 0 0',
				[theme.breakpoints.up('md')]: {
					flex: '1 0 0',
				},
			},
		},
		close: {
			cursor: 'pointer',
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(1),
			color: theme.palette.grey[500],
			fontWeight: 500,
		},
		signInButton: {
			marginRight: theme.spacing(2),
			color: theme.palette.grey[500],
			borderColor: theme.palette.grey[500],
		},
		signUpButton: {
			color: theme.palette.primary.main,
			borderColor: theme.palette.primary.main,
		},
		authLogo: {
			marginLeft: theme.spacing(2),
			height: 24,
			'& > path': {
				fill: theme.palette.primary.main,
			},
		},
		arrow: {
			position: 'absolute',
			fontSize: 7,
			width: '3em',
			height: '3em',
			'&::before': {
				content: '""',
				margin: 'auto',
				display: 'block',
				width: 0,
				height: 0,
				borderStyle: 'solid',
			},
		},

		avatarSidebarContainer: {
			background: '#f5f5f5',
			color: 'rgb(88, 87, 87)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			paddingBottom: theme.spacing(2),
			paddingTop: theme.spacing(2),
			'& div:first-child': {
				width: theme.spacing(10),
				height: theme.spacing(10),
			},
			'& div:last-child': {
				marginTop: theme.spacing(1),
				color: theme.palette.grey[800],
				fontWeight: 500,
			},
		},
		avatarContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'row',
			marginRight: theme.spacing(2),
			'& > svg': {
				fill: theme.palette.grey[500],
			},
		},
		avatar: {
			width: theme.spacing(6),
			height: theme.spacing(6),
		},
		popper: {
			zIndex: 1,
			marginTop: 15,
			minWidth: 150,
			'& > div': {
				borderRadius: 8,
			},
			'& $arrow': {
				top: 0,
				left: 'auto',
				right: theme.spacing(1.5),
				marginTop: '-0.9em',
				width: '3em',
				height: '1em',
				'&::before': {
					borderWidth: '0 1em 1em 1em',
					borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
				},
			},
		},
		sidebarDrawer: {
			width: 250,
		},
		employersButton: {
			alignSelf: 'stretch',
		},
	})
