import React, { memo } from 'react'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useLocation, matchPath, useHistory } from 'react-router-dom'

import { IBreadcrumbsView, ECompanyActionTypes } from 'models'
import { StoreValue } from 'store'
import styles from './styles'

const useStyles = makeStyles(styles)

const BreadcrumbsView: React.FunctionComponent<IBreadcrumbsView> = ({
	companyName,
	companySlug,
	section,
	breadcrumbPath,
	storeDispatch,
}) => {
	const location = useLocation()
	const classes = useStyles()
	const history = useHistory()
	const { i18n } = useTranslation()

	const handleClick = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		breadcrumbPath: string | null,
		goToCompanyProfile: boolean = false,
	) => {
		if (
			matchPath(location.pathname, {
				path:
					'/:language(en|ro)/employers/:companySlug/company-settings',
				exact: false,
			})
		) {
			event.preventDefault()
			storeDispatch({
				type: ECompanyActionTypes.setCompanyBreadcrumbPath,
				breadcrumbPath,
			})
			if (goToCompanyProfile) {
				history.push({
					pathname: `/${i18n.language}/employers/${companySlug}`,
				})
			}
		} else if (
			matchPath(location.pathname, {
				path:
					'/:language(en|ro)/employers/:companySlug/teams/:teamSlug',
				exact: false,
			})
		) {
			event.preventDefault()
			storeDispatch({
				type: ECompanyActionTypes.setCompanyBreadcrumbPath,
				path: breadcrumbPath,
			})
			if (goToCompanyProfile) {
				history.push({
					pathname: `/${i18n.language}/employers/${companySlug}`,
				})
			}
		}
	}
	return (
		<Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
			<Link
				color="inherit"
				onClick={(event: any) => handleClick(event, null, true)}
			>
				{companyName}
			</Link>
			<Link
				color="inherit"
				onClick={(event: any) => handleClick(event, null)}
			>
				{section}
			</Link>
			{breadcrumbPath && (
				<Typography color="textPrimary">{breadcrumbPath}</Typography>
			)}
		</Breadcrumbs>
	)
}

const BreadcrumbsComponent = memo((props: any) => (
	<StoreValue
		keys={['company.breadcrumbPath.path|breadcrumbPath', 'storeDispatch']}
	>
		<BreadcrumbsView {...props} />
	</StoreValue>
))
export default memo(BreadcrumbsComponent)
