import React, { memo, useState, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import EditIcon from '@material-ui/icons/EditOutlined'
import { useQuery } from '@apollo/react-hooks'
import Tooltip from '@material-ui/core/Tooltip'

import styles from '../../styles'
import WebsiteLinkIcon from 'assets/images/website-link.svg'
import PhoneIcon from 'assets/images/phone.svg'
import EmailIcon from 'assets/images/email.svg'
import LocationIcon from 'assets/images/location.svg'
import { ICompanyInformations } from 'models'
import { capitalize } from 'utils'
import { RoLocations } from 'store'
import InformationsDialog from './dialog'

const useStyles = makeStyles(styles)

const CompanyInformations: React.FunctionComponent<ICompanyInformations> = ({
	companyId,
	companySlug,
	name,
	dateFounded,
	industry,
	website,
	phones,
	emails,
	canEdit,
	companyHq = null,
	companyOffices,
}) => {
	const [openInformationsModal, setOpenInformationsModal] = useState(false)

	let displayedAddress = null
	if (companyHq) {
		displayedAddress = `${
			companyHq.address ? companyHq.address + ', ' : ''
		}  
		${companyHq.zipCode ? companyHq.zipCode + ', ' : ''}
		${companyHq.location.region2}, ${companyHq.location.name}
	`
	}
	let regions = null
	const { data: regionsData }: any = useQuery(RoLocations, {
		skip: !!regions,
		variables: {
			data: {
				type_in: ['Judeţe', 'National capital'],
			},
		},
	})

	regions = useMemo(() => regionsData || null, [regionsData])

	const classes = useStyles()
	const { t } = useTranslation()
	const isMobile = useMediaQuery('@media (max-width:400px)')

	const handleToggleInformationsModal = useCallback(() => {
		setOpenInformationsModal(!openInformationsModal)
	}, [openInformationsModal])

	return (
		<Grid container={true}>
			<Grid
				container={true}
				direction={isMobile ? 'column' : 'row'}
				className={classes.mainInfoWrap}
			>
				<Grid
					item={true}
					className={classes.companyDetailsInformationItemContainer}
				>
					<span
						className={classes.companyMainInformationItem}
						style={
							name.length > 52
								? {
										height: 'unset',
										margin: '30px 0px',
										lineHeight: '18px',
								  }
								: undefined
						}
					>
						{name}
					</span>
					{industry && industry.key && (
						<span
							className={classes.companyMainInformationItem}
							style={
								name.length > 52
									? {
											height: 'unset',
											margin: '30px 0px',
											lineHeight: '18px',
									  }
									: undefined
							}
						>
							{capitalize(industry && industry.key)}
						</span>
					)}
					{dateFounded && (
						<span
							className={classes.companyMainInformationItem}
							style={
								name.length > 52
									? {
											height: 'unset',
											margin: '30px 0px',
											lineHeight: '18px',
									  }
									: undefined
							}
						>
							{t('Founded in')} {dateFounded}
						</span>
					)}
				</Grid>
			</Grid>
			<Grid
				container={true}
				direction={isMobile ? 'column' : 'row'}
				className={classes.companyDetailsInformation}
			>
				{website && (
					<Grid
						item={true}
						className={
							classes.companyDetailsInformationItemContainer
						}
					>
						<a
							href={'https://' + (website ?? '').replace(/http(s?):\/\//, '')}
							target="_blank"
							rel="noopener noreferrer"
							className={classes.companyDetailsInformationLink}
						>
							<img
								src={WebsiteLinkIcon}
								className={classes.companyDetailsIcon}
								alt=""
							/>
							<span
								className={
									classes.companyDetailsInformationItem
								}
							>
								{website}
							</span>
						</a>
					</Grid>
				)}
				{phones && (
					<Grid
						item={true}
						className={
							classes.companyDetailsInformationItemContainer
						}
					>
						<a
							href={`tel:${phones}`}
							className={classes.companyDetailsInformationLink}
						>
							<img
								src={PhoneIcon}
								className={classes.companyDetailsIcon}
								alt=""
							/>
							<span
								className={
									classes.companyDetailsInformationItem
								}
							>
								{phones}
							</span>
						</a>
					</Grid>
				)}
				{emails && (
					<Grid
						item={true}
						className={
							classes.companyDetailsInformationItemContainer
						}
					>
						<a
							href={`mailto:${emails}`}
							className={classes.companyDetailsInformationLink}
						>
							<img
								src={EmailIcon}
								className={classes.companyDetailsIcon}
								alt=""
							/>
							<span
								className={
									classes.companyDetailsInformationItem
								}
							>
								{emails}
							</span>
						</a>
					</Grid>
				)}
			</Grid>

			{displayedAddress && (
				<Grid item className={classes.companyDetailsAddressWrap}>
					<img
						src={LocationIcon}
						className={classes.companyDetailsIcon}
						alt=""
					/>
					<span className={classes.companyDetailsInformationItem}>
						{t('Headquarters in')} {displayedAddress}
					</span>
					{companyOffices.length > 1 && (
						<Tooltip
							disableFocusListener
							disableTouchListener
							classes={{ tooltip: classes.tooltipCustomWidth }}
							title={
								<div>
									{companyOffices
										.filter(
											(item: any, index: number) =>
												!item.hq,
										)
										.map((item: any, index: number) => {
											let office = `${
												item.address
													? item.address + ' | '
													: ''
											} ${
												item.zipCode
													? item.zipCode + ' | '
													: ''
											}
											${item.location.name} | ${item.location.region2}`
											return (
												<div
													key={`extra-office-${index}`}
												>
													{index + 1 + '. '}
													{office}
												</div>
											)
										})}
								</div>
							}
						>
							<span className={classes.additionalLocations}>
								+{companyOffices.length - 1}
							</span>
						</Tooltip>
					)}
				</Grid>
			)}

			{canEdit && (
				<Chip
					onClick={handleToggleInformationsModal}
					style={{ margin: 2, right: '50px', background: '#fff' }}
					icon={<EditIcon />}
					variant="outlined"
					color={'secondary'}
					clickable={true}
					classes={{
						label: classes.editIconLabel,
						root: classes.editRootElement,
					}}
				/>
			)}
			<InformationsDialog
				openModal={openInformationsModal}
				toggleModal={handleToggleInformationsModal}
				companyId={companyId}
				companySlug={companySlug}
				name={name}
				dateFounded={dateFounded}
				industry={industry}
				website={website}
				phones={phones}
				emails={emails}
				companyHq={companyHq}
				companyOffices={companyOffices}
			/>
		</Grid>
	)
}

export default memo(CompanyInformations)
