import React, { memo, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import styles from '../styles'
// import company from '../mockdata'
import { ProfileArticles } from 'components'
import Fab from '@material-ui/core/Fab'
import { useHistory, useParams } from 'react-router'

const useStyles = makeStyles(styles)

const CompanyInsideTeam: React.FunctionComponent<any> = props => {
	const { articles, refetch, companyId, canEdit, goToCreateStoryProp } = props
	const classes = useStyles()
	const { t, i18n } = useTranslation()
	const history = useHistory()
	const { companySlug }: { companySlug?: string } = useParams()

	const goToCreateStory = useCallback(() => {
		history.push({
			pathname: `/${i18n.language}/employers/${companySlug}/create-story`,
			state: {
				reset: true,
				companyId: companyId,
			},
		})
	}, [history, i18n.language, companySlug, companyId])

	useEffect(() => {
		if (goToCreateStoryProp) goToCreateStory()
	}, [goToCreateStoryProp, goToCreateStory])

	return (
		<Grid
			className={classes.companySection}
			container={true}
			direction="column"
		>
			<Grid item={true}>
				<h2 className={classes.sectionTitle}>
					{t('Life')}{' '}
					<span className={classes.emphasizedText}>
						{t('inside the team')}
					</span>
				</h2>
			</Grid>
			{canEdit && (
				<Grid item={true} className={classes.teamLifeSection}>
					<div className={classes.addStoryRoot}>
						<h3 className={classes.storyPlaceholder}>
							{t(
								'Add stories that represents the life at your office',
							)}
						</h3>
						<Fab
							onClick={goToCreateStory}
							variant="extended"
							size="medium"
							color="primary"
							classes={{
								sizeMedium: classes.addStoryBtn,
							}}
						>
							<span>{t('Add story')}</span>
						</Fab>
					</div>
				</Grid>
			)}
			<Grid
				item={true}
				className={`${classes.sectionContent} ${classes.teamLifeSection}`}
			>
				<ProfileArticles
					companySlug={companySlug}
					companyId={companyId}
					articles={articles}
					refetch={refetch}
					canEdit={canEdit}
				/>
			</Grid>
		</Grid>
	)
}

export default memo(CompanyInsideTeam)
