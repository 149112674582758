import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		companyCardContent: {
			justifyContent: 'space-between',
			width: '100%',
			height: '100%',
			position: 'relative',
			margin: '35px 0px 50px 0',
			'&:hover > #companyLinkWrap': {
				[theme.breakpoints.up('sm')]: {
					visibility: 'visible',
					opacity: 1,
				},
			},
			'& > #companyLinkWrap': {
				[theme.breakpoints.down('sm')]: {
					visibility: 'visible',
					opacity: 1,
				},
			},
			[theme.breakpoints.down('sm')]: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
			},
		},
		companyImageContainer: {
			height: '260px',
			overflow: 'hidden',
			borderRadius: '5px',
			'-webkit-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.2)',
			'-moz-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.2)',
			boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.2)',
			// '-webkit-box-shadow': '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			// '-moz-box-shadow': '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			// boxShadow: '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			pointerEvents: 'none',
		},
		cardImg: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
		companyBadge: {
			width: 'auto',
			maxWidth: '600px',
			position: 'absolute',
			top: '-31px',
			left: '20px',
			'-webkit-box-shadow': '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			'-moz-box-shadow': '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			boxShadow: '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			[theme.breakpoints.down('sm')]: {
				maxWidth: '290px',
			},
			zIndex: 1,
		},
		companyBadgeAvatar: {
			border: '1px solid #ebebeb',
		},
		detailsWrap: {
			cursor: 'pointer',
			position: 'relative',
			display: 'flex',
			flex: 1,
			height: '60px',
			alignItems: 'center',
		},
		detailsTitle: {
			color: '#1B1A7C',
			display: 'flex',
			flexFlow: 'column',
			fontWeight: 'bold',
			paddingLeft: '20px',
		},

		arrowBlue: {
			color: '#1B1A7C',
			fontSize: 20,
		},
		arrowRightIcon: {
			marginLeft: 'auto',
		},
		arrowWhite: {
			color: '#fff',
		},
		mainTitle: {
			fontSize: 18,
			fontWeight: 700,
			color: '#fff',
			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
		},
		subTitle: {
			fontSize: 14,
			fontWeight: 300,
			color: '#fff',
		},
		discoverCompany: {
			background: 'rgba(27, 26, 124, 0.8)',
			borderRadius: '0 0 0 5px',
			'&:hover': {
				background: 'rgba(27, 26, 124, 0.9)',
			},
		},
		meetTeams: {
			background: 'rgba(251, 38, 82, 0.8)',
			borderRadius: '0 0 5px 0',
			'&:hover': {
				background: 'rgba(251, 38, 82, 0.9)',
			},
		},
		companyLinkWrap: {
			position: 'relative',
			bottom: 0,
			color: '#fff',
			height: '60px',
			width: '100%',
			visibility: 'hidden',
			opacity: 0,
			transition: 'visibility 0.2s, opacity 0.2s linear',
			marginTop: '-60px',
			[theme.breakpoints.down('sm')]: {
				position: 'relative',
				marginTop: '-60px',
			},
		},
		teamCompanyInfo: {
			position: 'absolute',
			right: theme.spacing(2),
			top: -10,
			background: '#ffffff',
			fontWeight: 400,
			color: theme.palette.grey[800],
			fontSize: 12,
			lineHeight: '18px',
			border: `1px solid #ebebeb`,
			borderRadius: 12,
			[theme.breakpoints.down('sm')]: {
				right: 5,
			},
			'& > span': {
				lineHeight: '18px',
				padding: theme.spacing(1),
			},
		},
		video: {
			display: 'flex',
			'&:hover': {
				cursor: 'pointer',
			},
			position: 'relative',
			height: '100%',
			// paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
			overflow: 'hidden',
		},
		player: {
			pointerEvents: 'none',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			'& iframe': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},
		},
	})
