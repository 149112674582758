import gql from 'graphql-tag'

import { pageInfoData } from './common'

export const articlesByEntityResults = gql`
	fragment articlesByEntityResults on Article {
		id
		author {
			id
			email
			profile {
				id
				firstName
				lastName
			}
		}
		title
		body
		slug
		description
		isPost
		featuredVideo
		featuredImage {
			id
			filename
		}
	}
`

export const standardArticleResult = gql`
	fragment standardArticleResult on Article {
		id
		author {
			id
			uid
			email
			profile {
				id
				firstName
				lastName
				cvFilePath
				cvFileOriginalName
				worksAt {
					id
					company
					position
				}
				hasAvatar
				avatarContentType
				avatarPath
			}
		}
		worksAt {
			id
			company
			position
		}
		readDuration
		title
		body
		slug
		description
		isPost
		postAs
		postingCompany {
			id
			name
			slug
			hasLogo
			logoPath
			industry {
				id
				key
			}
		}
		createdAt
		tags {
			id
			uniqueIdentifier
			title
			votes
			canVote
			voters {
				id
				firstName
				lastName
				user {
					id
					uid
				}
			}
		}
		updatedAt
		featuredVideo
		featuredImage {
			id
			filename
		}
	}
`

export const articleConnectionData = gql`
	fragment articleConnectionData on ArticlesConnection {
		edges {
			node {
				...standardArticleResult
			}
			cursor
		}
		pageInfo {
			...pageInfoData
		}
	}
	${standardArticleResult}
	${pageInfoData}
`
