import gql from 'graphql-tag'
import {
	standardArticleResult,
	standardCompanyResult,
	standardTeamResult,
} from '../fragments'

export const SearchQuery = gql`
	query search($data: String) {
		search(data: $data) {
			Articles {
				...standardArticleResult
			}
			Companies {
				id
				name
				slug
				dateFounded
				industry {
					id
					key
				}
				hasLogo
				logoPath
				hasCover
				coverPath
				featuredVideo
			}
			People {
				id
				uid
				profile {
					id
					firstName
					lastName
					hasAvatar
					avatarPath
					worksAt {
						id
						company
						position
					}
				}
			}
			Teams {
				id
				name
				slug
				company {
					id
					name
					slug
				}
			}
			Jobs {
				id
				slug
				description
				title
				contractType
				seniorityLevel
				jobType
				locationType
				featuredImage
				company {
					id
					logoPath
					hasCover
					hasLogo
					name
					slug
					description
					coverPath
					featuredVideo
					industry {
						id
						key
					}
				}
				salary {
					id
					currency
					amountMin
					amountMax
					rate
				}
				locations {
					id
					location {
						id
						name
						region2
					}
				}
			}
			CompaniesCount
			ArticlesCount
			PeopleCount
			TeamsCount
			JobsCount
		}
	}
	${standardArticleResult}
`
export const SearchPeopleQuery = gql`
	query searchPeople($offset: Int!, $query: String) {
		searchPeople(offset: $offset, query: $query) {
			edges {
				node {
					id
					uid
					profile {
						id
						firstName
						lastName
						hasAvatar
						avatarPath
						worksAt {
							id
							company
							position
						}
					}
				}
			}
			pageInfo {
				hasNextPage
			}
		}
	}
`
export const SearchCompaniesQuery = gql`
	query searchCompanies($data: SearchCompaniesInput) {
		searchCompanies(data: $data) {
			...standardCompanyResult
		}
	}
	${standardCompanyResult}
`
export const SearchArticlesQuery = gql`
	query searchArticles($offset: Int!, $query: String) {
		searchArticles(offset: $offset, query: $query) {
			edges {
				node {
					...standardArticleResult
				}
			}
			pageInfo {
				hasNextPage
			}
		}
	}
	${standardArticleResult}
`
export const SearchTeamsQuery = gql`
	query searchTeams($data: SearchTeamsInput) {
		searchTeams(data: $data) {
			...standardTeamResult
		}
	}
	${standardTeamResult}
`
