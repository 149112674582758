import gql from 'graphql-tag'
import {
	articlesByEntityResults,
	standardArticleResult,
	articleConnectionData,
} from 'store/fragments'

// Mutations

export const CreateArticleMutation = gql`
	mutation handleArticle($article: ArticleInput!) {
		handleArticle(article: $article) {
			...standardArticleResult
		}
	}
	${standardArticleResult}
`
export const DeleteArticleMutation = gql`
	mutation deleteArticle($id: String!) {
		deleteArticle(id: $id) {
			...standardArticleResult
		}
	}
	${standardArticleResult}
`

export const ArticlesByEntity = gql`
	query articles(
		$language: LanguageCodeType!
		$userId: String
		$companyId: String
		$teamId: String
	) {
		articles(
			data: {
				userId: $userId
				companyId: $companyId
				teamId: $teamId
				language: $language
			}
		) {
			...articlesByEntityResults
		}
	}
	${articlesByEntityResults}
`
export const Article = gql`
	query article($id: String, $slug: String) {
		article(id: $id, slug: $slug) {
			...standardArticleResult
		}
	}
	${standardArticleResult}
`

export const FeedArticlesQuery = gql`
	query feedArticles(
		$language: LanguageCodeType!
		$userId: String
		$uid: String
		$companySlug: String
		$teamId: String
		$first: Int!
		$after: String
	) {
		feedArticles(
			language: $language
			userId: $userId
			userUid: $uid
			companySlug: $companySlug
			teamId: $teamId
			first: $first
			after: $after
		) {
			...articleConnectionData
		}
	}
	${articleConnectionData}
`
export const FeedTeamArticlesQuery = gql`
	query feedTeamArticles($teamId: String, $first: Int!, $after: String) {
		feedTeamArticles(teamId: $teamId, first: $first, after: $after) {
			...articleConnectionData
		}
	}
	${articleConnectionData}
`

export const ArticleTagVotersQuery = gql`
	query getArticleTagVoters($articleId: String) {
		getArticleTagVoters(articleId: $articleId) {
			tag {
				id
				title
			}
			voters {
				id
				user {
					id
					uid
				}
				firstName
				lastName
				avatarPath

				worksAt {
					id
					company
					position
				}
			}
		}
	}
`
