import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		deleteButton: {
			padding: 0,
			marginLeft: 'auto',
			width: theme.spacing(10),
			height: theme.spacing(3),
			fontSize: theme.spacing(1.3),
		},
		cancelDeletionButton: {
			backgroundColor: theme.palette.grey[300],
		},
		confirmDeletionButton: {
			color: '#ffffff',
			backgroundColor: theme.palette.secondary.main,
			'&:hover': {
				backgroundColor: '#fc2652d1',
			},
		},
		deleteDialog: {
			minWidth: '400px',
		},
	})
