import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		industryContainer: {
			position: 'relative',
			alignSelf: 'stretch',
			marginBottom: -8,
		},
		suggestionsContainerOpen: {
			position: 'absolute',
			zIndex: 1,
			marginTop: theme.spacing(1),
			left: 0,
			right: 0,
		},
		suggestion: {
			display: 'block',
		},
		suggestionsList: {
			margin: 0,
			padding: 0,
			listStyleType: 'none',
		},
		selectItem: {
			overflow: 'auto !important',
		},
	})
