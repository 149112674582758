import React, { FunctionComponent, memo, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

import { SearchInputContext } from '../component'
import { StoreValue } from 'store'
import styles from '../styles'
import { TCancelButtonProps, EAppStateActionTypes } from 'models'

const useStyles = makeStyles(styles)

const CancelButton: FunctionComponent<TCancelButtonProps> = ({
	storeDispatch,
}) => {
	const history = useHistory()
	const classes = useStyles()
	const { i18n } = useTranslation()
	const [, setIsFocused] = useContext(SearchInputContext)
	const onCancel = () => {
		storeDispatch({
			type: EAppStateActionTypes.updateQuery,
			query: '',
		})
		history.push(`/${i18n.language}`)
		setIsFocused(false)
	}

	return (
		<div className={classes.close} onClick={onCancel}>
			Cancel
		</div>
	)
}

export default memo(() => (
	<StoreValue keys={['storeDispatch']}>
		<CancelButton />
	</StoreValue>
))
