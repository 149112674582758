import { useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'

import { getArticleCommentsCollectionPath } from '../common'
import { IDeleteCommentData } from 'models'

export const useDeleteComment = (articleId: string | number) => {
	const [firestore] = useState(firebase.firestore())
	const handleDeleteComment = async (commentData: IDeleteCommentData) => {
		return firestore
			.collection(getArticleCommentsCollectionPath(articleId))
			.doc(`${commentData.id}`)
			.delete()
	}

	return [handleDeleteComment]
}
