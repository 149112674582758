import React, {
	memo,
	useCallback,
	useState,
	RefObject,
	useRef,
	ChangeEvent,
	useMemo,
	useEffect,
} from 'react'
import DOMPurify from 'dompurify'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import AddAPhoto from '@material-ui/icons/AddAPhotoOutlined'
import { KeyboardArrowLeft } from '@material-ui/icons'
import SlowMotionVideo from '@material-ui/icons/SlowMotionVideoOutlined'
import SettignsIcon from '@material-ui/icons/SettingsOutlined'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'
import ReactPlayer from 'react-player'
import { Loading } from 'components'
import Chip from '@material-ui/core/Chip'
import { useHistory } from 'react-router'
import { useMediaQuery } from '@material-ui/core'

import CompanyPageProgress from './companyPageStatus'
import { Badge } from '../index'
import styles from './styles'
import CompanyHeaderImage from 'assets/images/company-header-image.jpg'
import {
	EEntity,
	IEntityHeaderProps,
	ECompanyActionTypes,
	EAppStateActionTypes,
} from 'models'
import {
	UpdateCompanyDetails,
	UpdateProfileDetails,
	StoreValue,
	UpdateTeamDetails,
} from 'store'
import {
	useS3Upload,
	buildUserCover,
	buildCompanyCover,
	buildTeamCover,
} from 'utils'
import { DeleteTeam } from 'screens/team/components'
import { BreadcrumbsView } from 'screens/company/components'

const useStyles = makeStyles(styles)

const EntityHeaderView: React.FunctionComponent<IEntityHeaderProps> = ({
	canEdit,
	bCanEdit,
	toggle,
	currentTabIndex,
	tabs,
	name,
	description,
	company,
	loading,
	user,
	type,
	storeUserId,
	team,
	hasCompanyArticles,
	hasCompanyTeams,
	handleGoToCreateStory,
	toggleAddTeamModal,
	// viewAs,
	storeDispatch,
	hasCompanyJobs,
	storeCompanyPageViewAs = 'member', // TODO: this initialization is temporary until I modify all the necessary files
	storeUserPageViewAs = 'member',
}) => {
	const [updateMutationHandle] = useMutation(
		type === EEntity.company
			? UpdateCompanyDetails
			: type === EEntity.person
			? UpdateProfileDetails
			: UpdateTeamDetails,
	)
	const { t, i18n } = useTranslation()
	const [showVideo, setShowVideo] = useState(false)
	const [uploading, setUploading] = useState(false)
	const [videoPath, setVideoPath] = useState(
		(company && company.featuredVideo) || '',
	)
	const [showUploadCrop, setShowUploadCrop] = useState(false)
	const classes = useStyles()
	const uploadInput: RefObject<HTMLInputElement> = useRef(null)
	const theme: Theme = useTheme()
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const [stickyHeader, setStickyHeader] = useState(false)
	const [
		companyPageProgressElement,
		setCompanyPageProgressElement,
	] = useState<HTMLElement | null>(null)
	const [totalHeaderOffset, setTotalHeaderOffset] = useState(0)
	const [entityHeaderContentHeight, setEntityHeaderContentHeight] = useState(
		0,
	)
	const headerExtraPaddingTop = isSmDown ? 45 : 45

	const triggerFileSelect = () => {
		if (uploadInput && uploadInput.current) uploadInput.current.click()
	}
	const history = useHistory()
	const goBack = useCallback(() => {
		history.goBack()
	}, [history])
	const section = useMemo(
		() =>
			type === EEntity.company
				? {
						name: 'company',
						s3Index: 'company_cover',
						id: company && company.id,
						extension: '-company-cover-original.jpg',
				  }
				: type === EEntity.person
				? {
						name: 'profile',
						s3Index: 'profile_cover',
						id: (user && user.id) || undefined,
						extension: '-profile-cover-original.jpg',
				  }
				: {
						name: 'team',
						s3Index: 'team_cover',
						id: (team && team.id) || undefined,
						extension: '-team-cover-original.jpg',
				  },
		[type, company, user, team],
	)
	const { percent, fileId, run } = useS3Upload(
		section.id,
		section.s3Index,
		(user && user.id) || storeUserId || null,
	)
	let handleFileVariables: any = {
		language: i18n.language,
		details: {
			featuredVideo: null,
			coverPath: fileId + section.extension,
			hasCover: true,
		},
	}

	// I added any because of the "The operand of a 'delete' operator must be optional" error.
	let saveVideoVariables:
		| any
		| {
				language: string
				details: {
					featuredVideo: string
					coverPath: null
					hasCover: boolean
				}
		  } = {
		language: i18n.language,
		details: {
			featuredVideo: videoPath,
			coverPath: null,
			hasCover: false,
		},
	}
	let viewAs = ''
	switch (type) {
		case EEntity.company: {
			Object.assign(saveVideoVariables.details, {
				id: section.id,
			})
			Object.assign(handleFileVariables.details, {
				id: section.id,
			})
			viewAs = storeCompanyPageViewAs
			break
		}
		case EEntity.person: {
			Object.assign(saveVideoVariables.details, {
				userId: section.id,
			})
			Object.assign(handleFileVariables.details, {
				userId: section.id,
			})
			viewAs = storeUserPageViewAs
			break
		}
		case EEntity.team: {
			delete saveVideoVariables.language
			Object.assign(saveVideoVariables.details, {
				id: section.id,
			})
			delete handleFileVariables.language
			Object.assign(handleFileVariables.details, {
				id: section.id,
				name: team && team.name ? team.name : '',
			})
			viewAs = storeCompanyPageViewAs // Team is under company section, so the same rule will apply for the team.
			break
		}

		default:
			break
	}

	const handleFileSelect = useCallback(
		(ev: ChangeEvent<HTMLInputElement>) => {
			const source =
				uploadInput &&
				uploadInput.current &&
				uploadInput.current.files &&
				uploadInput.current.files[0]
			if (!source) return
			setUploading(true)
			run(
				source,
				section.extension,
				async () => {
					await updateMutationHandle({
						variables: handleFileVariables,
					})
					setUploading(false)
				},
				() => {
					setUploading(false)
				},
			)
		},
		[
			uploadInput,
			run,
			updateMutationHandle,
			section.extension,
			handleFileVariables,
		],
	)
	const setTab = useCallback(
		(index: string) => () => {
			toggle(index)
		},
		[toggle],
	)
	const toggleVideo = useCallback(() => {
		setShowVideo(state => !state)
	}, [])

	const saveVideo = useCallback(async () => {
		await updateMutationHandle({
			variables: saveVideoVariables,
		})
		toggleVideo()
	}, [toggleVideo, updateMutationHandle, saveVideoVariables])

	const handleChangeVideo = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setVideoPath(event.target.value)
		},
		[],
	)

	const toggleViewAs = useCallback(() => {
		if (storeDispatch) {
			storeDispatch({
				type: ECompanyActionTypes.switchViewAs,
			})
			storeDispatch({
				type:
					type === EEntity.company || type === EEntity.team
						? EAppStateActionTypes.updateGuideCompanyViewAs
						: EAppStateActionTypes.updateGuideUserViewAs,
			})
		}
	}, [storeDispatch, type])

	const companyProgressElementHeight = useMemo(() => {
		return companyPageProgressElement
			? companyPageProgressElement.offsetHeight + 20
			: 0
	}, [companyPageProgressElement])

	const output = useMemo(() => {
		if (company) {
			return (
				<>
					{company.hasCover ? (
						<img
							src={buildCompanyCover(company)}
							className={classes.headerImage}
							alt=""
						/>
					) : company.featuredVideo ? (
						<ReactPlayer
							url={company.featuredVideo}
							controls={true}
							width="100%"
							height="100%"
						/>
					) : (
						<img
							src={CompanyHeaderImage}
							className={classes.headerImage}
							alt=""
						/>
					)}
					{canEdit && (
						<Chip
							avatar={undefined}
							label={
								'View as ' +
								(viewAs
									? viewAs === 'admin'
										? 'Member'
										: 'Admin'
									: '')
							}
							onClick={toggleViewAs}
							variant="outlined"
							className={classes.viewAsMember}
							style={{
								position: 'absolute',
								bottom: 10,
								right: 10,
							}}
						/>
					)}
				</>
			)
		}
		if (team) {
			return (
				<Grid item={true} className={classes.teamHeaderRoot}>
					<Grid container className={classes.teamHeadCont}>
						<Grid
							item={true}
							xs={8}
							className={classes.teamResourceContainer}
						>
							{team.hasCover && (
								<img
									src={buildTeamCover(team)}
									className={classes.headerImage}
									alt=""
								/>
							)}
							{team.featuredVideo && (
								<div className={classes.video}>
									<ReactPlayer
										url={team.featuredVideo}
										controls={true}
										width="100%"
										height="100%"
									/>
								</div>
							)}
							{!team.hasCover && (
								<div className={classes.teamImgPlaceholder}>
									{/* <Button
										onClick={() => goBack()}
										className={classes.backBtn}
									>
										<KeyboardArrowLeft />
										<span className={classes.backBtnText}>
											{' '}
											{'Back'}
										</span>
									</Button> */}
									<>
										<input
											onChange={handleFileSelect}
											ref={uploadInput}
											type="file"
											style={{ display: 'none' }}
											accept="image/*"
										/>
										<IconButton
											aria-label="Add team cover image"
											onClick={triggerFileSelect}
										>
											<AddAPhoto
												fontSize={'large'}
												style={{
													color: '#fff',
												}}
											/>
										</IconButton>
									</>
								</div>
							)}
							{canEdit && (
								<Chip
									avatar={undefined}
									label={
										'View as ' +
										(viewAs
											? viewAs === 'admin'
												? 'Member'
												: 'Admin'
											: '')
									}
									onClick={toggleViewAs}
									variant="outlined"
									className={classes.viewAsMember}
									style={{
										position: 'absolute',
										bottom: 10,
										right: 10,
									}}
								/>
							)}
						</Grid>

						<Grid
							xs={4}
							item={true}
							className={classes.teamHeadDescriptionRoot}
						>
							<h3 className={classes.teamHeadTitle}>{name}</h3>
							<h4 className={classes.teamHeadDesc}>
								<div
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(description),
									}}
								></div>
							</h4>
						</Grid>
					</Grid>
				</Grid>
			)
		}

		if (user && user.profile) {
			return (
				<>
					{user.profile.hasCover ? (
						<img
							src={buildUserCover(user)}
							className={classes.headerImage}
							alt=""
						/>
					) : user.profile.featuredVideo ? (
						<ReactPlayer
							url={user.profile.featuredVideo}
							// className={classes.player}
							controls={true}
							width="100%"
							height="100%"
						/>
					) : (
						<img
							src={CompanyHeaderImage}
							className={classes.headerImage}
							alt=""
						/>
					)}
					{canEdit && (
						<Chip
							avatar={undefined}
							label={
								'View as ' +
								(viewAs
									? viewAs === 'admin'
										? 'Member'
										: 'Admin'
									: '')
							}
							onClick={toggleViewAs}
							variant="outlined"
							className={classes.viewAsMember}
							style={{
								position: 'absolute',
								bottom: 10,
								right: 10,
							}}
						/>
					)}
				</>
			)
		}

		return (
			<img
				src={CompanyHeaderImage}
				className={classes.headerImage}
				alt=""
			/>
		)
	}, [
		company,
		team,
		user,
		canEdit,
		viewAs,
		toggleViewAs,
		classes,
		description,
		handleFileSelect,
		name,
	])
	const handleShowUploadCrop = useCallback(() => {
		setShowUploadCrop(true)
	}, [])

	useEffect(() => {
		if (showUploadCrop) setShowUploadCrop(false)
	}, [showUploadCrop, setShowUploadCrop])

	useEffect(() => {
		const header: HTMLElement | null = document.getElementById(
			'menu-header',
		)
		const companyPageProgressElement: HTMLElement | null = document.getElementById(
			'company-page-progress',
		)
		const entityHeaderContentElement: HTMLElement | null = document.getElementById(
			'entity-header-content',
		)
		setEntityHeaderContentHeight(
			entityHeaderContentElement
				? entityHeaderContentElement?.offsetHeight - 50
				: 0,
		)
		setCompanyPageProgressElement(companyPageProgressElement)
		const companyPageProgressHeightOffset = companyPageProgressElement
			? companyPageProgressElement.offsetHeight
			: 0
		const headerOffset = header ? header.offsetTop : 0
		const totalHeaderOffset =
			companyPageProgressHeightOffset + headerOffset + 57
		setTotalHeaderOffset(totalHeaderOffset)
		const sticky = totalHeaderOffset
		const scrollListen: any = window.addEventListener('scroll', (): any => {
			if (window.pageYOffset > sticky) {
				setStickyHeader(true)
			} else {
				setStickyHeader(false)
			}
		})
		return () => {
			window.removeEventListener('scroll', scrollListen)
		}
	}, [storeCompanyPageViewAs])

	return (
		<Grid
			direction="column"
			container={true}
			id="entity-header-container"
			style={{
				paddingTop: stickyHeader
					? totalHeaderOffset + headerExtraPaddingTop
					: 0,
			}}
		>
			<Grid
				id="entity-header"
				style={{
					top:
						companyPageProgressElement && stickyHeader
							? -entityHeaderContentHeight -
							  companyProgressElementHeight +
							  30
							: stickyHeader
							? -entityHeaderContentHeight
							: 0,
				}}
				className={classNames([
					classes.entityHeader,
					{
						[classes.stickyHeader]: stickyHeader,
						//  stickyHeaderAdminView:
						// 	storeCompanyPageViewAs === 'admin',
					},
				])}
				container={true}
				direction="column"
			>
				{team && type === EEntity.team && (
					<Grid
						style={{
							display: 'flex',
							marginBottom: '0px 10px',
							width: '100%',
							maxWidth: 670,
						}}
					>
						<div>
							<Button
								className={classes.backBtn}
								onClick={() => goBack()}
								style={{
									top: 'unset',
									left: 'unset',
									position: 'relative',
								}}
							>
								<KeyboardArrowLeft />
								<span className={classes.backBtnText}>
									{'Back'}
								</span>
							</Button>
						</div>
						<div className={classes.breadcrumbsContainer}>
							<BreadcrumbsView
								companyName={team.company.name}
								section={team.name}
								companySlug={team.company.slug}
							/>
						</div>
					</Grid>
				)}
				{company && canEdit && viewAs === 'admin' && (
					<Grid
						id={'company-page-progress'}
						container
						className={classes.entityCompanyProgress}
					>
						<CompanyPageProgress
							companyData={{
								...company,
								hasCompanyArticles,
								hasCompanyTeams,
								hasCompanyJobs,
								handleShowUploadCrop,
								triggerFileSelect,
								handleGoToCreateStory,
								toggleAddTeamModal,
							}}
						/>
					</Grid>
				)}
				<Grid
					className={classes.entityHeaderContent}
					style={type === EEntity.team ? { marginTop: 20 } : {}}
					container={true}
					direction="column"
					id="entity-header-content"
				>
					{!team && (
						<Badge
							type={type}
							title={name}
							description={description}
							extraClasses={classes.headerBadge}
							company={company}
							user={user}
							canEdit={canEdit}
							showUploadCrop={showUploadCrop}
						/>
					)}
					<Grid
						className={classes.headerImageContainer}
						container={true}
						direction="column"
						style={{ position: 'relative' }}
					>
						{loading || uploading ? (
							<div className={classes.loader}>
								{uploading ? <span>{percent} %</span> : ''}
								<Loading size={48} />
							</div>
						) : (
							output
						)}
					</Grid>
					<Grid
						id="menu-header"
						className={classes.menuHeaderWrap}
						container={true}
						direction="row"
					>
						{Object.keys(tabs).map((tab: string) => {
							return (
								<Grid
									key={`tab-${tab}`}
									item={true}
									onClick={setTab(tab)}
									className={classNames(
										classes.tabLabelContainer,
										currentTabIndex === tab
											? classes.selectedTabLabelContainer
											: '',
									)}
								>
									<span>{tabs[tab]}</span>
								</Grid>
							)
						})}
						{type === EEntity.company &&
							canEdit &&
							viewAs === 'admin' && (
								<>
									<Grid
										key={`company-tab-settigns`}
										item={true}
										className={classNames(
											classes.tabLabelContainer,
											classes.alignRight,
											currentTabIndex ===
												'company-settings'
												? classes.selectedTabLabelContainer
												: '',
										)}
									>
										<span
											onClick={setTab('company-settings')}
										>
											{t('Settings')}
										</span>
									</Grid>
								</>
							)}
						{type === EEntity.person && canEdit && (
							<Grid
								key={`person-tab-settigns`}
								item={true}
								onClick={setTab('settings')}
								className={classNames(
									classes.tabLabelContainer,
									classes.alignRight,
									currentTabIndex === 'settings'
										? classes.selectedTabLabelContainer
										: '',
								)}
							>
								<SettignsIcon />
								<span>{t('Settings')}</span>
							</Grid>
						)}

						{team && type === EEntity.team && bCanEdit && (
							<DeleteTeam
								id={team.id}
								companySlug={team.company.slug}
							/>
						)}
					</Grid>
					{canEdit && viewAs === 'admin' && (
						<>
							<input
								onChange={handleFileSelect}
								ref={uploadInput}
								type="file"
								style={{ display: 'none' }}
								accept="image/*"
							/>
							<IconButton
								aria-label="Add photo cover"
								className={classNames([
									classes.addCoverPhoto,
									{
										[classes.teamAddCoverPhoto]: team,
									},
								])}
								onClick={triggerFileSelect}
							>
								<AddAPhoto
									fontSize="default"
									style={{ color: '#fff' }}
								/>
							</IconButton>
							<IconButton
								aria-label="Add video cover"
								className={classNames([
									classes.addCoverVideo,
									{
										[classes.teamAddCoverVideo]: team,
									},
								])}
								onClick={toggleVideo}
							>
								<SlowMotionVideo
									fontSize="default"
									style={{ color: '#fff' }}
								/>
							</IconButton>
						</>
					)}
				</Grid>
				{canEdit && (
					<Dialog
						open={showVideo}
						onClose={toggleVideo}
						aria-labelledby="form-dialog-title"
						fullWidth
						maxWidth="sm"
						scroll="body"
					>
						<DialogContent style={{ overflow: 'hidden' }}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField
										id="standard-number"
										label={t('Video url')}
										value={videoPath}
										onChange={handleChangeVideo}
										type="text"
										className={classes.textInputRoot}
										InputLabelProps={{
											shrink: true,
										}}
										margin="normal"
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={toggleVideo} color="primary">
								{t('Cancel')}
							</Button>
							<Button onClick={saveVideo} color="primary">
								{t('Save')}
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</Grid>
		</Grid>
	)
}

const EntityHeader = memo((props: IEntityHeaderProps) => (
	<StoreValue
		{...props}
		keys={[
			'user.id|storeUserId',
			'storeDispatch',
			'guides.companyPageViewAs|storeCompanyPageViewAs',
			'guides.userPageViewAs|storeUserPageViewAs',
		]}
	>
		<EntityHeaderView {...props} />
	</StoreValue>
))

export default EntityHeader
