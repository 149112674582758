import { fade } from '@material-ui/core/styles/colorManipulator'
import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		container: {
			flex: '1 0 0',
			[theme.breakpoints.only('xs')]: {
				marginLeft: theme.spacing(1),
			},
		},
		gutterRight: {
			marginRight: theme.spacing(1),
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.black, 0.07),
			width: '100%',
		},
		searchIcon: {
			width: theme.spacing(5),
			color: fade(theme.palette.common.black, 0.4),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			right: 0,
		},
		closeIcon: {
			pointerEvents: 'all',
			cursor: 'pointer',
			zIndex: 1,
		},
		inputRoot: {
			color: 'inherit',
			width: '100%',
		},
		inputInput: {
			paddingTop: theme.spacing(1),
			paddingRight: theme.spacing(5),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			transition: theme.transitions.create('width'),
			width: '100%',
			color: fade(theme.palette.common.black, 0.4),
			fontFamily: theme.typography.fontFamily,
			'&::placeholder': {
				textOverflow: 'ellipsis !important',
				color: '#989898',
				[theme.breakpoints.only('xs')]:{
					fontSize: 14
				}

			},
		},
		marginLeftRight: {
			marginRight: 0,
			marginLeft: theme.spacing(1),
		},
		subtitle: {
			cursor: 'normal',
			color: 'grey',
			fontSize: '14px',
			paddingLeft: '16px',
			fontWeight: 'normal',
		},
		rightMenuContentItems: {
			borderRadius: '8px',
			background: '#f5f5f5',
			color: 'rgb(88, 87, 87)',
			fontWeight: 'bold',
			'& >div ': {
				paddingTop: '4px !important',
				paddingBottom: '4px !important',
			},
			'& span': {
				maxHeight: '25px',
				fontWeight: 'bold',
			},
			'& li': {
				minHeight: '40px',
				fontWeight: 'bold',
			},
		},
		listItem: {
			backgroundColor: 'red',
		},
		rightMenuCompanyLabel: {
			marginTop: '0px',
			color: '#1879ce',
			fontWeight: 'bold',
		},
		languageButton: {
			borderColor: 'rgba(255, 255, 255, 0)',
			color: theme.palette.grey[800],
			fontWeight: 300,
		},
		languageDivider: {
			color: theme.palette.grey[400],
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			fontWeight: 300,
		},
		languageActive: {
			borderColor: '#1879ce', // theme.palette.primary.main,
			color: '#1879ce', //theme.palette.primary.main,
			fontWeight: 'bold', // 500,
		},
		avatar: {
			color: '#1879ce',
			backgroundColor: '#1879ce',
			width: theme.spacing(1),
			height: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		whiteBackground: {
			backgroundColor: '#ffffff',
		},
	})
