import gql from 'graphql-tag'

// Queries

export const S3GetEditorHashQuery = gql`
	query getS3UploadHash($keyStart: String!) {
		getS3UploadHash(keyStart: $keyStart) {
			bucket
			region
			keyStart
			params {
				policy
				acl
				XAmzAlgorithm
				XAmzCredential
				XAmzDate
				XAmzSignature
			}
		}
	}
`
