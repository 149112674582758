// import { setCookie } from '.'
import { EAppStateActionTypes, IAppStateGuides } from 'models'

export default (state: IAppStateGuides, action: any): IAppStateGuides => {
	let newState = state
	switch (action.type) {
		case EAppStateActionTypes.updateGuide:
			newState = {
				...state,
				[action.guide]: action.value,
			}
			break
		case EAppStateActionTypes.updateGuideCompanyViewAs:
			newState = {
				...state,
				companyPageViewAs:
					state.companyPageViewAs === 'member' ? 'admin' : 'member',
			}
			break
		case EAppStateActionTypes.updateGuideUserViewAs:
			newState = {
				...state,
				userPageViewAs:
					state.userPageViewAs === 'member' ? 'admin' : 'member',
			}
			break
		default:
			break
	}
	// setCookie(newState)
	return newState
}
