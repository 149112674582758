
const company: any = {
	name: 'Vodafone',
	activity: 'Telecommunications',
	image: 'https://fillmurray.com/200/300',
	logo: 'https://fillmurray.com/200/300',
	foundingDate: new Date(2000, 1, 1),
	website: 'http://www.vodafone.ro',
	phoneNumber: '+40745745745',
	emailAddress: 'mail@address.com',
	offices: ['Bucharest', 'Cluj', 'Iasi', 'Texas', 'London', 'Paris'],
	about: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices. Massa enim nec dui nunc mattis enim. Amet facilisis magna etiam tempor orci. Justo laoreet sit amet cursus. Felis imperdiet proin fermentum leo vel orci porta. Morbi tristique senectus et netus et. Auctor urna nunc id cursus metus aliquam eleifend. Senectus et netus et malesuada. Ipsum consequat nisl vel pretium lectus quam id leo in. Id nibh tortor id aliquet lectus proin nibh nisl condimentum.',
	productsAndServices: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices. Massa enim nec dui nunc mattis enim. Amet facilisis magna etiam tempor orci. Justo laoreet sit amet cursus. Felis imperdiet proin fermentum leo vel orci porta. Morbi tristique senectus et netus et. Auctor urna nunc id cursus metus aliquam eleifend. Senectus et netus et malesuada. Ipsum consequat nisl vel pretium lectus quam id leo in. Id nibh tortor id aliquet lectus proin nibh nisl condimentum.',
	teamLifeItems: [{
		title: 'Team life',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		link: 'https://fillmurray.com/200/300',
		linkType: 'image'
	},
	{
		title: 'Team life',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		link: 'https://www.youtube.com/watch?v=SWZ_4YBFBhs',
		linkType: 'video'
	},
	{
		title: 'Team life',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		link: 'https://fillmurray.com/200/300',
		linkType: 'image'
	},
	{
		title: 'Team life',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		link: 'https://fillmurray.com/200/300',
		linkType: 'image'
	}],
	qa: [{
		question: 'Donec sit amet tellus class taciti per dolor?Donec sit amet tellus class taciti per dolor?Donec sit amet tellus class taciti per dolor?Donec sit amet tellus class taciti per dolor?Donec sit amet tellus class taciti per dolor?',
		answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		question: 'Donec sit amet tellus class taciti per dolor?',
		answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
	},
	{
		question: 'Donec sit amet tellus class taciti per dolor?',
		answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
	}],
	teamsDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices. Massa enim nec dui nunc mattis enim. Amet facilisis magna etiam tempor orci. Justo laoreet sit amet cursus. Felis imperdiet proin fermentum leo vel orci porta. Morbi tristique senectus et netus et. Auctor urna nunc id cursus metus aliquam eleifend. Senectus et netus et malesuada. Ipsum consequat nisl vel pretium lectus quam id leo in. Id nibh tortor id aliquet lectus proin nibh nisl condimentum.',
	teams: [
		{
			title: 'Team name',
			description: '20-30 members'
		},
		{
			title: 'Team name',
			description: '10-20 members'
		},
		{
			title: 'Team name',
			description: '20-30 members'
		},
		{
			title: 'Team name',
			description: '10-20 members'
		},
		{
			title: 'Team name',
			description: '20-30 members'
		},
		{
			title: 'Team name',
			description: '10-20 members'
		}
	]

};

export default company;
