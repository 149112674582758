import React, { memo, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router'
import { useTranslation, Trans } from 'react-i18next'
import ReactPlayer from 'react-player'
import { IconButton } from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

import { Badge } from '../index'
import styles from './styles'
import CompanyHeaderImage from 'assets/images/company-header-image.jpg'
import { buildCompanyCover, buildTeamCover } from 'utils'
import { EEntity, EAppStateActionTypes } from 'models'
import { StoreValue } from 'store'

const useStyles = makeStyles(styles)

const CardImageView: React.FunctionComponent<any> = ({
	company,
	team,
	storeDispatch,
}) => {
	const theme: any = useTheme()
	const isMdDown: boolean = useMediaQuery(theme.breakpoints.down('md'))
	const history = useHistory()
	const { i18n } = useTranslation()
	const classes = useStyles()

	const handleGoToTeam = useCallback(() => {
		if (team && team.company) {
			history.push({
				pathname: `/${i18n.language}/employers/${team.company.slug}/teams/${team.slug}`,
			})
		}
	}, [team, history, i18n.language])

	const renderImageCard = useCallback(() => {
		if (company) {
			return company.hasCover ? (
				<img
					src={buildCompanyCover(company)}
					className={classes.cardImg}
					alt=""
				/>
			) : company.featuredVideo ? (
				<div className={classes.video}>
					<ReactPlayer
						url={company.featuredVideo}
						className={classes.player}
						controls={true}
						width="100%"
						height="100%"
					/>
				</div>
			) : (
				<img
					src={CompanyHeaderImage}
					className={classes.cardImg}
					alt=""
				/>
			)
		}
		if (team) {
			let imgSrc = ''
			team.hasCover
				? (imgSrc = buildTeamCover(team))
				: (imgSrc = CompanyHeaderImage)
			return (
				<img
					src={imgSrc}
					className={classes.cardImg}
					alt=""
					style={{ cursor: 'pointer' }}
					onClick={handleGoToTeam}
				/>
			)
		}
	}, [classes, company, team, handleGoToTeam])

	const goToCompany = useCallback(() => {
		history.push(`/${i18n.language}/employers/${company.slug}`, company)
		storeDispatch({
			type: EAppStateActionTypes.updateQuery,
			query: '',
		})
	}, [history, storeDispatch, company, i18n.language])

	const goToTeam = useCallback(() => {
		history.push(
			`/${i18n.language}/employers/${team?.company?.slug}/teams/${team?.slug}`,
			company,
		)
		storeDispatch({
			type: EAppStateActionTypes.updateQuery,
			query: '',
		})
	}, [history, storeDispatch, team, company, i18n.language])

	const goToTeams = useCallback(() => {
		history.push({
			pathname: `/${i18n.language}/employers/${company.slug}/teams`,
		})
		storeDispatch({
			type: EAppStateActionTypes.updateQuery,
			query: '',
		})
	}, [company, storeDispatch, history, i18n.language])

	return (
		<Grid
			className={classes.companyCardContent}
			container={true}
			direction="column"
		>
			{company && (
				<Badge
					{...company}
					company={company}
					title={company.name}
					type={EEntity.company}
					description={
						company && company.industry && company.industry.key
					}
					extraClasses={classes.companyBadge}
					extraAvatarClasses={classes.companyBadgeAvatar}
					onClick={goToCompany}
				/>
			)}
			{team && (
				<>
					<Badge
						{...team}
						team={team}
						title={team.name}
						type={EEntity.team}
						description={team && team.department}
						extraClasses={classes.companyBadge}
						extraAvatarClasses={classes.companyBadgeAvatar}
						onClick={goToTeam}
					/>
					<div className={classes.teamCompanyInfo}>
						<span>at {team.company.name} company</span>
					</div>
				</>
			)}

			<Grid
				className={classes.companyImageContainer}
				container={true}
				direction="column"
			>
				{renderImageCard()}
			</Grid>
			{company && (
				<Grid
					id="companyLinkWrap"
					container
					className={classes.companyLinkWrap}
				>
					<Grid item xs={8} onClick={goToCompany}>
						<div
							className={`${classes.detailsWrap} ${classes.discoverCompany}`}
						>
							<div className={classes.detailsTitle}>
								<span className={classes.mainTitle}>
									<Trans i18nKey="discoverOurCompany">
										Discover our <br /> company
									</Trans>
								</span>
							</div>
							<IconButton
								className={classes.arrowRightIcon}
								onClick={goToCompany}
							>
								<KeyboardArrowRight
									className={classes.arrowWhite}
									style={
										isMdDown
											? { fontSize: 25 }
											: { fontSize: 30 }
									}
								/>
							</IconButton>
						</div>
					</Grid>

					<Grid item xs={4} onClick={goToTeams}>
						<div
							className={`${classes.detailsWrap} ${classes.meetTeams}`}
						>
							<div className={classes.detailsTitle}>
								<span className={classes.mainTitle}>
									<Trans i18nKey="meetOurTeams">
										Meet our <br /> teams
									</Trans>
								</span>
							</div>
							<IconButton className={classes.arrowRightIcon}>
								<KeyboardArrowRight
									className={classes.arrowWhite}
									style={
										isMdDown
											? { fontSize: 25 }
											: { fontSize: 30 }
									}
									//onClick={goToTeams(company)}
								/>
							</IconButton>
						</div>
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}

const CardImage = memo((props: any) => (
	<StoreValue keys={['storeDispatch']}>
		<CardImageView {...props} />
	</StoreValue>
))
export default CardImage
