import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		breadcrumb: {
			'& li': {
				[theme.breakpoints.down('sm')]: {
					margin: '0px 2px',
				},
			},
			'& li > a, p': {
				width: '250px',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				[theme.breakpoints.down('md')]: {
					fontSize: `${theme.spacing(2)}px`,
				},
				[theme.breakpoints.down('sm')]: {
					fontSize: `${theme.spacing(1.5)}px`,
				},
			},
			'& li > a': {
				cursor: 'pointer',
			},
		},
		inactiveLink: {
			opacity: 0.5,
			textDecoration: 'none !important',
			cursor: 'text !important',
		},
	})
