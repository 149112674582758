import React, { memo, useState, useCallback, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import deburr from 'lodash/deburr'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Select from '@material-ui/core/Select'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { useParams } from 'react-router-dom'

import generalStyles from '../../../styles'
import styles from './styles'
import {
	IndustriesQuery,
	UpdateCompanyDetails,
	RoLocations,
	HandleCompanyOffices,
	ChangeCompanyHq,
} from 'store'
import { IIndustry } from 'models'
import { combineStyles } from 'utils'

const combinedStyles: any = combineStyles(generalStyles, styles)
const useStyles = makeStyles(combinedStyles)

function renderInputComponent(inputProps: any) {
	const { classes, inputRef = () => {}, ref, ...other } = inputProps
	return (
		<TextField
			fullWidth
			InputProps={{
				inputRef: node => {
					ref(node)
					inputRef(node)
				},
			}}
			InputLabelProps={{
				shrink: true,
			}}
			{...other}
		/>
	)
}

function renderSuggestion(
	suggestion: IIndustry,
	{ query, isHighlighted }: Autosuggest.RenderSuggestionParams,
) {
	const matches = match(suggestion.key, query)
	const parts = parse(suggestion.key, matches)

	return (
		<MenuItem selected={isHighlighted} component="div">
			<div>
				{parts.map((part: any, index: number) => (
					<span
						key={`${part.text}_${index}`}
						style={{ fontWeight: part.highlight ? 500 : 400 }}
					>
						{part.text}
					</span>
				))}
			</div>
		</MenuItem>
	)
}

interface State {
	industry: string
	name: string
	dateFounded: string
	website: string
	phones: string
	emails: string
}

const InformationsDialog: React.FunctionComponent<any> = ({
	companyId,
	companySlug,
	name,
	dateFounded,
	industry,
	website,
	phones,
	emails,
	companyHq = null,
	companyOffices,
	toggleModal,
	openModal,
}) => {
	const params: any = useParams()
	const [enableEditAddress, setEnableEditAddress] = useState<boolean>(false)
	const [checkRequiredHqFields, setCheckRequiredHqFields] = useState({
		region: false,
		locationId: false,
	})

	const [streetName, setStreetName] = React.useState<string | ''>(
		(companyHq && companyHq.address) || '',
	)
	const [zipCode, setZipCode] = React.useState<string | ''>(
		(companyHq && companyHq.zipCode) || '',
	)

	const [region, setRegion] = useState<string | undefined>(
		companyHq && companyHq.location
			? companyHq.location.region2
			: undefined,
	)

	const [locationId, setLocationId] = useState<string | undefined>(
		companyHq && companyHq.location ? companyHq.location.id : undefined,
	)

	const [locationName, setLocationName] = useState<string | ''>(
		companyHq && companyHq.location && companyHq.location.name,
	)

	const [state, updateState] = useState({
		industry: (industry && industry.key) || '',
		name: name,
		dateFounded: dateFounded || '',
		website: website || '',
		phones: phones || '',
		emails: emails || '',
	})
	const [industrySuggestions, setSuggestions] = useState<IIndustry[]>([])
	const [companyHqId, setCompanyHqId] = useState(companyHq && companyHq.id)

	const [handleChangeCompanyHqMutation] = useMutation(ChangeCompanyHq)

	let displayedAddress = null
	if (region && locationName) {
		displayedAddress = `${streetName ? streetName + ', ' : ''}  
		${zipCode ? zipCode + ', ' : ''}
		${region}, ${locationName}
	`
	}
	let regions = null
	const { data: regionsData }: any = useQuery(RoLocations, {
		skip: !!regions,
		variables: {
			data: {
				type_in: ['Judeţe', 'National capital'],
			},
		},
	})

	regions = useMemo(() => regionsData || null, [regionsData])
	const { data: locations }: any = useQuery(RoLocations, {
		skip: !region,
		variables: {
			data: {
				region2: region && region,
				type_not_in: ['Judeţe'],
			},
		},
	})

	const handleChangeCompanyHq = useCallback(
		(event: React.ChangeEvent<{ value: unknown }>) => {
			if (companyOffices.length > 1) {
				setCompanyHqId(event.target.value)
				let newHq = companyOffices.find((item: any) => {
					return item.id === event.target.value
				})
				setStreetName(newHq.address ? newHq.address : '')
				setRegion(newHq.location.region2)
				setLocationId(newHq.location.id)
				setZipCode(newHq.zipCode ? newHq.zipCode : '')
				setLocationName(newHq.location.name)
			}
		},
		[companyOffices],
	)

	const [handleCompanyOfficesMutation] = useMutation(HandleCompanyOffices)

	const [updateMutationHandle] = useMutation(UpdateCompanyDetails)
	const { data = {} }: any = useQuery(IndustriesQuery)
	const getSuggestions = useCallback(
		(value: string) => {
			const inputValue = deburr(value.trim()).toLowerCase()
			const inputLength = inputValue.length
			let count = 0

			return inputLength === 0
				? []
				: data.industries.filter((industry: IIndustry) => {
						const keep =
							count < 5 &&
							industry.key.toLowerCase().includes(inputValue)

						if (keep) {
							count += 1
						}

						return keep
				  })
		},
		[data.industries],
	)
	const getSuggestionValue = (industry: IIndustry) => {
		return industry.key
	}

	const classes = useStyles()
	const { t, i18n } = useTranslation()

	const handleSuggestionsFetchRequested = ({ value }: any) => {
		setSuggestions(getSuggestions(value))
	}

	const handleSuggestionsClearRequested = () => {
		setSuggestions([])
	}

	const autosuggestProps = {
		renderInputComponent,
		suggestions: industrySuggestions,
		onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		getSuggestionValue,
		renderSuggestion,
	}

	const handleIndustryChange = (
		event: React.ChangeEvent<{}>,
		{ newValue }: Autosuggest.ChangeEvent,
	) => {
		updateState(state => ({
			...state,
			industry: newValue || '',
		}))
	}

	const handleStateChange = (name: keyof State) => (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		updateState({
			...state,
			[name]: event.target.value || '',
		})
	}

	const handleRegionChange = useCallback((event: any) => {
		setRegion(event.target.value)
	}, [])

	const handleLocationChange = useCallback((event: any, child: any) => {
		setLocationId(event.target.value)
		setLocationName(child.props.children)
	}, [])

	const handleEnableEditAddress = useCallback(
		(enableEditAddress: boolean) => {
			setEnableEditAddress(enableEditAddress)
		},
		[],
	)

	const updateStreetName = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setStreetName(e.target.value)
		},
		[],
	)

	const updateZipCode = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setZipCode(e.target.value)
		},
		[],
	)

	const resetState = useCallback(() => {
		setStreetName((companyHq && companyHq.address) || '')
		setRegion(
			companyHq && companyHq.location
				? companyHq.location.region2
				: undefined,
		)
		setLocationId(
			companyHq && companyHq.location ? companyHq.location.id : undefined,
		)
		setZipCode((companyHq && companyHq.zipCode) || '')
		setEnableEditAddress(false)
		// setNewHqAdded(companyHq ? false : true)
		updateState({
			industry: industry ? industry.key : '',
			name: name ? name : '',
			dateFounded: dateFounded ? dateFounded : '',
			website: website ? website : '',
			phones: phones ? phones : '',
			emails: emails ? emails : '',
		})
	}, [companyHq, industry, name, dateFounded, website, phones, emails])

	const handleToggleModal = useCallback(() => {
		toggleModal()
	}, [toggleModal])

	const handleValidateHqForm = useCallback(
		(triggerButton?: 'save' | 'done' | 'cancel'): boolean => {
			// check the mandatory fields: street, region and location
			let error = false
			if (triggerButton === 'done' && !enableEditAddress) {
				// if the form is opening
				handleEnableEditAddress(!enableEditAddress)
				return true
			}
			if (triggerButton === 'cancel') {
				handleEnableEditAddress(!enableEditAddress)
				resetState()
				return true
			}

			if (triggerButton === 'save' && !enableEditAddress) {
				return true
			}

			let requiredFieldsError = {
				...checkRequiredHqFields,
			}

			if (!region) {
				error = true
				requiredFieldsError = {
					...requiredFieldsError,
					region: true,
				}
			}
			if (!locationId) {
				error = true
				requiredFieldsError = {
					...requiredFieldsError,
					locationId: true,
				}
			}
			if (triggerButton === 'done' || triggerButton === 'save') {
				if (!error) {
					handleEnableEditAddress(!enableEditAddress)
					return true
				} else {
					setCheckRequiredHqFields({ ...requiredFieldsError })
					return false
				}
			} else {
				return true
			}
		},
		[
			checkRequiredHqFields,
			locationId,
			region,
			// streetName,
			enableEditAddress,
			handleEnableEditAddress,
			resetState,
		],
	)

	const save = useCallback(async () => {
		if (!handleValidateHqForm('save')) {
			return
		}
		const { industry: industryLabel, ...rest } = state
		const industryId = data.industries.find(
			(item: IIndustry) => item.key === industryLabel,
		)
		await updateMutationHandle({
			variables: {
				language: i18n.language,
				details: {
					...rest,
					id: companyId,
					industryId: (industryId && industryId.id) || '',
				},
			},
		}).then(async () => {
			await handleCompanyOfficesMutation({
				variables: {
					data: {
						id: companyHqId,
						companySlug,
						address: streetName,
						locationId,
						zipCode,
					},
				},
			}).then(() => {
				if (
					companyHq &&
					companyOffices.length > 1 &&
					companyHqId !== companyHq.id
				) {
					// if the headquarter was changed
					handleChangeCompanyHqMutation({
						variables: {
							id: companyHqId,
							companySlug: params.companySlug,
						},
					})
				}
			})
		})
		handleToggleModal()
	}, [
		state,
		data.industries,
		i18n.language,
		updateMutationHandle,
		companySlug,
		companyId,
		handleToggleModal,
		locationId,
		streetName,
		zipCode,
		handleCompanyOfficesMutation,
		companyHqId,
		handleValidateHqForm,
		companyOffices.length,
		handleChangeCompanyHqMutation,
		params,
		companyHq,
	])

	useEffect(() => {
		if (!openModal) {
			resetState()
		}
	}, [openModal, resetState, companyHq])

	return (
		<>
			<Dialog
				open={openModal}
				aria-labelledby="form-dialog-title"
				fullWidth
				maxWidth="md"
				scroll="body"
				disableBackdropClick
				disableEscapeKeyDown
			>
				<DialogContent style={{ overflow: 'hidden' }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								id="name"
								label={t('Name')}
								value={state.name}
								onChange={handleStateChange('name')}
								type="text"
								className={classes.textInputRoot}
								InputLabelProps={{
									shrink: true,
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								id="dateFounded"
								label={t('Date founded')}
								value={state.dateFounded}
								onChange={handleStateChange('dateFounded')}
								type="text"
								className={classes.textInputRoot}
								InputLabelProps={{
									shrink: true,
								}}
								margin="normal"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignSelf: 'center',
							}}
						>
							<Autosuggest
								{...autosuggestProps}
								inputProps={{
									// @ts-ignore
									classes,
									id: 'react-autosuggest-simple',
									label: 'Industry',
									placeholder: t('Industry search'),
									value: state.industry,
									onChange: handleIndustryChange,
								}}
								theme={{
									container: classes.industryContainer,
									suggestionsContainerOpen:
										classes.suggestionsContainerOpen,
									suggestionsList: classes.suggestionsList,
									suggestion: classes.suggestion,
								}}
								renderSuggestionsContainer={(options: any) => (
									<Paper {...options.containerProps} square>
										{options.children}
									</Paper>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								id="website"
								label={t('Website')}
								value={state.website}
								onChange={handleStateChange('website')}
								type="text"
								className={classes.textInputRoot}
								InputLabelProps={{
									shrink: true,
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								id="phones"
								label={t('Phone')}
								value={state.phones}
								onChange={handleStateChange('phones')}
								type="text"
								className={classes.textInputRoot}
								InputLabelProps={{
									shrink: true,
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								id="email"
								label={t('Email')}
								value={state.emails}
								onChange={handleStateChange('emails')}
								type="text"
								className={classes.textInputRoot}
								InputLabelProps={{
									shrink: true,
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Typography
								color="textSecondary"
								display="block"
								variant="caption"
							>
								{t('Headquarters')}
							</Typography>
							<Divider variant="fullWidth" />
						</Grid>
						{
							<>
								{enableEditAddress && (
									<>
										<Grid item xs={12} sm={6} md={4}>
											<TextField
												label={'Street'}
												value={streetName}
												placeholder={'Street'}
												className={
													classes.textInputRoot
												}
												InputLabelProps={{
													className:
														classes.labelTextField,
												}}
												InputProps={{
													className: classes.input,
												}}
												margin="normal"
												onChange={updateStreetName}
												fullWidth
												// error={
												// 	checkRequiredHqFields.streetName
												// }
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<FormControl
												className={classes.formControl}
											>
												<InputLabel htmlFor="company-region">
													{t('Region')}
												</InputLabel>
												<Select
													value={region ? region : ''}
													onChange={
														handleRegionChange
													}
													inputProps={{
														name: t('Region'),
														id: 'experience-region',
													}}
													error={
														checkRequiredHqFields.region
													}
												>
													{regions &&
														regions.roLocations &&
														regions.roLocations.map(
															(item: any) => {
																return (
																	<MenuItem
																		key={
																			item.id
																		}
																		value={
																			item.name
																		}
																	>
																		{
																			item.name
																		}
																	</MenuItem>
																)
															},
														)}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<FormControl
												className={classes.formControl}
											>
												<InputLabel htmlFor="company-location">
													{t('Location')}
												</InputLabel>
												<Select
													value={
														locationId
															? locationId
															: ''
													}
													disabled={!region}
													onChange={
														handleLocationChange
													}
													inputProps={{
														name: t('Location'),
														id:
															'experience-location',
													}}
													error={
														checkRequiredHqFields.locationId
													}
												>
													{locations &&
														locations.roLocations &&
														locations.roLocations.map(
															(item: any) => {
																return (
																	<MenuItem
																		key={
																			item.id
																		}
																		value={
																			item.id
																		}
																	>
																		{
																			item.name
																		}
																	</MenuItem>
																)
															},
														)}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<TextField
												label={'Zip code'}
												value={zipCode}
												placeholder={'State'}
												className={classes.textField}
												InputLabelProps={{
													className:
														classes.labelTextField,
												}}
												InputProps={{
													className: classes.input,
												}}
												margin="normal"
												onChange={updateZipCode}
												fullWidth
											/>
										</Grid>
									</>
								)}
								{!enableEditAddress && displayedAddress && (
									<Grid item xs={12} sm={12} md={12}>
										{displayedAddress}
									</Grid>
								)}
								<Grid item xs={12} sm={12} md={12}>
									{/* <span
										className={classes.dialogButton}
										onClick={() =>
											handleValidateHqForm('done')
										}
									>
										{!enableEditAddress ? 'Edit' : 'Done'}
									</span> */}
									<button
										className={classes.submitBtn}
										onClick={() =>
											handleValidateHqForm('done')
										}
									>
										{!enableEditAddress ? 'Edit' : 'Done'}
									</button>

									{enableEditAddress && (
										// <span
										// 	className={classes.dialogButton}
										// 	onClick={() =>
										// 		handleValidateHqForm('cancel')
										// 	}
										// >
										// 	{t('Cancel')}
										// </span>
										<button
											className={classes.submitBtn}
											onClick={() =>
												handleValidateHqForm('cancel')
											}
										>
											{t('Cancel')}
										</button>
									)}
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<FormControl
										className={classes.formControl}
									>
										<InputLabel id="demo-simple-select-helper-label">
											{t('Change the headquarters')}
										</InputLabel>
										<Select
											labelId="demo-simple-select-helper-label"
											id="demo-simple-select-helper"
											value={companyHqId}
											onChange={handleChangeCompanyHq}
											name="headquarters"
										>
											{companyOffices &&
												companyOffices.map(
													(
														item: any,
														index: number,
													) => {
														// build the address
														let selectedLocation = `${item.location.region2}, ${item.location.name}`

														let displayedAddress = `${
															item.address
																? item.address +
																  ', '
																: ''
														} ${
															item.zipCode
																? item.zipCode +
																  ', '
																: ''
														} 
									${selectedLocation}`
														return (
															<MenuItem
																classes={{
																	root:
																		classes.selectItem,
																}}
																key={`locations_${index}`}
																value={item.id}
															>
																{
																	displayedAddress
																}
															</MenuItem>
														)
													},
												)}
										</Select>
									</FormControl>
								</Grid>
							</>
						}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleToggleModal} color="primary">
						{t('Cancel')}
					</Button>
					<Button onClick={save} color="primary">
						{t('Save')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default memo(InformationsDialog)
