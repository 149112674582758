import gql from 'graphql-tag'

import { pageInfoData } from './common'

export const standardTeamResult = gql`
	fragment standardTeamResult on Team {
		id
		name
		slug
		description
		department {
			id
			key
			roTitle
			enTitle
			roCategory
			enCategory
		}
		noOfMembers
		members {
			id
			user {
				id
				uid
				profile {
					id
					aboutMe
					email
					firstName
					lastName
					hasAvatar
					avatarPath
					hasCover
					coverPath
					worksAt {
						id
						company
						position
					}
				}
			}
		}
		disabilityRatio
		averageAge
		maleRatio
		femaleRatio
		coverContentType
		coverPath
		coverBackground
		hasLogo
		logoContentType
		logoPath
		hasCover
		featuredVideo
		locations {
			id
			address
			location {
				id
				name
				region2
			}
			zipCode
		}
		company {
			id
			name
			slug
			hasLogo
			logoPath
			hasCover
			coverPath
			owner {
				id
			}
			industry {
				id
				key
			}
			offices {
				id
				address
				zipCode
				location {
					id
					name
					region2
				}
				hq
			}
		}
	}
`

export const TeamMemberFragment = gql`
	fragment TeamMember on User {
		id
		profile {
			...UserProfile
		}
		_ig
		_ft
	}
`

export const teamConnectionData = gql`
	fragment teamConnectionData on TeamsConnection {
		edges {
			node {
				...standardTeamResult
			}
			cursor
		}
		pageInfo {
			...pageInfoData
		}
	}
	${standardTeamResult}
	${pageInfoData}
`
