import gql from 'graphql-tag'

export const RoLocations = gql`
	query roLocations($data: roLocationsInput) {
		roLocations(data: $data) {
			id
			type
			name
			region2
		}
	}
`

export const AddCompanyLocations = gql`
	query roLocations($data: roLocationsInput) {
		roLocations(data: $data) {
			id
			name
		}
	}
`
