import { useState, useEffect, useCallback, useRef } from 'react'
import axios from 'axios'
import cuid from 'cuid'

import {
	s3BucketURL,
	articlesFolder,
	companiesFolder,
	profilesFolder,
	teamsFolder,
	jobsFolder,
} from './s3'
import { ICompany, IUser, ITeam } from 'models'
import { SIGN_REQUEST_URIS } from '.'

export const buildUserCvFile = (userId: string, cvFilePath: string) =>
	`${s3BucketURL}/${profilesFolder}/${userId}/${cvFilePath}`

export const buildUserAvatar = (userId: string, avatarPath: string) =>
	`${s3BucketURL}/${profilesFolder}/${userId}/${avatarPath}`

export const buildUserCover = (user: IUser) =>
	`${s3BucketURL}/${profilesFolder}/${user.id}/${user.profile.coverPath}`

export const buildCompanyCover = (company: ICompany) =>
	`${s3BucketURL}/${companiesFolder}/${company.id}/${company.coverPath}`

export const buildCompanyLogo = (companyId: string, imageName: string) =>
	`${s3BucketURL}/${companiesFolder}/${companyId}/${imageName}`

export const buildTeamCover = (team: ITeam) =>
	`${s3BucketURL}/${teamsFolder}/${team.id}/${team.coverPath}`

export const buildTeamLogo = (teamId: string, imageName: string) =>
	`${s3BucketURL}/${teamsFolder}/${teamId}/${imageName}`

export const buildArticleImage = (articlId: string, imageName: string) =>
	`${s3BucketURL}/${articlesFolder}/${articlId}/${imageName}`

export const buildJobImage = (jobId: string, imageName: string) =>
	`${s3BucketURL}/${jobsFolder}/${jobId}/${imageName}`

export const getExtension = (path: string) => {
	var basename: string | undefined = path.split(/[\\/]/).pop(), // extract file name from full path ...
		// (supports `\\` and `/` separators)
		pos = basename && basename.lastIndexOf('.') // get last position of `.`

	if (basename === '' || !pos || pos < 1)
		// if file name is empty or ...
		return '' //  `.` not found (-1) or comes first (0)

	return basename && basename.slice(pos + 1) // extract extension ignoring `.`
}

export const useDebounce: React.FunctionComponent<any> = (value, delay) => {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(() => {
		// Set debouncedValue to value (passed in) after the specified delay
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		// Return a cleanup function that will be called every time ...
		// ... useEffect is re-called. useEffect will only be re-called ...
		// ... if value changes (see the inputs array below).
		// This is how we prevent debouncedValue from changing if value is ...
		// ... changed within the delay period. Timeout gets cleared and restarted.
		// To put it in context, if the user is typing within our app's ...
		// ... search box, we don't want the debouncedValue to update until ...
		// ... they've stopped typing for more than 500ms.
		return () => {
			clearTimeout(handler)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]) // ... need to be able to change that dynamically. // You could also add the "delay" var to inputs array if you ... // Only re-call effect if value changes

	return debouncedValue
}

export const useDebounceCallback = (callback: any, delay: any) => {
	const latestCallback: any = useRef()
	const [callCount, setCallCount] = useState(0)

	useEffect(() => {
		latestCallback.current = callback
	}, [callback])

	useEffect(() => {
		if (callCount > 0) {
			const fire = () => {
				setCallCount(0)
				latestCallback.current()
			}

			const id = setTimeout(fire, delay)
			return () => clearTimeout(id)
		}
	}, [callCount, delay])

	return (cb: any) => setCallCount(callCount => callCount + 1)
}

export const capitalize = (s: string) => {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
}

export const useS3Upload = (
	id: string | undefined,
	type: string,
	userId: string | null,
) => {
	const [fileId] = useState(cuid())
	const [percent, update] = useState(0)
	const run = useCallback(
		(
			sourceFile: any,
			fileExtension: string,
			complete: () => void,
			error: () => void,
		) => {
			const acceptedTypes = [
				'image/jpeg',
				'image/jpg',
				'image/png',
				'image/tiff',
				'image/bmp',
				'image/tga',
				'image/gif',
			]

			if (
				!id ||
				!userId ||
				!sourceFile ||
				(sourceFile &&
					sourceFile.type &&
					!acceptedTypes.includes(sourceFile.type))
			) {
				alert('Sorry ... invalid data provided')
				error()
				return
			}
			let blob
			if (typeof sourceFile === 'object') blob = sourceFile
			else blob = sourceFile.slice(0, sourceFile.size, sourceFile.type)
			const file = new File([blob], `${fileId}${fileExtension}`, {
				type: sourceFile.type,
			})
			const params = {
				userId: userId,
				fileName: file && file.name,
				contentType: file && file.type,
				id,
				type,
				environment: process.env.REACT_APP_ENVIRONMENT,
			}
			axios({
				method: 'post',
				// @ts-ignore
				url: SIGN_REQUEST_URIS[process.env.REACT_APP_ENVIRONMENT],
				data: params,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			})
				.then(({ data: { signedUrl } }: any) => {
					var options = {
						headers: {
							'Content-Type': file && file.type,
						},
						onUploadProgress: (progressEvent: any) => {
							let newPercent: any =
								(progressEvent.loaded / progressEvent.total) *
								100
							if (newPercent > 100) newPercent = 100
							update(newPercent.toFixed(0))
						},
					}
					axios
						.put(signedUrl, file, options)
						.then(complete)
						.catch((error: any) => {
							console.error(error)
						})
				})
				.catch((error: any) => {
					console.error(error)
				})
		},
		[fileId, update, id, type, userId],
	)

	return {
		fileId,
		percent,
		run,
	}
}

export const createImage: (url: string) => Promise<HTMLImageElement> = (
	url: string,
) =>
	new Promise((resolve, reject) => {
		const image = new Image()
		image.addEventListener('load', () => resolve(image))
		image.addEventListener('error', error => reject(error))
		image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
		image.src = url
	})

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export const getCroppedImg: (
	imageSrc: string,
	pixelCrop: { width: number; height: number; x: number; y: number },
	imageExtension?: string,
) => Promise<Blob | null> = async (
	imageSrc: string,
	pixelCrop: { width: number; height: number; x: number; y: number },
	imageExtension?: string,
) => {
	const image: HTMLImageElement = await createImage(imageSrc)
	const canvas: HTMLCanvasElement = document.createElement('canvas')
	canvas.width = pixelCrop.width
	canvas.height = pixelCrop.height
	const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')

	if (ctx) {
		ctx.fillStyle = '#ffffff'
		ctx.fillRect(0, 0, pixelCrop.width, pixelCrop.height)
		ctx.drawImage(
			image,
			pixelCrop.x,
			pixelCrop.y,
			pixelCrop.width,
			pixelCrop.height,
			0,
			0,
			pixelCrop.width,
			pixelCrop.height,
		)
	}

	// As Base64 string
	// return canvas.toDataURL('image/jpeg');

	let imageType = imageExtension ? `image/${imageExtension}` : 'image/jpeg'
	// As a blob
	return new Promise((resolve, reject) => {
		canvas.toBlob(file => {
			resolve(file)
		}, imageType)
	})
}

export const ConditionalWrapper = ({
	condition,
	wrapper,
	children,
}: {
	condition: boolean
	wrapper: any
	children: any
}) => (condition ? wrapper(children) : children)

export const cleanHeaderName = (name?: string) =>
	name
		? name
				.replace(/\$/gi, '-')
				.replace(/\./gi, '--')
				.replace(/\//gi, '---')
				.toLowerCase()
		: ''

export const truncateText = (str: string, no_words: number) => {
	return str.split(' ').splice(0, no_words).join(' ')
}

export const groupBy = (list: [], keyGetter: Function) => {
	const map = new Map()
	list.forEach((item: any) => {
		const key = keyGetter(item)
		const collection = map.get(key)
		if (!collection) {
			map.set(key, [item])
		} else {
			collection.push(item)
		}
	})
	return map
}

export const combineStyles = (...styles: any) => {
	return function CombineStyles(theme: any) {
		const outStyles = styles.map((arg: any) => {
			// Apply the "theme" object for style functions.
			if (typeof arg === 'function') {
				return arg(theme)
			}
			// Objects need no change.
			return arg
		})

		return outStyles.reduce((acc: any, val: any) => Object.assign(acc, val))
	}
}

export const scrollTopContainer = (element: any, duration: number = 20) => {
	const intervalId = setInterval(() => {
		if (element.scrollTop > 0) {
			element.scrollTop = element.scrollTop - 50
		} else {
			clearInterval(intervalId)
		}
	}, duration)
}

export const replaceUnicodeToHTMLEntities = (matched: string) => {
	const mapObj: { [key: string]: string } = {
		'&amp;': '&',
	}
	return mapObj[matched]
}

export const TOKEN_HEADER =
	'$2y$12$iL6jraxFdRgCTGc9L7q5COQIIVMgIgEopV7WT52XDj3.OxLUhMwEK'
export const REFRESH_TOKEN_HEADER =
	'$2y$12$jyCIIA8HOWqIby7C9qUNWOo7SDJa/XBsP3oIdKJtvmLjvOwouLUJa'
