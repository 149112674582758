import React, { useCallback, useState, memo } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation, Trans, Translation } from 'react-i18next'
import {
	FormControl,
	Grid,
	TextField,
	useMediaQuery,
	InputLabel,
	Input,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import * as _ from 'lodash'
import classNames from 'classnames'
import Datetime from 'react-datetime'
import moment from 'moment'

import styles from './styles'
import { combineStyles } from 'utils'
import generalStyles from 'screens/search/styles'

const combinedStyles: any = combineStyles(generalStyles, styles)
const useStyles = makeStyles(combinedStyles)

const JobApplicantsFilterSection: React.FunctionComponent<any> = ({
	handleFilterData,
	toggleDrawer,
	filterData,
}) => {
	const theme = useTheme()
	const classes = useStyles()
	const { t } = useTranslation()
	const isSmDown: boolean = useMediaQuery(theme.breakpoints.down('sm'))
	const [name, setName] = useState<String>(filterData.name)

	const delayedTitleQuery = useCallback(
		_.debounce((name: string) => handleFilterData('name', name), 500),
		[name],
	)

	const filterByTitle = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setName(event.target.value)
			//_.debounce(handleCompanyName(event.target.value), 500)
			delayedTitleQuery(event.target.value)
		},
		[delayedTitleQuery],
	)

	const handleApplicationDateStart = useCallback(
		dt => {
			if (dt && moment.isMoment(dt))
				handleFilterData('applicationDateStart', moment(dt).format())
			else {
				handleFilterData('applicationDateStart', '')
			}
		},
		[handleFilterData],
	)

	const handleApplicationDateEnd = useCallback(
		dt => {
			if (dt && moment.isMoment(dt))
				handleFilterData('applicationDateEnd', moment(dt).endOf('day'))
			else {
				handleFilterData('applicationDateEnd', '')
			}
		},
		[handleFilterData],
	)

	return (
		<Grid
			container
			direction={'column'}
			spacing={1}
			className={classes.filterWrapper}
		>
			<Grid item xs={12}>
				<div
					className={classNames(
						classes.searchSectionTitle,
						classes.searchSection,
					)}
				>
					{isSmDown && (
						<div>
							<ArrowBackIcon onClick={toggleDrawer(false)} />
						</div>
					)}
					<div>
						<Trans key="advancedSearchTitle">
							{t('Advanced')}&nbsp;
							<span className={classes.searchSectionTitleMain}>
								{t('Search-1')}
							</span>
						</Trans>
					</div>
				</div>
			</Grid>
			<Grid item xs={12}>
				<FormControl className={classes.formControl}>
					<TextField
						id="name"
						label={t('Applicant Name')}
						variant="outlined"
						InputProps={{
							classes: {
								root: classes.outlinedInputRoot,
								notchedOutline: classes.notchedOutline,
							},
						}}
						inputProps={{
							style: {
								padding: '10px 5px',
							},
						}}
						InputLabelProps={{
							classes: {
								outlined: classes.outlinedLabel,
								root: classes.notchedOutlineLabel,
							},
						}}
						onChange={filterByTitle}
						value={name}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<Datetime
					className={classes.inputDateTime}
					timeFormat={false}
					closeOnSelect={true}
					onChange={handleApplicationDateStart}
					input={true}
					defaultValue={
						filterData.applicationDateStart &&
						moment(filterData.applicationDateStart).format(
							'YYYY-MM-DD',
						)
					}
					dateFormat={'YYYY-MM-DD'}
					renderInput={(props: any, open: any, close: any) => {
						return (
							<FormControl
								style={{
									width: '100%',
								}}
								className={classes.formControl}
							>
								<InputLabel htmlFor="application_date_start">
									<Translation key="experienceDate">
										{t => (
											<div
												className={classNames(
													classes.labelField,
												)}
											>
												{t(
													'Application date (start date)',
												)}
											</div>
										)}
									</Translation>
								</InputLabel>
								<Input
									name="application_date_start"
									{...props}
									onClick={open}
								/>
							</FormControl>
						)
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<Datetime
					className={classes.inputDateTime}
					timeFormat={false}
					closeOnSelect={true}
					onChange={handleApplicationDateEnd}
					input={true}
					defaultValue={
						filterData.applicationDateEnd &&
						moment(filterData.applicationDateEnd).format(
							'YYYY-MM-DD',
						)
					}
					dateFormat={'YYYY-MM-DD'}
					renderInput={(props: any, open: any, close: any) => {
						return (
							<FormControl
								style={{
									width: '100%',
								}}
								className={classes.formControl}
							>
								<InputLabel htmlFor="application_date_start">
									<Translation key="experienceDate">
										{t => (
											<div
												className={classNames(
													classes.labelField,
												)}
											>
												{t(
													'Application date (end date)',
												)}
											</div>
										)}
									</Translation>
								</InputLabel>
								<Input
									name="application_date_end"
									{...props}
									onClick={open}
								/>
							</FormControl>
						)
					}}
				/>
			</Grid>
		</Grid>
	)
}

export default memo(JobApplicantsFilterSection)
