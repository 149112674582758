import gql from 'graphql-tag'

export const GetChillPackCompanies = gql`
	query getChillPackCompanies {
		getChillPackCompanies {
			id
			name
		}
	}
`

export const ExportChillPackRecipients = gql`
	query exportChillPackRecipients {
		exportChillPackRecipients {
			id
			email
			chillPackRecipientClaim
			createdAt
			recipientLastName
			recipientFirstName
			companyName
		}
	}
`

// MUATTIONS

export const AddChillPackRecipient = gql`
	mutation addChillPackRecipient($data: ChillPackRecipientInput!) {
		addChillPackRecipient(data: $data) {
			firstName
			lastName
			email
			company {
				id
				name
			}
		}
	}
`
export const ResendQRCodeForChillPack = gql`
	mutation resendQRCodeForChillPack($email: String!) {
		resendQRCodeForChillPack(email: $email) {
			code
		}
	}
`
export const ConfirmEmailForChillPackRecipient = gql`
	mutation confirmEmailForChillPackRecipient(
		$chillPackRecipientId: String!
		$pin: String!
	) {
		confirmEmailForChillPackRecipient(
			chillPackRecipientId: $chillPackRecipientId
			pin: $pin
		) {
			id
			firstName
			lastName
			email
		}
	}
`
