import React, { memo, useCallback, useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import styles from '../styles'
import { UserQuery, StoreValue } from 'store'
import { ICommentProps, EEntity } from 'models'
import { useDeleteComment } from 'components/firebase'
import { EntityLogo } from 'components'

const useStyles = makeStyles(styles)

const CommentView: React.FunctionComponent<ICommentProps> = ({
	data: { date, text, userId, id: commentId },
	articleId,
	onDelete,
	storeUserId,
}) => {
	const [deletting, setDeletting] = useState(false)
	const [handleDeleteComment] = useDeleteComment(articleId)
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const id = open ? 'comment-menu' : undefined
	const { t } = useTranslation()
	const classes = useStyles()
	const { data: { user } = { user: null }, loading }: any = useQuery(
		UserQuery,
		{
			variables: {
				id: userId,
			},
		},
	)
	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			setAnchorEl(event.currentTarget)
		},
		[setAnchorEl],
	)

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [setAnchorEl])

	const deleteComment = useCallback(async () => {
		setDeletting(true)
		try {
			await handleDeleteComment({ id: commentId })
			onDelete(commentId)
		} catch (error) {
			console.error(error)
			setAnchorEl(null)
			setDeletting(false)
		}
	}, [setAnchorEl, handleDeleteComment, commentId, onDelete])

	return (
		<div
			className={classes.commnetContainer}
			style={{ opacity: deletting ? 0.2 : 1 }}
		>
			<div className={classes.commentHeader}>
				<EntityLogo
					type={EEntity.person}
					extraAvatarClasses={classes.avatar}
					user={user}
				/>
				<div className={classes.userInfo}>
					<div>
						{(user && user.profile && user.profile.firstName) || (
							<div
								style={{
									width: 50,
									background: '#ebebeb',
									minHeight: 18,
									display: 'inline-block',
								}}
							/>
						)}{' '}
						{(user && user.profile && user.profile.lastName) || (
							<div
								style={{
									width: 50,
									background: '#ebebeb',
									minHeight: 18,
									display: 'inline-block',
								}}
							/>
						)}
					</div>
					<div className={classes.extraInfo}>
						<span>{t('Works at')}</span>
						<span>
							{loading ? (
								<div
									style={{
										width: 50,
										background: '#ebebeb',
										minHeight: 18,
										display: 'inline-block',
									}}
								/>
							) : (
								(user &&
									user.profile &&
									user.profile.worksAt &&
									user.profile.worksAt.company) ||
								'n/a'
							)}
						</span>
						<span className="postDate">
							{moment(
								(date && date.seconds * 1000) || date,
							).fromNow()}
						</span>
					</div>
				</div>
			</div>
			<div className={classes.text}>{text}</div>
			{storeUserId === userId && (
				<>
					<IconButton
						aria-describedby={id}
						aria-label="More"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={handleClick}
						classes={{
							root: classes.commentMoreButton,
						}}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id={id}
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleClose}
						classes={{
							paper: classes.commentMoreMenu,
						}}
						PaperProps={{
							style: {
								// maxHeight: 24 * 4.5,
								width: 'auto',
							},
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						getContentAnchorEl={null}
					>
						<MenuItem
							// selected={option === 'Pyxis'}
							onClick={deleteComment}
						>
							{t('Delete comment')}
						</MenuItem>
					</Menu>
				</>
			)}
		</div>
	)
}

const Comment = (props: ICommentProps) => (
	<StoreValue keys={['user.id|storeUserId']}>
		<CommentView {...props} />
	</StoreValue>
)
export default memo(Comment)
