import React, { memo, useState, useCallback, useMemo, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
// import { useQuery } from '@apollo/react-hooks'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import UserAvatar from '@material-ui/icons/AccountCircleOutlined'
import TeamIcon from '@material-ui/icons/PeopleOutline'

// import DefaultLogo from 'assets/images/logo.png'
import styles from './styles'
import {
	buildCompanyLogo,
	buildUserAvatar,
	buildTeamLogo /*capitalize*/,
} from 'utils'
import { StoreValue } from 'store'
import { UploadCrop } from 'components'
import { EEntity } from 'models'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import AddAPhoto from '@material-ui/icons/AddAPhotoOutlined'

// import UserAvatar from '@material-ui/icons/AccountCircleOutlined'
// import CompanyLogo from 'assets/images/company-generic-logo.png'

const useStyles = makeStyles(styles)

const EntityLogo: React.FunctionComponent<any> = ({
	type,
	alt,
	company,
	team,
	user,
	extraAvatarClasses,
	fullView,
	disabledEdit = true,
	onAdd = null,
	isAvatarUploaded = false,
	handleAvatarUploadedState,
	showUploadCrop = false,
}) => {
	const classes = useStyles({})
	// const { data }: any = useQuery(UserQuery, {
	// 	skip: !user,
	// 	variables: {
	// 		id: user && user.id,
	// 	},
	// })
	// const userProfile = (data && data.user) || null
	const [percent, setPercent] = useState<number>(0)
	const [show, setShow] = useState(false)
	if (percent === 100 && onAdd) {
		handleAvatarUploadedState()
	}

	const toggleShow = useCallback(() => {
		if (!disabledEdit) setShow(show => !show)
	}, [disabledEdit])

	const avatarSrc = useMemo(() => {
		switch (type) {
			// case EEntity.person:
			// 	return UserAvatar
			case EEntity.company:
				return onAdd && !isAvatarUploaded
					? null
					: company && company.id
					? buildCompanyLogo(
							(company && company.id) || '',
							(company && company.hasLogo && company.logoPath) ||
								'company-logo-original.jpg',
					  )
					: null
			case EEntity.team:
				return team && team.id && team.hasLogo
					? buildTeamLogo(
							(team && team.id) || '',
							(team && team.hasLogo && team.logoPath) ||
								'team-logo-original.jpg',
					  )
					: null
			default:
				return undefined
		}
	}, [type, company, team, onAdd, isAvatarUploaded])
	const updatePercent = useCallback(value => {
		const parsedValue = parseInt(value, 10)
		setPercent(parsedValue)
	}, [])

	useEffect(() => {
		if (showUploadCrop) {
			toggleShow()
		}
	}, [showUploadCrop, toggleShow])

	return (
		<>
			{Boolean(percent) && (
				<div
					className={classNames([
						fullView
							? classes.avatarFullViewContainer
							: classes.avatarContainer,
						extraAvatarClasses,
					])}
				>
					<CircularProgress
						thickness={1.5}
						variant="static"
						value={percent || 0}
						classes={{
							root: classes.loader,
						}}
					/>
					<span style={{ position: 'absolute' }}>{percent}</span>
				</div>
			)}
			{!Boolean(percent) &&
				(onAdd && !isAvatarUploaded ? (
					<IconButton
						onClick={toggleShow}
						className={classes.uploadBtn}
						aria-label="Add company foto"
					>
						<AddAPhoto fontSize="default" />
					</IconButton>
				) : (
					<Avatar
						alt={alt}
						src={
							(type === EEntity.company && avatarSrc) ||
							(type === EEntity.team && avatarSrc) ||
							(type === EEntity.person && user
								? user.profile &&
								  user.profile.hasAvatar &&
								  buildUserAvatar(
										user.id,
										user.profile.avatarPath,
								  )
								: '')
						}
						onClick={toggleShow}
						className={classNames([
							fullView
								? classes.avatarFullViewContainer
								: classes.avatarContainer,
							extraAvatarClasses,
						])}
						imgProps={{
							style:
								type === EEntity.company ||
								type === EEntity.team // TODO: Talk with Rares about this line
									? {
											objectFit: 'contain',
											backgroundColor: 'rgba(0, 0, 0, 0)',
									  }
									: undefined,
						}}
					>
						{type === EEntity.person &&
						(!user || !user.profile || !user.profile.hasAvatar) ? (
							<UserAvatar />
						) : null}
						{type === EEntity.team && (!team || !team.hasLogo) ? (
							<TeamIcon />
						) : null}
					</Avatar>
				))}
			{!disabledEdit && (
				<UploadCrop
					open={show}
					handleClose={toggleShow}
					updatePercent={updatePercent}
					type={type}
					user={user}
					company={company}
					team={team}
					onAdd={onAdd}
				/>
			)}
		</>
	)
}

export default memo((props: any) => (
	<StoreValue keys={['user|storeUser']}>
		<EntityLogo {...props} />
	</StoreValue>
))
