// import { setCookie } from '.'
import { EAppStateActionTypes, IAppStateSearch } from 'models'

export default (state: IAppStateSearch, action: any): IAppStateSearch => {
	let newState = state
	switch (action.type) {
		case EAppStateActionTypes.updateQuery:
			newState = {
				...state,
				query: action.query,
			}
			break
		case EAppStateActionTypes.updateSearchLoading:
			newState = {
				...state,
				loading: action.loading,
			}
			break
		case EAppStateActionTypes.updateDebounceQuery:
			newState = {
				...state,
				debounceQuery: action.debounceQuery,
			}
			break
		case EAppStateActionTypes.updateSearchSection:
			newState = {
				...state,
				section: action.section,
			}
			break
		default:
			break
	}
	// setCookie(newState)

	return newState
}
