import React, { memo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Card, CardContent, IconButton } from '@material-ui/core'
import { useTranslation, Trans } from 'react-i18next'
import { KeyboardArrowRight } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import styles from './styles'
import { combineStyles } from 'utils'
import generalStyles from 'styles/common'
import classNames from 'classnames'

const EntityTeamLink: React.FunctionComponent<any> = props => {
	const { toggle, teamName } = props
	const { t, i18n } = useTranslation()
	const combinedStyles: any = combineStyles(generalStyles, styles)
	const useStyles = makeStyles(combinedStyles)
	const classes = useStyles()
	const history = useHistory()

	const setTab = useCallback(
		(tabIndex: 'index' | 'posts') => () => {
			toggle(tabIndex)
		},
		[toggle],
	)

	const handleSeeJobs = useCallback(() => {
		let pathname = `/${i18n.language}/jobs`
		history.push(pathname, { teamName })
	}, [i18n, history, teamName])

	return (
		<React.Fragment>
			<Grid
				container
				style={{ maxWidth: '670px', marginTop: '20px' }}
				spacing={2}
			>
				<Grid item xs={12} sm={8}>
					<Card classes={{ root: classNames(classes.boxRoot, classes.boxShadow)}}>
						<CardContent
							classes={{ root: classes.cardGray }}
							onClick={handleSeeJobs}
						>
							<div className={classes.details}>
								<span className={classes.detailsTitle}>
									<span className={classes.mainTitle}>
										{
											<Trans key="discoverOurJobs">
												{t('Discover our')}
												<br />
												{t('jobs')}
											</Trans>
										}
									</span>
								</span>
								<IconButton className={classes.arrowRightIcon}>
									<KeyboardArrowRight
										className={classes.arrowBlue}
										style={{ fontSize: 30 }}
									/>
								</IconButton>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Card classes={{ root: classNames(classes.boxRoot, classes.boxShadow) }}>
						<CardContent
							classes={{ root: classes.cardContentRoot }}
						>
							<div
								onClick={setTab('posts')}
								className={classes.details}
							>
								<span className={classes.detailsTitle}>
									<span className={classes.mainTitle}>
										{t('Our')}
									</span>
									<span className={classes.mainTitle}>
										{t('latest posts')}
									</span>
								</span>
								<IconButton className={classes.arrowRightIcon}>
									<KeyboardArrowRight
										className={classes.arrowBlue}
										style={{ fontSize: 30 }}
									/>
								</IconButton>
							</div>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}
export default memo(EntityTeamLink)
