import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		jobApplicantItem: {
			border: '1px solid #ebebeb',
			borderRadius: '7px',
			margin: '25px 0px',
			position: 'relative',
			// '&:hover': {
			// 	cursor: 'pointer',
			// },
			backgroundColor: '#ffffff',
		},
		jobResourceContainer: {
			maxHeight: '260px',
			borderRadius: '8px 8px 0px 0px',
			[theme.breakpoints.up('sm')]: {
				borderRadius: '8px 0px 0px 8px',
			},
		},

		jobApplicantItemInformation: {
			padding: theme.spacing(2),
			position: 'relative',
			maxHeight: '260px',
			[theme.breakpoints.down('sm')]: {
				paddingTop: '10px',
			},
		},
		infoRow: {
			marginBottom: 5,
			display: 'flex',
			flexDirection: 'row',
			'& .label': {
				fontSize: '16px',
				fontWeight: 'bold',
				color: '#424242',
				marginRight: theme.spacing(1),
			},
			'& .data': {
				fontSize: '15px',
				color: '#424242',
			},
		},
	})
