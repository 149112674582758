import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		profileArticleItem: {
			border: '1px solid #ebebeb',
			// borderRadius: '7px',
			margin: '15px 0px',
			overflow: 'hidden',
			'&:hover': {
				cursor: 'pointer',
			},
			borderRadius: '5px',
			[theme.breakpoints.down('sm')]: {
				borderBottomLeftRadius: '5px',
				borderBottomRightRadius: '5px',
			},
		},
		profileArticleResourceContainer: {
			maxHeight: '260px',
			overflow: 'hidden',
			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
			borderTopLeftRadius: '5px',
			borderBottomLeftRadius: '5px',
			[theme.breakpoints.down('sm')]: {
				borderTopLeftRadius: '5px',
				borderTopRightRadius: '5px',
				borderBottomLeftRadius: '0px',
			},
		},
		profileArticleResourceContainerReverse: {
			maxHeight: '260px',
			overflow: 'hidden',
			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
			borderTopRightRadius: '5px',
			borderBottomRightRadius: '5px',
			[theme.breakpoints.down('sm')]: {
				borderTopRightRadius: '5px',
				borderTopLeftRadius: '5px',
				borderBottomLeftRadius: '0px',
				borderBottomRightRadius: '0px',
			},
		},
		profileArticleItemInformation: {
			padding: '10px',
			maxHeight: '260px',
			backgroundColor: '#ffffff',
			borderTopRightRadius: '5px',
			borderBottomRightRadius: '5px',
			[theme.breakpoints.down('sm')]: {
				borderTopLeftRadius: '5px',
				borderBottomLeftRadius: '5px',
				borderBottomRightRadius: '5px',
			},
		},
		profileArticleItemInformationReverse: {
			padding: '10px',
			maxHeight: '260px',
			backgroundColor: '#ffffff',
			borderTopLeftRadius: '5px',
			borderBottomLeftRadius: '5px',
			[theme.breakpoints.down('sm')]: {
				borderTopLeftRadius: '0px',
				borderBottomLeftRadius: '5px',
				borderBottomRightRadius: '5px',
			},
		},
		profileArticleItemTitle: {
			fontSize: '16px',
			color: '#424242',
			marginBottom: '0px',
			width: '100%',
			fontWeight: 700
		},
		profileArticleItemDescription: {
			fontSize: '14px',
			fontWeight: 300,
			overflowWrap: 'break-word',
			width: '100%',
		},
		video: {
			display: 'flex',
			'&:hover': {
				cursor: 'pointer',
			},
			position: 'relative',
			paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
			'& .player': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				'& iframe': {
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
				},
			},
		},
		player: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			'& iframe': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},
		},
		editIconLabel: {
			paddingLeft: 6,
			paddingRight: 6,
		},
		editRootElement: {
			position: 'absolute',
			//top: -18,
			right: -40,
			[theme.breakpoints.down('md')]: {
				top: 10,
				right: 45,
				[theme.breakpoints.only('xs')]: {
					top: 5,
					width: 30,
					height: 30,
					'& svg': {
						fontSize: '1.2rem',
					},
				},
			},
		},
		deleteRootElement: {
			position: 'absolute',
			// top: 5,
			right: -80,
			[theme.breakpoints.down('md')]: {
				top: 10,
				right: 5,
				[theme.breakpoints.only('xs')]: {
					top: 5,
					width: 30,
					height: 30,
					'& svg': {
						fontSize: '1.2rem',
					},
				},
			},
		},
		seeMore: {
			fontStyle: 'italic',
			fontWeight: 'bold',
		},
	})
