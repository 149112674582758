import gql from 'graphql-tag'

import { UserRoleFragment } from 'store/fragments'

// QUERIES
export const GetCompanyUsersRoles = gql`
	query getCompanyUsersRoles($companyId: String) {
		getCompanyUsersRoles(companyId: $companyId) {
			...UserRoleFragment
		}
	}
	${UserRoleFragment}
`

// MUtATIONS
export const HandleCompanyUsersRole = gql`
	mutation handleCompanyUsersRole($data: HandleCompanyUsersRoleInput) {
		handleCompanyUsersRole(data: $data) {
			...UserRoleFragment
		}
	}
	${UserRoleFragment}
`
export const DeleteCompanyUserRole = gql`
	mutation deleteCompanyUserRole($data: DeleteCompanyRoleInput) {
		deleteCompanyUserRole(data: $data) {
			...UserRoleFragment
		}
	}
	${UserRoleFragment}
`
