import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'
import InfiniteScroll from 'react-infinite-scroller'

import { FeedTeamArticlesQuery } from 'store'
import styles from './styles'
import { PlaceholderContent } from 'utils'
import { Loading, Card } from 'components'

const useStyles = makeStyles(styles)

const Stories: React.FunctionComponent<any> = props => {
	const { teamId, teamName } = props
	const classes = useStyles()
	// const { t } = useTranslation()
	const { data, loading, fetchMore, refetch }: any = useQuery(
		FeedTeamArticlesQuery,
		{
			fetchPolicy: 'network-only',
			variables: {
				teamId,
				first: 3,
				after: '',
			},
		},
	)

	const articles: any[] = data
		? data &&
		  data.feedTeamArticles &&
		  data.feedTeamArticles.edges.map((edge: any) => edge.node)
		: []
	const hasNextPage = data
		? data &&
		  data.feedTeamArticles &&
		  data.feedTeamArticles.pageInfo.hasNextPage
		: false
	return (
		<Grid
			className={classes.profileMainContent}
			container={true}
			direction="column"
		>
			<Grid
				item={true}
				className={`${classes.sectionContent} ${classes.storiesSection}`}
			>
				{!loading ? (
					<section
						style={{
							flexGrow: 1,
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<InfiniteScroll
							pageStart={0}
							loadMore={() =>
								fetchMore({
									variables: {
										after:
											data.feedTeamArticles.edges[
												data.feedTeamArticles.edges
													.length - 1
											].cursor,
									},
									updateQuery: (
										previousQueryResult: any,
										{
											fetchMoreResult: {
												feedTeamArticles: {
													edges: newEdges,
													pageInfo,
												},
											},
										}: any,
									) => {
										return newEdges.length > 0
											? {
													// Put the new articles at the end of the list and update `pageInfo`
													feedTeamArticles: {
														__typename:
															previousQueryResult
																.feedTeamArticles
																.__typename,
														edges: [
															...previousQueryResult
																.feedTeamArticles
																.edges,
															...newEdges,
														],
														pageInfo,
													},
											  }
											: previousQueryResult
									},
								})
							}
							hasMore={hasNextPage}
							loader={
								<div
									style={{ flexGrow: 1, display: 'flex' }}
									key={'loading'}
								>
									<Loading size={48} />
								</div>
							}
						>
							{articles && articles.length > 0 ? (
								articles.map((article: any) => (
									<Card
										article={article}
										key={article.id}
										refetch={refetch}
									/>
								))
							) : (
								<PlaceholderContent data={teamName} />
							)}
						</InfiniteScroll>
					</section>
				) : (
					<div
						style={{ flexGrow: 1, display: 'flex', marginTop: 110 }}
					>
						<Loading size={58} />
					</div>
				)}
			</Grid>
		</Grid>
	)
}

export default memo(Stories)
