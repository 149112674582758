export default {
	translation: {
		//Terms and conditions / Privacy
		PrivacyDocument:
			'https://docs.google.com/document/d/e/2PACX-1vQ2A1-I8q9BiYMxsfvN9m97gJOPNhdelTKuJi0ZA5qvGmGhOGDF6aOzZqWKoaP03oh6266TGQQuwdzr/pub?embedded=true',
		TermsConditions:
			'https://docs.google.com/document/d/e/2PACX-1vTPzgG-BeyKTQxJNJW2FTXUA_H28Olr8PuGF94qxmNFBuRH4CVIwiv4USVjL6-7xjw2RjGj6N8Rsq3N/pub?embedded=true',
		// 'People News': 'People News',
		People: 'People',
		// 'Explore Teams': 'Explore Teams',
		Teams: 'Teams',
		Results: 'Results',
		Companies: 'Employers',
		Articles: 'Articles',
		'Log In': 'Log In',
		'Sign up': 'Sign up',
		'About CV30': 'About CV30',
		// Today: 'Today',
		// 'People say': 'People say',
		'Create post': 'Create post',
		// 'People all around the world, from different teams and domains, share their knowhow, work experience and team culture':
		// 	'People all around the world, from different teams and domains, share their knowhow, work experience and team culture',
		'Write a post': 'Write a post',
		Post: 'Post',
		'Write a Long Article': 'Write a Long Article',
		Article: 'Article',
		'Write your post content here': 'Write your post content here',
		'Write your article content here': 'Write your article content here',
		'Article title': 'Article title',
		'Publish article': 'Publish article',
		'Describe your article ...': 'Describe your article ...',
		'Describe your post in a sentence, to give a relevant sneak peack':
			'Describe your post in a sentence, to give a relevant sneak peack',
		'Add featured image': 'Add featured image',
		'Tag your article': 'Tag your article',
		'Add featured video': 'Add featured video',
		'Video url': 'Video url',
		'Paste in a Video / Youtube link': 'Paste in a Video / Youtube link',
		'start typing': 'start typing',
		'Search for your desired tags': 'Search for your desired tags',
		'Available Tags': 'Available Tags',
		'Selected Tags': 'Selected Tags',
		'What’s new?': 'Newsfeed',
		'People, Teams, Jobs, Articles ...':
			'People, Teams, Jobs, Articles ...',
		'Your successful career': 'Your successful career',
		'is based on the perfect team!': 'is based on the perfect team!',
		'To find the best team and work environment for you,':
			'To find the best team and work environment for you,',
		'discover employers of interest before you apply to jobs.':
			'discover employers of interest before you apply to jobs.',
		'What’s': 'What’s',
		'new?': 'new?',
		'What’s-1': 'What’s',
		'new?-1': 'new?',
		'Employers and professionals from various domains share their experience, talk about team culture and showcase their work environment, so that you make the best decisions in your career.':
			'Employers and professionals from various domains share their experience, talk about team culture and showcase their work environment, so that you make the best decisions in your career.',
		Employers: 'Employers',
		'See Employers': 'Employers',
		'Explore Employers': 'See Employers',
		Done: 'Done',
		'Log out': 'Log out',
		'LOG IN': 'LOG IN',
		'Email address': 'Email address',
		'Forgot your password?': 'Forgot your password?',
		'You don’t have an account?': 'You don’t have an account?',
		'You already have an account?': 'You already have an account?',
		'Sign up now!': 'Sign up now!',
		'Log in now!': 'Log in now!',
		'Back to': 'Back to',
		Settings: 'Settings',
		'My Jobs': 'My Jobs',
		'My profile': 'My profile',
		'Add company': 'Add company',
		'Company settings': 'Company settings',
		'Name of the Company': 'Name of the Company',
		'Name of the Company is required': 'Name of the Company is required',
		Manage: 'Manage',
		'Publish post': 'Publish post',
		'Account was created successfully!':
			'Account was created successfully!',
		'Please check your email and activate your account':
			'Please check your email and activate your account',
		'Account activated successfully!': 'Account activated successfully!',
		'Yey ... you can now login': 'Yey ... you can now login',
		John: 'John',
		Smith: 'Smith',
		Appreciate: 'Appreciate',
		// 'Your career depends': 'Your career depends',
		// 'on the people you work with!': 'on the people you work with!',
		// 'Discover your dream team,': 'Discover your dream team,',
		// 'choose your next career step.': 'choose your next career step.',
		'Post as': 'Post as',
		'Appreciate this post by tapping on one (or more) tags below':
			'Appreciate this post by tapping on one (or more) tags below',
		'Endorse FirstName for Label': 'Endorse {{firstName}} for {{label}}',
		'The Company': 'The Company',
		Stories: 'Feed',
		'Company posts': 'Company posts',
		'My posts': 'My posts',
		'Meet our': 'Meet our',
		'Meet our-1': 'Meet our',
		'Teams-1': 'Teams',
		About: 'About',
		'About-1': 'About the',
		'Products and services': 'Company`s',
		company: 'products & services',
		Life: 'Work',
		'inside the team': 'environment',
		'Q&A': 'Q&A',
		'Founded in': 'Founded in',
		'Ofﬁces in': 'Ofﬁces in',
		'choose up to 5 tags (other users will appreciate them)':
			'choose up to 5 tags (other users will appreciate them)',
		'People will endorse the post based on tags (add up to 5)':
			'People will endorse the post based on tags (add up to 5)',
		'About me': 'About me',
		Skills: 'Skills',
		Values: 'Values',
		'View all': 'View all',
		me: 'me',
		Experience: 'Experience',
		My: 'My',
		Present: 'Present',
		At: 'At',
		'About us': 'About us',
		'For companies': 'For companies',
		'Terms & conditions': 'Terms & conditions',
		Privacy: 'Privacy',
		Contact: 'Contact',
		FAQ: 'FAQ',
		Comments: 'Comments',
		'your comment': 'your comment',
		Sendit: 'Send',
		'Delete comment': 'Delete comment',
		Endorsed: 'Endorsed',
		times: 'times',
		'see more': 'see more',
		Select: 'Select',
		'Team Type': 'Team Type',
		'Discover our': 'Discover our',
		jobs: 'jobs',
		Our: 'Our',
		'latest posts': 'latest posts',
		Explore: 'Explore',
		Advanced: 'Advanced',
		'Search-1': 'Search',
		'Job Title': 'Job Title',
		'Team name': 'Team name',
		'Min. salary': 'Min. salary',
		'Max. salary': 'Max. salary',
		'Type of job': 'Type of job',
		'Seniority level': 'Seniority level',
		'Job OnSite or Remote': 'Job OnSite or Remote',
		'Save this job': 'Save this job',
		'Post Type': 'Post Type',
		'Location Type': 'Location Type',
		'Seniority Level': 'Seniority Level',
		'20/week': '20/week',
		'40/week': '40/week',
		'About the': 'About the',
		Team: 'Team',
		'Company-1': 'Company',
		Profile: 'Profile',
		' team': ' team',
		'Meet the teams of top employers, see how they work daily, engage with people alike through content of interest, all before you apply for a position.':
			'Meet the teams of top employers, see how they work daily, engage with people alike through content of interest, all before you apply for a position.',
		'Termeni și condiții': 'Terms and conditions',
		'Acceptarea acordului de utilizare': 'Accepting the usage agreement',
		'Acest acord se referă la paginile web operate de S.C. cv30 WORLDWIDE GROUP S.R.L. cu sediul social în București, Strada General Barbu Vlădoianu, nr. 26, sector 1, (denumită în continuare cv30). Site-ul, serviciile şi conţinutul www.cv30.co, denumit în continuare şi “Site-ul” vă sunt oferite condiţionat de acceptarea dumneavoastră fără nici o modificare a condiţiilor din prezentul acord. Dacă nu acceptaţi termenii şi condiţiile statuate în acest document, nu folosiţi site-ul şi serviciile oferite prin intermediul lui. Prin utilizarea site-ului, indicaţi că acceptaţi să respectaţi "Termenii şi Condiţiile". ':
			'This agreement refers to the web pages operated by S.C. cv30 WORLDWIDE GROUP S.R.L. with its registered office in Bucharest, General Barbu Vlădoianu street, no. 26, sector 1, (hereinafter referred to as cv30). The Website, the Services and the Content www.cv30.co, hereinafter referred to as the "Site" are provided to you conditionally upon your acceptance without any modification of the terms of this Agreement. If you do not accept the terms and conditions set forth in this document, do not use the site and the services offered through it. By using the site, you indicate that you agree to abide by the "Terms and Conditions".',
		'Descrierea serviciilor': 'Description of services',
		'Site-ul www.cv30.co oferă utilizatorilor acces la o colecţie bogată de resurse şi informaţii cu privire la educaţie şi carieră. Dumneavoastră înţelegeţi şi sunteţi de acord că aceste servicii includ şi publicitate, iar publicitatea este necesară site-ului pentru furnizarea serviciilor sale. Dumneavoastră înţelegeţi şi sunteţi de acord că serviciile site-ului includ diverse anunţuri şi informaţii ce sunt actualizate constant şi au ca subiect educaţia şi cariera. Dumneavoastră înţelegeţi şi sunteţi de acord că serviciile sunt oferite "aşa cum sunt", iar site-ul nu îşi asumă nicio responsabilitate pentru ştergerea, transmiterea sau salvarea greşită a setărilor personalizate. Dumneavoastră înţelegeţi şi sunteţi de acord cu faptul că informațiile prezente pe site sunt cu titlu orientativ.':
			'The website www.cv30.co offers users access to a rich collection of education and career resources and information. You understand and agree that these services also include advertising, and advertising is necessary for the site to provide its services. You understand and agree that the site services include various announcements and information that are constantly updated and related to education and career. You understand and agree that the services are provided "as they are" and the site assumes no responsibility for deleting, transmitting or saving the custom settings incorrectly. You understand and agree that the information presented on the site is for guidance only.',
		'Obligaţii la înregistrare': 'Obligations when registering',
		'Pentru a folosi serviciile oferite de site, vă obligaţi să':
			'In order to use the services offered by the site, you are obliged to',
		'1. furnizaţi date adevărate, exacte şi complete despre dumneavoastră, aşa cum este cerut de formularul de înregistrare al site-ului ':
			'1. provide true, accurate and complete data about you as required by the site registration form',
		'2. menţineţi şi înnoiţi, atunci când situaţia o cere, datele de înregistrare pentru a fi adevărate, exacte şi complete.':
			'2. keep and update, when the situation requires, the registration data to be true, accurate and complete.',
		'Dacă veţi furniza informaţii care nu sunt conforme cu realitatea, inexacte sau incomplete, compania are dreptul să suspende sau să desfiinţeze contul dumneavoastră şi să refuze toate încercările curente sau viitoare de folosire a site-ului.':
			'If you provide inaccurate, incomplete or not real information, the company has the right to suspend or terminate your account and to refuse any current or future attempts to use the site.',
		'Responsabilităţile utilizatorului': 'User’s responsibilities',
		'Ca utilizator sunteţi responsabil de propriile acţiuni, precum şi de consecinţele pe care acestea le pot avea, în urma publicării materialelor pe care le încărcaţi, puneţi la dispoziţie în mod public pe contul pus la dispoziţie de companie.':
			'As a user you are responsible for your own actions, as well as the consequences that they may have, after publishing the materials you upload, make publicly available on the account made available by the company.',
		'Vă obligaţi să nu faceţi următoarele lucruri':
			'You are obliged not to do the following',
		'1. să publicaţi material cu drept de autor, dacă nu sunteţi autorul sau dacă nu aveţi permisiunea autorului de a publica materialul respectiv;':
			'1. to publish copyrighted material, if you are not the author or if you do not have the author’s permission to publish that material;',
		'2. să publicaţi materiale obscene, defăimătoare, de ameninţare, discriminorii sau răuvoitoare faţă de un alt utilizator, persoană fizică sau juridică sau faţă de orice alt terţ;':
			'2. to publish obscene, defamatory, threatening, discriminatory or malicious material towards another user, private person or legal entity or any other third party;',
		'3. să publicaţi o imagine sau o afirmaţie explicit sexuală;':
			'3. to publish a sexually explicit image or affirmation;',
		'4. să publicaţi materiale care conţin viruşi, viermi sau alte programe cu intenţia de a distruge orice sistem sau informaţie;':
			'4. to publish materials containing viruses, worms or other programs with the intention of destroying any system or information;',
		'5. încarca, difuza sau transmite în alt mod orice conţinut pentru care nu aveţi dreptul legal de transmitere sau difuzare în orice condiţii, sub orice sistem juridic, român sau străin, relaţii contractuale sau de încredere (cum sunt informaţiile confidenţiale, cele aflate sub drept de proprietate, aflate sau dezvăluite ca parte a relaţiilor de serviciu sau aflate sub incidenţa acordurilor de confidenţialitate);':
			'5. to upload, broadcast or otherwise transmit any content for which you do not have the legal right to transmit or broadcast under any conditions, under any legal system, Romanian or foreign, contractual or trust relations (such as confidential information, those under property rights, found or disclosed as part of the service relations or subject to confidentiality agreements);',
		'6. încarca, difuza sau transmite în alt mod orice tip de publicitate, materiale promoţionale, "junk mail", "spam", scrisori în lanţ, scheme piramidale, sau orice altă formă de solicitări, cu excepţia porţiunilor din serviciu care sunt formate cu acest scop;':
			'to upload, disseminate or otherwise transmit any type of advertising, promotional materials, "junk mail", "spam", chain letters, pyramid schemes, or any other form of requests, except for portions of the service that are formed with this purpose;',
		'7. avea adrese multiple în cadrul site-ului care se aseamănă sau care sunt create pe aceeaşi temă;':
			'7. to have multiple addresses within the site that are similar or created on the same theme;',
		'8. promova sau furniza informaţii despre modul de derulare a activităţilor ilegale, promova rănirea fizică sau verbală împotriva oricărui grup sau oricărei persoane, sau pentru a promova orice act de cruzime faţă de animale. Aceasta poate include, dar nu este limitat, a furniza informaţii despre modul de a fabrica, achiziţiona sau asambla bombe, grenade sau alte tipuri de arme şi crearea de site-uri "crush";':
			'8. to promote or provide information on how illegal activities are carried out, promote physical or verbal injury against any group or person, or to promote any act of cruelty to animals. This may include, but is not limited to, providing information on how to manufacture, purchase or assemble bombs, grenades or other types of weapons and the creation of "crush" sites;',
		'9. difuza sau transmite în alt mod date personale (nume, adresa, numere de telefon) sau înregistrarea acestora în rubricile care pot fi vizualizate de către ceilalţi utilizatori (descrieri, pseudonim etc.);':
			'9. to disseminate or otherwise transmit personal data (name, address, telephone numbers) or their registration in the fields that can be viewed by other users (descriptions, aliases, etc.);',
		'10. difuza sau transmite orice alt material care contravine, în orice fel, normelor legale în vigoare.':
			'10. to diffuse or transmit any other material that contravenes, in any way, the legal norms in force.',
		'Compania nu garantează credibilitatea, acurateţea informaţiilor publicate de către utilizatori sau nu girează vreo părere exprimată de către utilizatori.':
			'The company does not guarantee the credibility, accuracy of the information published by the users or does not guarantee any opinion expressed by the users.',
		'Compania acţionează ca un factor pasiv în distribuţia online şi publicarea informaţiilor furnizate de către utilizatori şi nu are obligaţia de a verifica în avans materialul publicat de către utilizatori. De aceea, compania nu răspunde în niciun mod cu privire la informaţiile şi datele postate, difuzate sau transmise de către utilizatorii săi. Dacă i se solicită de către un utilizator, compania poate investiga şi verifica afirmaţiile şi poate hotărî dacă informaţiile respective trebuie îndepărtate. De asemenea, permiteţi oricărei persoane care este abonată să acceseze, să vizualizeze, să stocheze sau să reproducă asemenea materiale în scopuri personale. Supus celor menţionate înainte, posesorul unui astfel de material publicat pe site îşi menţine orice drepturi care ar putea deriva de aici.':
			'The company acts as a passive factor in the online distribution and publication of the information provided by the users and is not obliged to check in advance the material published by the users. Therefore, the company is not responsible in any way regarding the information and data posted, disseminated or transmitted by its users. If requested by a user, the company can investigate and verify the claims and decide whether the information should be removed. Also, allow anyone who is a subscriber to access, view, store or reproduce such material for personal purposes. Subject to the foregoing, the owner of such material published on the site maintains any rights that may derive from it.',
		'Informaţii pentru utilizatori': 'Information for users',
		'În momentul în care vă înregistraţi pe site, vi se vor cere anumite informaţii incluzând o adresă de e-mail validă. Pe lângă termenii şi condiţiile care vor fi reglementate în politica site-ului, dumneavoastră înţelegeţi şi acceptaţi faptul că această companie poate divulga unei terţe persoane anumite informaţii conţinute în cererea dumneavoastră de înregistrare.':
			'When you register on the site, you will be required to provide certain information including a valid email address. In addition to the terms and conditions that will be governed by the site policy, you understand and agree that this company may disclose to third parties certain information contained in your application for registration.',
		'Compania nu va divulga unei terţe persoane numele, adresa de e-mail sau numărul dumneavoastră de telefon fără consimţământul dumneavoastră, cu excepţia cazurilor prevăzute de lege când comunicarea acestor informaţii este necesară şi în conformitate cu politica de confidenţialitate.':
			'The Company will not disclose your name, e-mail address or telephone number to a third party without your consent, unless otherwise provided by law when the disclosure of this information is required and in accordance with the privacy policy.',
		'Compania îşi rezervă dreptul de a oferi serviciile şi produsele unui terţ, bazându-se pe datele menţionate în înregistrarea dumneavoastră în orice moment după ce aceasta a avut loc; asemenea oferte pot fi făcute de către companie sau de către un terţ implicat.':
			'The Company reserves the right to offer the services and products of a third party, based on the data mentioned in your registration at any time after it has taken place; such offers may be made by the company or by a third party involved.',
		'Pentru mai multe detalii despre divulgarea datelor dumneavoastră, vedeți “Nota de informare privind confidențialitatea datelor”. Aceste operațiuni respectă Regulamentul General privind Protecția Datelor 2016/679.':
			'For more details about disclosing your data, see the "Data Privacy Notice". These operations comply with the General Regulation on Data Protection 2016/679.',
		'Contul, parola şi securitatea datelor':
			'Account, password and data security',
		'Sunteţi răspunzători de păstrarea confidenţialităţii asupra informaţiilor şi a parolei dumneavoastră. Veţi fi răspunzători de utilizarea înregistrării dumneavoastră, indiferent dacă utilizarea se face cu sau fără voia dumneavoastră. Sunteţi de acord să sesizaţi compania în legătură cu orice utilizare neautorizată a parolei şi a înregistrării dumneavoastră. Sunteţi de asemenea de acord să folosiţi butonul de ieşire din contul dumneavoastră când doriţi să părăsiţi site-ul. Compania nu va fi răspunzătoare pentru nici o pagubă morală sau materială provocată prin nerespectarea acestei prevederi de către dumneavoastră.':
			'You are responsible for maintaining the confidentiality of your information and password. You will be responsible for the use of registration, regardless of whether the usage is made with or without your consent. You agree to notify the Company of any unauthorized use of your password and registration. You also agree to use the logout button in your account when you wish to leave the site. The Company will not be liable for any moral or material damage caused by non-compliance with this provision by you.',
		'Dacă consideră de cuviinţă, compania vă poate bloca parola, utilizarea serviciilor sau folosirea oricăror altor servicii ale sale de care beneficiaţi, sau să scoată şi să şteargă orice material din cadrul serviciilor, pentru orice motiv sau fără motiv. Compania poate de asemenea, oricând doreşte şi fără a da socoteală, întrerupe furnizarea serviciilor, sau a unei părţi a lor, cu sau fără nici o notificare prealabilă.':
			'If it deems fit, the company may block your password, use of the services or use of any of its other services that you benefit from, or remove and delete any material within the services, for any reason or for no reason. The Company may also, at any time and without any consideration, discontinue the provision of the Services, or any part thereof, with or without any prior notice.',
		'Orice încetare a accesului dumneavoastră la serviciile site-ului, conform oricărei reguli din prezentul Regulament, poate fi efectuată fără o avertizare anticipată, şi luaţi la cunoştinţă şi sunteţi de acord că firma poate dezactiva sau şterge imediat contul dumneavoastră din cadrul site-ului şi toate informaţiile în legătură cu acesta şi/sau să interzică orice acces ulterior la serviciile site-ului. Mai mult, compania nu va fi răspunzătoare faţă de dumneavoastră sau orice terţă parte în orice mod, pentru interzicerea şi/sau ştergerea contului şi a accesului la serviciile puse la dispoziţie prin intermediul site-ului.':
			'Any termination of your access to the services of the site, in accordance with any rule of this Regulation, may be made without prior notice, and you acknowledge and agree that the company may immediately deactivate or delete your account within the site and all the information related to it and / or to prohibit any subsequent access to the services of the site. Furthermore, the company will not be liable to you or any third party in any way, for the prohibition and / or deletion of the account and access to the services made available through the site.',
		'Utilizarea materialelor': 'Use of materials',
		'Compania vă autorizează să vizualizaţi informaţia de pe site doar în scopuri personale şi pentru uz necomercial. Conţinutul acestui site, adică textele, grafica, fotografiile, precum şi alte materiale sunt protejate de legea dreptului de autor. Întregul material este proprietatea companiei. Compilaţia (adică adunarea şi rearanjarea) conţinutului acestui site este dreptul exclusiv al companiei, drept protejat de legile în vigoare. Folosirea neautorizată a materialului reprezintă o încălcare a legii dreptului de autor sau a altor legi. Nu aveţi permisiunea de a vinde sau a modifica materialul, a-l expune public, a-l distribui sau a-l folosi în orice alt mod, în scopuri publice sau comerciale.':
			'The company authorizes you to view the information on the site for personal and non-commercial use only. The content of this site, meaning texts, graphics, photographs, and other materials are protected by copyright law. All material is the property of the company. The compilation (meaning the assembly and rearrangement) of the content of this site is the exclusive right of the company, as protected by the laws in force. Unauthorized use of the material is a violation of copyright law or other laws. You do not have the permission to sell or modify the material, display it publicly, distribute or otherwise use it, for public or commercial purposes.',
		'Pe serverele care aparţin sau care sunt închiriate de către companie accesul oricărui utilizator neautorizat este interzis. În caz de încercare şi/sau accesare, fără drept, a acestor servere, fapta constituie infracţiune şi se pedepseşte conform legislaţiei române în vigoare.':
			'On the servers belonging to or rented by the company, the access of any unauthorized user is prohibited. In case of trial and / or access, without right, of these servers, the deed is a crime and is punished according to the Romanian legislation in force.',
		'Proprietatea materialelor şi informaţiilor introduse pe site':
			'Ownership of materials and information posted on the site',
		'Compania nu va pretinde un drept de proprietate asupra materialelor pe care le furnizaţi (inclusiv observaţiile şi sugestiile) sau pe care le postaţi, încărcaţi sau trimiteţi către site. Totuşi, transmiţând aceste materiale, dumneavoastră sunteţi de acord să acordaţi companiei permisiunea de a utiliza materialele inclusiv să le copieze, distribuie, transmită, publice, reproducă, modifice şi să traducă; de a menţiona numele dumneavoastră în legătură cu materialul pe care l-aţi transmis; şi dreptul de a cesiona la rândul ei toate aceste drepturi oricărui partener al companiei.':
			'The Company will not claim ownership rights over the materials you provide (including comments and suggestions) or that you post, upload or send to the site. However, by submitting these materials, you agree to grant the Company permission to use the materials including copy, distribute, transmit, publish, reproduce, modify and translate; to mention your name in relation to the material you submitted; and the right to assign all these rights to any partner of the company.',
		'Nu se vor pretinde şi nici acorda niciun fel de compensaţii pentru utilizarea materialului în felul arătat mai sus. Compania nu are obligaţia de a transmite pe site sau folosi în orice mod materialul furnizat de dumneavoastră; compania are de asemenea dreptul să şteargă materialul dumneavoastră în orice moment şi fără a avea nevoie de un motiv în acest sens.':
			'No compensation will be claimed or granted for the use of the material as described above. The company is not obliged to transmit on the site or use in any way the material provided by you; the company also has the right to delete your material at any time and without needing a reason to do so.',
		'Relaţia cu companiile care fac publicitate pe site-ul www.cv30.co':
			'Relationship with companies that advertise on the website www.cv30.co',
		'Compania rulează campanii publicitare şi promoţii în cadrul site-ului său. Corespondenţa sau afacerile sau participarea în promoţii ale companiilor de publicitate găsite pe sau prin intermediul site-urilor, inclusiv plăți sau livrări ale unor bunuri sau servicii şi orice alte condiţii, termene, garanţii sau reprezentări asociate cu astfel de relaţii sunt numai în responsabilitatea dumneavoastră şi a acestor companii. Compania nu este răspunzătoare şi nu va fi trasă la răspundere pentru orice pierdere sau prejudiciu de orice fel, rezultat din astfel de relaţii sau rezultat din prezenţa unor astfel de companii în cadrul campaniilor publicitare ale site-ului. Prin agrearea termenilor prezentului Contract, ne acordaţi dreptul de a vă trimite periodic e-mail-uri cu informaţii sau oferte speciale.':
			'The company runs advertising campaigns and promotions within its website. Correspondence or business or participation in promotions of advertising companies found on or through the websites, including payments or deliveries of goods or services and any other conditions, terms, guarantees or representations associated with such relationships are solely your responsibility and of these companies. The company is not responsible and will not be held liable for any loss or damage of any kind, as a result of such relationships or as a result of the presence of such companies in the advertising campaigns of the site. By agreeing to the terms of this Agreement, you grant us the right to periodically send you emails with information or special offers.',
		'Legături către alţi furnizori de informaţii sau servicii':
			'Links to other information or service providers',
		'Site-ul poate furniza, sau terţe părţi pot furniza legături (link-uri) către alte site-uri World Wide Web sau alte resurse. Deoarece compania nu are niciun control asupra acestor site-uri sau resurse, luaţi cunoştinţă şi sunteţi de acord că nu suntem responsabili de disponibilitatea acestora şi nu garantăm şi nu suntem responsabili pentru niciun conţinut, publicitate, produse sau alte materiale de pe sau disponibile pe acest site sau resurse.':
			'The Site may provide, or third parties may provide links (links) to other World Wide Web sites or other resources. As the company has no control over these sites or resources, you acknowledge and agree that we are not responsible for their availability and do not guarantee and are not responsible for any content, advertising, products or other materials on or available on this site or resources.',
		'Mai mult, recunoaşteţi şi sunteţi de acord că nu vom fi responsabili sau pasibili de a plăti despăgubiri, direct sau indirect pentru orice pagubă sau pierdere cauzată sau presupusă a fi fost cauzată de sau în legătură cu folosirea sau încrederea în informaţiile furnizate de un asemenea conţinut, bunuri sau servicii disponibile pe sau prin intermediul unor astfel de site-uri sau resurse.':
			'Further, you acknowledge and agree that we will not be liable or liable to pay compensation, directly or indirectly for any loss or loss caused or alleged to have been caused by or in connection with the use or reliance on information provided by such content , goods or services available on or through such sites or resources.',
		'Responsabilităţile companiei': 'Company’s responsibilities',
		'Deoarece identificarea utilizatorilor pe Internet este dificilă, site-ul nu confirmă faptul că fiecare utilizator este ceea ce pretinde. Deoarece nu suntem şi nu putem fi implicaţi în relaţiile user-to-user sau nu putem controla comportamentul utilizatorilor www.cv30.co, în eventualitatea unei dispute cu unul sau mai mulţi utilizatori, compania (prin agenţii, administratorii şi angajaţii săi) este absolvită de orice răspundere sau despăgubire (directă sau indirectă) de orice fel şi de orice natură, cunoscută sau necunoscută, suspectată sau nu, dezvăluită sau nu, ivită în vreun mod care are legătură cu disputele.':
			'Because identifying users on the Internet is difficult, the site does not confirm that each user is what he claims. Because we are not and cannot be involved in user-to-user relationships or we cannot control the behavior of www.cv30.co users, in the event of a dispute with one or more users, the company (through its agents, administrators and employees) is acquitted of any liability or compensation (direct or indirect) of any kind and of any nature, known or unknown, suspected or not, whether or not disclosed, arises in any way related to the disputes.',
		'Compania nu are obligaţia legală de a controla informaţiile oferite de alţi utilizatori, disponibile pe site. Informaţiile altor persoane pot fi neplăcute, vătămătoare sau inexacte.':
			'The company has no legal obligation to control the information provided by other users, available on the site. Other people’s information may be unpleasant, harmful or inaccurate.',
		'Materialul poate conţine inexactităţi sau greşeli de scriere. Compania nu îşi asumă veridicitatea şi exactitatea materialului de pe site. Dumneavoastră luaţi la cunoştinţă şi sunteţi de acord cu faptul că sunteţi singurul responsabil pentru forma, conţinutul sau exactitatea materialului conţinut sau plasat de dumneavoastră pe site.':
			'The material may contain inaccuracies or typos. The company does not assume the truthfulness and accuracy of the material on the site. You acknowledge and agree that you are solely responsible for the form, content or accuracy of the material contained or posted by you on the site.',
		'Acord de confidenţialitate': 'Confidentiality agreement',
		'Echipa companiei încearcă să protejeze cât mai mult dreptul la intimitate şi la imagine al utilizatorilor site-ului. Ne propunem să vă oferim dumneavoastră, utilizatorilor site-ului, o experienţă online sigură, care să nu vă afecteze în mod negativ viaţa personală. În acest sens depunem toate eforturile pentru a ne asigura că informaţiile pe care dumneavoastră le introduceţi în baza noastră de date sunt folosite numai în scopurile pe care dumneavoastră le aveţi în vedere.':
			'The company’s team tries to protect as much as possible the right to privacy and image of the users of the site. We aim to provide you, the users of the site, a safe online experience that will not adversely affect your personal life. In this regard, we make every effort to ensure that the information you enter into our database is used only for the purposes you have in mind.',
		'Afișarea datelor personale în spatiile/domeniile publice ale www.cv30.co':
			'Display of personal data in public spaces / domains of www.cv30.co',
		'Informațiile cu caracter personal postate de către utilizatori în locurile/spatiile publice aparținând www.cv30.co, cum ar fi forum-uri, chat-uri, liste de discutii, baza de date cu CV-urile căutabile, pot fi copiate și stocate de către terți asupra cărora www.cv30.co nu are nici un control. Noi nu suntem responsabili pentru faptul că terțe părți utilizează informații pe care le-ați postat sau le-ați făcut disponibile în spatiile publice aparținând www.cv30.co.':
			'Personal information posted by users in public places / spaces belonging to www.cv30.co, such as forums, chats, discussion lists, databases with searchable CVs, can be copied and stored by to third parties over which www.cv30.co has no control. We are not responsible for the fact that third parties use information you have posted or made available in public spaces belonging to www.cv30.co.',
		'Profil ': 'Profile',
		'Din moment ce www.cv30.co este un site de dezvoltare personala si profesionala online vă acordăm posibilitatea de înregistrare a propriului profil în baza noastră de date. Există două modalități 1. Vă puteți înregistra cv-ul în baza noastră de date. 2. Puteți crea profilul de cariera, iar angajatorii vor avea acces la datele dvs. de contact. Puteți să ștergeți profilul din baza noastră de date oricând (dar nu mai devreme de 6 luni de la cea mai recentă aplicare făcută de dvs. pentru un loc de munca). Dacă postați datele altei persoane este necesar ca persoana respectivă să își fi dat în prealabil acordul pentru acest act.':
			'Since www.cv30.co is a site for online personal and professional development, we give you the possibility to register your profile in our database. There are two ways 1. You can register your CV in our database. 2. You can create your career profile and employers will have access to your contact details. You can delete the profile from our database at any time (but not earlier than 6 months after your most recent job application). If you post the data of another person, it is necessary that the person has previously given their consent for this act.',
		'Evenimente ': 'Events',
		'Compania organizează periodic evenimente la care vă înscrieți prin intermediul website-ului. Imaginile, video-urile și textele din cadrul evenimentelor sunt proprietatea companiei și dumneavoastră sunteţi de acord să acordaţi companiei permisiunea de a utiliza materialele inclusiv să le copieze, distribuie, transmită, publice, reproducă, modifice şi să traducă; de a menţiona numele dumneavoastră în legătură cu materialul pe care l-aţi transmis; şi dreptul de a cesiona la rândul ei toate aceste drepturi oricărui partener al companiei.':
			'The company periodically organizes events to which you register through the website. The images, videos and texts of the events are the property of the company and you agree to grant the company permission to use the materials including copy, distribute, transmit, publish, reproduce, modify and translate; to mention your name in relation to the material you submitted; and the right to assign all these rights to any partner of the company.',
		'Nu se vor pretinde şi nici acorda niciun fel de compensaţii pentru utilizarea materialului în felul arătat mai sus. Compania nu are obligaţia de a transmite pe site sau folosi în orice mod materialul din cadrul evenimentelor; compania are de asemenea dreptul să şteargă materialul în orice moment şi fără a avea nevoie de un motiv în acest sens.':
			'No compensation will be claimed or granted for the use of the material as described above. The company has no obligation to transmit on the site or use in any way the material within the events; the company also has the right to delete the material at any time and without needing a reason to do so.',
		'Dreptul de a modifica și șterge datele dvs. deținute de operator www.cv30.co conferă dreptul utilizatorului de a modifica datele de contact pe care le-ați înregistrat, unele aspecte de care sunteți interesat, incluzând noile informații despre produse și servicii lansate. De asemenea, puteți solicita operatorului ștergerea datelor dvs. oricând.':
			'The right to modify and delete your data held by the operator www.cv30.co grants the user the right to modify the contact data you have registered, some aspects of which you are interested, including new information about products and services launched. You can also ask the operator to delete your data at any time. ',
		'Securitatea datelor': 'Data security',
		'Pentru confidențialitatea și securitatea datelor, contul dumneavoastra pe www.cv30.co este protejat de o parolă.':
			'For privacy and data security, your account on www.cv30.co is protected by a password.',
		'Cookies ': 'Cookies ',
		"Pentru a vă oferi o navigare cât mai fluidă pe site-ul www.cv30.co, folosim o facilitate a browserului Internet numita 'cookie'. Cookies sunt fișiere de dimensiuni reduse pe care browserul le plasează pe hard-diskul utilizatorului. Aceste cookies sunt folosite pentru a memora numele utilizatorilor, parolele și preferințele, pentru a monitoriza parcursuri pe site, și pentru a personaliza paginile în funcție de vizitator. Aveți opțiunea de a seta browserul astfel încât să respingă cookies. În acest caz însă, va exista un impact negativ asupra navigării pe site-ul nostru.":
			'In order to provide you with a smooth navigation on the website www.cv30.co, we use an Internet browser feature called ‘cookie’. Cookies are small files that the browser places on the user’s hard disk.These cookies are used to store usernames, passwords and preferences, to monitor site visits, and to customize pages according to each visitor.You have the option to set your browser to reject cookies.In this case, however, there will be a negative impact on browsing our site.',
		'Notificari SMS': 'SMS notifications',
		'www.cv30.co poate trimite ocazional alerte de tip SMS utilizatorilor înregistrați cu cont creat pe site pentru a-i informa despre evenimente de carieră, job-uri, programe de recrutare ale companiilor prezente pe cv30.ro, produse și servicii de care ar putea fi interesați sau alte aspecte importante legate de contul lor.':
			'www.cv30.co may occasionally send SMS alerts to registered users with an account created on the site to inform them about career events, jobs, recruitment programs of companies present on cv30.ro, products and services that could be interested or other important issues related to their account.',
		'Feedback ': 'Feedback',
		'www.cv30.co oferă posibilitatea utilizatorilor site-ului de a trimite comentarii, întrebări și sugestii. Orice informație care este trimisă prin intermediul formularelor de contact va fi folosită ținând cont de dreptul la confidențialitate și la imagine al persoanelor.':
			'www.cv30.co offers the site users the possibility to submit comments, questions and suggestions. Any information that is sent through the contact forms will be used taking into account the right to privacy and the image of the persons.',
		'Modificări ale politicii de confidențialitate':
			'Changes to the privacy policy',
		'Dacă vom considera că este necesară o schimbare a regulilor de confidențialitate, vom publica respectivele modificări în această pagină pentru a vă informa cu privire la tipurile de informații pe care le colectam și modul în care le utilizam. Dacă aveți întrebări cu privire la politica noastră de confidențialitate vă rugăm sa ne scrieți la contact@cv30.co.':
			'If we will consider that a change of privacy rules is necessary, we will publish the respective changes on this page to inform you about the types of information we collect and how we use them. If you have any questions regarding our privacy policy, please contact us at contact@cv30.co.',
		'Notă de informare privind protecția datelor personale':
			'Information note regarding the protection of personal data',
		'Începând cu data de 25 mai 2018, va intra în vigoare Regulamentul (UE) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (Regulamentul general privind protecția datelor).':
			'Starting with May 25, 2018, Regulation (EU) 2016/679 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46 / EC will enter into force (General regulation on data protection).',
		'cv30 Worldwide Group SRL - cu sediul în Str. Gral Barbu Vladoianu, nr 26, sector 1, Bucuresti, este operator de date cu caracter personal în scopuri de marketing în baza notificării 35367.':
			'cv30 Worldwide Group SRL - based in General Barbu Vladoianu Street, no. 26, sector 1, Bucharest, is a personal data operator for marketing purposes based on 35367 notification.',
		'Conformitatea cv30 în legătură cu Regulamentul general privind protecția datelor':
			'Cv30 compliance regarding the General Data Protection Regulation',
		'1. Cum ne contactați?': '1. How do you contact us?',
		'CV30 WORLDWIDE GROUP SRL - Strada General Barbu Vlădoianu':
			'CV30 WORLDWIDE GROUP SRL - General Barbu Vlădoianu Street ',
		'contact@cv30.co': 'contact@cv30.co',
		'2. Ce acoperă Nota noastră de informare?':
			'2. What does our Information Note cover?',
		'2.1. Nota noastră de informare': '2.1. Our information note',
		'2.1.1. produce efecte de la 25.05.2018':
			'2.1.1. produces effects from 25.05.2018',
		'2.1.2. este publicată la 15.05.2018':
			'2.1.2. is published on 15.05.2018',
		'2.2. Nota noastră de informare se aplică':
			'2.2. Our information note applies',
		'2.2.1. websiteului nostru cv30.co': '2.2.1. to our website cv30.co',
		'2.2.2. newsletter-ului nostru.': '2.2.2. our newsletter.',
		'3. De ce și cum prelucrăm datele dumneavoastră personale?':
			'3. Why and how do we process your personal data?',
		'Colectăm câteva informații personale despre vizitatori și userii site-ului.':
			'We collect some personal information about visitors and users of the site.',
		'Colectăm informații precum nume, prenume, email, telefon, oraș, comentarii și date din serviciile de web analytics. De asemenea, prelucrăm date când aplicați la job-uri (datele din cv) și datele pe care le furnizați când vă înscrieți la un eveniment cv30.':
			'We collect information such as name, first name, email, telephone, city, comments and data from web analytics services. We also process data when you apply to jobs (cv data) and the data you provide when you sign up for a cv30 event.',
		'Suntem siguri că primești zilnic o multitudine de mailuri, motiv pentru care vrem sa ne limităm la ceea ce este esențial și relevant. Pe scurt, fără spam.':
			'We are sure that you receive a lot of emails daily, which is why we want to limit ourselves to what is essential and relevant. In short, no spam.',
		'4. Cine este responsabil pentru prelucrarea datelor dumneavoastră personale?':
			'4. Who is responsible for the processing of your personal data?',
		'4.2. cv30': 'cv30',
		'- decide de ce sunt prelucrate datele dumneavoastră personale,':
			'- decide why your personal data is processed,',
		'- decide cum sunt prelucrate datele dumneavoastră personale, și':
			'- decide how your personal data is processed, and',
		'- este responsabila pentru prelucrarea datelor dumneavoastră personale.':
			'- is responsible for the processing of your personal data.',
		'4.2.1. cv30 are un responsabil cu protecția datelor personale.':
			'4.2.1. cv30 is responsible for the protection of personal data.',
		'- Datele de contact sunt Codrin-Andrei Pascariu, codrin.pascariu@cv30.co, 0311096740, Strada General Barbu Vladoianu, nr. 26':
			'- The contact details are Codrin-Andrei Pascariu, codrin.pascariu@cv30.co, 0311096740, General Barbu Vladoianu Street, no. 26.',
		'5. De la cine și cum colectăm datele dumneavoastră personale?':
			'From whom and how do we collect your personal data?',
		'5.1. Colectăm datele dumneavoastră personale':
			'5.1. We collect your personal data',
		'5.1.1. direct de la dumneavoastră.': '5.1.1. directly from you.',
		'5.2. Colectăm datele dumneavoastră personale':
			'5.2. We collect your personal data',
		'5.2.1. electronic, folosind un formular web.':
			'5.2.1. electronically, using a web form.',
		'5.2.2. electronic': '5.2.2. electronic',
		'- prin stocarea de informație pe echipamentele dumneavoastră, sau':
			'- by storing information on your equipment, or',
		'- accesând informație stocată pe echipamentele dumneavoastră, ori':
			'- accessing information stored on your equipment, or',
		'- citind informație emisă de echipamentele dumnevoastră.':
			'- reading information provided by your equipment.',
		'Când ne furnizați datele dumneavoastră personale, furnizarea dumneavoastră este':
			'When you provide us with your personal data, your provide is',
		'5.3.1. permisă și voluntară. Ni le puteți furniza liber nume, email, telefon, oraș, studii':
			'5.3.1. allowed and voluntary. You can provide them freely name, email, phone, city, education',
		'5.4. Dacă nu reușiți să ne furnizați datele dumneavoastră personale și această furnizare este voluntară, atunci acest lucru nu vă poate afecta.':
			'5.4. If you fail to provide us with your personal data and this provision is voluntary, then this may not affect you.',
		'6. Care sunt temeiurile noastre legale pentru prelucrarea datelor dumneavoastră personale?':
			'6. What are our legal bases for processing your personal data?',
		'6.1. Noi procesăm date personale "nesensibile".':
			'6.1. We process "non-sensitive" personal data.',
		'6.2. Temeiul legal pentru prelucrarea datelor dumneavoastră personale "nesensibile" este':
			'6.2. The legal basis for processing your "non-sensitive" personal data is',
		'6.2.1. consimțământul dumneavoastră.': '6.2.1. your consent.',
		'6.2.2. Interesul': '6.2.2. interest',
		'- nostru legitim, sau': '- our legitimate, or',
		'- legitim al unei terțe părți.': '- legitimate of a third party.',
		'Prelucrăm datele dumneavoastră personale pe baza unor interese care sunt':
			'We process your personal data based on interests that are',
		'- legitime,': '- legitimate,',
		'- reale, și': '- real, and',
		'- prezente.': '- present.',
		'Prelucrarea datelor dumneavoastră personale este necesară pentru interesele legitime pe care le urmărim.':
			'The processing of your personal data is necessary for the legitimate interests we pursue.',
		'Interesele noastre legitime pot fi înlăturate de':
			'Our legitimate interests can be removed by',
		'- interesele dumneavoastră și': '- your interests and',
		'- drepturi dumneavoastră fundamentale.': '- your fundamental rights.',
		'Vă protejăm în mod adecvat': 'We adequately protect your',
		'- interesele și': '- interests and',
		'- drepturile și libertățile.': '- rights and freedoms.',
		'7. În ce situații prelucrăm datele dumneavoastră personale?':
			'7. In what situations we process your personal data?',
		'7.1. Colectăm date personale': '7.1. We collect personal data',
		'7.1.1. ale cititorilor, utilizatori ai siteului, membrii ai comunității, participanți la evenimente':
			'7.1.1. of readers, site users, community members, event attendees',
		'7.2.Suntem': '7.2. We are',
		'7.2.1. o întreprindere.': '7.2.1. an enterprise.',
		'7.3. Procesăm datele dumneavoastră personale din':
			'7.3. We process your personal data from',
		'7.3.1. sectorul privat.': '7.3.1. the private sector.',
		'7.4. Procesăm datele dumneavoastră personale într-o situație care implică':
			'7.4. We process your personal data in a situation that involves',
		'7.4.1. o activitate online.': '',
		'8. Folosim procese de profilare automată și decizii automatizate?':
			'8. Do we use automated profiling processes and automated decisions?',
		'8.1. Folosim datele dumneavoastră personale pentru a evalua automat aspecte ale personalității dumneavoastră.':
			'8.1. We use your personal data to automatically evaluate aspects of your personality.',
		'Evaluarea automată': 'Automatic evaluation',
		'- poate include o analiză a caracteristicilor dumneavoastră.':
			'- may include an analysis of your characteristics.',
		'- poate include predicții cu privire la comportamentul dumneavoastră.':
			'- may include predictions about your behavior.',
		'- este realizată exclusiv cu mijloace informatice.':
			'- is made exclusively by computer means.',
		'- este realizată fără implicare umană.':
			'- is performed without human involvement.',
		'- va fi menționată în continuare prin referire la termenul “profilare complet automatizată”.':
			'- will be referred to below by the term "fully automated profiling".',
		'8.2. Folosim datele dumneavoastră personale pentru a lua decizii automate despre dumneavoastră.':
			'8.2. We use your personal data to make automatic decisions about you.',
		'Deciziile automate': 'Automatic decisions',
		'- pot include "profilarea automată în totalitate" a dumneavoastră':
			'- may include your "fully automatic profiling"',
		'- sunt realizate numai de un calculator.':
			'- are made only by a computer.',
		'- sunt făcute fără intervenție umană.':
			'- are made without human intervention.',
		'- sunt denumite în continuare "procese decizionale complet automatizate".':
			'- are hereinafter referred to as "fully automated decision-making processes".',
		'8.3. Deciziile noastre "complet automate" despre dumneavoastră se bazează pe "profilarea automată" a dumneavoastră.':
			'8.3. Our "fully automatic" decisions about you are based on your "automatic profiling".',
		'8.4. Ne fundamentăm procesul de “decizie complet automată” despre dumneavoastră pe următoarea logică de prelucrare':
			'8.4. We base our "fully automatic decision" process on you based on the following processing logic',
		'8.4.1. Daca un cititor accesează articole dintr-o anumită categorie, vom considera că aria sa de interes este legată de acea categorie de articole și vom încerca să personalizăm, în viitor, conținutul pe baza acelei analize.':
			'8.4.1. If a reader accesses articles from a certain category, we will consider that his area of interest is related to that category of articles and we will try to personalize the content based on that analysis in the future.',
		'8.5. Deciziile noastre "complet automate" nu au un impact asupra':
			'8.5. Our "fully automatic" decisions have no impact on',
		'- drepturilor dumneavoastră, sau': '- your rights, or',
		'- statutului dumneavoastră juridic.': '- your legal status.',
		'Deciziile noastre "complet automate" nu au un impact real semnificativ asupra':
			'Our "fully automatic" decisions have no real significant impact on',
		'- circumstanțelor aferente situației dumneavoastră,':
			'- the circumstances of your situation,',
		'- comportamentului dumneavoastră, sau': '- your behavior, or',
		' - alegerilor dumneavoastră.': '- your choices.',
		'8.6. Temeiul legal pentru prelucrarea noastră referitoare la deciziile "complet automate" referitoare la dumneavoastră este':
			'8.6. The legal basis for our processing regarding "fully automatic" decisions regarding you is',
		'8.6.1. o prelucrare în scopul': '8.6.1. a processing for the purpose',
		'- intereselor noastre legitime, sau': '- our legitimate interests, or',
		'- intereselor legitime ale unei terțe părți.':
			'- the legitimate interests of a third party.',
		'8.7. Datele dumneavoastră personale pe care sistemul nostru se bazează atunci când ia "decizii complet automate" sunt':
			'8.7. Your personal data that our system relies on when making "fully automatic decisions" are',
		'8.7.1. date personale “nesensibile”.':
			'8.7.1. personal data "insensitive".',
		'8.8. Deciziile noastre “complet automate” nu vă pot afecta.':
			'8.8. Our "fully automatic" decisions cannot affect you.',
		'9. Despre scopurile pentru care prelucrăm date personale':
			'9. About the purposes for which we process personal data',
		'9.1. Prelucrăm datele dumneavoastră personale pentru scopurile descrise în Secțiunea 3.':
			'9.1. We process your personal data for the purposes described in Section 3.',
		'Scopurile noastre, pentru care prelucrăm date personale, sunt':
			'Our purposes, for which we process personal data, are',
		'- reale,': '- real,',
		'- prezente, și': '- present, and',
		'- legitime.': '- legitimate.',
		'9.2. Nu vă prelucrăm datele dumneavoastră personale în scopuri secundare care sunt incompatibile cu scopurile principale pentru care datele dumneavoastră personale sunt colectate inițial,':
			'9.2. We do not process your personal data for secondary purposes that are incompatible with the primary purposes for which your personal data is initially collected,',
		'- fără consimțământul dumneavoastră prealabil,':
			'- without your prior consent,',
		'- fără să existe un interes legitim în acest sens, și':
			'- without a legitimate interest in this, and',
		'- fără un temei juridic.': '- without a legal basis.',
		'9.3. Vă informăm înainte de prelucrarea datelor dumneavoastră personale în scopuri secundare':
			'9.3. We inform you before processing your personal data for secondary purposes',
		'- în primul rând, dacă colectăm inițial datele dumneavoastră personale pentru un scop principal și':
			'- first, if we initially collect your personal data for a primary purpose and',
		'- dacă scopul nostru secundar este incompatibil cu scopul principal.':
			'- if our secondary purpose is incompatible with the primary purpose.',
		'10. Cât timp păstrăm datele dumneavoastră personale?':
			'10. How long do we keep your personal data?',
		'10.1. Limităm durata de stocare a datelor dumneavoastră personale la ceea ce este necesar pentru scopurile noastre de procesare.':
			'10.1. We limit the storage time of your personal data to what is necessary for our processing purposes.',
		'10.2. Revizuim necesitatea păstrării în continuare a datelor dumneavoastră personale În fiecare an analizăm datele colectate și prelucrate, în vederea filtrării, sortării și menținerii prelucrării doar pentru datele în cazul cărora scopul prelucrării este actual.':
			'10.2. We review the need for further retention of your personal data Each year we analyze the collected and processed data, in order to filter, sort and maintain the processing only for the data for which the purpose of the processing is current.',
		'10.3. Ștergem datele dumneavoastră personale într-un interval de timp specificat Ștergem datele dumneavoastră la un an de la data la care relația dumneavoastră cu noi se încheie (clauza aplicabilă în cazul newsletterelor, de la momentul la care cititorul nu mai accesează conținutul newsletterelor noastre).':
			'10.3. We delete your personal data within a specified period of time We delete your data one year from the date your relationship with us ends (the clause applicable to newsletters, from the moment the reader no longer accesses the content of our newsletters).',
		'10.4. Ștergem datele dumneavoastră în momentul în care ne solicitați acest lucru, cu excepția datelor a căror furnizare și prelucrare este impusă de o prevedere legală, pe care le ștergem în termenul prevăzut de lege pentru aceasta.':
			'10.4. We delete your data when you ask us to do so, except for the data whose provision and processing is required by a legal provision, which we delete within the time limit provided by law for it.',
		'10.4.1. În cazul în care păstrarea datelor dumneavoastră personale este necesară în scopurile specificate de lege, putem păstra în continuare datele dumneavoastră personale.':
			'10.4.1. If the storage of your personal data is required for the purposes specified by law, we may continue to keep your personal data.',
		'11. Dezvăluim datele dumneavoastră personale?':
			'11. Do we disclose your personal data?',
		'11.1. Dezvăluim datele dumneavoastră personale unor destinatari.':
			'11.1. We disclose your personal data to some recipients.',
		'11.2. Încadrarea legală pe care se bazează divulgarea datelor dumneavoastră personale către destinatari este':
			'11.2. The legal framework on which the disclosure of your personal data to recipients is based on',
		'11.2.1. consimțământul dumneavoastră.': '11.2.1. your consent.',
		'11.3. Dacă în viitor vom dezvălui datele dumneavoastră personale unui destinatar, atunci vă vom informa asupra':
			'11.3. If we disclose your personal data to a recipient in the future, then we will inform you on',
		'- momentului dezvăluirii și': '- the time of disclosure, and',
		'- numelui destinatarilor.': '- the name of the recipients.',
		'12. Transferăm datele dumneavoastră personale în afara UE sau SEE?':
			'12. Do we transfer your personal data outside the EU or the EEA?',
		'12.1. Nu vă transferăm datele personale':
			'12.1. We do not transfer your personal data',
		'- în țări din afara UE sau SEE, sau':
			'- in countries outside the EU or the EEA, or',
		'- către organizații internaționale.':
			'- to international organizations.',
		'13. Sunt datele dumneavoastră personale în siguranță?':
			'13. Is your personal data safe?',
		'13.1. Îți ținem în siguranță datele personale':
			'13.1. We keep your personal data safe',
		'- cu măsuri tehnice corespunzătoare,':
			'- with appropriate technical measures,',
		'- cu măsuri organizatorice adecvate,':
			'- with appropriate organizational measures,',
		'- cu un nivel adecvat de securitate,':
			'- with an adequate level of security,',
		'- împotriva prelucrării neautorizate,':
			'- against unauthorized processing,',
		'- împotriva prelucrării ilegale,': '- against illegal processing,',
		'- împotriva pierderii accidentale sau ilegale,':
			'- against accidental or illegal loss,',
		'- împotriva distrugerii accidentale sau ilegale,':
			'- against accidental or illegal destruction,',
		'- împotriva daunelor accidentale sau ilegale.':
			'- against accidental or illegal damages.',
		'13.2. Am implementat măsuri pentru a':
			'13.2. We have implemented measures to',
		'13.2.1. descoperi breșe de securitate.':
			'13.2.1. discover security breaches.',
		'13.2.2. documenta cauzele incidentului de securitate.':
			'13.2.2. document the causes of the security incident.',
		'13.2.3. documenta care date personale sunt afectate de incidentul de securitate.':
			'13.2.3. the document what personal data is affected by the security incident.',
		'13.2.4. documenta acțiunile (și motivele acțiunilor) pentru a remedia încălcarea securității.':
			'13.2.4. document actions (and reasons for actions) to remedy the security breach.',
		'13.2.5. limita consecințele incidentului de securitate.':
			'13.2.5. limit the consequences of the security incident.',
		'13.2.6. recupera date personale.': '13.2.6. recover personal data.',
		'13.2.7. reveni la o stare normală de prelucrare a datelor cu caracter personal.':
			'13.2.7. return to a normal state of processing of personal data.',
		'13.3. Dacă avem un grad de certitudine rezonabil că s-a produs o încălcare a securității prelucrării datelor dumneavoastră personale, atunci':
			'13.3. If we have a reasonable degree of certainty that a breach of security of processing of your personal data has occurred, then',
		'13.3.1. raportăm incidentul de securitate catre managementul companiei noastre.':
			'13.3.1. we report the security incident to the management of our company.',
		'13.3.2. desemnăm o persoana responsabilă pentru':
			'13.3.2. we designate a person responsible for',
		'- a analiza dacă încălcarea securității poate avea efecte nefavorabile pentru dumneavoastră,':
			'- analyze if the breach of security can have adverse effects for you,',
		'- a informa personalul relevant din organizația noastră,':
			'- to inform the relevant personnel of our organization,',
		'- a determina în ce măsură este necesară notificarea Autorității de Supraveghere cu privire la incidentul de securitate, și':
			'- determine to what extent it is necessary to notify the Supervisory Authority of the security incident, and',
		'- a stabili dacă este necesar să vă comunicăm informații despre incidentul de securitate.':
			'- determine if it is necessary to communicate information about the security incident.',
		'13.3.3. investigăm incidentul de securitate.':
			'13.3.3. we are investigating the security incident.',
		'13.3.4. încercăm să împiedicăm incidentul de securitate să ducă la':
			'13.3.4. we are trying to prevent the security incident from leading to',
		'- distrugerea accidentală sau ilegală a datelor personale,':
			'- accidental or illegal destruction of personal data,',
		'- pierderea accidentală sau ilegală a controlului datelor cu caracter personal,':
			'- accidental or illegal loss of control of personal data,',
		'- pierderea accidentală sau ilegală a accesului la datele cu caracter personal,':
			'- accidental or illegal loss of access to personal data,',
		'- alterarea accidentală sau ilegală a datelor cu caracter personal,':
			'- accidental or illegal alteration of personal data,',
		'- divulgarea neautorizată a datelor cu caracter personal, sau':
			'- unauthorized disclosure of personal data, or',
		'- acces neautorizat la datele cu caracter personal.':
			'- unauthorized access to personal data.',
		'13.3.5. depunem toate eforturile pentru atenuarea riscului imediat de producere a unui prejudiciu.':
			'13.3.5. we make every effort to mitigate the immediate risk of injury.',
		'13.3.6. notificăm Autoritatea de Supraveghere cu privire la incidentul de securitate, daca încălcarea este susceptibilă să ducă la un risc ridicat pentru drepturile și libertățile dumneavoastră.':
			'13.3.6. we notify the Supervisory Authority of the security incident, if the violation is likely to lead to a high risk for your rights and freedoms.',
		'13.3.7. vă informăm despre încălcarea securității':
			'13.3.7. we inform you about security breach',
		'- dacă încălcarea este susceptibilă să ducă la un risc ridicat pentru drepturile și libertățile dumneavoastră,':
			'- if the violation is likely to lead to a high risk for your rights and freedoms,',
		'- cât mai repede posibil,': '- as soon as possible,',
		'- prin canale de contact adecvate, de ex. prin e-mail, SMS, bannere proeminente pe site-ul nostru, comunicări poștale, reclame proeminente în mass-media etc.':
			'- through appropriate contact channels, e.g. via email, SMS, prominent banners on our site, postal communications, prominent advertisements in the media, etc.',
		'- Nu suntem obligați să vă informăm direct dacă':
			'- We are not obliged to inform you directly if',
		'- am luat măsuri pentru a face ca datele dvs. personale să fie incomprehensibile oricărei persoane care nu este autorizată să le acceseze,':
			'- we have taken steps to make your personal data incomprehensible to any person who is not authorized to access it,',
		'- imediat după incidentul de securitate, am luat măsuri pentru a ne asigura că riscul ridicat pentru drepturile și libertățile dumneavoastră nu mai este posibil să se producă sau':
			'- immediately after the security incident, we have taken steps to ensure that the high risk for your rights and freedoms is no longer possible or',
		'- ar implica eforturi disproporționate. Într-un astfel de caz, vă vom informa prin intermediul rețelelor publice.':
			'- would involve disproportionate efforts. In such a case, we will inform you through the public networks.',
		'14. Care sunt drepturile dumneavoastră?': '14. What are your rights?',
		'14.1. Vă respectăm drepturile care privesc protecția datelor dumneavoastră personale.':
			'14.1. We respect your rights regarding the protection of your personal data.',
		'14.2. Aveți dreptul de acces la datele dumneavoastră personale.':
			'14.2. You have the right to access your personal data.',
		'În cazul în care solicitați să confirmăm dacă prelucrăm sau nu datele dumneavoastră personale, atunci aveți un drept care ne obligă să confirmăm că noi':
			'If you ask us to confirm whether or not we process your personal data, then you have a right which obliges us to confirm that',
		'- prelucrăm datele dumneavoastră personale sau':
			'- we process your personal data or',
		'- nu prelucrăm datele dumneavoastră personale.':
			'- we do not process your personal data.',
		'Dreptul dumneavoastră de a obține confirmarea de la noi că prelucrăm (sau nu prelucrăm) datele dumneavoastră personale':
			'Your right to obtain confirmation from us that we process (or do not process) your personal data',
		'- nu include date anonime.': '- does not include anonymous data.',
		'- include doar datele personale care vă privesc.':
			'- includes only the personal data concerning you.',
		'- include date pseudonime care pot fi în mod clar legate de dumneavoastră.':
			'- includes pseudonym data that can be clearly linked to you.',
		'Trebuie să vă dăm acces la datele dumneavoastră personale dacă':
			'We need to give you access to your personal data if',
		'- solicitați să confirmăm dacă prelucrăm sau nu datele dumneavoastră personale și':
			'- ask us to confirm whether or not we process your personal data and',
		'- prelucrăm datele dumneavoastră personale și':
			'- we process your personal data and',
		'- solicitați accesul la datele dumneavoastră personale.':
			'- request access to your personal data.',
		'Trebuie să vă furnizăm o copie a datelor dumneavoastră personale dacă':
			'We must provide you with a copy of your personal data if',
		'- solicitați o copie a datelor dumneavoastră personale.':
			'- request a copy of your personal data.',
		'Dacă solicitați copii suplimentare ale datelor dumneavoastră personale, atunci vă putem percepe o taxă rezonabilă, care se bazează pe costurile administrative necesare pentru îndeplinirea cererii.':
			'If you request additional copies of your personal data, then we may charge you a reasonable fee, which is based on the administrative costs required to complete the request.',
		'Aveți dreptul la informațiile privind garanțiile pe care le-am implementat pentru transferul datelor dumneavoastră personale către o țară care este în afara UE și SEE dacă':
			'You have the right to information on the guarantees we have implemented for the transfer of your personal data to a country outside the EU and the EEA if',
		'- vă transferăm datele dumneavoastră personale într-o țară care este în afara UE și SEE.':
			'- we transfer your personal data to a country outside the EU and the EEA.',
		'14.3. Aveți dreptul la rectificarea datelor dumneavoastră personale.':
			'14.3. You have the right to rectify your personal data.',
		'Dreptul de a obține rectificarea datelor dumneavoastră cu caracter personal care sunt inexacte':
			'The right to obtain the rectification of your personal data that are inaccurate',
		'- include date pseudonime care pot fi în mod clar legate de dumneavoastră':
			'- includes pseudonym data that can be clearly linked to you',
		'Trebuie să rectificăm datele dumneavoastră personale dacă':
			'We must rectify your personal data if',
		'- prelucrăm datele dumneavoastră personale, și':
			'- we process your personal data, and',
		'- datele dumneavoastră personale sunt inexacte, și':
			'- your personal data is inaccurate, and',
		'- solicitați să obțineți rectificarea datelor dumneavoastră personale.':
			'- you ask to have your personal data rectified.',
		'Trebuie să completăm datele dumneavoastră personale dacă':
			'We must complete your personal data if',
		'- datele dumneavoastră personale sunt incomplete, și':
			'- your personal data is incomplete, and',
		'- solicitați să obțineți completarea datelor dumneavoastră personale.':
			'- you ask to have your personal data completed.',
		'Aveți dreptul să ne furnizați o declarație suplimentară.':
			'You have the right to provide us with an additional statement.',
		'Trebuie să comunicăm rectificarea datelor dumneavoastră personale destinatarilor datelor dumneavoastră personale (dacă există).':
			'We must communicate the rectification of your personal data to the recipients of your personal data (if any).',
		'Nu comunicăm rectificarea datelor dumneavoastră personale destinatarilor datelor dumneavoastră personale în cazul în care comunicarea către destinatar':
			'We do not communicate the rectification of your personal data to the recipients of your personal data when communicating to the recipient',
		'- este imposibilă, sau': '- is impossible, or',
		'- implică un efort disproporționat.':
			'- involves a disproportionate effort.',
		'14.4. Aveți dreptul la ștergerea datelor dumneavoastră personale.':
			'14.4. You have the right to delete your personal data.',
		'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate, dacă':
			'We must delete your personal data without undue delay, if',
		'- solicitați ștergerea datelor dumneavoastră personale, și':
			'- request the deletion of your personal data, and',
		'- datele dumneavoastră personale nu sunt necesare scopurilor noastre de prelucrare a datelor dumneavoastră personale.':
			'- your personal data are not necessary for our purposes for the processing of your personal data.',
		'Trebuie să ștergem datele dumneavoastră personale fără întârzieri nejustificate dacă':
			'We must delete your personal data without undue delay if',
		'- vă retrageți consimțământul pe care se bazează prelucrarea datelor dumneavoastră personale, și':
			'- withdraw the consent on which the processing of your personal data is based, and',
		'- nu există un alt temei juridic pentru prelucrarea datelor dumneavoastră personale.':
			'- there is no other legal basis for processing your personal data.',
		'- solicitați să obțineți ștergerea datelor dumneavoastră personale, și':
			'- you ask to have your personal data deleted, and',
		'- prelucrarea datelor dumneavoastră personale este necesară pentru îndeplinirea unei sarcini pe care o îndeplinim în interes public, sau':
			'- processing of your personal data is necessary for the performance of a task we perform in the public interest, or',
		'- prelucrarea datelor dumneavoastră personale este necesară în cadrul exercitării unei autorități oficiale cu care este învestit, și':
			'- the processing of your personal data is necessary in the exercise of an official authority with which it is invested, and',
		'- prelucrarea este necesară în scopul intereselor legitime pe care le urmărim, sau':
			'- processing is necessary for the purposes of the legitimate interests we pursue, or',
		'- prelucrarea este necesară în scopul intereselor legitime pe care o terță parte le urmărește, și':
			'- processing is necessary for the purposes of the legitimate interests that a third party pursues, and',
		'- obiectați la prelucrarea de către noi a datelor dumneavoastră personale, și':
			'- object to the processing by us of your personal data, and',
		'- prelucrarea datelor dumneavoastră cu caracter personal are un motiv legitim care nu prevalează asupra obiecției dumneavoastră':
			'- the processing of your personal data has a legitimate reason that does not prevail over your objection',
		'- solicitați ștergerea datelor dumneavoastră personale și':
			'- request the deletion of your personal data and',
		'- contestați prelucrarea de către noi a datelor dumneavoastră personale în scop de marketing direct, și':
			'- dispute the processing by us of your personal data for direct marketing purposes, and',
		'- prelucrarea datelor dumneavoastră personale este ilegală.':
			'- the processing of your personal data is illegal',
		'- datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale care ne revine în temeiul dreptului Uniunii sau al dreptului intern al unui stat membru.':
			'- personal data must be deleted in order to comply with a legal obligation under Union law or the national law of a Member State.',
		'- datele dumneavoastră personale au fost colectate în legătură cu oferirea de servicii ale societății informaționale.':
			'- your personal data has been collected in connection with the offering services belonging to informational society.',
		'Trebuie să comunicăm ștergerea datelor dumneavoastră personale destinatarilor către care le dezvăluim (dacă există).':
			'We must communicate the deletion of your personal data to the recipients to whom we disclose (if any).',
		'Nu comunicăm ștergerea datelor dumneavoastră personale destinatarilor către care le dezvăluim, în cazul în care comunicarea către destinatar':
			'We do not communicate the deletion of your personal data to the recipients to whom we disclose, if the communication to the recipient',
		'14.5. Aveți dreptul să obțineți de la noi restricționarea prelucrării datelor dumneavoastră personale.':
			'14.5. You have the right to obtain from us the restriction of the processing of your personal data.',
		'Dreptul dumneavoastră de a obține restricții privind prelucrarea datelor dumneavoastră personale':
			'Your right to obtain restrictions on the processing of your personal data',
		'- include datele personale care vă privesc.':
			'- includes personal data concerning you.',
		'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale pentru o perioadă, în scopul de a verifica exactitatea datelor dumneavoastră personale dacă':
			'We must restrict the processing of your personal data for a period, in order to verify the accuracy of your personal data if',
		'- solicitați să obțineți restricționarea prelucrării datelor dumneavoastră personale și':
			'- you request to obtain the restriction of the processing of your personal data and',
		'- contestați exactitatea datelor dumneavoastră personale.':
			'- you dispute the accuracy of your personal data.',
		'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale dacă':
			'We must restrict the processing of your personal data if',
		'- prelucrarea datelor dumneavoastră personale este ilegală și':
			'- processing of your personal data is illegal and',
		'- vă opuneți ștergerii datelor dumneavoastră personale.':
			'- you oppose the deletion of your personal data.',
		'- nu avem nevoie de datele dumneavoastră personale în scopul prelucrării noastre și':
			'- we do not need your personal data for the purpose of our processing and',
		'- solicitați datele dumneavoastră personale pentru a stabili o reclamație legală sau':
			'- you request your personal data to establish a legal claim or',
		'- solicitați datele dumneavoastră personale pentru a vă exercita o plângere legală sau':
			'- you request your personal data to file a legal complaint or',
		'- aveți nevoie de datele dumneavoastră personale pentru a vă apăra împotriva unei plângeri legale.':
			'- you need your personal data to defend yourself against a legal complaint.',
		'Trebuie să restricționăm prelucrarea datelor dumneavoastră personale dacă solicitați să obțineți restricționarea':
			'We must restrict the processing of your personal data if you request to obtain the restriction',
		'- prelucrării datelor dumneavoastră personale și':
			'- processing of your personal data and',
		'- obiectați de prelucrarea datelor dumneavoastră personale care sunt necesare pentru îndeplinirea unei sarcini pe care o îndeplinim în interes public, sau':
			'- you object to the processing of your personal data that is necessary for the performance of a task we perform in the public interest, or',
		'- vă opuneți prelucrării datelor dumneavoastră personale care sunt necesare în exercitarea unei autorități oficiale care ne este încredințată și':
			'- you object to the processing of your personal data that is necessary in the exercise of an official authority entrusted to us, and',
		'- vă opuneți prelucrării datelor dumneavoastră personale care sunt necesare în scopul intereselor legitime pe care le urmărim și':
			'- you object to the processing of your personal data that are necessary for the purposes of the legitimate interests we pursue and',
		'- așteptați să verificați dacă prelucrarea datelor dumneavoastră personale are un motiv legitim care nu depășește obiecția dumneavoastră':
			'- wait to see if the processing of your personal data has a legitimate reason that does not go beyond your objection',
		'Trebuie să comunicăm restricționarea prelucrării datelor dumneavoastră personale către destinatarii datelor dumneavoastră personale (dacă există).':
			'We must communicate the restriction of the processing of your personal data to the recipients of your personal data (if any).',
		'Nu comunicăm restricționarea prelucrării datelor dumneavoastră personale către destinatarii datelor dumneavoastră personale în cazul în care comunicarea către destinatar':
			'We do not communicate the restriction of the processing of your personal data to the recipients of your personal data if the communication to the recipient',
		'- este imposibilă sau': '- is impossible or',
		'Dacă restricționăm prelucrarea datelor dumneavoastră personale, atunci putem':
			'If we restrict the processing of your personal data, then we can',
		'- stoca datele dumneavoastră personale,':
			'- store your personal data,',
		'- prelucra datele dumneavoastră personale pe baza consimțământului dumneavoastră,':
			'- process your personal data based on your consent,',
		'- prelucra datele dumneavoastră personale pentru a întemeia o reclamație legală,':
			'- process your personal data to file a legal complaint,',
		'- prelucra datele dumneavoastră personale pentru a exercita o plângere legală,':
			'- process your personal data to execute a legal complaint,',
		'- prelucra datele dumneavoastră personale pentru a ne apăra împotriva unei plângeri legale,':
			'- process your personal data to defend us against a legal complaint,',
		'- prelucra datele dumneavoastră personale pentru a proteja drepturile unei persoane,':
			'- process your personal data to protect the rights of a person,',
		'- prelucra datele dumneavoastră personale din motive de interes public ale Uniunii sau ale unui stat membru.':
			'- process your personal data for reasons of public interest of the Union or of a State Member.',
		'Dacă obțineți o restricționare privind prelucrarea datelor dumneavoastră personale, trebuie să vă informăm înainte de ridicarea restricționării.':
			'If you obtain a restriction on the processing of your personal data, we must inform you before lifting the restriction.',
		'14.6. Dacă prelucrăm datele dumneavoastră personale în scopuri de marketing direct, inclusiv profilarea (în măsura în care este legată de un astfel de marketing direct), aveți dreptul să obiectați cu privire la prelucrarea datelor dumneavoastră personale în acest scop.':
			'14.6. If we process your personal data for direct marketing purposes, including profiling (insofar as it relates to such direct marketing), you have the right to object to the processing of your personal data for this purpose.',
		'Dreptul dumneavoastră de a obiecta la prelucrarea datelor dumneavoastră personale în scopuri de marketing direct':
			'Your right to object to the processing of your personal data for direct marketing purposes',
		'- este un drept pe care îl aveți în orice moment.':
			'- is a right you have at any time.',
		'Dacă obiectați la prelucrarea datelor dumneavoastră personale în scopuri de marketing direct, atunci trebuie să omitem prelucrarea datelor dumneavoastră personale în acest scop.':
			'If you object to the processing of your personal data for direct marketing purposes, then we must omit the processing of your personal data for this purpose.',
		'Dacă vă procesăm datele dumneavoastră personale în scopuri de marketing direct, inclusiv profilarea (în măsura în care este legată de un astfel de marketing direct), atunci':
			'If we process your personal data for direct marketing purposes, including profiling (insofar as it relates to such direct marketing), then',
		'- trebuie să vă aducem la cunoștință, în mod explicit, acest drept, cel mai târziu la momentul primei comunicări cu dumneavoastră și':
			'- we must explicitly notify you of this right, at the latest at the time of first communication with you, and',
		'- trebuie să vă prezentăm acest drept în mod clar și separat de orice altă informație.':
			'- we must present this right to you in a clear and separate manner from any other information.',
		'15. Cum vă puteți exercita drepturile?':
			'15. How can you exercise your rights?',
		'15.1. Vă invităm să comunicați cu noi despre exercitarea drepturilor dumneavoastră privind protecția datelor dumneavoastră personale.':
			'15.1. We invite you to communicate with us about the exercise of your rights regarding the protection of your personal data.',
		'15.2. Acceptăm numai solicitări scrise, deoarece nu putem face față solicitărilor verbale imediat, fără să':
			'15.2. We only accept written requests, because we cannot deal with verbal requests immediately, without',
		'- analizăm mai întâi conținutului cererii și fără să':
			'- we first analyze the content of the application and without it',
		'- vă identificăm mai întâi.': '- we identify you first.',
		'Cererea dumneavoastră trebuie să conțină o descriere detaliată și precisă a dreptului pe care doriți să-l exercitați.':
			'Your application must contain a detailed and precise description of the right you wish to exercise.',
		'Trebuie să ne furnizați o copie a unui document de identificare pentru a vă confirma identitatea ca, de exemplu':
			'You must provide us with a copy of an identification document to confirm your identity, for example',
		'- un carnet de identitate sau': '- an identity card or',
		'- un pașaport.': '- a passport.',
		'Documentul trebuie să conțină': 'The document must contain',
		'- un număr de identificare,': '- an identification number,',
		'- țara de emisiune,': '- Country of Issue,',
		'- perioada de valabilitate,': '- Period of validity,',
		'- numele dumneavoastră,': '- your name,',
		'- adresa dumneavoastră, și': '- your address, and',
		'- data dumneavoastră de naștere.': '- your date of birth.',
		'Orice alte date conținute în copia documentului de identificare, cum ar fi o fotografie sau orice alte caracteristici personale, pot fi mascate.':
			'Any other data contained in the copy of the identification document, such as a photograph or any other personal characteristics, may be hidden.',
		'Nu vom accepta alte mijloace de a vă asigura identitatea.':
			'We will not accept other means of securing your identity.',
		'Dacă doriți să propuneți alternative, le vom evalua de la caz la caz.':
			'If you want to propose alternatives, we will evaluate them on a case-by-case basis.',
		'Folosirea informațiilor din documentul dumneavoastră de identificare':
			'Usage of information from your identification document',
		'- este limitată la activitatea de confirmare a identității dumneavoastră și':
			'- is limited to the activity of confirming your identity, and',
		'- nu va genera o stocare a datelor dumneavoastră personale mai mult decât este necesar în acest scop.':
			'- it will not generate more storage of your personal data than is necessary for this purpose.',
		'Puteți trimite solicitarea dumneavoastră referitoare la protecția datelor dumneavoastră personale la':
			'You can send your request regarding the protection of your personal data to',
		'15.2.1 email contact@cv30.co': '15.2.1 email contact@cv30.co',
		'15.3. Veți primi răspunsul nostru la cererile dumneavoastră care vizează protecția datelor dumneavoastră personale la adresa de pe care am primit solicitarea.':
			'15.3. You will receive our response to your requests regarding the protection of your personal data at the address from which we received the request.',
		'15.4. Am desemnat o persoană responsabilă de tratarea cererilor dumneavoastră referitoare la protecția datelor dumneavoastră personale.':
			'15.4. We have appointed a person responsible for handling your requests regarding the protection of your personal data.',
		'15.5. Am implementat politici care ne asigură faptul că o cerere privind protecția datelor dumneavoastră personale este':
			'15.5. We have implemented policies that assure us that a request regarding the protection of your personal data is',
		'- recunoscută, și': '- recognized, and',
		'- soluționată în termenele prevăzute de lege.':
			'- resolved within the terms provided by law.',
		'15.6. Vă informăm despre modul în care ne ocupăm de cererea dumneavoastră (prin care vă exercitați drepturile) cu privire la protecția datelor dumneavoastră personale':
			'15.6. We inform you of how we handle your request (by which you exercise your rights) regarding the protection of your personal data',
		'15.6.1 intr-o lună de la data primirii cererii.':
			'15.6.1 within one month from the date of receipt of the request.',
		'16. Aveți dreptul de a depune o plângere?':
			'16. Are you entitled to file a complaint?',
		'16.1. Puteți depune o plângere la o autoritate de supraveghere':
			'16.1. You can file a complaint with a supervisory authority',
		'- la domiciliul dumneavoastră obișnuit în UE și SEE.':
			'- at your usual place of residence in the EU and the EEA.',
		'- la locul dumneavoastră de muncă în UE și SEE.':
			'- at your place of work in the EU and the EEA.',
		'- la locul presupusei încălcări în UE și în SEE.':
			'- at the place of the alleged infringement in the EU and the EEA.',
		'Autoritatea de supraveghere trebuie să vă informeze într-un termen rezonabil cu privire la':
			'The supervisory authority must inform you within a reasonable time',
		'- progresul plângerii și': '- the progress of the complaint and',
		'- rezultatul plângerii.': '- the result of the complaint.',
		'16.2. Puteți mandata o organizație să depună o plângere în numele dumneavoastră la o autoritate de supraveghere.':
			'16.2. You can mandate an organization to file a complaint on your behalf to a supervisory authority.',
		'Autoritatea de supraveghere trebuie să vă informeze într-un termen rezonabil cu privre la':
			'The Supervisory Authority must inform you within a reasonable time regarding',
		'16.3. Aveți dreptul de a exercita o cale de atac judiciară în UE și în SEE împotriva':
			'16.3. You have the right to sue in the EU and the EEA',
		'- unui operator,': '- an operator,',
		'- unui împuternicit și': '- a power of attorney and',
		'- unei Autorități de Supraveghere.': '- a Supervisory Authority.',
		'16.4. Puteti mandata o organizație să exercite, în numele dumneavoastră, dreptul':
			'16.4. You can mandate an organization to exercise the right on your behalf',
		'- la o cale de atac judiciară.': '- a judicial appeal.',
		'- la o despăgubire pentru un prejudiciu rezultat ca urmare a unei încălcări a legii privind protecția datelor cu caracter personal.':
			'- to compensation for damage resulting from a violation of the law on the protection of personal data.',
		'17. Puteți alege setările de confidențialitate?':
			'17. Can you choose the privacy settings?',
		'17.1. În prezent, nu ne puteți declara alegerea dumneavoastră privind prelucrarea datelor dumneavoastră personale.':
			'17.1. Currently, you cannot declare your choice regarding the processing of your personal data.',
		'18. Veți fi informat despre schimbările politicii de confidențialitate?':
			'18. Will you be informed about changes to the privacy policy?',
		'18.1. Dacă ne schimbăm politica de confidențialitate, vom publica o nouă versiune a acesteia.':
			'18.1. If we change our privacy policy, we will publish a new version of it.',
		'18.2. Nu punem la dispoziție versiunile anterioare ale politicii noastre de confidențialitate.':
			'18.2. We do not provide previous versions of our privacy policy.',
		'19. Explicații cu privire la termenii și expresiile utilizați în această notă de informare':
			'19. Explanations regarding the terms and expressions used in this information note',
		'19.1. Toţi termenii şi expresiile folosiți în această Notă de Informare vor avea înţelesul dat mai jos, cu excepţia cazului în care se prevede altfel în cuprinsul Notei de Informare.':
			'19.1. All terms and expressions used in this Information Note will have the meaning given below, unless otherwise stated in the Information Note.',
		'19.1.1. Date cu caracter personal înseamnă orice informații privind o persoană fizică identificată sau identificabilă („persoana vizată”).':
			'19.1.1. Personal data means any information about an identified or identifiable natural person ("data subject").',
		'O persoană fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, în special prin referire la un element de identificare, cum ar fi':
			'An identifiable private person is a person who can be identified, directly or indirectly, especially by reference to an identification element, such as',
		'- un nume': '- a name',
		'- un număr de identificare': '- an identification number',
		'- date de localizare': '- location data',
		'- un identificator online': '- an online identifier',
		'- identitatea fizică a unei persoane persoane fizice':
			'- physical identity of a natural person',
		'- identitatea fiziologică a unei persoane persoane fizice':
			'- the physiological identity of a natural person',
		'- identitatea genetică a unei persoane persoane fizice':
			'- the genetic identity of a natural person',
		'- identitatea psihică a unei persoane persoane fizice':
			'- the mental identity of a natural person',
		'- identitatea economică a unei persoane persoane fizice':
			'- the economic identity of a natural person',
		'- identitatea culturală a unei persoane persoane fizice':
			'- the cultural identity of a natural person',
		'- identitatea socială a unei persoane persoane fizice':
			'- the social identity of a natural person',
		'19.1.2. Datele personale sensibile sunt - conform GDPR - numite categorii speciale de date personale.':
			'19.1.2. Sensitive personal data is - according to the GDPR - called special categories of personal data.',
		'Datele personale sunt sensibile dacă prelucrarea acestor date personale dezvăluie':
			'Personal data are sensitive if the processing of these personal data reveals',
		'- originea rasială,': '- racial origin,',
		'- originea etnică,': '- ethnic origin,',
		'- opiniile politice,': '- political opinions,',
		'- credințele religioase,': '- religious beliefs,',
		'- credințele filosofice,': '- philosophical beliefs,',
		'- apartenența la un sindicat.': '- membership of a trade union.',
		'De asemenea, datele personale sunt sensibile dacă':
			'Also, personal data are sensitive if',
		'- datele genetice sunt prelucrate pentru scopul de a identifica unic o persoană fizică,':
			'- genetic data are processed for the purpose of uniquely identifying a private person,',
		'- datele biometrice sunt prelucrate pentru scopul de a identifica unic o persoană fizică.':
			'- biometric data are processed for the purpose of uniquely identifying a private person.',
		'Datele personale sensibile mai includ':
			'Sensitive personal data also includes',
		'- date care vizează starea de sănătate,': '- data on health status,',
		'- date care vizează viața sexuală a unei persoane fizice,':
			'- data concerning the sexual life of a private person,',
		'- date care vizează orientarea sexuală a unei persoane fizice.':
			'- data concerning the sexual orientation of a private person.',
		'19.1.3. Nu există o listă exhaustivă a acestor date cu caracter personal.':
			'19.1.3. There is no exhaustive list of such personal data.',
		'19.1.4. Pseudonimizarea Datelor Personale înseamnă prelucrarea datelor cu caracter personal într-un asemenea mod încât acestea să nu mai poată fi atribuite unei anume persoane vizate fără a se utiliza informații suplimentare, cu condiția ca aceste informații suplimentare să fie stocate separat și să facă obiectul unor măsuri de natură tehnică și organizatorică care să asigure neatribuirea respectivelor date cu caracter personal unei persoane fizice identificate sau identificabile.':
			'19.1.4. Pseudonymization of Personal Data means the processing of personal data in such a way that they can no longer be assigned to a particular data subject without using additional information, under condition that such additional information is stored separately and is subject to technical and organizational measures that ensures the non-attribution of the respective personal data to an identified or identifiable private person.',
		'19.1.5. Prelucrare semnifică orice operațiune sau set de operațiuni efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi':
			'19.1.5. Processing means any operation or set of operations performed on personal data or on personal data sets, with or without the use of automated means, such as',
		'- colectarea,': '- collect,',
		'- înregistrarea,': '- register,',
		'- organizarea,': '- organization,',
		'- structurarea,': '- structuring,',
		'- stocarea,': '- storage,',
		'- adaptarea,': '- adaptation,',
		'- alterarea,': '- alteration,',
		'- extragerea,': '- extraction,',
		'- consultarea,': '- consultation,',
		'- utilizarea,': '- usage,',
		'- ștergerea sau distrugerea': '- deletion or destruction',
		'- etc.': '- etc.',
		'19.1.6. Restricționarea prelucrării înseamnă marcarea datelor personale stocate cu scopul de a limita prelucrarea acestora în viitor.':
			'19.1.6. Restriction of processing means marking of personal data stored in order to limit their processing in the future.',
		'19.1.7. Scopul prelucrării înseamnă motivul pentru care este realizată prelucrarea datelor personale.':
			'19.1.7. The purpose of the processing is the reason why the processing of personal data is performed.',
		'19.1.8. Profilarea': '19.1.8. Profiling',
		'- (1) trebuie să fie o formă automată de prelucrare, ce include':
			'- (1) must be an automatic form of processing, which includes',
		'- Prelucrare exclusiv automată (la care face referire Art. 22 din GDPR) și':
			'- Exclusive automatic processing (referred to in Article 22 of the GDPR) and',
		'- Prelucrare parțial automată (dacă o persoană fizică este implicată în prelucrarea datelor personale nu înseamnă neapărat ca prelucrarea nu constituie profilare)':
			'- Partially automatic processing (if a private person is involved in the processing of personal data does not necessarily mean that processing does not constitute profiling)',
		'- (2) trebuie să fie efectuată cu privire la datele cu caracter personal; și':
			'- (2) must be carried out with regard to personal data; and',
		'- (3) obiectivul profilării trebuie să fie acela de a evalua aspectele personale legate de o persoană fizică, în special de a analiza sau de a face previziuni despre persoane.':
			'- (3) the aim of profiling should be to evaluate the personal aspects related to a private person, in particular to analyze or make predictions about persons.',
		'Rețineți că simpla evaluare sau clasificare a persoanelor în mod automat pe baza unor caracteristici cum ar fi vârsta, sexul și înălțimea acestora ar putea fi considerate profilare automată, indiferent de scopul predictiv.':
			'Note that the mere evaluation or classification of persons automatically based on characteristics such as their age, gender and height could be considered automatic profiling, regardless of their predictive purpose.',
		'19.1.9. Decizii bazate exclusiv pe prelucrarea automată':
			'19.1.9. Decisions based solely on automatic processing',
		'- (1) înseamnă luarea unor decizii prin mijloace tehnologice fără implicare umană; și care':
			'- (1) means making decisions through technological means without human involvement; and which',
		'- (2) se bazează pe datele cu caracter personal':
			'- (2) is based on personal data',
		'- furnizate direct de persoanele în cauză (cum ar fi răspunsurile la un chestionar); sau':
			'- provided directly by the persons concerned (such as the answers to a questionnaire); or',
		'- observate cu privire la persoane (cum ar fi datele privind locația, colectate prin intermediul unei aplicații) sau':
			'- observed on people (such as location data, collected through an application), or',
		'- derivate sau deduse, cum ar fi profilul persoanei care a fost deja creat (de ex. un scor de credit) pot fi realizate cu sau fără profilare; profilarea poate avea loc fără a lua decizii automate.':
			'- derivatives or deductions, such as the profile of the person who has already been created (e.g. a credit score) can be made with or without profiling; profiling can take place without making automatic decisions.',
		'19.1.10. Operator înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care, singur sau împreună cu altele, stabilește scopurile și mijloacele de prelucrare a datelor cu caracter personal; atunci când scopurile și mijloacele prelucrării sunt stabilite prin dreptul Uniunii sau dreptul intern, operatorul sau criteriile specifice pentru desemnarea acestuia pot fi prevăzute în dreptul Uniunii sau în dreptul intern.':
			'19.01.10. Operator means the private person or legal entity, the public authority, the agency or another institution which, alone or together with others, establishes the purposes and the means for the processing of personal data; when the purposes and means of processing are established by Union law or national law, the operator or the specific criteria for its designation may be laid down in Union law or national law.',
		'19.1.11. Împuternicit înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care prelucrează datele cu caracter personal în numele și pe seama operatorului.':
			'19.01.11. Authorized means the private person or legal entity, the public authority, the agency or other institution that processes personal data on behalf and on behalf of the operator.',
		'19.1.12. Destinatar înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism căreia (căruia) îi sunt divulgate datele cu caracter personal, indiferent dacă este sau nu o parte terță. Cu toate acestea, autoritățile publice cărora li se pot comunica date cu caracter personal în cadrul unei anumite anchete în conformitate cu dreptul Uniunii sau cu dreptul intern nu sunt considerate destinatari; prelucrarea acestor date de către autoritățile publice respective respectă normele aplicabile în materie de protecție a datelor, în conformitate cu scopurile prelucrării.':
			'19.01.12. Recipient means the private person or legal entity, public authority, agency or other institution to whom personal data are disclosed, whether or not it is a third party. However, public authorities to whom personal data may be communicated in a particular investigation in accordance with Union or national law are not considered as recipients; the processing of this data by the respective public authorities complies with the applicable data protection rules, in accordance with the purposes of the processing.',
		'19.1.13. Parte Terță înseamnă o persoană fizică sau juridică, autoritate publică, agenție sau organism altul decât persoana vizată, operatorul, persoana împuternicită de operator și persoanele care, sub directa autoritate a operatorului sau a persoanei împuternicite de operator, sunt autorizate să prelucreze date cu caracter personal.':
			'19.01.13. Third Party means a private person or legal entity, public authority, agency or institution other than the data subject, the operator, the person empowered by the operator and the persons who, under the direct authority of the operator or the person empowered by the operator, are authorized to process personal data.',
		'19.1.14. Reprezentant înseamnă o persoană fizică sau juridică stabilită în Uniune, desemnată în scris de către operator sau persoana împuternicită de operator în temeiul articolului 27, care reprezintă operatorul sau persoana împuternicită în ceea ce privește obligațiile lor respective care le revin în temeiul prezentului regulament.':
			'01.19.14. Representative means a private person or legal entity established in the Union, designated in writing by the operator or the person empowered by the operator under Article 27, who represents the operator or person empowered in respect of their respective obligations under this Regulation.',
		'19.1.15. Autoritate de supraveghere înseamnă o autoritate publică independentă instituită de un stat membru în temeiul articolului 51 GDPR.':
			'01.19.15. Supervisory authority means an independent public authority established by a Member State under Article 51 GDPR.',
		'19.1.16. Reguli corporatiste obligatorii înseamnă politicile în materie de protecție a datelor cu caracter personal care trebuie respectate de un operator sau de o persoană împuternicită de operator stabilită pe teritoriul unui stat membru, în ceea ce privește transferurile sau seturile de transferuri de date cu caracter personal către un operator sau o persoană împuternicită de operator în una sau mai multe țări terțe în cadrul unui grup de întreprinderi sau al unui grup de întreprinderi implicate într-o activitate economică comună.':
			'19.01.16. Mandatory corporate rules mean the policies regarding the protection of personal data that must be respected by an operator or a person authorized by the operator established on the territory of a Member State, regarding the transfers or sets of transfers of personal data to an operator or a person authorized by the operator in one or more third countries within a group of undertakings or a group of undertakings involved in a common economic activity.',
		'19.1.17. Scutul de confidențialitate UE-SUA (Privacy Shield)':
			'19.01.17. EU-US Privacy Shield (Privacy Shield)',
		'- Cadrul UE - SUA Privacy Shield a fost pus la punct de către Departamentul de Comerț al SUA și Comisia Europeană pentru a oferi companiilor de pe ambele părți ale Atlanticului un mecanism pentru a se conforma cerințelor de protecție a datelor atunci când transferă date cu caracter personal din Uniunea Europeană către Statele Unite în sprijinul comerțului transatlantic. La 12 iulie 2016 Comisia Europeană a aprobat cadrul UE-SUA Privacy Shield ca fiind adecvat pentru a permite transferul de date în conformitate cu legislația UE.':
			'- The EU-US Privacy Shield framework has been developed by the US Department of Commerce and the European Commission to provide companies on both sides of the Atlantic with a mechanism to comply with data protection requirements when transferring personal data from the European Union to the United States in support of transatlantic trade. On July 12, 2016 the European Commission approved the EU-US Privacy Shield framework as being appropriate to allow data transfer in accordance with EU law.',
		'19.1.18. Deciziile de Adecvare ale Comisiei':
			'19.01.18. Commission Adequacy Decisions',
		'- Comisia Europeană are competența de a determina, pe baza articolului 45 GDPR, dacă o țară din afara UE oferă un nivel adecvat de protecție a datelor, fie prin legislația internă, fie prin angajamentele internaționale pe care le-a încheiat.':
			'- The European Commission has the power to determine, on the basis of Article 45 GDPR, whether a non-EU country offers an adequate level of data protection, either by national law or by the international commitments concluded.',
		'- Efectul unei astfel de decizii este că datele cu caracter personal pot proveni din SEE (UE și Norvegia, Liechtenstein și Islanda) în acea țară terță, fără a fi necesară nicio altă măsură de protecție.':
			'- The effect of such a decision is that personal data may come from the EEA (EU and Norway, Liechtenstein and Iceland) in that third country, without any further protection being required.',
		'- Comisia Europeană a recunoscut, până în prezent, un nivel de protecție adecvat, pentru Andorra, Argentina, Canada (organizații comerciale - PIPEDA), Insulele Feroe, Guernsey, Israel, Insula Man, Jersey, Noua Zeelandă, Elveția, Uruguay și SUA (limitată la cadrul Privacy Shield).':
			'- The European Commission has so far recognized an adequate level of protection for Andorra, Argentina, Canada (trade organizations - PIPEDA), Faroe Islands, Guernsey, Israel, Isle of Man, Jersey, New Zealand, Switzerland, Uruguay and the USA (limited to the Privacy Shield framework).',
		'19.1.19. Încălcarea securității datelor cu caracter personal înseamnă o încălcare a securității care duce, în mod accidental sau ilegal, la distrugerea, pierderea, modificarea, sau divulgarea neautorizată a datelor cu caracter personal transmise, stocate sau prelucrate într-un alt mod, sau la accesul neautorizat la acestea.':
			'19.01.19. Violation of the security of personal data means a breach of security that results, accidental or illegal, to destruction, loss, modification, or unauthorized disclosure of personal data transmitted, stored or otherwise processed, or to unauthorized access at these.',
		'19.1.20. Întreprindere înseamnă o persoană fizică sau juridică ce desfășoară o activitate economică, indiferent de forma juridică a acesteia, inclusiv parteneriate sau asociații care desfășoară în mod regulat o activitate economică.':
			'01.19.20. Enterprise means a private person or legal entity carrying on an economic activity, regardless of its legal form, including partnerships or associations that regularly carry out an economic activity.',
		'19.1.21. Grup de întreprinderi înseamnă o întreprindere care exercită controlul și întreprinderile controlate de aceasta;':
			'19.01.21. An enterprise group means an enterprise which exercises control and the enterprises controlled by it.',
		'19.1.22. Organizație internațională înseamnă o organizație și organismele sale subordonate reglementate de dreptul internațional public sau orice alt organism care este instituit printr-un acord încheiat între două sau mai multe țări sau în temeiul unui astfel de acord.':
			'19.01.22. International organization means an organization and its subordinate institutions governed by public international law or any other institution that is established by an agreement concluded between two or more countries or under such an agreement.',
		'Limitarea responsabilității': 'Limitation of liability',
		'Afirmaţi în mod expres că aţi luat cunoştinţă şi sunteţi de acord că această companie nu va fi răspunzătoare pentru nici un fel de pagube directe, indirecte, accidentale, speciale, inclusiv, dar nu limitat la nerealizare de profit, posibilităţii de folosire, date sau alte pagube (chiar dacă compania a fost înainte informată de posibilitatea apariţiei unor asemenea pierderi), rezultând din':
			'You expressly acknowledge that you have become aware of and agree that this company will not be liable for any direct, indirect, accidental, special, including, but not limited to lack of profit, types of usage, data or other damages (even if the company was previously informed of the possibility of such losses), resulting from',
		'1. utilizarea sau imposibilitatea utilizării serviciilor site-ului;':
			'1. the use or impossibility of using the services of the site;',
		'2. acces neautorizat la sau deteriorarea transmisiilor sau datelor dumneavoastră;':
			'2. Unauthorized access to or damage to your transmissions or data;',
		'3. declaraţii sau acţiuni a oricărei terţe părţi asupra serviciilor site-ului;':
			'3. statements or actions of any third party on the services of the site;',
		'4. orice altă problemă legată de serviciile site-ului.':
			'4. any other problem related to the services of the site.',
		'Declaraţi în mod expres că înţelegeţi şi că sunteţi de acord cu următoarele afirmaţii':
			'You expressly state that you understand and agree with the following statements',
		'1. utilizarea serviciilor site-ului se face pe propria răspundere. Serviciile sunt furnizate pe principiul "aşa cum sunt" sau "aşa cum sunt disponibile".':
			'1. the use of the services of the site is made on its own responsibility. Services are provided on the "as they are" or "as available" principle.',
		'2. Compania nu oferă nici o garanţie că':
			'2. The Company makes no warranty that',
		'serviciile vor împlini toate cerinţele dumneavoastră;':
			'- the services will meet all your requirements;',
		'serviciile vor fi neîntrerupte, la timp, sigure sau fără erori;':
			'- services will be uninterrupted, on time, secure or error free;',
		'rezultatele ce se vor putea obţine din folosirea site-ului şi a serviciilor puse la dispoziţie vor fi corecte sau de încredere;':
			'- the results that can be obtained from the use of the site and the services provided will be correct or reliable;',
		'orice eroare de program va fi corectată;':
			'- any program errors will be corrected;',
		'3. Orice material descărcat sau obţinut în alt fel prin utilizarea serviciilor puse la dispoziţie de companie se află astfel la discreţia şi poate fi folosit doar pe riscul propriu şi veţi singura persoană responsabilă de eventualele distrugeri cauzate computerului de pe care accesaţi site-ul sau alte pierderi de date ce pot rezulta din descărcarea acestui tip de conţinut.':
			'3. Any material downloaded or otherwise obtained through the use of the services provided by the company is thus at its discretion and can be used only at your own risk and you will be the only person responsible for any damage caused to the computer from which you access the site or other data loss that may result from downloading this type of content.',
		'Generalităţi ': 'Overview',
		'Dacă vreo prevedere din acest capitol "Termeni şi condiţii" este considerată invalidă de către forurile legale competente, invaliditatea unei asemenea prevederi nu va afecta valabilitatea celorlalte prevederi ale "Termeni şi condiţii", acestea rămânând în vigoare. "Termeni şi condiţii" constituie în întregime un acord încheiat între dumneavoastră şi companie în privinţa utilizării site-ului www.cv30.co.Aceste condiţii pot fi modificate de noi la anumite intervale, fără o anunţare sau o acceptare prealabilă a utilizatorilor. Puteţi citi cea mai recentă versiune a acestor condiţii oricând, accesând aceasta pagină.':
			'If any provision of this chapter "Terms and conditions" is considered invalid by the competent legal forums, the invalidity of such a provision will not affect the validity of the other provisions of the "Terms and conditions", these remaining in force. "Terms and Conditions" constitutes an entire agreement between you and the company regarding the use of the website www.cv30.co. These conditions may be changed by us at certain intervals without prior notice or acceptance by users.You can read the latest version of these terms at any time by accessing this page.',
		'Prezentul acord constituie unică convenţie dintre dumneavoastră şi companie şi reglementează folosirea de către dumneavoastră a serviciilor site-ului www.cv30.co , impunându-se în faţa oricărui acord precedent dintre dumneavoastră şi companie sau site-urile sale (inclusiv, dar nu limitat la o versiune precedentă a Regulamentului). Puteţi de asemenea fi supuşi unei alte versiuni a Regulamentului care se poate aplica atunci când folosiţi alte servicii ale companiei sau ale partenerilor săi, conţinut al oricărei terţe părţi, sau software a oricărui terţ. Regulamentul şi relaţiile dintre utilizatori şi companie vor fi guvernate de legile aplicabile în România.':
			'This agreement is the only agreement between you and the company and regulates your use of the services of the www.cv30.co site, imposing itself in front of any previous agreement between you and the company or its sites (including but not limited to an earlier version of the Regulation). You may also be subject to another version of the Regulation that may apply when using other services of the company or its partners, content from any third party, or software from any third party. The regulation and the relations between the users and the company will be governed by the laws applicable in Romania.',
		'Actualizarea acordului de utilizare a fost realizata la data de 15 mai 2020.':
			'The use agreement was updated on May 15, 2020.',
		'We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.':
			'We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.',
		'Works at': 'Works at',
		'Are you sure you want to delete this post?':
			'Are you sure you want to delete this post?',
		Yes: 'Yes',
		No: 'No',
		Delete: 'Delete',
		'Long Article': 'Long Article',
		'Are you sure you want to delete this article?':
			'Are you sure you want to delete this article?',
		'choose up to 5 tags for your post':
			'choose up to 5 tags for your post',
		'choose up to 5 tags for your article':
			'choose up to 5 tags for your article',
		'Description goes here': 'Description goes here',
		'Add new experience': 'Add new experience',
		Title: 'Title',
		Description: 'Description',
		Start: 'Start',
		End: 'End',
		Region: 'Region',
		Location: 'Location',
		Company: 'Company',
		Position: 'Position',
		'Use this as my current position': 'Use this as my current position',
		Cancel: 'Cancel',
		Save: 'Save',
		'Are you sure you want to delete this experience?':
			'Are you sure you want to delete this experience?',
		'search and select desired skills': 'search and select desired skills',
		cancel: 'cancel',
		save: 'save',
		'Edit experience': 'Edit experience',
		'your values, go here': 'your values, go here',
		'First Name': 'First Name',
		'Last Name': 'Last Name',
		Username: 'Username',
		'letters, numbers, including .-_': 'letters, numbers, including .-_',
		'Check Availability': 'Check Availability',
		'New Password': 'New Password',
		'only fill in to change the password':
			'only fill in to change the password',
		'Confirm New Password': 'Confirm New Password',
		'must match the new password': 'must match the new password',
		'Your current password is missing or incorrect':
			'Your current password is missing or incorrect',
		Update: 'Update',
		Name: 'Name',
		'Date founded': 'Date founded',
		'Industry search': 'Industry search',
		Website: 'Website',
		Phone: 'Phone',
		Email: 'Email',
		Addresses: 'Addresses',
		'saved!': 'saved!',
		"We couldn't find anything\nTry to refine your search term":
			"We couldn't find anything\nTry to refine your search term",
		'Search for Employers, Teams, Jobs or Articles':
			'Search for Employers, Teams, Jobs or Articles',
		'No account associated with this email address':
			'No account associated with this email address',
		'If the address exists in our database, you will receive an email with the password recovery instructions.':
			'If the address exists in our database, you will receive an email with the password recovery instructions.',
		'Sorry, this mail could not be sent at this time. Please try again later.':
			'Sorry, this mail could not be sent at this time. Please try again later.',
		'Your email address or password is incorrect. Please try again':
			'Your email address or password is incorrect. Please try again',
		'No tags found': 'No tags found',
		'Uploading ... ': 'Uploading ... ',
		'Preparing ...': 'Preparing ...',
		'Type a location...': 'Type a location...',
		"I'll will do it later": "I'll will do it later",
		'Describe your company': 'Describe your company',
		// 'Discover our team': 'Discover our team',
		'Meet our teams': 'Meet our teams',
		'Create page': 'Create <br /> page',
		'Upload profile image': 'Upload <br /> profile image',
		'Upload cover photo/video': 'Upload <br /> cover photo/video',
		'Create teams': 'Create <br /> teams',
		'Create Roles': 'Create <br /> Roles',
		'Write a story': 'Write <br /> a story',
		'Create jobs': 'Create <br /> jobs',
		'Your new company page is ready!': 'Your new company page is ready!',
		'Tell visitor more about your company!':
			'Tell visitor more about your company!',
		'Welcome, CompanyName!': 'Welcome, {{companyName}}!',
		'Upload Profile Picture': 'Upload Profile Picture',
		Industry: 'Industry',
		'Company type': 'Company type',
		'Company size': 'Company size',
		'Location of office(s)': 'Location of office(s)',
		'Describe your company here...': 'Describe your company here...',
		'Add the e-mail addresses': 'Add the e-mail addresses',
		'Add the e-mail addresses of your team members':
			'Add the e-mail addresses of your team members',
		teams: 'teams',
		companySettings: '<strong>Settings<strong>',
		Administrator: 'Administrator',
		Add: 'Add',
		'Content managers': 'Content managers',
		'User accounts': 'User accounts',
		'Business ID': 'Business ID',
		Notifications: 'Notifications',
		Jobs: 'Jobs',
		'Meet the': 'Meet the',
		'Team members': 'Team members',
		'average age': 'Average age:',
		employees: 'employees',
		female: 'female',
		male: 'male',
		'with disability': 'with disability',
		'Add members': 'Add members',
		'Offices in': 'Offices in',
		'Add stories that represents the life at your office':
			'Add stories that represents the life at your office',
		'Add story': 'Add story',
		'Publish story': 'Publish story',
		'My Company': 'My Company',
		'Company profile': 'Company profile',
		'Jobs (coming soon)': 'Jobs (coming soon)',
		'Name not available': 'Name not available',
		Search: 'Search',
		All: 'All',
		Locations: 'Locations',
		'Add email addresses...': 'Add email addresses...',
		"I'll  do it later": "I'll  do it later",
		'Team Name': 'Team Name',
		'Learn more': 'Learn more',
		'The team': 'The team',
		'Team posts': 'Team posts',
		'No of members': 'No of members',
		'Female ratio': 'Female ratio',
		'Male ratio': 'Male ratio',
		'Average age': 'Average age',
		'Disability ratio': 'Disability ratio',
		Share: 'Share',
		Apply: 'Apply',
		'Salary Range': 'Salary Range',
		'Contract Type': 'Contract Type',
		Hours: 'Hours',
		Benefits: 'Benefits',
		'Skills required': 'Skills required',
		'Job description': 'Job description',
		'Create a new Job opening!': 'Create a new Job opening!',
		'Name of your Job': 'Name of your Job',
		'Name of your Job opening...': 'Name of your Job opening...',
		'Describe your job': 'Describe your job',
		'Expiration date of the opening': 'Expiration date of the opening',
		'Expiration date': 'Expiration date',
		'Describe your new Job opening here. Talk about your requirements and benefits ...':
			'Describe your new Job opening here. Talk about your requirements and benefits ...',
		Next: 'Next',
		'Name of your Job is required': 'Name of your Job is required',
		'Define the salary range': 'Define the salary range',
		'Starting from': 'Starting from',
		To: 'To',
		Currency: 'Currency',
		Rate: 'Rate',
		'Add Required Skills. Search from our database ...':
			'Add Required Skills. Search from our database ...',
		'What is the Contract type?': 'What is the Contract type?',
		'What kind of job is this?': 'What kind of job is this?',
		'Is this job On site or Remote?': 'Is this job On site or Remote?',
		'What Seniority level are you looking for?':
			'What Seniority level are you looking for?',
		'Finally, introduce the team!': 'Finally, introduce the team!',
		'What team is this part of?': 'What team is this part of?',
		'And who will be the boss?': 'And who will be the boss?',
		'Search among your teams ...': 'Search among your teams ...',
		'Search for a team member ...': 'Search for a team member ...',
		None: 'None',
		'Search for required skills...': 'Search for required skills...',
		'Benefits.': 'Benefits.',
		'Add locations': 'Add locations',
		'Search for locations': 'Search for locations',
		'Talk about your benefits ...': 'Talk about your benefits ...',
		'Edit Job Details': 'Edit Job Details',
		'Creating job progress': 'Creating job progress',
		Back: 'Back',
		Skip: 'Skip',
		'Choose the step you want to change from the ones below.':
			'Choose the step you want to change from the ones below.',
		Required: 'Required',
		Optional: 'Optional',
		Department: 'Department',
		'Team Size': 'Team Size',
		'Average Age': 'Average Age',
		'Allocated to the whole company': 'Allocated to the whole company',
		'People all around the world from different teams and domains share their knowhow, work experience and their team culture.':
			'People all around the world from different teams and domains share their knowhow, work experience and their team culture.',
		exploreCompanies: 'Explore <2>Companies</2>',
		'Back to CompanyName': 'Back to {{CompanyName}}',
		searchPlaceholder: 'Search: Employers, Teams, Articles, Jobs',
		searchEmployersAndTeamsPlaceholder: 'Search: Employers, Teams',
		discoverOurCompany: 'Discover our <br/> company',
		meetOurTeams: 'Meet our <br/> teams',
		'My Settings': 'My Settings',
		'Teams of': 'Teams',
		advancedSearchTitle: 'Advanced <1>Search</1>',
		checkBackLaterForPosts: "Check back later to see {{data}} 's posts",
		checkBackLaterForTeams: "Check back later to see {{data}} 's teams",
		'No teams created yet': 'No teams created yet',
		'No posts created yet': 'No posts created yet',
		'Add a new job': 'Add a new job',
		'Please, go in your profile page and upload your CV before applying.':
			'Please, go in your profile page and upload your CV before applying.',
		'In order to apply for this job you need to be logged in and have a CV file uploaded.':
			'In order to apply for this job you need to be logged in and have a CV file uploaded.',
		'Optional: If you fill in a field, it is necessary to fill in all the fields':
			'Optional: If you fill in a field, it is necessary to fill in all the fields',
		University: 'University',
		Specialization: 'Specialization',
		'Please select the specialization': 'Please select the specialization',
		Year: 'Year',
		'Please select the year': 'Please select the year',
		'City of residence': 'City of residence',
		Submit: 'Submit',
		Faculty: 'Faculty',
		bannerMessages: `Make Mondays Happy!<1/> Don’t just find a job, find a team where
					you belong.<3/> Meet the teams of top employers, see how they
					work daily, engage with people alike through content of
					interest, all before you apply for a position.`,
		'See more': 'See more',
		successfullyRegisteredForStudentsKit:
			'You have successfully registered. Please, check your email address to confirm the email. After confirmation you will receive the QR code for StudentsKit.',
		closedStudentsKit:
			'The registration period for the Students Kit program has ended.',
		'You will receive the email with the QR code for StudentsKit':
			'You will receive the email with the QR code for StudentsKit',
		'Registration failed': 'Registration failed',
		'Start typing to see the list': 'Start typing to see the list',
		'The two passwords that you entered do not match!':
			'The two passwords that you entered do not match!',
		'Email verified successfully, you will receive the email with the QR code for StudentsKit':
			'Email verified successfully, you will receive the email with the QR code for StudentsKit',
		'Your email has already been confirmed':
			'Your email has already been confirmed',
		'Sorry ... something went wrong': 'Sorry ... something went wrong',
		'Go to home page': 'Go to home page',
		Agree: 'Agree',
		Disagree: 'Disagree',
		'Recover code': 'Recover code',
		Register: 'Register',
		'Please enter your first name': 'Please enter your first name',
		'Please enter your last name': 'Please enter your last name',
		'Please enter your email': 'Please enter your email',
		'Please enter your faculty': 'Please enter your faculty',
		'Please enter your specialization': 'Please enter your specialization',
		'Please select your university level':
			'Please select your university level',
		'Please enter the location': 'Please enter the location',
		'Please enter your password': 'Please enter your password',
		'Please confirm your password': 'Please confirm your password',
		Password: 'Password',
		'Confirm Password': 'Confirm Password',
		TermsAndConditionsLabel: `<0>I read and I agree to the</0><1>Terms and conditions</1>`,
		Send: 'Send',
		'There is another team with the same name.':
			'There is another team with the same name.',
		'Are you sure you want to leave? You will lose your data if you continue!':
			'Are you sure you want to leave? You will lose your data if you continue!',
		TheNameIsAlreadyTaken: 'The name is already taken!',
		whatsnew: `What's <1>new?</1>`,
		'Students Kit 2021 is available only in the universities listed.':
			'Students Kit 2021 is available only in the universities listed.',
		'Delete Account': 'Delete Account',
		'Are you sure you want to delete the account?':
			'Are you sure you want to delete the account?',
		'Account deleted successfully!': 'Account deleted successfully!',
	},
}
