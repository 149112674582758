import React, { useCallback, useEffect, useState, memo, useMemo } from 'react'
import CompanyHeaderImage from 'assets/images/company-header-image.jpg'
import { Badge } from 'components'
import Chip from '@material-ui/core/Chip'
import DOMPurify from 'dompurify'
import ReactPlayer from 'react-player'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import { useMediaQuery, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation, matchPath, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify'
import textVersion from 'textversionjs'

import { EEntity, ECompanyActionTypes } from 'models'
import {
	buildJobImage,
	combineStyles,
	useCheckUserPermission,
	EnumUserRoles,
} from 'utils'
import styles from './styles'
import commonStyles from './../../styles/common'
import classNames from 'classnames'
import { DeleteJob, StoreValue } from 'store'
import { PrimaryButton } from 'components'

const getContractType = (
	value: 'FULL_TIME' | 'PART_TIME' | 'PROJECT_BASE' | 'ONE_TIME',
) => {
	switch (value) {
		case 'FULL_TIME':
			return 'Full Time'
		case 'PART_TIME':
			return 'Part Time'
		case 'PROJECT_BASE':
			return 'Project Based'
		case 'ONE_TIME':
			return 'One Time'
	}
}

const getSeniorityLevel = (value: 'BEGINNER' | 'MIDDLE' | 'SENIOR') => {
	switch (value) {
		case 'BEGINNER':
			return 'Beginner'
		case 'MIDDLE':
			return 'Middle'
		case 'SENIOR':
			return 'Senior'
	}
}

const getJobTypeLoc = (value: 'ON_SITE' | 'REMOTE' | 'ALL') => {
	switch (value) {
		case 'ON_SITE':
			return 'On site'
		case 'REMOTE':
			return 'Remote'
		case 'ALL':
			return 'On site & Remote'
	}
}

const getJobType = (value: 'INTERNSHIP' | 'INTERN' | 'FREELANCE') => {
	switch (value) {
		case 'INTERNSHIP':
			return 'Internship'
		case 'INTERN':
			return 'Intern'
		case 'FREELANCE':
			return 'Freelance'
	}
}

// const useStyles = makeStyles(styles)

const JobCardView: React.FunctionComponent<any> = ({
	data,
	refetch,
	storeDispatch,
	user,
}) => {
	const hasUserAccess: boolean = useCheckUserPermission(
		EnumUserRoles.JOB_PUBLISHER,
		data.company,
	)

	const {
		company,
		locations,
		slug,
		videoUrl,
		featuredImage,
		name,
		title,
		seniorityLevel,
		salary,
		locationType,
		jobType,
		description,
		contractType,
		id,
		applicants,
	} = data
	const plainTextDescription = textVersion(DOMPurify.sanitize(description))
	const location = useLocation()
	const { companySlug }: { companySlug: string } = useParams()
	const isCompanySettingsPage = matchPath(location.pathname, {
		path: '/:language(en|ro)/employers/:companySlug/company-settings',
		exact: false,
	})
	const isMobile = useMediaQuery('@media (max-width:650px)')
	const combinedStyles: any = combineStyles(commonStyles, styles)
	const useStyles = makeStyles(combinedStyles)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const classes = useStyles()
	const history = useHistory()
	const [
		jobResourceContainerHeight,
		setJobResourceContainerHeight,
	] = useState(208)
	const { i18n, t } = useTranslation()
	const [deleteJobMutationHandle] = useMutation(DeleteJob)
	const handleGoToCompany = useCallback(
		(companySlug: string): any => {
			return history.push(`/${i18n.language}/employers/${companySlug}`)
		},
		[history, i18n],
	)

	const handleGoToJob = useCallback(() => {
		history.push(`/${i18n?.language}/${company?.slug}/jobs/${slug}/${id}`)
	}, [history, i18n, id, company, slug])

	const goToEdit = useCallback(() => {
		history.push({
			pathname: `/${i18n?.language}/${company?.slug}/jobs/edit/${slug}/${id}`,
			state: {
				update: true,
				editedJob: data,
			},
		})
	}, [history, i18n, slug, data, company, id])

	const toggleDeleteModal = useCallback(() => {
		setOpenDeleteModal(openDeleteModal => !openDeleteModal)
	}, [])

	const deleteJob = useCallback(() => {
		const mutate = async () => {
			try {
				await deleteJobMutationHandle({
					variables: {
						data: {
							id: id,
							companyId: company.id,
						},
					},
				}).then(() => {
					refetch()
					toast.success(t('The job has been deleted successfully!'), {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					})
				})
			} catch (error) {
				console.error(error)
				toast.error(
					t('The job could not be deleted. Please try again!'),
					{
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					},
				)
			}
		}
		mutate()
		toggleDeleteModal()
	}, [company, t, deleteJobMutationHandle, id, refetch, toggleDeleteModal])

	const handleSeeApplicants:
		| ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
		| undefined = useCallback(
		event => {
			event?.stopPropagation()
			storeDispatch({
				type: ECompanyActionTypes.setCompanyBreadcrumbPath,
				path: title,
			})
			storeDispatch({
				type: ECompanyActionTypes.setCompanySettingsJobApplicantsJobId,
				jobId: id,
			})
		},
		[storeDispatch, title, id],
	)

	const userPermissionToSeeApplicants = useMemo(() => {
		if (user?._ig) {
			return true
		} else if (companySlug && company && user) {
			const hasRoles = user?.companyRoles?.find((item: any) => {
				return item.roles.includes(EnumUserRoles.COMPANY_ADMIN)
			})
			const ownTheCompany = user?.profile?.ownedCompanies?.find(
				(item: any) => {
					return item.id === company.id
				},
			)
			return hasRoles ? true : ownTheCompany ? true : false
		} else {
			return false
		}
	}, [companySlug, company, user])

	useEffect(() => {
		const handleWindowResize = () =>
			setJobResourceContainerHeight((260 * window.innerWidth) / 650)
		window.addEventListener('resize', handleWindowResize)

		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	})

	return !data || !slug || !company ? null : (
		<>
			{/* <Fade in={!isRemoved} timeout={300}> */}
			<Grid
				container={true}
				className={classNames(classes.jobItem, classes.defaultShadow)}
			>
				{!isCompanySettingsPage && (
					<Badge
						type={EEntity.company}
						extraClasses={classes.badgeExtra}
						company={company}
						title={company.name}
						description={
							company && company.industry && company.industry.key
						}
						onClick={() => handleGoToCompany(company.slug)}
					/>
				)}
				{locations &&
					locations.length > 0 &&
					locations[0].location.name && (
						<div className={classes.location}>
							<RoomOutlinedIcon
								classes={{
									root: classes.locationIcon,
								}}
							/>
							<span>
								{locations.length > 0 &&
									locations[0].location.name}
							</span>
						</div>
					)}
				{hasUserAccess && (
					<>
						<Chip
							onClick={goToEdit}
							style={{ margin: 2, background: '#fff' }}
							icon={<EditIcon />}
							variant="outlined"
							color={'secondary'}
							clickable={true}
							classes={{
								label: classes.editIconLabel,
								root: classes.editRootElement,
								icon: classes.editIcon,
							}}
						/>
						<Chip
							onClick={toggleDeleteModal}
							style={{ margin: 2, background: '#fff' }}
							icon={<DeleteIcon />}
							variant="outlined"
							color={'secondary'}
							clickable={true}
							classes={{
								label: classes.editIconLabel,
								root: classes.deleteRootElement,
								icon: classes.deleteIcon,
							}}
						/>
					</>
				)}

				<Grid
					onClick={handleGoToJob}
					item={true}
					xs={isMobile ? 12 : 5}
					md={5}
					className={classes.jobResourceContainer}
					style={{
						minHeight:
							isMobile && videoUrl
								? `${jobResourceContainerHeight}px`
								: 'undefined',
					}}
				>
					<div className={classes.imgVideoContainer}>
						{featuredImage ? (
							<img
								src={buildJobImage(id, featuredImage)}
								alt={name}
							/>
						) : videoUrl ? (
							<ReactPlayer
								url={videoUrl}
								controls={true}
								width="100%"
								height="100%"
								style={{
									flex: 1,
									overflow: 'hidden',
								}}
							/>
						) : (
							<img alt={name} src={CompanyHeaderImage} />
						)}
						{
							// if the user is in company's profile
							userPermissionToSeeApplicants && (
								<div className={classes.seeApplicantsBtn}>
									<PrimaryButton
										onClick={handleSeeApplicants}
										label={
											applicants.length > 0
												? applicants.length === 1
													? `See ${applicants.length} Applicant`
													: `See ${applicants.length} Applicants`
												: `${applicants.length} Applicants`
										}
									/>
								</div>
							)
						}
					</div>
				</Grid>
				<Grid
					onClick={handleGoToJob}
					item={true}
					xs={isMobile ? 12 : 7}
					md={7}
					className={classes.teamItemInformation}
				>
					<Grid container={true} direction="column">
						<h3
							className={classes.jobItemTitle}
							style={{ marginTop: 0 }}
						>
							{title}
						</h3>
						<div>
							{seniorityLevel && (
								// TODO: review this line: Currently, in job create form you cannot add multiple seniority level categories
								<Chip
									className={`${classes.chip}`}
									label={getSeniorityLevel(seniorityLevel)}
									size={'small'}
									clickable={false}
								/>
							)}
						</div>
						<div className={classes.jobDetails}>
							{contractType && (
								<span className={classes.jobDescriptionItem}>
									{getContractType(contractType)}
								</span>
							)}
							{salary &&
								(salary.amountMin !== 0 ||
									salary.amountMax !== 0) && (
									<span
										className={classes.jobDescriptionItem}
									>
										{salary.currency &&
											`${t('Salary Range')} ${
												salary.amountMin
											}-${salary.amountMax} ${
												salary.currency &&
												salary.currency.toUpperCase()
											}`}
									</span>
								)}
						</div>
						<div className={classes.jobDetails}>
							{jobType && (
								<span className={classes.jobDescriptionItem}>
									{t('Post Type')}: {getJobType(jobType)}
								</span>
							)}
							{locationType && (
								<span className={classes.jobDescriptionItem}>
									{t('Location Type')}:{' '}
									{getJobTypeLoc(locationType)}
								</span>
							)}
						</div>
						<div className={classes.jobDescription}>
							{title.length + plainTextDescription.length >
							150 ? (
								<div>
									{`${plainTextDescription.substr(
										0,
										150 - title.length,
									)}`}
									&nbsp;
									<span className={classes.seeMore}>
										{`...${t('See more')}`}
									</span>
								</div>
							) : (
								plainTextDescription
							)}
						</div>
					</Grid>
				</Grid>
			</Grid>
			{/* </Fade> */}
			<Dialog
				open={openDeleteModal}
				onClose={toggleDeleteModal}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					{data && t('Are you sure you want to delete this job?')}
				</DialogTitle>
				<DialogActions>
					<Button color="primary" onClick={deleteJob}>
						{t('Yes')}
					</Button>
					<Button
						color="primary"
						autoFocus
						onClick={toggleDeleteModal}
					>
						{t('No')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

const JobCard = memo((props: any) => (
	<StoreValue keys={['storeDispatch', 'user']}>
		<JobCardView {...props} />
	</StoreValue>
))

export default JobCard
