import React, {
	memo,
	useState,
	useCallback,
	useRef,
	useEffect,
	useMemo,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import InputBase from '@material-ui/core/InputBase'
import Fab from '@material-ui/core/Fab'
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import { useHistory } from 'react-router-dom'
import dompurify from 'dompurify'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import InsertEmoticonSharpIcon from '@material-ui/icons/InsertEmoticonSharp'
import CustomScroll from 'react-custom-scroll'
import 'react-custom-scroll/dist/customScroll.css'
import { useMutation } from '@apollo/react-hooks'

import styles from './styles'
import { useAddComment, useComments } from '../firebase'
import { StoreValue, NotifyArticleAuthorMutation } from 'store'
import { Comment } from './components'
import { TCommentsProps } from 'models'

const useStyles = makeStyles(styles)
const sanitizer = dompurify.sanitize

const ConditionalWrapper = ({
	condition,
	wrapper,
	children,
}: {
	condition: boolean
	wrapper: any
	children: any
}) => (condition ? wrapper(children) : children)

const Comments: React.FunctionComponent<TCommentsProps> = ({
	articleId,
	commentsPerPage,
	viewMode,
	storeUser,
	firebase,
	firebaseUser,
}) => {
	const history = useHistory()
	const counterRef = useRef<HTMLDivElement>()
	const firebaseAddComment = useAddComment(articleId, firebase, firebaseUser)
	const classes = useStyles()
	const theme = useTheme()
	const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
	const { t, i18n } = useTranslation()
	const [inputText, setInputText] = useState('')
	const [notifyArticleAuthor] = useMutation(NotifyArticleAuthorMutation)
	const [comments, hasMore, fetchMore] = useComments(
		articleId,
		commentsPerPage || 3,
		firebase,
	)
	const [liveComments, setLiveComments] = useState<any[]>([])
	const [deletedComments, setDeletedComments] = useState<string[]>([])
	const [showEmojis, setShowEmojis] = useState(false)
	const emojiPicker = useRef(null)

	const onDelete = useCallback(
		(commentId: string) => {
			setDeletedComments(deletedComments.concat([commentId]))
			return undefined
		},
		[deletedComments],
	)
	const handleSearchChange = (event: any) => {
		if (event && event.target) setInputText(event.target.value)
	}
	const handleAddComment = useCallback(async () => {
		if (!storeUser) return null
		const newComment = await firebaseAddComment(
			sanitizer(inputText, {
				ALLOWED_TAGS: ['b', 'i', 'em', 'strong'],
			}),
			{
				userId: storeUser.id,
				hasAvatar: storeUser.profile.hasAvatar,
				firstName: storeUser.profile.firstName,
				lastName: storeUser.profile.lastName,
				worksAt:
					(storeUser.profile.worksAt &&
						storeUser.profile.worksAt.company) ||
					'n/a',
			},
		)
		setLiveComments([
			{
				id: newComment.id,
				text: sanitizer(inputText, {
					ALLOWED_TAGS: ['b', 'i', 'em', 'strong'],
				}),
				userId: storeUser.id,
				hasAvatar: storeUser.profile.hasAvatar,
				firstName: storeUser.profile.firstName,
				lastName: storeUser.profile.lastName,
				worksAt:
					(storeUser.profile.worksAt &&
						storeUser.profile.worksAt.company) ||
					'n/a',
				date: new Date(),
			},
			...liveComments,
		])
		setInputText('')

		await notifyArticleAuthor({
			variables: {
				language: i18n.language,
				articleId,
			},
		})
	}, [
		firebaseAddComment,
		articleId,
		i18n.language,
		notifyArticleAuthor,
		inputText,
		storeUser,
		liveComments,
	])

	const handleSelectEmoji = (emoji: any) => {
		setShowEmojis(false)
		setInputText(`${inputText} ${emoji.native}`)
	}

	const goToLogin = useCallback(() => {
		history.push(`/${i18n.language}/auth/login`)
	}, [history, i18n.language])

	useEffect(() => {
		if (counterRef && counterRef.current && counterRef.current.dataset)
			counterRef.current.dataset.counter = Number(
				255 - inputText.length,
			).toString()
	}, [counterRef, inputText])
	const realComments = useMemo(() => {
		return [...liveComments, ...comments].filter(
			item => deletedComments.indexOf(item.id) === -1,
		)
	}, [liveComments, comments, deletedComments])

	let signInToCommentStyle: any = viewMode ? { marginRight: 16 } : undefined

	useEffect(() => {
		function handleClickOutside(event: unknown) {
			//@ts-ignore
			if (!emojiPicker?.current?.contains(event.target)) {
				setShowEmojis(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [emojiPicker])

	return (
		<div className={classes.container}>
			<ConditionalWrapper
				condition={isUpMd}
				wrapper={(children: any) => (
					<CustomScroll allowOuterScroll={true}>
						{' '}
						{children}{' '}
					</CustomScroll>
				)}
			>
				{realComments.length > 0 &&
					realComments.map(comment => (
						<Comment
							key={comment.id}
							data={comment}
							articleId={articleId}
							onDelete={onDelete}
						/>
					))}
			</ConditionalWrapper>
			{hasMore && (
				<div onClick={fetchMore} className={classes.loadMore}>
					Load more
				</div>
			)}
			{storeUser && (
				<div className={classes.newComment}>
					<InputBase
						ref={counterRef}
						multiline={true}
						onChange={handleSearchChange}
						className={classes.input}
						placeholder={t('your comment')}
						inputProps={{
							'aria-label': 'your comment',
							rows: 1,
						}}
						value={inputText}
						autoFocus={true}
						endAdornment={
							// !showEmojis && (
							<IconButton
								onClick={() => setShowEmojis(true)}
								color={showEmojis ? 'primary' : 'inherit'}
							>
								<InsertEmoticonSharpIcon />
							</IconButton>
							// )
						}
					/>
					{showEmojis && (
						<span ref={emojiPicker} className={classes.emoji}>
							<Picker
								onSelect={handleSelectEmoji}
								set="apple"
								showPreview={false}
								showSkinTones={false}
								perLine={8}
							/>
						</span>
					)}
					<div>
						<Fab
							variant="extended"
							size="small"
							color="primary"
							aria-label="Add"
							className={classes.send}
							disabled={inputText.trim().length === 0}
							onClick={handleAddComment}
						>
							{/* <NavigationIcon /> */}
							<>{t('Sendit')}</>
						</Fab>
					</div>
				</div>
			)}
			{!storeUser && (
				<div
					className={classes.unauthenticated}
					onClick={goToLogin}
					style={signInToCommentStyle}
				>
					{t('sign in to comment')}
				</div>
			)}
		</div>
	)
}

export default memo((props: TCommentsProps) => (
	<StoreValue keys={['user|storeUser', 'firebase', 'firebaseUser']}>
		<Comments {...props} />
	</StoreValue>
))
