import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		defaultShadow: {
			'-webkit-box-shadow': '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			'-moz-box-shadow': '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			boxShadow: '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
		},
		boxShadow: {
			boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.2)',
			'-webkit-box-shadow':'0px 2px 5px 0px rgba(0,0,0,0.2)',
				'-moz-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.2)'
		}
	})
