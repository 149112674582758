import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import styles from '../styles'
import { ProfileArticles } from 'components'

const useStyles = makeStyles(styles)

const CompanyInsideTeam: React.FunctionComponent<any> = props => {
	const { articles, refetch } = props
	const classes = useStyles()
	const { t } = useTranslation()
	return (
		<Grid
			className={classes.companySection}
			container={true}
			direction="row"
		>
			<Grid item={true}>
				<h2 className={classes.sectionTitle}>
					{t('Life')}{' '}
					<span className={classes.emphasizedText}>
						{t('inside the team')}
					</span>
				</h2>
			</Grid>
			<Grid
				item={true}
				className={`${classes.sectionContent} ${classes.teamLifeSection}`}
			>
				<ProfileArticles articles={articles} refetch={refetch} />
			</Grid>
		</Grid>
	)
}

export default memo(CompanyInsideTeam)
