import gql from 'graphql-tag'
import {
	AuthenticationUserFragment,
	UserProfileFragment,
} from 'store/fragments'

// Queries

export const CheckEmailQuery = gql`
	query checkEmail($email: String!) {
		checkEmail(email: $email) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`
export const CheckCredentialsQuery = gql`
	query checkCredentials {
		checkCredentials {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`

// Mutations

export const LoginMutation = gql`
	mutation login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`
export const LogoutMutation = gql`
	mutation logout {
		logout {
			status
			error
		}
	}
`
export const RegisterMutation = gql`
	mutation register($data: RegisterInput!) {
		register(data: $data) {
			status
			error
		}
	}
`
export const ForgotPasswordMutation = gql`
	mutation forgotPassword($email: String!, $language: String!) {
		forgotPassword(email: $email, language: $language) {
			status
		}
	}
`
export const UpdateForgotPasswordMutation = gql`
	mutation updateForgotPassword(
		$token: String!
		$password: String!
		$language: String!
	) {
		updateForgotPassword(
			token: $token
			password: $password
			language: $language
		) {
			status
		}
	}
`
export const ActivateAccountMutation = gql`
	mutation ($token: String!, $language: String!) {
		activateAccount(token: $token, language: $language) {
			status
			error
		}
	}
`
export const FbLoginMutation = gql`
	mutation facebookConnect(
		$email: String
		$firstName: String
		$lastName: String
		$code: String
	) {
		facebookConnect(
			data: {
				email: $email
				firstName: $firstName
				lastName: $lastName
				code: $code
			}
		) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`
export const GgLoginMutation = gql`
	mutation googleConnect(
		$email: String
		$firstName: String
		$lastName: String
		$code: String
	) {
		googleConnect(
			data: {
				email: $email
				firstName: $firstName
				lastName: $lastName
				code: $code
			}
		) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`
export const LinkedinLoginMutation = gql`
	mutation linkedinConnect($code: String!) {
		linkedinConnect(data: { code: $code }) {
			...AuthenticationUser
		}
	}
	${AuthenticationUserFragment}
	${UserProfileFragment}
`

export const DeleteAccountMutation = gql`
	mutation deleteAccount($userId: String!) {
		deleteAccount(userId: $userId) {
			status
		}
	}
`
