import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		root: {
			'& h6': {
				display: 'flex',
				'& > span': {
					overflow: 'hidden',
					whiteSpace: 'normal',
					marginRight: 12,
					textOverflow: 'ellipsis',
					fontSize: 32,
					lineHeight: '38px',
				},
			},
		},
		paper: {
			paddingTop: 15,
			margin: 'auto',
			paddingBottom: 15,
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
		contentRoot: {
			display: 'flex',
			padding: 0,
		},
		headerBadgeWrap: {
			position: 'relative',
			width: '100%',
			height: '80px',

		},
		badgeExtra: {
			position: 'absolute',
			top: 0
		},
		date: {
			position: 'absolute',
			right: theme.spacing(2),
			top: '5px',
			fontWeight: 300,
			color: '#989898',
			fontSize: 12,
			lineHeight: '18px',
			[theme.breakpoints.down('sm')]: {
				right: theme.spacing(1),
			},
			'& > span': {
				border: `1px solid #ebebeb`,
				borderRadius: 12,
				lineHeight: '18px',
				padding: theme.spacing(0.5),
				backgroundColor: '#ffffff',
			},
			[theme.breakpoints.up('lg')]: {
				display: 'flex',
				height: theme.spacing(5),
				lineHeight: theme.spacing(5) + 'px',
				top: '5px',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				paddingRight: 0,
				maxWidth: theme.spacing(60),
			},
		},
		worksAt: {
			[theme.breakpoints.down('sm')]: {
				paddingTop: '5px',
				paddingLeft: '10px',
				paddingRight: '10px',
				backgroundColor: '#ffffff',
			},
			backgroundColor: '#ffffff',
			padding: theme.spacing(1),
			color: theme.palette.grey[800],
			fontWeight: 300,
			fontSize: 12,
			lineHeight: '16px',
			borderBottom: `1px solid #ebebeb`,

			[theme.breakpoints.up('lg')]: {
				border: `1px solid #ebebeb`,
				borderRadius: theme.spacing(2.5),
				marginLeft: theme.spacing(1),
			},
		},
		ellipsis: {
			flexWrap: 'nowrap',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			width: '100%',
		},
		worksAtAccent: {
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '10px',
				paddingRight: '10px',
			},
			color: theme.palette.primary.main,
			fontWeight: 500,
		},
		contentCustomScroll: {
			//display: 'flex',
			// '& .rcs-custom-scroll': {
			// 	display: 'flex',
			// 	flexGrow: 1,
			// 	minWidth: 'auto',
			// 	'& > .rcs-outer-container': {
			// 		display: 'flex',
			// 		flexGrow: 1,
			// 		'& > .rcs-inner-container': {
			// 			display: 'flex',
			// 			flexGrow: 1,
			// 		},
			// 	},
			// },
			'& .rcs-custom-scroll .rcs-inner-handle': {
				height: 'calc(100% - 12px)',
				marginTop: '6px',
				backgroundColor: `${theme.palette.secondary.light} !important`,
				borderRadius: '3px',
			},
		},

		closeButton: {
			position: 'relative',
			right: -5,
			width: 24,
			height: 24,
			padding: 0,
		},
		articleTitle: {
			fontWeight: 500,
			color: '#1B1A7C',
			overflow: 'hidden',
			whiteSpace: 'normal',
			textOverflow: 'ellipsis',
			fontSize: 32,
			lineHeight: '38px',
			margin: '5px 0px',

			[theme.breakpoints.down('sm')]: {
				fontSize: 28,
				[theme.breakpoints.down('xs')]: {
					fontSize: 22,
				}
			}
		},
		image: {
			width: '100%',
			display: 'flex',
			height: 'max-content',
			'& > img': {
				maxWidth: '100%',
				maxHeight: '100%',
				objectFit: 'contain',
				flexGrow: 1,
				width: '100%',
				height: '100%',
			},
		},
		video: {
			display: 'flex',
			position: 'relative',
			paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
		},
		player: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			'& iframe': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},
		},
		articleEndorsements: {
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			paddingRight: 0,
			// '& $newComment': {
			// 	paddingRight: 10,
			// },
		},
		contentRootContainer: {
			display: 'flex',
			flexDirection: 'column',
		},
		deleteIconLabel: {
			paddingLeft: 6,
			paddingRight: 6,
		},
		deleteIconContainer: {
			display: 'flex',
			flexDirection: 'row',
			[theme.breakpoints.up('md')]: {
				marginLeft: 'auto'
			}
		},
		editIconLabel: {
			paddingLeft: 6,
			paddingRight: 6,
		},
		articleDescriptionWrap: {
			fontWeight: 300,
			fontSize: 18,
			color: theme.palette.grey[600],
			overflowY: 'auto',
			[theme.breakpoints.down('sm')]: {
				fontSize: 16,
				[theme.breakpoints.down('xs')]: {
					fontSize: 14,
				}
			}
		}
	})
