import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import ContentPlaceholder from '../assets/images/content-placeholder.png'

const PlaceholderContent: React.FunctionComponent<any> = ({
	data,
	title = 'No posts created yet',
	entity = 'posts',
}) => {
	const { t } = useTranslation()
	let i18nextKey = 'checkBackLaterForPosts'
	if (entity === 'teams') {
		i18nextKey = 'checkBackLaterForTeams'
		title = 'No teams created yet'
	} else {
	}
	return (
		<div className={'placeholderContent'}>
			<img src={ContentPlaceholder} alt="" />
			<div className={'title'}>{t(title)}</div>
			<div className={'subtitle'}>{t(i18nextKey, { data })}</div>
		</div>
	)
}

export default memo(PlaceholderContent)
