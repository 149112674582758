import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useQuery } from '@apollo/react-hooks'
import InfiniteScroll from 'react-infinite-scroller'
import { useTranslation } from 'react-i18next'

import { FeedArticlesQuery } from 'store'
import styles from './styles'
import { Loading, Card } from 'components'
import { PlaceholderContent } from 'utils'

const useStyles = makeStyles(styles)

const Stories: React.FunctionComponent<any> = props => {
	const { companySlug, companyName, canEdit } = props
	const { i18n } = useTranslation()
	const classes = useStyles()
	// const { t } = useTranslation()
	const { data, loading, fetchMore, refetch }: any = useQuery(
		FeedArticlesQuery,
		{
			fetchPolicy: 'network-only',
			variables: {
				language: i18n.language,
				companySlug,
				first: 3,
				after: '',
			},
		},
	)

	const articles: any[] = data
		? data &&
		  data.feedArticles &&
		  data.feedArticles.edges.map((edge: any) => edge.node)
		: []
	const hasNextPage = data
		? data && data.feedArticles && data.feedArticles.pageInfo.hasNextPage
		: false

	return (
		<>
			<Grid
				className={classes.profileMainContent}
				container={true}
				direction="column"
			>
				<Grid
					item={true}
					className={`${classes.sectionContent} ${classes.storiesSection}`}
				>
					{!loading ? (
						<section
							style={{
								flexGrow: 1,
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<InfiniteScroll
								pageStart={0}
								loadMore={() =>
									fetchMore({
										variables: {
											after:
												data.feedArticles.edges[
													data.feedArticles.edges
														.length - 1
												].cursor,
										},
										updateQuery: (
											previousQueryResult: any,
											{
												fetchMoreResult: {
													feedArticles: {
														edges: newEdges,
														pageInfo,
													},
												},
											}: any,
										) => {
											return newEdges.length > 0
												? {
														// Put the new articles at the end of the list and update `pageInfo`
														feedArticles: {
															__typename:
																previousQueryResult
																	.feedArticles
																	.__typename,
															edges: [
																...previousQueryResult
																	.feedArticles
																	.edges,
																...newEdges,
															],
															pageInfo,
														},
												  }
												: previousQueryResult
										},
									})
								}
								hasMore={hasNextPage}
								loader={
									<div
										style={{ flexGrow: 1, display: 'flex' }}
										key={'loading'}
									>
										<Loading size={48} />
									</div>
								}
							>
								{articles && articles.length ? (
									articles.map((article: any) => (
										<Card
											article={article}
											key={article.id}
											refetch={refetch}
											canEdit={canEdit}
										/>
									))
								) : (
									<PlaceholderContent data={companyName} />
								)}
							</InfiniteScroll>
						</section>
					) : (
						<div
							style={{
								flexGrow: 1,
								display: 'flex',
								marginTop: 110,
							}}
						>
							<Loading size={58} />
						</div>
					)}
				</Grid>
			</Grid>
		</>
	)
}

export default memo(Stories)
