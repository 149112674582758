import React, { lazy, Suspense, useEffect } from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import CookieBot from 'react-cookiebot'
import mixpanel from 'mixpanel-browser'

import './styles.css'
import i18n from './i18n'
import { Loading } from 'components'
import * as serviceWorker from './serviceWorker'
import 'tracking'

const theme = createMuiTheme({
	typography: {
		fontFamily: 'Open Sans, sans-serif',
	},
	palette: {
		background: {
			default: '#ffffff',
		},
		grey: {
			200: '#ebebeb',
			300: '#989898',
			400: '#bababa',
			500: '#929292',
			600: '#212121',
			700: '#313131',
			800: '#414141',
			900: '#424242',
			A100: '#dbdbdb',
		},
		primary: {
			main: '#1B1A7C',
		},
		secondary: {
			main: '#FC2652',
		},
		action: {
			active: '#FC2652',
		},
		text: {
			primary: '#1B1A7C',
			secondary: '#414141',
		},
	},
})

const App = lazy(() => import('./App'))

const ScrollToTop: React.FunctionComponent<{}> = ({ children }) => {
	const { pathname, state }: { pathname: any; state: any } = useLocation()
	useEffect(() => {
		if (state && !state.keepScroll) {
			window.scrollTo(0, 0)
		}
	}, [pathname, state])
	return <>{children}</>
}

mixpanel.init(
	'09ace9b32ddfb3d8580475193a498afd',
	// { api_host: 'https://api-eu.mixpanel.com/' },
	// '',
)

const domainGroupId: any = '0614e2eb-70ad-4b0e-a451-7007640165de'

ReactDOM.render(
	<ThemeProvider theme={theme} key="main-app-theme">
		<CssBaseline />
		<I18nextProvider i18n={i18n}>
			<Router>
				<ScrollToTop>
					<Suspense fallback={<Loading size={48} />}>
						<App />
						<CookieBot
							domainGroupId={domainGroupId}
							language="en|ro"
						/>
					</Suspense>
				</ScrollToTop>
			</Router>
		</I18nextProvider>
	</ThemeProvider>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
