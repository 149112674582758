import gql from 'graphql-tag'

export const UserProfileFragment = gql`
	fragment UserProfile on Profile {
		id
		aboutMe
		email
		firstName
		lastName
		hasAvatar
		avatarPath
		hasCover
		coverPath
		cvFilePath
		cvFileOriginalName
		featuredVideo
		ownedCompanies {
			id
			hasLogo
			name
			slug
			logoPath
		}
		worksAt {
			id
			company
			position
		}
		values {
			id
			title
		}
		skills {
			id
			title
		}
		experience {
			id
			position
			company
			startDate
			location {
				id
				type
				name
				region2
			}
			endDate
			isCurrent
			isPresent
			title
			description
		}
		user {
			id
			email
		}
		userOnBoardingFormVisited
	}
`

export const UserProfileArticlesFragment = gql`
	fragment UserProfileArticles on Profile {
		id
		featuredArticles {
			id
			title
			slug
			body
			description
			readDuration
			isPost
			featuredImage {
				id
				filename
			}
			featuredVideo
		}
	}
`
