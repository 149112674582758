export enum EEntity {
	company = 'Company',
	person = 'User',
	team = 'Team',
}

export enum ETagActionType {
	populate = 'populate',
	select = 'select',
	deselect = 'deselect',
}

export enum EArticleSectionTypes {
	post = 'post',
	article = 'article',
}

export enum EArticleActionTypes {
	setSection = 'set_section',
	setTitle = 'set_title',
	setBody = 'set_body',
	setPostAs = 'set_post_as',
	setDescription = 'set_description',
	seITagging = 'set_tagging',
	setFeaturedImage = 'set_featured_image',
	setFeaturedVideo = 'set_featured_video',
	setPublishPostSuccess = 'set_publish_post_success',
	setPublishPostError = 'set_publish_post_error',
	setError = 'set_error',
	seITags = 'set_tags',
	seITagsWithouITagging = 'set_tags_without_tagging',
	resetState = 'reset_state',
	clearMedia = 'clear_media',
}

export enum EAuthTextfieldType {
	password = 'password',
	email = 'email',
	firstName = 'firstName',
	lastName = 'lastName',
}

export enum EAuthActionTypes {
	setSection = 'set_section',
	incrementStep = 'increment_step',
	decrementStep = 'decrement_step',
	setEmail = 'set_email',
	setPassword = 'set_password',
	setFirstName = 'set_first_name',
	setLastName = 'set_last_name',
	setSignUpSuccess = 'set_sign_up_success',
	setActivationSuccess = 'set_activation_success',
	setActivationError = 'set_activation_error',
	authFailed = 'authentication_failed',
	setForgotPasswordSuccess = 'set_forgot_password_success',
	setForgotPasswordFailed = 'set_forgot_password_failed',
	setUpdateForgotPasswordSuccess = 'set_update_forgot_password_success',
	setUpdateForgotPasswordFailed = 'set_update_forgot_password_failed',
	setTemporaryUser = 'set_temporary_user',
}
export enum EAuthSectionTypes {
	login = 'login',
	signUp = 'signUp',
	forgotPassword = 'forgotPassword',
}

export enum EEndorsementsSectionTypes {
	endorsement = 'endorsements',
	comments = 'comments',
}
export enum EEndorsementsActionTypes {
	toggleSection = 'toggle_section',
}

export enum EAppStateActionTypes {
	updateQuery = 'uupdate_query',
	updateSearchSection = 'update_search_section',
	updateSearchLoading = 'update_search_loading',
	updateDebounceQuery = 'update_debounce_query',
	updateUser = 'update_user',
	updateUserOwnedCompanies = 'update_user_owned_companies',
	setUserCvFile = 'set_user_cv_file',
	resetUser = 'reset_user',
	updateGuide = 'update_guide',
	updateGuideCompanyViewAs = 'update_guide_company_view_as',
	updateGuideUserViewAs = 'update_guide_user_view_as',
	initFirebase = 'init_firebase',
	setFirebaseUser = 'set_firebase_user',
	setCheckCredentials = 'set_check_credentials',
	setUserCompanyRoles = 'set_user_company_roles',
}

export enum EEntityAboutSectionType {
	companyAbout = 'company_about',
	companyProductsAndServices = 'company_products_and_services',
	companyTeams = 'company_teams',
	teamAbout = 'team_about',
	profileAbout = 'profile_about',
}

export enum ETextFontSize {
	small = 'small',
	normal = 'normal',
}

export enum ELocationActionType {
	populate = 'populate',
	select = 'select',
	deselect = 'deselect',
}

export enum ECompanyActionTypes {
	setId = 'set_id',
	setName = 'set_name',
	setAddress = 'set_address',
	setOwnerId = 'set_owner_id',
	setDescription = 'set_description',
	setTypeId = 'set_type',
	setIndustryId = 'set_industry',
	setNoOfEmployees = 'set_no_of_employees',
	setHasLogo = 'set_has_logo',
	setLogoPath = 'set_logo_path',
	setOffices = 'set_offices',
	setPopulateOffices = 'set_populate_offices',
	setDeselectOffices = 'set_deselect_offices',
	setSelectOffices = 'set_select_offices',
	setPopulateHeadquarters = 'set_populate_headquarters',
	setDeselectHeadquarters = 'set_deselect_headquarters',
	setSelectHeadquarters = 'set_select_headquarters',
	setUploadLogo = 'set_upload_logo',
	setError = 'set_error',
	resetState = 'reset_state',
	switchViewAs = 'switch_view_as',
	setCompanySettingsTab = 'set_company_settings_tab',
	setCompanyBreadcrumbPath = 'set_company_breadcrumb_path',
	setCompanySettingsJobApplicantsJobId = 'set_company_settings_job_applicants_job_id',
}

export enum EJobActionTypes {
	setCompanyJob = 'set_company_job',
	setJobDescription = 'set_job_description',
	setTitle = 'set_title',
	setLocations = 'set_locations',
	setExpirationDate = 'set_expiration_date',
	setCurrency = 'set_currency',
	setStartSalary = 'set_start_salary',
	setEndSalary = 'set_end_salary',
	setSalaryRate = 'set_salary_rate',
	setSkills = 'set_skill',
	setDeselecSkill = 'set_deselect_skill',
	setBenefits = 'set_benfits',
	setDeselecBenefits = 'set_deselect_benefits',
	setContractType = 'set_contract_type',
	setJobType = 'set_job_type',
	setJobTypeByLoc = 'set_job_type_loc',
	setError = 'set_error',
	setSeniorityLevel = 'set_seniority_level',
	setTeam = 'set_team',
	setTeamMembers = 'set_team_members',
	setTeamLeader = 'set_team_leader',
	resetState = 'reset_state',
	clearMedia = 'clear_media',
	setFeaturedImage = 'set_featured_image',
	deleteFeaturedImg = 'delete_featured_img',
	setFeaturedVideo = 'set_featured_video',
	deleteFeaturedVideo = 'delete_featured_video',
	setSearchForDefaultCompanyOffices = 'set_search_for_default_company_offices',
	setSearchForDefaultCompanyTeams = 'set_search_for_default_company_teams',
	setApplyJobExternalUrl = 'set_apply_job_external_url',
	setJob = 'set_job',
	setId = 'set_id',
	initState = 'init_state',
}

export enum EUserOnboardingActionTypes {
	setExperienceStartDatetime = 'set_experience_start_dt',
	setExperienceEndDatetime = 'set_experience_end_dt',
	setExperienceRegion = 'set_experience_region',
	setExperienceLocation = 'set_experience_location',
	setExperienceCompany = 'set_experience_company',
	setExperienceIsPresent = 'set_experience_is_present',
	setExperiencePosition = 'set_experience_position',
	setExperienceDesc = 'set_experience_description',
	setExperienceTitle = 'set_experience_title',
	setExperienceIsCurrent = 'set_experience_is_current',
	setFirstName = 'set_first_name',
	setLastName = 'set_last_name',
	setAboutMe = 'set_about_me',
	setImageCover = 'set_image_cover',
	setImageAvatar = 'set_image_avatar',
	setSkills = 'set_skills',
	setValues = 'set_values',
	setExperience = 'set_experience',
	setUserCv = 'set_user_cv',
	resetState = 'reset_state',
	setError = 'set_error',
}
export enum EJobTextCategory {
	description = 'description',
	benefits = 'benefits',
}
