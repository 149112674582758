import Chip from '@material-ui/core/Chip'
import CheckIcon from '@material-ui/icons/Check'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import React, { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../styles'
import { makeStyles, TextField } from '@material-ui/core'

const useStyles = makeStyles(styles)

const InviteTeamMembers: React.FunctionComponent<any> = props => {
	const { isReady } = props
	const { t } = useTranslation()
	const classes = useStyles()
	const [openModal, setOpenModal] = useState(false)

	const toggleModal = useCallback(() => {
		setOpenModal(state => !state)
	}, [])

	const [teamMembers, setTeamMembers] = useState('')
	const updateTeamMembers = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setTeamMembers(e.target.value)
		},
		[],
	)

	return (
		<React.Fragment>
			{isReady && (
				<Chip
					onClick={toggleModal}
					style={{ margin: 2, background: '#fff' }}
					icon={<CheckIcon />}
					variant="outlined"
					color={'secondary'}
					clickable={true}
					classes={{
						label: classes.editIconLabel,
						root: classes.editRootElement,
					}}
				/>
			)}

			<Dialog
				open={openModal}
				onClose={toggleModal}
				aria-labelledby="form-dialog-title"
				fullWidth
				maxWidth="md"
				scroll="body"
				classes={{
					paperWidthMd: classes.paperWidthMd,
				}}
			>
				<DialogContent style={{ overflow: 'hidden' }}>
					<Grid container>
						<Grid item xs={12}>
							<span className={classes.titleSection}>
								<span className={classes.inviteModalTitle}>
									Your new company page is ready!
								</span>
								<span className={classes.inviteModalSubTitle}>
									Invite team members to join CV30, to help
									you spread the word!
								</span>
							</span>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label={t(
									'Add the e-mail addresses of your team members',
								)}
								multiline
								rows="4"
								value={teamMembers}
								placeholder={t('Add email addresses...')}
								className={classes.textInputRoot}
								margin="normal"
								onChange={updateTeamMembers}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<div className={classes.submitSection}>
								<span className={classes.infoText}>
									{t(`I'll  do it later`)}
								</span>
								<button
									className={classes.submitBtn}
									onClick={toggleModal}
								>
									{'Save'}
								</button>
							</div>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	)
}
export default memo(InviteTeamMembers)
