import React, { useContext, memo } from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDownOutlined'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import styles from '../styles'
import { EntityLogo } from 'components'
import { SearchInputContext } from '../component'
import Cancel from './cancelButton'
import MenuContent from './menuContent'
import { ReactComponent as AuthIcon } from 'assets/images/auth.svg'
import { StoreValue } from 'store'
import { EEntity, TRightMenuProps } from 'models'

const useStyles = makeStyles(styles)

const RightMenu: React.FunctionComponent<TRightMenuProps> = ({ storeUser }) => {
	const history = useHistory()
	const classes = useStyles()
	const { t, i18n } = useTranslation()
	const [isMenuVisible, setIsMenuVisible] = React.useState(false)
	const [isProfileMenuVisible, setIsProfileMenuVisible] = React.useState(
		false,
	)
	const menuRef: React.Ref<any> = React.useRef(null)
	const arrowRef: React.Ref<any> = React.useRef(null)
	const menuProfileRef: React.Ref<any> = React.useRef(null)
	const arrowProfileRef: React.Ref<any> = React.useRef(null)
	const theme: Theme = useTheme()
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const [isFocused] = useContext(SearchInputContext)
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const shouldShowClose = isSmDown || false
	const shouldShowMenus = (!isFocused && isSmDown) || isMdUp

	const showMenu = () => {
		setIsProfileMenuVisible(false)
		setIsMenuVisible(true)
	}
	const closeMenu = (event: React.MouseEvent | React.ChangeEvent<any>) => {
		if (event && menuRef && menuRef.current) {
			if (menuRef.current.contains(event.target)) return null
		}
		setIsMenuVisible(false)
	}
	const showProfileMenu = () => {
		setIsMenuVisible(false)
		setIsProfileMenuVisible(true)
	}
	const closeProfileMenu = (
		event: React.MouseEvent | React.ChangeEvent<any>,
	) => {
		if (menuRef && menuRef.current) {
			if (menuRef.current.contains(event.target)) return null
		}
		setIsProfileMenuVisible(false)
	}
	const authenticate = () => history.push(`/${i18n.language}/auth`)
	const goToSignUp = () => {
		history.push(`/${i18n.language}/auth/signUp`)
	}
	const goToLogin = () => {
		history.push(`/${i18n.language}/auth/login`)
	}

	return (
		<div
			className={classNames(classes.rightMenu, {
				[classes.unsetFlex]: shouldShowClose && isFocused,
			})}
		>
			{!storeUser && isSmDown && !isFocused && (
				<IconButton onClick={authenticate}>
					<AuthIcon className={classes.authLogo} />
				</IconButton>
			)}
			{!storeUser && isMdUp && (
				<>
					<Button
						onClick={goToLogin}
						variant="outlined"
						classes={{
							root: classes.signInButton,
						}}
					>
						{t('Log In')}
					</Button>
					<Button
						onClick={goToSignUp}
						variant="outlined"
						classes={{
							root: classes.signUpButton,
						}}
					>
						{t('Sign up')}
					</Button>
				</>
			)}
			{shouldShowClose && isFocused && <Cancel />}
			{storeUser && !isSmDown && (
				<>
					<div
						className={classes.avatarContainer}
						onClick={showProfileMenu}
						ref={menuProfileRef}
					>
						<EntityLogo
							type={EEntity.person}
							fullView={false}
							user={storeUser}
						/>
						<KeyboardArrowDown />
					</div>
					{!isSmDown && (
						<Popper
							className={classes.popper}
							open={isProfileMenuVisible}
							placement="bottom-end"
							anchorEl={
								(menuProfileRef && menuProfileRef.current) ||
								null
							}
							disablePortal={true}
							modifiers={{
								flip: {
									enabled: true,
								},
								preventOverflow: {
									enabled: true,
								},
								arrow: {
									enabled:
										!!arrowProfileRef &&
										arrowProfileRef.current,
									element:
										arrowProfileRef &&
										arrowProfileRef.current,
								},
							}}
						>
							<span
								className={classes.arrow}
								ref={arrowProfileRef}
							/>
							<Paper elevation={2}>
								<ClickAwayListener
									onClickAway={closeProfileMenu}
								>
									<div>
										<MenuContent
											closeMenu={closeMenu}
											closeProfileMenu={closeProfileMenu}
											type="profile"
											userCompany={
												storeUser &&
												storeUser.profile &&
												storeUser.profile
													.ownedCompanies &&
												storeUser.profile
													.ownedCompanies[0]
											}
										/>
									</div>
								</ClickAwayListener>
							</Paper>
						</Popper>
					)}
				</>
			)}
			{shouldShowMenus && (
				<>
					<IconButton
						buttonRef={menuRef}
						onClick={showMenu}
						className={classes.menuButton}
						color="inherit"
						aria-label="Open drawer"
					>
						<MenuIcon />
					</IconButton>
					{!isSmDown && (
						<Popper
							className={classes.popper}
							open={isMenuVisible}
							placement="bottom-end"
							anchorEl={menuRef.current}
							disablePortal={true}
							modifiers={{
								flip: {
									enabled: true,
								},
								preventOverflow: {
									enabled: true,
								},
								arrow: {
									enabled: !!arrowRef && arrowRef.current,
									element: arrowRef && arrowRef.current,
								},
							}}
						>
							<span className={classes.arrow} ref={arrowRef} />
							<Paper elevation={2}>
								<ClickAwayListener onClickAway={closeMenu}>
									<div>
										<MenuContent
											closeMenu={closeMenu}
											closeProfileMenu={closeProfileMenu}
											type="site"
										/>
									</div>
								</ClickAwayListener>
							</Paper>
						</Popper>
					)}
					{isSmDown && (
						<SwipeableDrawer
							anchor="right"
							open={isMenuVisible}
							onOpen={showMenu}
							onClose={closeMenu}
							classes={{
								paper: classes.sidebarDrawer,
							}}
						>
							<div tabIndex={0} role="button">
								{storeUser && (
									<div
										className={
											classes.avatarSidebarContainer
										}
									>
										<EntityLogo
											type={EEntity.person}
											fullView={false}
											user={storeUser}
										/>
										<div>{storeUser.profile.firstName}</div>
									</div>
								)}
								<MenuContent
									closeMenu={closeMenu}
									closeProfileMenu={closeProfileMenu}
									type="full"
									userCompany={
										storeUser &&
										storeUser.profile &&
										storeUser.profile.ownedCompanies &&
										storeUser.profile.ownedCompanies[0]
									}
								/>
							</div>
						</SwipeableDrawer>
					)}
				</>
			)}
		</div>
	)
}

export default memo((props: TRightMenuProps) => (
	<StoreValue keys={['user|storeUser']}>
		<RightMenu {...props} />
	</StoreValue>
))
