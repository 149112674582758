import { IAppState } from 'models'
import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseApp = firebase.initializeApp({
	apiKey: 'AIzaSyD3BQ3mNupKYAYNyVMAIUEkvIIWuTMUxMM',
	authDomain: 'cv30dev-feda5.firebaseapp.com',
	databaseURL: 'https://cv30dev-feda5.firebaseio.com',
	projectId: 'cv30dev-feda5',
	storageBucket: 'cv30dev-feda5.appspot.com',
	messagingSenderId: '956898530395',
	appId: '1:956898530395:web:04a62746a7d8cbf9',
})

export let initialState: IAppState = {
	credentialsState: {
		checkCredentials: false,
	},
	guides: {
		appreciate: false,
		companyPageViewAs: 'member',
		userPageViewAs: 'member',
	},
	user: null,
	firebaseUser: null,
	search: {
		query: '',
		section: '',
		loading: false,
		debounceQuery: '',
	},
	firebase: {
		instance: firebaseApp,
	},
	company: {
		viewAs: 'member', //TODO: This line should be deleted. It was replaced by userPageViewAs prop from guideds
		id: '',
		name: '',
		ownerId: '', // here we store userId from global store
		description: '',
		typeId: '',
		industryId: '',
		noOfEmployees: 'ZeroTen', // TODO: here will be an enum with a default value
		hasLogo: false,
		logoPath: '',
		offices: {
			available: [],
			selected: [],
		},
		headquarters: {
			available: [],
			selected: [],
		},
		companySettingsTab: null,
		error: {
			name: false,
			industry: false,
			description: false,
			logoPath: false,
			companyType: false,
			noOfEmployees: false,
			headquarters: false,
		},
		breadcrumbPath: {
			path: null,
		},
		companySettings: {
			jobApplicants: {
				jobId: null,
			},
		},
	},
	job: {
		id: '',
		title: '',
		team: undefined,
		teamLeader: undefined,
		teamMembers: [],
		locations: [],
		jobCompany: undefined,
		// jobTeam: undefined,
		expiryDate: '',
		jobDescription: '',
		featuredImage: undefined,
		featuredVideo: '',
		skills: [],
		benefits: '',
		endSalary: undefined,
		salary: {
			amountMin: '',
			amountMax: '',
			currency: '',
			rate: '',
		},
		applyJobExternalUrl: undefined,
		contractType: undefined,
		jobRate: undefined,
		jobType: undefined,
		jobTypeLoc: [],
		seniorityLevel: undefined,
		currency: undefined,
		success: {
			publish: false,
		},
		searchForDefaultCompanyOffices: true,
		searchForDefaultCompanyTeams: true,
		defaultCompanyOffices: [],
		defaultCompanyTeams: [],
		error: {
			title: false,
			applyJobExternalUrl: false,
			jobDescription: false,
			expiryDate: false,
			team: false,
			teamLeader: false,
			locations: false,
			skills: false,
			benefits: false,
			contractType: false,
			jobType: false,
			jobTypeLoc: false,
			seniorityLevel: false,
			salary: {
				amountMin: false,
				amountMax: false,
				currency: false,
				rate: false,
			},
		},
	},
}

const cookieName =
	'$2y$12$1aXvMvnP2u84vafju9uy9.vTXSjvaQ.lNEPW4MYEklr0.6F2PnH22='
const cookies = document.cookie.split(';')
for (var i = 0; i < cookies.length; i++) {
	let cookie = cookies[i]
	while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length)
	if (cookie.indexOf(cookieName) === 0) {
		initialState = {
			...initialState,
			...JSON.parse(cookie.substring(cookieName.length, cookie.length)),
		}
		break
	}
}

// if (!initialState.firebase.instance)
initialState.firebase.instance = firebaseApp
initialState.firebaseUser = null

export default initialState
