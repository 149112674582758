import React, { useCallback, useState, useMemo, memo } from 'react'
import {
	createStyles,
	makeStyles,
	Theme,
	useTheme,
	withStyles,
} from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import {
	FormControl,
	Grid,
	IconButton,
	InputBase,
	MenuItem,
	Paper,
	Select,
	TextField,
	FormControlLabel,
	useMediaQuery,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InputLabel from '@material-ui/core/InputLabel'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SearchIcon from '@material-ui/icons/Search'
import Chip from '@material-ui/core/Chip'
import { Close } from '@material-ui/icons'
import Checkbox from '@material-ui/core/Checkbox'
import * as _ from 'lodash'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useQuery } from '@apollo/react-hooks'
import classNames from 'classnames'
import { useLocation, matchPath } from 'react-router-dom'

import {
	IndustriesQuery,
	TeamDepartmentQuery,
	RoLocations,
	GetSkills,
} from 'store'
import styles from './styles'
import { combineStyles } from 'utils'
import generalStyles from 'screens/search/styles'

const combinedStyles: any = combineStyles(generalStyles, styles)
const useStyles = makeStyles(combinedStyles)

const BootstrapInput = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'label + &': {
				marginTop: theme.spacing(2) - 5,
			},
		},
		input: {
			borderRadius: 2,
			position: 'relative',
			border: '1px solid #ebebeb',
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			color: theme.palette.grey[600],
			transition: theme.transitions.create([
				'border-color',
				'box-shadow',
			]),
			fontFamily: 'Open Sans, sans-serif',
			'&:focus': {
				borderRadius: 2,
			},
		},
	}),
)(InputBase)

const contractTypes: { [key: string]: any } = {
	FULL_TIME: 'Full Time',
	PART_TIME: 'Part Time',
	ONE_TIME: 'One Time',
	PROJECT_BASED: 'Project based',
}
const jobTypes: { [key: string]: any } = {
	INTERNSHIP: 'Internship',
	INTERN: 'Intern',
	FREELANCE: 'Freelance',
}
const jobTypeLocList: { [key: string]: any } = {
	ON_SITE: 'OnSite',
	REMOTE: 'Remote',
}

const seniorityLevelList: { [key: string]: any } = {
	BEGINNER: 'Beginner',
	MIDDLE: 'Middle',
	SENIOR: 'Senior',
}

function getStyles(industryName: string, industry: string[], theme: Theme) {
	return {
		fontWeight:
			industry.indexOf(industryName) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	}
}

const JobsFilterSection: React.FunctionComponent<any> = ({
	handleTitle,
	handleDepartmentIds,
	handleLocationsIds,
	handleCompanyName,
	handleTeamName,
	handleSkills,
	handleMinSalary,
	handleMaxSalary,
	handleCurrency,
	handleJobTypes,
	handleSeniorityLevels,
	handleContractTypes,
	handleLocationTypes,
	toggleDrawer,
}) => {
	// TODO: delete the company name from browser history stack (on page refresh the companyName is still there, it should not be there)
	const {
		state,
	}: { state: { companyName?: string; teamName?: string } } = useLocation()

	const theme = useTheme()
	const classes = useStyles()
	const { t } = useTranslation()
	const [title, setTitle] = useState<String>('')
	const [companyName, setCompanyName] = useState<String>(
		state && state.companyName ? state.companyName : '',
	)
	const [teamName, setTeamName] = useState<String>(
		state && state.teamName ? state.teamName : '',
	)
	const [startSalary, setStartSalary] = useState<String>('')
	const [endSalary, setEndSalary] = useState<String>('')
	const [currency, setCurrency] = useState<'' | 'Ron' | 'Eur' | 'Usd'>('')
	const isSmDown: boolean = useMediaQuery(theme.breakpoints.down('sm'))
	const [inputLocation, setInputLocation] = useState<String>('')
	const [inputSkill, setInputSkill] = useState<String>('')
	const [selectedLocations, setSelectedLoc] = useState<any[]>([])
	const [selectedSkills, setSelectedSkills] = useState<any[]>([])
	const [jobTypeFilter, setJobTypeFilter] = useState<any[]>([])
	const [jobTypeLocFilter, setJobTypeLocFilter] = useState<any[]>([])
	const [seniorityLevelFilter, setSeniorityLevelFilter] = useState<any[]>([])
	const [contractTypeFilters, setContractTypesFilter] = useState<any[]>([])
	const [departmentsIds, setDepartmentsIds] = useState<any[]>([])
	const [openDepartmentsList, setOpenDepartmentsList] = useState(false)
	const location = useLocation()
	const isCompanySettingsPage = matchPath(location.pathname, {
		path: '/:language(en|ro)/employers/:companySlug/company-settings',
		exact: false,
	})
	let companyIndustries: any = { industries: {} } // TODO: add an interface here.

	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	}

	const { loading: loadingLocations, data: locations } = useQuery(
		RoLocations,
		{
			skip: !inputLocation,
			fetchPolicy: 'cache-first',
			variables: {
				data: {
					name: inputLocation,
					emptyResult: true,
					limitRows: 10,
					type_in: [
						'Comună',
						'Oraş',
						'Municipiu',
						'National capital',
						'Sectoarele municipiului',
					],
				},
			},
		},
	)

	const { loading: loadingSkills, data: skills } = useQuery(GetSkills, {
		skip: !inputSkill,
		fetchPolicy: 'cache-first',
		variables: {
			key: inputSkill,
		},
	})

	const { data: companyIndustriesData } = useQuery(IndustriesQuery, {
		skip: companyIndustries.length > 0,
		fetchPolicy: 'cache-first',
	})
	companyIndustries = useMemo(
		() => companyIndustriesData || { industries: [] },
		[companyIndustriesData],
	)

	const { data: teamDepartmentsData } = useQuery(TeamDepartmentQuery)
	const teamDepartments = useMemo(
		() => (teamDepartmentsData ? teamDepartmentsData.teamDepartments : []),
		[teamDepartmentsData],
	)

	const filterByDepartment = useCallback(
		(event: React.ChangeEvent<{ value: unknown }>) => {
			event.stopPropagation()
			const options: any = event.target.value as HTMLSelectElement
			setDepartmentsIds(options)
			handleDepartmentIds(options)
		},
		[handleDepartmentIds],
	)

	const delayedTitleQuery = useCallback(
		_.debounce((title: string) => handleTitle(title), 500),
		[],
	)

	const filterByTitle = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setTitle(event.target.value)
			//_.debounce(handleCompanyName(event.target.value), 500)
			delayedTitleQuery(event.target.value)
		},
		[delayedTitleQuery],
	)

	const delayedComapanyNameQuery = useCallback(
		_.debounce(
			(companyName: string) => handleCompanyName(companyName),
			500,
		),
		[],
	)

	const filterByCompany = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setCompanyName(event.target.value)
			delayedComapanyNameQuery(event.target.value)
		},
		[delayedComapanyNameQuery],
	)
	const filterByTeam = useCallback(
		(event: any) => {
			handleTeamName(event.target.value)
			setTeamName(event.target.value)
		},
		[handleTeamName],
	)
	const filterByStartSalary = useCallback(
		(event: any) => {
			if (event && event.target) {
				setStartSalary(event.target.value)
				handleMinSalary(event.target.value)
			} else setStartSalary('')
		},
		[handleMinSalary],
	)
	const filterByEndSalary = useCallback(
		(event: any) => {
			if (event && event.target) {
				setEndSalary(event.target.value)
				handleMaxSalary(event.target.value)
			} else setEndSalary('')
		},
		[handleMaxSalary],
	)
	const filterByCurrency = useCallback(
		(event: any) => {
			let resultedCurrency =
				currency === event.target.value ? '' : event.target.value
			setCurrency(resultedCurrency)
			handleCurrency(resultedCurrency)
		},
		[handleCurrency, currency],
	)
	const handleSearchLocation = useCallback((event: any) => {
		if (event && event.target) {
			setInputLocation(event.target.value)
		} else setInputLocation('')
	}, [])
	const handleSearchSkillChange = useCallback((event: any) => {
		if (event && event.target) {
			setInputSkill(event.target.value)
		} else setInputSkill('')
	}, [])
	const checkIfExistLocation = useCallback(
		(location: any) => {
			const existInList = _.find(
				selectedLocations,
				(item: any) => item.id === location.id,
			)
			return existInList === undefined
		},
		[selectedLocations],
	)
	const checkIfExistSkill = useCallback(
		(skill: any) => {
			const existInList = _.find(
				selectedSkills,
				(item: any) => item.id === skill.id,
			)
			return existInList === undefined
		},
		[selectedSkills],
	)
	const filterByLocation = useCallback(
		(location: any) => {
			if (checkIfExistLocation(location)) {
				setSelectedLoc([...selectedLocations, location])
				setInputLocation('')
			}
			let selectedLocationsIds = [...selectedLocations, location].map(
				item => {
					return item.id
				},
			)
			handleLocationsIds(selectedLocationsIds)
		},
		[checkIfExistLocation, selectedLocations, handleLocationsIds],
	)
	const filterBySkills = useCallback(
		(skill: any) => {
			if (checkIfExistSkill(skill)) {
				setSelectedSkills([...selectedSkills, skill])
				setInputSkill('')
			}
			let selectedSkillsIds = [...selectedSkills, skill].map(item => {
				return item.id
			})
			handleSkills(selectedSkillsIds)
		},
		[checkIfExistSkill, handleSkills, selectedSkills],
	)
	const deselectLocation = (location: any) => {
		const filterList = _.filter(
			selectedLocations,
			(item: any) => item.id !== location.id,
		)
		setSelectedLoc(filterList)
		handleLocationsIds(filterList)
	}
	const deselectSkill = (skill: any) => {
		const filterList = _.filter(
			selectedSkills,
			(item: any) => item.id !== skill.id,
		)
		setSelectedSkills(filterList)
		handleSkills(filterList)
	}
	const checkIfSelected = useCallback((item: any, array: any[]) => {
		const index = _.indexOf(array, item)
		return index === -1
	}, [])

	const filterByJobType = (jobTypes: any) => {
		if (checkIfSelected(jobTypes, jobTypeFilter)) {
			setJobTypeFilter([...jobTypeFilter, jobTypes])
			handleJobTypes([...jobTypeFilter, jobTypes])
		} else {
			const newList = _.filter(
				jobTypeFilter,
				(item: any) => item !== jobTypes,
			)
			setJobTypeFilter(newList)
			handleJobTypes(newList)
		}
	}
	const filterByLocationTypes = useCallback(
		(locationType: any) => {
			if (checkIfSelected(locationType, jobTypeLocFilter)) {
				setJobTypeLocFilter([...jobTypeLocFilter, locationType])
				handleLocationTypes([...jobTypeLocFilter, locationType])
			} else {
				const newList = _.filter(
					jobTypeLocFilter,
					(item: any) => item !== locationType,
				)
				setJobTypeLocFilter(newList)
				handleLocationTypes(newList)
			}
		},
		[checkIfSelected, handleLocationTypes, jobTypeLocFilter],
	)
	const filterByContractTypes = useCallback(
		(type: any) => {
			if (checkIfSelected(type, contractTypeFilters)) {
				setContractTypesFilter([...contractTypeFilters, type])
				handleContractTypes([...contractTypeFilters, type])
			} else {
				const newList = _.filter(
					contractTypeFilters,
					(item: any) => item !== type,
				)
				setContractTypesFilter(newList)
				handleContractTypes(newList)
			}
		},
		[checkIfSelected, contractTypeFilters, handleContractTypes],
	)
	const filterBySeniorityLevel = (seniorityLevel: any) => {
		if (checkIfSelected(seniorityLevel, seniorityLevelFilter)) {
			setSeniorityLevelFilter([...seniorityLevelFilter, seniorityLevel])
			handleSeniorityLevels([...seniorityLevelFilter, seniorityLevel])
		} else {
			const newList = _.filter(
				seniorityLevelFilter,
				(item: any) => item !== seniorityLevel,
			)
			setSeniorityLevelFilter(newList)
			handleSeniorityLevels(newList)
		}
	}

	const handleOpenDepartmentsList = useCallback(
		(value: 'open' | 'close' | 'delete', event: MouseEvent) => {
			if (value === 'open') {
				setOpenDepartmentsList(true)
			} else if (value === 'close') {
				setOpenDepartmentsList(false)
			} else if (value === 'delete') {
				event.stopPropagation()
			}
		},
		[],
	)

	const handleDeselectDepartment = useCallback(
		(departmentId: string) => {
			const newIds = departmentsIds.filter(val => val !== departmentId)
			setDepartmentsIds(newIds)
			handleDepartmentIds(newIds)
		},
		[departmentsIds, handleDepartmentIds],
	)

	return (
		<Grid
			container
			direction={'column'}
			spacing={1}
			className={classes.filterWrapper}
		>
			<Grid item xs={12}>
				<div
					className={classNames(
						classes.searchSectionTitle,
						classes.searchSection,
					)}
				>
					{isSmDown && (
						<div>
							<ArrowBackIcon onClick={toggleDrawer(false)} />
						</div>
					)}
					<div>
						<Trans key="advancedSearchTitle">
							{t('Advanced')}&nbsp;
							<span className={classes.searchSectionTitleMain}>
								{t('Search-1')}
							</span>
						</Trans>
					</div>
				</div>
			</Grid>
			<Grid item xs={12}>
				<FormControl className={classes.formControl}>
					<TextField
						id="name"
						label={t('Job Title')}
						variant="outlined"
						InputProps={{
							classes: {
								root: classes.outlinedInputRoot,
								notchedOutline: classes.notchedOutline,
							},
						}}
						inputProps={{
							style: {
								padding: '10px 5px',
							},
						}}
						InputLabelProps={{
							classes: {
								outlined: classes.outlinedLabel,
								root: classes.notchedOutlineLabel,
							},
						}}
						onChange={filterByTitle}
						value={title}
					/>
				</FormControl>
			</Grid>
			{!isCompanySettingsPage && (
				<Grid item xs={12}>
					<FormControl className={classes.formControl}>
						<TextField
							id="company_name"
							label={t('Company')}
							variant="outlined"
							InputProps={{
								classes: {
									root: classes.outlinedInputRoot,
									notchedOutline: classes.notchedOutline,
								},
							}}
							inputProps={{
								style: {
									padding: '10px 5px',
								},
							}}
							InputLabelProps={{
								classes: {
									outlined: classes.outlinedLabel,
									root: classes.notchedOutlineLabel,
								},
							}}
							value={companyName}
							onChange={filterByCompany}
						/>
					</FormControl>
				</Grid>
			)}

			<Grid item xs={12}>
				<div className={classes.selectedLocation}>
					{selectedLocations.map((item: any) => (
						<Chip
							key={'selectedLoc' + item.name}
							variant="outlined"
							className={classes.chip}
							label={item.name}
							clickable={true}
							size={'small'}
							onDelete={() => deselectLocation(item)}
							deleteIcon={<Close />}
						/>
					))}
				</div>
				<div className={classes.searchField}>
					<InputBase
						onChange={handleSearchLocation}
						className={classes.input}
						classes={{
							root: classes.inputBase,
						}}
						placeholder={t('Locations')}
						inputProps={{ 'aria-label': 'Locations' }}
						value={inputLocation}
					/>
					<IconButton
						className={classes.iconButton}
						aria-label="search"
					>
						<SearchIcon
							fontSize={'small'}
							style={{ color: '#929292' }}
						/>
					</IconButton>
				</div>
				<ClickAwayListener onClickAway={() => {}}>
					<Paper>
						{loadingLocations && 'Loading ...'}
						{locations &&
							locations.roLocations &&
							locations.roLocations.map(
								(item: any, index: number) => (
									<Chip
										key={`${item.name}_${index}`}
										variant="outlined"
										label={item.name}
										className={classes.chip}
										clickable={checkIfExistLocation(item)}
										size={'small'}
										onClick={() =>
											!checkIfExistLocation(item)
												? null
												: filterByLocation(item)
										}
									/>
								),
							)}
						{locations &&
							locations.length === 0 &&
							inputLocation && (
								<div>{t('No locations found')}</div>
							)}
					</Paper>
				</ClickAwayListener>
			</Grid>
			<Grid item xs={12}>
				<FormControl style={{ width: '100%' }}>
					<InputLabel
						id="departments-mutiple-chip-label"
						className={classes.labelTeams}
					>
						{t('Department')}
					</InputLabel>
					<Select
						labelId="departments-mutiple-chip-label"
						id="departments-mutiple-chip"
						classes={{ icon: classes.selectIcon }}
						multiple
						value={departmentsIds}
						onChange={filterByDepartment}
						open={openDepartmentsList}
						onOpen={(event: any) =>
							handleOpenDepartmentsList('open', event)
						}
						onClose={(event: any) =>
							handleOpenDepartmentsList('close', event)
						}
						IconComponent={ExpandMoreIcon}
						input={<BootstrapInput />}
						renderValue={(selected: any) => {
							let selectedLabels = teamDepartments.filter(
								(item: any) => {
									return selected.indexOf(item.id) > -1
								},
							)
							return (
								<div className={classes.chips}>
									{(selectedLabels as any[]).map(
										(value: any, index: number) => {
											return (
												<Chip
													key={`${index}`}
													label={
														value.key
															.charAt(0)
															.toUpperCase() +
														value.key.slice(1)
													}
													className={classes.chip}
													onDelete={() =>
														handleDeselectDepartment(
															value.id,
														)
													}
													deleteIcon={
														<CloseIcon
															classes={{
																root:
																	classes.iconClose,
															}}
															fontSize={'small'}
														/>
													}
													onMouseDown={(event: any) =>
														handleOpenDepartmentsList(
															'delete',
															event,
														)
													}
												/>
											)
										},
									)}
								</div>
							)
						}}
						MenuProps={MenuProps}
					>
						{teamDepartments.length > 0 &&
							teamDepartments.map((item: any, index: number) => {
								return (
									<MenuItem
										key={`department_${index}`}
										value={item.id}
										style={getStyles(
											item,
											item.key.charAt(0).toUpperCase() +
												item.key.slice(1),
											theme,
										)}
									>
										{item.key.charAt(0).toUpperCase() +
											item.key.slice(1)}
									</MenuItem>
								)
							})}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl
					className={classes.formControl}
					style={{ marginTop: 8 }}
				>
					<TextField
						id="teamName"
						label={t('Team name')}
						variant="outlined"
						InputProps={{
							classes: {
								root: classes.outlinedInputRoot,
								notchedOutline: classes.notchedOutline,
							},
						}}
						inputProps={{
							style: {
								padding: '10px 5px',
							},
						}}
						InputLabelProps={{
							classes: {
								outlined: classes.outlinedLabel,
								root: classes.notchedOutlineLabel,
							},
						}}
						value={teamName}
						onChange={filterByTeam}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<div className={classes.selectedLocation}>
					{selectedSkills.map((item: any) => (
						<Chip
							key={'selectedSkills' + item.key}
							variant="outlined"
							className={classes.chip}
							label={item.title}
							clickable={true}
							size={'small'}
							onDelete={() => deselectSkill(item)}
							deleteIcon={<Close />}
						/>
					))}
				</div>
				<div className={classes.searchField}>
					<InputBase
						onChange={handleSearchSkillChange}
						className={classes.input}
						classes={{
							root: classes.inputBase,
						}}
						placeholder={t('Skills')}
						inputProps={{ 'aria-label': 'Skills' }}
						value={inputSkill}
					/>
					<IconButton
						className={classes.iconButton}
						aria-label="search"
					>
						<SearchIcon
							fontSize={'small'}
							style={{ color: '#929292' }}
						/>
					</IconButton>
				</div>
				<ClickAwayListener onClickAway={() => {}}>
					<Paper>
						{loadingSkills && 'Loading ...'}
						{skills &&
							skills.searchSkills &&
							skills.searchSkills.map(
								(item: any, index: number) => (
									<Chip
										key={`${item.key}_${index}`}
										variant="outlined"
										label={item.title}
										className={classes.chip}
										clickable={checkIfExistSkill(item)}
										size={'small'}
										onClick={() =>
											!checkIfExistSkill(item)
												? null
												: filterBySkills(item)
										}
									/>
								),
							)}
						{skills && skills.length === 0 && inputLocation && (
							<div>{t('No skills found')}</div>
						)}
					</Paper>
				</ClickAwayListener>
			</Grid>
			<Grid item xs={12}>
				<FormControl
					className={classNames(
						classes.formControl,
						classes.salaryMinTextField,
					)}
				>
					<TextField
						id="minSalary"
						label={t('Min. salary')}
						variant="outlined"
						InputProps={{
							classes: {
								root: classes.outlinedInputRoot,
								notchedOutline: classes.notchedOutline,
							},
						}}
						inputProps={{
							style: {
								padding: '10px 5px',
							},
						}}
						InputLabelProps={{
							classes: {
								outlined: classes.outlinedLabel,
								root: classes.notchedOutlineLabel,
							},
						}}
						type="number"
						value={startSalary}
						onChange={filterByStartSalary}
					/>
				</FormControl>
				<FormControl
					className={classNames(
						classes.formControl,
						classes.salaryMaxTextField,
					)}
				>
					<TextField
						id="maxSalary"
						label={t('Max. salary')}
						variant="outlined"
						InputProps={{
							classes: {
								root: classes.outlinedInputRoot,
								notchedOutline: classes.notchedOutline,
							},
						}}
						inputProps={{
							style: {
								padding: '10px 5px',
							},
						}}
						InputLabelProps={{
							classes: {
								outlined: classes.outlinedLabel,
								root: classes.notchedOutlineLabel,
							},
						}}
						type="number"
						value={endSalary}
						onChange={filterByEndSalary}
					/>
				</FormControl>
			</Grid>
			<Grid item={true} sm={6} xs={12} className={classes.currency}>
				<FormControlLabel
					control={
						<Checkbox
							checked={currency === 'Eur'}
							onChange={filterByCurrency}
							value="Eur"
							color="primary"
						/>
					}
					label="EUR"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={currency === 'Usd'}
							onChange={filterByCurrency}
							value="Usd"
							color="primary"
						/>
					}
					label="USD"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={currency === 'Ron'}
							onChange={filterByCurrency}
							value="Ron"
							color="primary"
						/>
					}
					label="RON"
				/>
			</Grid>
			<Grid item xs={12}>
				<div className={classes.labelText}>{t('Type of job')}</div>
				<div>
					{Object.keys(jobTypes).map(item => {
						return (
							<Chip
								key={jobTypes[item]}
								variant="outlined"
								className={`${classes.chip} ${
									!checkIfSelected(item, jobTypeFilter)
										? classes.chipActive
										: classes.chipInactive
								} `}
								label={jobTypes[item]}
								size={'small'}
								clickable={true}
								onClick={() => filterByJobType(item)}
							/>
						)
					})}
				</div>
			</Grid>
			<Grid item xs={12}>
				<div className={classes.labelText}>{t('Seniority level')}</div>
				<div>
					{Object.keys(seniorityLevelList).map(item => {
						return (
							<Chip
								key={seniorityLevelList[item]}
								variant="outlined"
								className={`${classes.chip} ${
									!checkIfSelected(item, seniorityLevelFilter)
										? classes.chipActive
										: classes.chipInactive
								} `}
								label={seniorityLevelList[item]}
								size={'small'}
								clickable={true}
								onClick={() => filterBySeniorityLevel(item)}
							/>
						)
					})}
				</div>
			</Grid>
			<Grid item xs={12}>
				<div className={classes.labelText}>{t('Contract Type')}</div>
				<div>
					{Object.keys(contractTypes).map(item => {
						return (
							<Chip
								key={contractTypes[item]}
								variant="outlined"
								className={`${classes.chip} ${
									!checkIfSelected(item, contractTypeFilters)
										? classes.chipActive
										: classes.chipInactive
								} `}
								label={contractTypes[item]}
								size={'small'}
								clickable={true}
								onClick={() => filterByContractTypes(item)}
							/>
						)
					})}
				</div>
			</Grid>
			<Grid item xs={12}>
				<div className={classes.labelText}>
					{t('Job OnSite or Remote')}
				</div>
				<div>
					{Object.keys(jobTypeLocList).map(item => {
						return (
							<Chip
								key={jobTypeLocList[item]}
								variant="outlined"
								className={`${classes.chip} ${
									!checkIfSelected(item, contractTypeFilters)
										? classes.chipActive
										: classes.chipInactive
								} `}
								label={jobTypeLocList[item]}
								size={'small'}
								clickable={true}
								onClick={() => filterByLocationTypes(item)}
							/>
						)
					})}
				</div>
			</Grid>
		</Grid>
	)
}

export default memo(JobsFilterSection)
