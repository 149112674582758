// import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'

const hotjars: any = {
	local: 1359728,
	development: 1359728,
	production: 161834,
}

// Test GA
// const gas: any = {
// 	local: 'UA-196311743-1',
// 	development: 'UA-196311743-1',
// 	production: 'UA-196311743-1',
// }

const gas: any = {
	local: 'UA-141903346-1',
	development: 'UA-141903346-1',
	production: 'UA-69482353-2',
}

// @ts-ignore
hotjar.initialize(hotjars[process.env.REACT_APP_ENVIRONMENT], 6)
// @ts-ignore
// ReactGA.initialize(gas[process.env.REACT_APP_ENVIRONMENT])
