import React, { memo, useState, useCallback, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useMediaQuery, Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import classNames from 'classnames'
import textVersion from 'textversionjs'
import DOMPurify from 'dompurify'

import styles from './styles'
import commonStyles from './../../styles/common'
import { buildTeamCover, combineStyles } from 'utils'
import CompanyHeaderImage from 'assets/images/company-header-image.jpg'
import { Badge } from 'components'
import { EEntity } from 'models'

const TeamCard: React.FunctionComponent<any> = ({ teamsData = [] }) => {
	const isMobile = useMediaQuery('@media (max-width:650px)')
	const combinedStyles: any = combineStyles(commonStyles, styles)
	const useStyles = makeStyles(combinedStyles)
	const classes = useStyles()
	const history = useHistory()
	const { i18n, t } = useTranslation()

	const [
		teamResourceContainerHeight,
		setTeamResourceContainerHeight,
	] = useState(208)

	const handleGoToTeamProfile = useCallback(
		(teamSlug: string, companySlug: string) => () => {
			history.push(
				`/${i18n.language}/employers/${companySlug}/teams/${teamSlug}`,
			)
		},
		[history, i18n.language],
	)

	useEffect(() => {
		const handleWindowResize = () =>
			setTeamResourceContainerHeight((260 * window.innerWidth) / 650)
		window.addEventListener('resize', handleWindowResize)

		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	})

	const handleGoToCompany = useCallback(
		(companySlug: string): any => {
			return history.push(`/${i18n.language}/employers/${companySlug}`)
		},
		[history, i18n],
	)

	return (
		<>
			<Grid container={true} direction="column">
				{teamsData.length > 0 &&
					teamsData.map((item: any, index: number) => {
						let companyHq: any
						let companyOffices = item.company.offices
						companyHq = companyOffices
							.filter((item: any) => {
								return item.hq
							})
							.shift()
						const plainTextDescription = textVersion(
							DOMPurify.sanitize(item.description),
						)

						return (
							<Grid
								container={true}
								className={classNames(
									classes.teamItem,
									classes.defaultShadow,
								)}
								key={`team ${index}`}
							>
								<Badge
									type={EEntity.company}
									extraClasses={classes.badgeExtra}
									company={item.company}
									title={item.company.name}
									description={
										item.company.industry &&
										item.company.industry.key
									}
									onClick={() =>
										handleGoToCompany(item.company.slug)
									}
								/>
								<div className={classes.location}>
									<RoomOutlinedIcon
										classes={{
											root: classes.locationIcon,
										}}
									/>
									<span>
										{companyHq &&
											companyHq.location.region2}
									</span>
								</div>
								<Grid
									item={true}
									xs={isMobile ? 12 : 8}
									md={8}
									className={classes.teamResourceContainer}
									style={{
										minHeight:
											isMobile && item.featuredVideo
												? `${teamResourceContainerHeight}px`
												: 'undefined',
									}}
								>
									{item.hasCover && (
										<img
											src={buildTeamCover(item)}
											alt={item.name}
											onClick={handleGoToTeamProfile(
												item.slug,
												item.company.slug,
											)}
										/>
									)}
									{!item.hasCover && item.featuredVideo && (
										<div className={classes.video}>
											<ReactPlayer
												url={item.featuredVideo}
												className={classes.player}
												controls={true}
												width="100%"
												height="100%"
												style={{
													flex: 1,
													overflow: 'hidden',
												}}
											/>
										</div>
									)}
									{!item.hasCover && !item.featuredVideo && (
										<img
											alt={item.name}
											src={CompanyHeaderImage}
											onClick={handleGoToTeamProfile(
												item.slug,
												item.company.slug,
											)}
										/>
									)}
								</Grid>
								<Grid
									item={true}
									xs={isMobile ? 12 : 4}
									md={4}
									className={classes.teamItemInformation}
									onClick={handleGoToTeamProfile(
										item.slug,
										item.company.slug,
									)}
								>
									<Grid container={true} direction="column">
										<h3
											className={classes.teamItemTitle}
											style={{ marginTop: 0 }}
										>
											{item.name}
										</h3>
										{item.name.length +
											plainTextDescription.length >
										200 ? (
											<div>
												{`${plainTextDescription.substr(
													0,
													200 - item.name.length,
												)}...`}
											</div>
										) : (
											plainTextDescription
										)}
									</Grid>
									<Fab
										variant="extended"
										size="small"
										color="primary"
										aria-label="small"
										style={{
											position: 'absolute',
											bottom: '10px',
											right: '10px',
											textTransform: 'none',
											fontSize: '12px',
											height: '25px',
										}}
										onClick={handleGoToTeamProfile(
											item.slug,
											item.company.slug,
										)}
									>
										{t('Learn more') || ''}
									</Fab>
								</Grid>
							</Grid>
						)
					})}
			</Grid>
		</>
	)
}

export default memo(TeamCard)
