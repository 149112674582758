import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		container: {
			boxShadow: '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			borderColor: '#ebebeb',
			'&:hover': {
				boxShadow: '0px 1px 3px 0px rgba(150, 150, 150, 0.1)!important',
				cursor: 'pointer',
			},
			borderRadius: theme.spacing(4),
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(0.5),
			paddingLeft: theme.spacing(0.5),
			paddingRight: theme.spacing(1.25),
			background: theme.palette.common.white,
			border: `1px solid ${theme.palette.grey.A100}`,
			maxWidth: theme.spacing(30),
			[theme.breakpoints.up('sm')]: {
				maxWidth: theme.spacing(40),
				[theme.breakpoints.up('md')]: {
					maxWidth: theme.spacing(50),
					[theme.breakpoints.up('lg')]: {
						maxWidth: theme.spacing(60),
					},
				},
			},
			[theme.breakpoints.down('sm')]: {
				maxWidth: theme.spacing(28),
			},
		},
		fullView: {
			height: theme.spacing(10),
			borderRadius: theme.spacing(5),
		},
		currentPosition: {
			fontSize: 14,
			fontWeight: 300,
		},
		worsAtLabel: {
			fontSize: 14,
			fontWeight: 300,
			marginRight: theme.spacing(0.5),
		},
		info: {
			flexGrow: 1,
			overflow: 'hidden',
			paddingLeft: theme.spacing(1),
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(0.5),
			paddingRight: theme.spacing(0.5),
		},
		ellipsis: {
			flexWrap: 'nowrap',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			width: '100%',
			// [theme.breakpoints.down('sm')]: {
			// 	whiteSpace: 'pre-wrap',
			// },
		},
		name: {
			color: theme.palette.grey[800],
			fontWeight: 500,
			fontSize: 16,
			lineHeight: '20px',
			[theme.breakpoints.down('sm')]: {
				fontSize: 13,
				lineHeight: '15px',
			},
		},
		role: {
			color: theme.palette.grey[800],
			fontWeight: 300,
			fontSize: 14,
			lineHeight: '18px',
			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
		},
		currentPositionItem: {
			color: '#277DBA',
			marginRight: theme.spacing(0.5),
			'&:last-child': {
				marginRight: 0,
				// marginLeft: theme.spacing(0.5),
			},
		},
		fullWidth: {
			width: '100%!important',
		},
		row: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			flexWrap: 'nowrap',
		},
		autoWidth: {
			width: 'auto',
		},
	})
