import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		boxRoot: {
			borderRadius: '5px',
		},
		cardContentRoot: {
			padding: 0,
			margin: 0,
			height: '80px',
			display: 'flex',
			flex: 1,
			alignItems: 'center',
			paddingBottom: '0 !important',
			[theme.breakpoints.down('sm')]: {
				height: '60px',
			},
		},
		imageContent: {
			position: 'relative',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			width: '100%',
			height: '80px',
			backgroundSize: 'cover',
			backgroundPosition: 'center 40%',
			background:
				'linear-gradient(to right, rgba(27,26, 124, 0.6),  rgba(252, 38, 82, 0.6)), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/skyscrapers.jpg)',
			display: 'flex',
			alignItems: 'center',
			flexFlow: 'row',
		},
		teamImage: {
			display: 'block',
			width: '100%',
			height: '100%',
		},
		cardImage: {
			position: 'relative',
			height: '150px',
		},
		imageTitle: {
			display: 'flex',
			flexFlow: 'column',
			fontWeight: 'bold',
			color: '#fff',
		},

		mainTitle: {
			fontSize: 20,
			fontWeight: 700,
			[theme.breakpoints.down('sm')]: {
				fontSize: 18,
				[theme.breakpoints.only('xs')]: {
					fontSize: 16,
				}
			},
		},
		subTitle: {
			fontSize: 14,
			fontWeight: 300,
		},
		arrowRightIcon: {
			marginLeft: 'auto',
		},
		arrowWhite: {
			color: '#fff',
		},
		details: {
			cursor: 'pointer',
			position: 'relative',
			display: 'flex',
			flex: 1,
			paddingLeft: '20px',
			alignItems: 'center'
		},
		detailsTitle: {
			color: '#1B1A7C',
			display: 'flex',
			flexFlow: 'column',
			fontWeight: 'bold',
		},
		cardGray: {
			padding: 0,
			margin: 0,
			height: '80px',
			background: '#ebebeb',
			paddingBottom: '0 !important',
			display: 'flex',
			flex: 1,
			alignItems: 'center',
			[theme.breakpoints.down('sm')]: {
				height: '60px',
			},
		},
		arrowBlue: {
			color: '#1B1A7C',
			fontSize: 20,
		},
	})
