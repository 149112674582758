export const FACEBOOK_URIS: { [key: string]: string } = {
	local: `https://localhost:3000/oauth/facebook`,
	development: `https://dev.cv30.co/oauth/facebook`,
	production: `https://cv30.co/oauth/facebook`,
}

export const BACKEND_URIS: { [key: string]: string } = {
	local: 'https://localhost:4000/graphql',
	development: 'https://api-dev.cv30.co/graphql',
	production: 'https://api.cv30.co/graphql',
}

export const GOOGLE_URIS: { [key: string]: string } = {
	local: `https://localhost:3000/oauth/google`,
	development: `https://dev.cv30.co/oauth/google`,
	production: `https://cv30.co/oauth/google`,
}

export const LINKEDIN_URIS: { [key: string]: string } = {
	local: `https://localhost:3000/oauth/linkedin`,
	development: `https://dev.cv30.co/oauth/linkedin`,
	production: `https://cv30.co/oauth/linkedin`,
}

export const SIGN_REQUEST_URIS: { [key: string]: string } = {
	local: 'https://sign.cv30.co/',
	development: 'https://sign.cv30.co/',
	production: 'https://sign.cv30.co/',
}
