import React, { memo } from 'react'
import Grid from '@material-ui/core/Grid'

import { JobApplicantCard } from './components'
import { StoreValue } from 'store'

const JobApplicantsFeedView: React.FunctionComponent<any> = ({
	jobApplicantsData = [],
}) => {
	return (
		<>
			<Grid container={true} direction="column">
				{jobApplicantsData.length > 0 &&
					jobApplicantsData.map((item: any, index: number) => {
						return (
							<JobApplicantCard
								key={`applicant-job-card-${index}`}
								data={item}
							/>
						)
					})}
			</Grid>
		</>
	)
}

const JobApplicantsFeed = memo((props: any) => (
	<StoreValue keys={['storeDispatch']}>
		<JobApplicantsFeedView {...props} />
	</StoreValue>
))
export default memo(JobApplicantsFeed)
