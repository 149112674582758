import React, { memo, useState, useCallback, MouseEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'

import { TeamMemberRow } from '.'
import AddTeamMembers from './addTeamMembers'
import styles from '../styles'
import {
	AddTeamMembers as AddTeamMembersMutation,
	DeleteTeamMember,
} from 'store'

const useStyles = makeStyles(styles)

const TeamMembers: React.FunctionComponent<any> = ({
	teamId = '',
	members,
	bCanEdit,
}) => {
	const classes = useStyles()
	const [toggleAddTeamMembers, setToggleAddTeamMembers] = useState(false)
	const { t } = useTranslation()
	// const { teamMembers } = teamDetails
	const [handleAddTeamMembersMutation] = useMutation(AddTeamMembersMutation)
	const [handleDeleteTeamMemberMutation] = useMutation(DeleteTeamMember)

	const handleToggleAddTeamMembers = useCallback(() => {
		setToggleAddTeamMembers(!toggleAddTeamMembers)
	}, [toggleAddTeamMembers])

	const handleSaveAddTeamMembers = useCallback(
		userIds => () => {
			handleAddTeamMembersMutation({
				variables: {
					data: { userIds, id: teamId },
				},
			})
			handleToggleAddTeamMembers()
		},
		[handleAddTeamMembersMutation, teamId, handleToggleAddTeamMembers],
	)

	const handleDeleteTeamMember = useCallback(
		(event: MouseEvent, memberId) => {
			event.stopPropagation()
			handleDeleteTeamMemberMutation({
				variables: {
					data: { id: memberId },
				},
			})
		},
		[handleDeleteTeamMemberMutation],
	)

	return (
		<Grid
			className={classes.teamMembersSection}
			container={true}
			direction="row"
		>
			<Grid xs={12} item={true}>
				<div className={classes.sectionTitleWrap}>
					<h2 className={classes.sectionTitle}>
						{t('Meet the')}{' '}
						<span className={classes.emphasizedText}>
							{t('Team members')}
						</span>
					</h2>
					{bCanEdit && !toggleAddTeamMembers && (
						<span
							className={classes.actionTitle}
							onClick={handleToggleAddTeamMembers}
						>
							+{t('Add members')}
						</span>
					)}
				</div>
			</Grid>
			<Grid xs={12} item={true}>
				{toggleAddTeamMembers && (
					<AddTeamMembers
						members={members}
						handleSaveAddTeamMembers={handleSaveAddTeamMembers}
						handleToggleAddTeamMembers={handleToggleAddTeamMembers}
					/>
				)}
			</Grid>
			<Grid
				item={true}
				className={`${classes.sectionContent} ${classes.meetMemberSection}`}
			>
				<Grid container spacing={1}>
					{members.map((teamMember: any) => {
						return (
							<Grid
								key={`member-${teamMember.id}`}
								container
								item
								xs={12}
								md={6}
								spacing={2}
							>
								<TeamMemberRow
									bCanEdit={bCanEdit}
									teamMember={teamMember}
									handleDeleteTeamMember={
										handleDeleteTeamMember
									}
								/>
							</Grid>
						)
					})}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default memo(TeamMembers)
