import React, { useCallback, useMemo, memo, useState } from 'react'
import AsyncSelect from 'react-select/async'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import { useQuery } from '@apollo/react-hooks'
import * as _ from 'lodash'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles'
import { UsersProfileQuery } from 'store'

const useStyles = makeStyles(styles)

const NoOptionsMessage: (props: any) => JSX.Element = props => (
	<Typography
		color="textSecondary"
		className={props.selectProps.classes.noOptionsMessage}
		{...props.innerProps}
	>
		{props.children}
	</Typography>
)

const inputComponent: (props: any) => JSX.Element = ({
	inputRef,
	...props
}) => <div ref={inputRef} {...props} />

const Control: (props: any) => JSX.Element = ({
	children,
	innerProps,
	innerRef,
	selectProps: { classes, TextFieldProps },
}) => (
	<TextField
		fullWidth
		InputProps={{
			inputComponent,
			inputProps: {
				className: classes.input,
				ref: innerRef,
				children,
				...innerProps,
			},
		}}
		{...TextFieldProps}
	/>
)

const Option: (props: any) => JSX.Element = props => (
	<MenuItem
		ref={props.innerRef}
		selected={props.isFocused}
		component="div"
		style={{
			fontWeight: props.isSelected ? 500 : 400,
		}}
		{...props.innerProps}
	>
		{props.children}
	</MenuItem>
)

const Placeholder: (props: any) => JSX.Element = ({
	selectProps,
	innerProps = {},
	children,
}) => (
	<Typography
		color="textSecondary"
		className={selectProps.classes.placeholder}
		{...innerProps}
	>
		{children}
	</Typography>
)

const ValueContainer: (props: any) => JSX.Element = props => (
	<div className={props.selectProps.classes.valueContainer}>
		{props.children}
	</div>
)

const SingleValue: (props: any) => JSX.Element = props => {
	return (
		<Typography
			className={props.selectProps.classes.singleValue}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	)
}
const MultiValue: (props: any) => JSX.Element = props => (
	<Chip
		tabIndex={-1}
		label={props.children}
		className={classNames(props.selectProps.classes.chip, {
			[props.selectProps.classes.chipFocused]: props.isFocused,
		})}
		onDelete={props.removeProps.onClick}
		deleteIcon={<CancelIcon {...props.removeProps} />}
	/>
)

const Menu: (props: any) => JSX.Element = props => (
	<Paper
		square
		className={props.selectProps.classes.paper}
		{...props.innerProps}
	>
		{props.children}
	</Paper>
)

const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	ValueContainer,
	SingleValue,
}

const AddTeamMembers: React.FunctionComponent<any> = ({
	members = [],
	handleToggleAddTeamMembers,
	handleSaveAddTeamMembers,
}) => {
	const [selectedMembers, setSelectedMembers] = useState([])
	const { t } = useTranslation()
	const classes = useStyles()
	const theme = useTheme()
	const { refetch: refetchUsersProfile } = useQuery(UsersProfileQuery, {
		skip: true,
	})

	const usersValuesIds = useMemo(
		() => members.map((item: any) => item.user.id),
		[members],
	)

	const selectStyles = {
		input: (base: any) => ({
			...base,
			color: theme.palette.text.primary,
			'& input': {
				font: 'inherit',
			},
		}),
		dropdownIndicator: (base: any) => ({
			...base,
			cursor: 'default',
		}),
	}

	const debouncedFetchUsersProfile = useCallback(
		_.debounce((searchTerm: any, callback: any) => {
			refetchUsersProfile({
				data: {
					name: searchTerm,
				},
			})
				.then((result: any) => {
					return callback(
						result.data
							? result.data.usersProfile
									.filter((item: any) => {
										return !usersValuesIds.includes(
											item.user.id,
										)
									})
									.map((item: any) => {
										let parseItem = {
											value: '',
											label: '',
										}
										parseItem.value = item.user.id
										parseItem.label = `${item.firstName} ${item.lastName}`
										return parseItem
									})
							: [],
					)
				})
				.catch((error: any) => {
					return callback(error, null)
				})
		}),
		[],
	)

	const handleGetUsersProfile = useCallback(
		(input: any, callback: any) => {
			if (!input) {
				return Promise.resolve({ options: [] })
			}
			debouncedFetchUsersProfile(input, callback)
		},
		[debouncedFetchUsersProfile],
	)
	const handleChange = useCallback(event => {
		let parseData = []
		if (event) {
			parseData = event.map((item: { value: string; label: string }) => {
				return item.value
			})
		}
		setSelectedMembers(parseData)
	}, [])

	return (
		<>
			<Grid container direction="row">
				<Grid xs={12} item={true}>
					<div className={classes.actionButtons}>
						<div
							className={classNames([
								selectedMembers.length === 0 &&
									classes.disabledSelect,
							])}
							onClick={handleSaveAddTeamMembers(selectedMembers)}
						>
							{t('Save')}
						</div>
						<div onClick={handleToggleAddTeamMembers}>
							{t('Cancel')}
						</div>
					</div>
				</Grid>
				<AsyncSelect
					loadOptions={handleGetUsersProfile}
					className={classes.asyncSelect}
					classes={classes}
					styles={selectStyles}
					components={components}
					// value={usersValues}
					onChange={event => handleChange(event)}
					inputId="react-select-multiple"
					TextFieldProps={{
						label: null,
						InputLabelProps: {
							htmlFor: 'react-select-multiple',
							shrink: true,
						},
					}}
					isMulti={true}
					isClearable={true}
					placeholder={''}
					backspaceRemovesValue={false}
				/>
			</Grid>
		</>
	)
}

export default memo(AddTeamMembers)
