import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		addTeamSubmitSection: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			marginTop: '10px',
			marginBottom: '10px',
			'& > button:nth-child(2)': {
				marginLeft: '5px !important',
			},
			'& > button': {
				marginLeft: 'unset !important',
			},
		},
	})
