import React, { memo, useState, useCallback } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import styles from './styles'
import { StoreValue, DeleteTeam as DeleteTeamMutation } from 'store'

const useStyles = makeStyles(styles)

const DeleteTeamView: React.FunctionComponent<any> = ({ id, companySlug }) => {
	const classes = useStyles()
	const { t, i18n } = useTranslation()
	const history = useHistory()
	const [open, setOpen] = useState(false)
	const [errordeletionText, setErrorDeletionText] = useState(false)
	const [deletionText, setDeletionText] = useState('')
	const [handleDeleteTeamMutation] = useMutation(DeleteTeamMutation)

	const handleClickOpen = useCallback(() => {
		setOpen(true)
	}, [])

	const handleConfirm = useCallback(() => {
		if (!errordeletionText && deletionText !== '') {
			setOpen(false)
			// update in db
			handleDeleteTeamMutation({
				variables: {
					data: {
						id,
					},
				},
			}).then((data: any) => {
				history.push(`/${i18n.language}/employers/${companySlug}/teams`)
			})
		} else {
			setErrorDeletionText(true)
			setOpen(true)
		}
	}, [
		errordeletionText,
		deletionText,
		handleDeleteTeamMutation,
		id,
		history,
		i18n,
		companySlug,
	])

	const handleCancel = useCallback(() => {
		setOpen(false)
		// reset the state
		setErrorDeletionText(false)
		setDeletionText('')
	}, [])

	const handleValidateDeletion = useCallback(
		(elem: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			const text = elem.target.value
			setDeletionText(text)
			if (text !== 'delete') {
				setErrorDeletionText(true)
			} else {
				setErrorDeletionText(false)
			}
		},
		[],
	)

	return (
		<>
			<Button
				variant="contained"
				color="secondary"
				classes={{ root: classes.deleteButton }}
				startIcon={<DeleteIcon fontSize="small" />}
				onClick={handleClickOpen}
			>
				{t('Delete')}
			</Button>
			<Dialog
				disableBackdropClick={true}
				open={open}
				//onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{ paperWidthSm: classes.deleteDialog }}
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t('This will permanently delete the team!')}
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="confirm_deletion_of_company"
						label={t('Please type in "delete" to confirm')}
						type="text"
						value={deletionText}
						fullWidth
						error={errordeletionText}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={handleValidateDeletion}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						classes={{ root: classes.confirmDeletionButton }}
						onClick={handleConfirm}
						color="primary"
					>
						{t('Yes')}
					</Button>
					<Button
						classes={{ root: classes.cancelDeletionButton }}
						onClick={handleCancel}
						color="primary"
						autoFocus
					>
						{t('Cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
const DeleteTeam = (props: any) => (
	<StoreValue
		keys={[
			'storeDispatch',
			'user.profile.ownedCompanies|storeUserOwnedCompanies',
		]}
	>
		<DeleteTeamView {...props} />
	</StoreValue>
)

export default memo(DeleteTeam)
