import gql from 'graphql-tag'

export const GetJobBenefits = gql`
	query jobBenefits {
		jobBenefits {
			id
			title
		}
	}
`
