import React, { memo, useCallback, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'
import { Helmet } from 'react-helmet'

import { Article } from 'store'
import ReadView from './component'
import { truncateText, buildArticleImage } from 'utils'

const Read: React.FunctionComponent<any> = props => {
	const { state, pathname }: { state: any; pathname: any } = useLocation()
	const history = useHistory()
	const {
		articleSlug,
		postId,
	}: { articleSlug?: string; postId?: string } = useParams()

	const { i18n } = useTranslation()
	let articleProp = state && state.article

	const { loading, data: articleData } = useQuery(Article, {
		skip: articleProp,
		variables: { id: postId, slug: articleSlug },
	})

	const article = useMemo(
		() => articleProp || (articleData && articleData.article) || null,
		[articleProp, articleData],
	)
	const handleClose = useCallback(() => {
		let goBack = ''
		let parsePathName = pathname.split('/')
		switch (parsePathName[parsePathName.length - 2]) {
			case 'posts':
				goBack = parsePathName
					.slice(0, parsePathName.length - 1)
					.join('/')
				break
			case 'articles':
			case 'story': {
				if (parsePathName[parsePathName.length - 3] === 'search') {
					goBack = parsePathName
						.slice(0, parsePathName.length - 1)
						.join('/')
				} else {
					goBack = parsePathName
						.slice(0, parsePathName.length - 2)
						.join('/')
				}
				break
			}
			case 'all':
				goBack = parsePathName
					.slice(0, parsePathName.length - 1)
					.join('/')
				break
			default:
				goBack = `/${i18n.language}`
				break
		}

		history.push({
			pathname: goBack,
			state: { keepScroll: true },
		})
	}, [history, pathname, i18n])
	let helmetTitle

	if (article && article.postAs === 'Company') {
		helmetTitle = article.postingCompany.name
	} else if (article && article.postAs === 'User') {
		helmetTitle = `${article.author.profile.firstName} ${article.author.profile.lastName}`
	}
	return (
		<>
			<Helmet>
				<meta property="og:title" content={helmetTitle} />
				<meta property="og:url" content={window.location.href} />
				<meta
					property="og:description"
					content={truncateText((article && article.body?.replace(/(<([^>]+)>)/gi, "")) || '', 100)}
				/>
				{article && article.featuredImage && (
					<meta
						property="og:image"
						content={buildArticleImage(
							article.id,
							article.featuredImage.filename,
						)}
					/>
				)}
				{article && article.featuredVideo && (
					<meta property="og:video" content={article.featuredVideo} />
				)}
			</Helmet>
			{article && (
				<ReadView
					loading={loading}
					article={article}
					handleClose={handleClose}
				/>
			)}
		</>
	)
}

export default memo(Read)
