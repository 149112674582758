import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import * as resources from './translations'

// @ts-ignore
i18n.use(initReactI18next)
	.use(LanguageDetector)
	.init(
		{
			// we init with resources
			fallbackLng: 'ro',
			resources,
			whitelist: ['en', 'ro'],
			// debug: true,
			// have a common namespace used around the full app
			ns: ['translation'],
			defaultNS: 'translation',
			keySeparator: false, // we use content as keys

			interpolation: {
				escapeValue: false, // not needed for react!!
			},

			react: {
				wait: true,
			},
		},
		err => {
			return console.error(
				'Something went wrong loading the language',
				err,
			)
		},
	)

export default i18n
