import React, { memo } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { combineStyles, buildUserCvFile } from 'utils'
import styles from './styles'
import commonStyles from 'styles/common'
import classNames from 'classnames'
import { StoreValue } from 'store'

const JobApplicantView: React.FunctionComponent<any> = ({ data }) => {
	const { t, i18n } = useTranslation()
	const { id, profile, uid } = data.user
	const combinedStyles: any = combineStyles(commonStyles, styles)
	const useStyles = makeStyles(combinedStyles)
	const classes = useStyles()

	return !data ? null : (
		<>
			<Grid
				container={true}
				className={classNames(
					classes.jobApplicantItem,
					classes.defaultShadow,
				)}
			>
				<Grid
					item={true}
					md={12}
					className={classes.jobApplicantItemInformation}
				>
					<Grid container={true} direction="column">
						<div className={classes.infoRow}>
							<div className={'label'}>{t('Name')}:</div>
							<div className={'data'}>
								{`${profile.firstName} ${profile.lastName}`}
							</div>
							<a
								style={{ marginLeft: '8px' }}
								rel="noopener noreferrer"
								target="_blank"
								href={`/${i18n.language}/people/${uid}`}
							>
								{t('View applicant profile')}
							</a>
						</div>
						<div className={classes.infoRow}>
							<div className={'label'}>
								{t('Application date')}:
							</div>
							<div className={'data'}>
								{moment(data.createdAt).format('yyyy-MM-DD')}
							</div>
						</div>
						{profile.cvFilePath && profile.cvFilePath[0] && (
							<div className={classes.infoRow}>
								<div className={'label'}>{t('CV File')}:</div>
								<div className={'data'}>
									<a
										href={buildUserCvFile(
											id,
											profile.cvFilePath[0],
										)}
									>
										{t('View')}
									</a>
								</div>
							</div>
						)}
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

const JobApplicantCard = memo((props: any) => (
	<StoreValue keys={['storeDispatch']}>
		<JobApplicantView {...props} />
	</StoreValue>
))

export default JobApplicantCard
