import { useState, useEffect, useMemo } from 'react'

import { getArticleCommentsCollectionPath } from '../common'
import { TUseComments } from 'models'

const DEFAULT_HISTORY_PAGE_SIZE = 3

export const useComments: TUseComments = (articleId, pageSize, firebase) => {
	const firebaseApp: any = useMemo(() => (firebase && firebase.instance) || null, [firebase])
	const firestore = (firebaseApp && firebaseApp.firestore()) || null
	const [comments, setComments] = useState<Array<{ [field: string]: any }>>(
		[],
	)
	const [hasMore, setHasMore] = useState(false)
	const [lastDoc, setLastDoc] = useState<
		firebase.firestore.QueryDocumentSnapshot
	>()
	const fetchMore = () => {
		if (hasMore && lastDoc) {
			firestore
				.collection(getArticleCommentsCollectionPath(articleId))
				.orderBy('date', 'desc')
				.startAfter(lastDoc)
				.limit(pageSize || DEFAULT_HISTORY_PAGE_SIZE)
				.get()
				.then((snapshot: any) => {
					if (snapshot.size) {
						setComments([
							...comments,
							...snapshot.docs.map((doc: any) => ({
								id: doc.id,
								...doc.data(),
							})),
						])
						setLastDoc(snapshot.docs[snapshot.size - 1])
					}
					setHasMore(
						snapshot.size ===
						(pageSize || DEFAULT_HISTORY_PAGE_SIZE),
					)
				})
		}
	}
	useEffect(() => {
		firestore
			.collection(getArticleCommentsCollectionPath(articleId))
			.orderBy('date', 'desc')
			.limit(pageSize || DEFAULT_HISTORY_PAGE_SIZE)
			.get()
			.then((snapshot: any) => {
				if (snapshot.size) {
					setComments([
						...snapshot.docs.map((doc: any) => ({
							id: doc.id,
							...doc.data(),
						})),
					])
					setLastDoc(snapshot.docs[snapshot.size - 1])
				}
				setHasMore(
					snapshot.size === (pageSize || DEFAULT_HISTORY_PAGE_SIZE),
				)
			})
	}, [articleId, firestore, pageSize])

	return [comments, hasMore, fetchMore]
}
