import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		subMenuList: {
			display: 'flex',
			flexDirection: 'column',
		},
		categoryHeader: {
			'& span': {
				fontWeight: 'bold',
				fontSize: '12px',
				color: 'grey',
			},
		},
	})
