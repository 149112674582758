import { ECompanyActionTypes, ICompanyState } from 'models'
import * as _ from 'lodash'

export default (state: ICompanyState, action: any): ICompanyState => {
	switch (action.type) {
		case ECompanyActionTypes.setId:
			return {
				...state,
				id: action.id,
			}
		case ECompanyActionTypes.setName:
			return {
				...state,
				name: action.name,
				error: {
					...state.error,
					name: _.isEmpty(action.name),
				},
			}
		// case ECompanyActionTypes.setAddress:
		// 	return {
		// 		...state,
		// 		address: action.address,
		// 	}

		case ECompanyActionTypes.setDescription:
			return {
				...state,
				description: action.description,
			}

		case ECompanyActionTypes.setTypeId:
			return {
				...state,
				typeId: action.typeId,
			}

		case ECompanyActionTypes.setIndustryId:
			return {
				...state,
				industryId: action.industryId,
				error: {
					...state.error,
					industry: _.isEmpty(action.industryId),
				},
			}

		case ECompanyActionTypes.setNoOfEmployees:
			return {
				...state,
				noOfEmployees: action.noOfEmployees,
			}

		case ECompanyActionTypes.setHasLogo:
			return {
				...state,
				hasLogo: action.hasLogo,
			}

		case ECompanyActionTypes.setLogoPath:
			return {
				...state,
				logoPath: action.logoPath,
				error: {
					...state.error,
					logoPath: _.isEmpty(action.logoPath),
				},
			}
		case ECompanyActionTypes.setUploadLogo:
			return {
				...state,
				logoPath: action.logoPath,
				hasLogo: action.hasLogo,
				error: {
					...state.error,
					logoPath: _.isEmpty(action.logoPath) || !action.hasLogo,
				},
			}

		case ECompanyActionTypes.setPopulateOffices: {
			return {
				...state,
				offices: {
					...state.offices,
					available: action.offices.availableLocations,
				},
			}
		}
		case ECompanyActionTypes.setSelectOffices:
			return {
				...state,
				offices: {
					...state.offices,
					selected: [...state.offices.selected].concat([
						action.offices.location,
					]),
				},
			}
		case ECompanyActionTypes.setDeselectOffices:
			return {
				...state,
				offices: {
					...state.offices,
					selected: [...state.offices.selected].filter(
						item =>
							action.offices.location &&
							item.name !== action.offices.location.name,
					),
				},
			}
		case ECompanyActionTypes.setPopulateHeadquarters: {
			return {
				...state,
				headquarters: {
					...state.headquarters,
					available: action.headquarters.availableLocations,
				},
			}
		}
		case ECompanyActionTypes.setSelectHeadquarters:
			const selectedHeadquarters = [
				...state.headquarters.selected,
			].concat([action.headquarters.location])
			return {
				...state,
				headquarters: {
					...state.headquarters,
					selected: selectedHeadquarters,
				},
				error: {
					...state.error,
					headquarters: _.isEmpty(selectedHeadquarters),
				},
			}
		case ECompanyActionTypes.setDeselectHeadquarters:
			const selectedHeadquartersList = [
				...state.headquarters.selected,
			].filter(
				item =>
					action.headquarters.location &&
					item.name !== action.headquarters.location.name,
			)
			return {
				...state,
				headquarters: {
					...state.headquarters,
					selected: selectedHeadquartersList,
				},
				error: {
					...state.error,
					headquarters: _.isEmpty(selectedHeadquartersList),
				},
			}
		case ECompanyActionTypes.switchViewAs:
			return {
				...state,
				viewAs: state.viewAs === 'admin' ? 'member' : 'admin',
			}
		case ECompanyActionTypes.setCompanySettingsTab:
			return {
				...state,
				companySettingsTab: action.companySettingsTab,
			}
		case ECompanyActionTypes.setCompanyBreadcrumbPath:
			return {
				...state,
				breadcrumbPath: {
					path: action.path,
				},
			}
		case ECompanyActionTypes.setCompanySettingsJobApplicantsJobId:
			return {
				...state,
				companySettings: {
					...state.companySettings,
					jobApplicants: {
						...state.companySettings.jobApplicants,
						jobId: action.jobId,
					},
				},
			}
		default:
			return state
	}
}
