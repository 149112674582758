import React, { memo, useMemo, useCallback, useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/styles'
import Zoom from '@material-ui/core/Zoom'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/CloseRounded'
import ReactPlayer from 'react-player'
import dompurify from 'dompurify'
import CustomScroll from 'react-custom-scroll'
import 'react-custom-scroll/dist/customScroll.css'
import Chip from '@material-ui/core/Chip'
import { useMutation } from '@apollo/react-hooks'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'

import { TReadProps, EEntity, EAppStateActionTypes } from 'models'
import styles from './styles'
import Endorsements from '../endorsements'
import { buildArticleImage, ConditionalWrapper } from 'utils'
import { DeleteArticleMutation, StoreValue } from 'store'
import { Loading } from 'components'
import EditIcon from '@material-ui/icons/EditOutlined'
import { useHistory } from 'react-router'
import { Badge } from '../'
import Hidden from '@material-ui/core/Hidden'
import moment from 'moment'
import classNames from 'classnames'

const sanitizer = dompurify.sanitize
const useStyles = makeStyles(styles)

const Media: React.FunctionComponent<any> = ({ article, classes }) => {
	const text = article.isPost
		? sanitizer((article && article.body) || '')
		: article.description && article.description.trim().length
		? sanitizer(article.description)
		: sanitizer(article.body).replace(/(([^\s]+\s\s*){30})(.*)/, '$1…')

	return (
		<>
			{article.featuredImage && article.featuredImage.filename && (
				<div className={classes.image}>
					<img
						src={buildArticleImage(
							article.id,
							article.featuredImage.filename,
						)}
						alt={article.isPost ? text : article.title}
					/>
				</div>
			)}
			{article.featuredVideo && (
				<div className={classes.video}>
					<ReactPlayer
						url={article.featuredVideo}
						className={classes.player}
						controls={true}
						width="100%"
						height="100%"
					/>
				</div>
			)}
		</>
	)
}

const ReadView: React.FunctionComponent<TReadProps> = ({
	article,
	handleClose,
	refetch,
	hideDeleteBtn = false,
	storeUserId,
	storeUserIg,
	loading,
	storeDispatch,
}) => {
	const classes = useStyles()
	const theme = useTheme()
	const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const isUp700 = useMediaQuery('(min-width:748px)')
	const [deleteArticleMutationHandle] = useMutation(DeleteArticleMutation)
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const { t, i18n } = useTranslation()
	let history = useHistory()
	const mdBreakPoint = useMediaQuery(theme.breakpoints.up('md'))

	const transitionDuration = useMemo(
		() => ({
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreen,
		}),
		[theme],
	)

	const articleAuthorName =
		article && article.postAs === 'Company'
			? (article.postingCompany && article.postingCompany.name) || ''
			: article && article.author
			? article.author.profile.firstName
			: ''

	const goBack = useCallback(
		event => {
			handleClose(event)
		},
		[handleClose],
	)

	const toggleConfirmDelete = useCallback(() => {
		setConfirmDelete(confirmDelete => !confirmDelete)
	}, [])

	const toggleDeleteModal = useCallback(() => {
		setOpenDeleteModal(openDeleteModal => !openDeleteModal)
	}, [])
	const goToEdit = useCallback(
		() =>
			history.push({
				pathname: `/${i18n.language}/edit`,
				state: {
					article,
				},
			}),
		[history, i18n.language, article],
	)
	const clearQueryHandle = useCallback(() => {
		storeDispatch({
			type: EAppStateActionTypes.updateQuery,
			query: '',
		})
	}, [storeDispatch])

	const publishDate =
		(article &&
			article.updatedAt &&
			moment(article.updatedAt).format('DD.MM.YYYY')) ||
		moment().format('DD.MM.YYYY')

	const goToEntity = useCallback(
		(entityType: EEntity) => () => {
			let entityParams: string | undefined
			let entityPath: string | undefined
			switch (entityType) {
				case 'Company':
					entityParams =
						article &&
						article.postingCompany &&
						article.postingCompany.slug
					entityPath = 'employers'
					break
				case 'User':
					entityParams =
						article && article.author && article.author.uid
					entityPath = 'people'
					break
				default:
					break
			}
			clearQueryHandle()
			history.push(`/${i18n.language}/${entityPath}/${entityParams}`)
		},
		[history, i18n.language, article, clearQueryHandle],
	)

	useEffect(() => {
		if (confirmDelete) {
			const mutate = async () => {
				try {
					await deleteArticleMutationHandle({
						variables: {
							id: article.id,
						},
					})
					goBack('')
					refetch()
				} catch (error) {
					console.error(error)
				}
			}
			mutate()
			setConfirmDelete(false)
			setOpenDeleteModal(false)
		}
	}, [refetch, confirmDelete, deleteArticleMutationHandle, article, goBack])

	return loading ? (
		<Loading />
	) : (
		<Dialog
			maxWidth="md"
			fullWidth
			fullScreen={!isUpMd && !isUp700}
			open={true}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
			transitionDuration={transitionDuration}
			TransitionComponent={Zoom}
			TransitionProps={{
				unmountOnExit: true,
			}}
			classes={{
				paper: classes.paper,
				container: classes.contentRootContainer,
			}}
			PaperProps={{
				style: {
					maxWidth: mdBreakPoint ? undefined : 616,
					height: article.isPost ? 'auto' : '100%',
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden',
				},
			}}
		>
			<DialogContent
				style={{
					overflow: isUpMd ? 'hidden' : 'auto',
					flexDirection: isUpMd ? 'row' : 'column',
					padding: !isUpMd ? '0 10px' : undefined,
					flexShrink: 1,
					height: '100%',
				}}
				classes={{ root: classes.contentRoot }}
			>
				<div className={classes.contentCustomScroll}>
					<ConditionalWrapper
						condition={isUpMd}
						wrapper={(children: any) => (
							<CustomScroll
								addScrolledClass={true}
								allowOuterScroll={true}
								heightRelativeToParent="calc(100% - 10px)"
								flex="1"
							>
								{children}
							</CustomScroll>
						)}
					>
						<div
							className={classes.articleDescriptionWrap}
							style={{
								minWidth: isUpMd ? 616 : '100%',
								maxWidth: isUpMd ? 616 : '100%',
								width: isUpMd ? 616 : '100%',
								padding: isUpMd ? '0 31px' : undefined,
							}}
						>
							<div style={{ display: 'flex' }}>
								{!hideDeleteBtn &&
									(storeUserIg ||
										storeUserId ===
											(article &&
												article.author &&
												article.author.id)) && (
										<div
											className={
												classes.deleteIconContainer
											}
										>
											<Chip
												onClick={goToEdit}
												style={{
													marginRight: 4,
													background: '#fff',
												}}
												icon={<EditIcon />}
												variant="outlined"
												color={'secondary'}
												clickable={true}
												classes={{
													label:
														classes.editIconLabel,
												}}
											/>
											<Chip
												onClick={toggleDeleteModal}
												style={{ background: '#fff' }}
												icon={<DeleteIcon />}
												variant="outlined"
												color={'secondary'}
												clickable={true}
												classes={{
													label:
														classes.deleteIconLabel,
												}}
											/>
										</div>
									)}
								{isSmDown && (
									<IconButton
										className={classes.closeButton}
										style={{ marginLeft: 'auto' }}
										onClick={goBack}
									>
										<CloseIcon />
									</IconButton>
								)}
							</div>
							<Dialog
								open={openDeleteModal}
								onClose={handleClose}
								aria-labelledby="responsive-dialog-title"
							>
								<DialogTitle id="responsive-dialog-title">
									{article && article.isPost
										? t(
												'Are you sure you want to delete this post?',
										  )
										: t(
												'Are you sure you want to delete this article?',
										  )}
								</DialogTitle>
								<DialogActions>
									<Button
										color="primary"
										onClick={toggleConfirmDelete}
									>
										{t('Yes')}
									</Button>
									<Button
										color="primary"
										autoFocus
										onClick={toggleDeleteModal}
									>
										{t('No')}
									</Button>
								</DialogActions>
							</Dialog>
							<div className={classes.headerBadgeWrap}>
								<Badge
									type={
										(article && article.postAs) ||
										EEntity.person
									}
									company={article && article.postingCompany}
									user={article?.author}
									extraClasses={classes.badgeExtra}
									title={
										article?.postAs === EEntity.person
											? article?.author?.profile
													?.firstName +
											  ' ' +
											  article?.author?.profile?.lastName
											: article?.postAs ===
											  EEntity.company
											? article?.postingCompany?.name
											: ''
									}
									description={
										article.postAs === EEntity.person
											? article?.author?.profile?.worksAt
													?.position
											: article.postAs === EEntity.company
											? article?.postingCompany?.industry
													?.key
											: ''
									}
									onClick={goToEntity(
										(article && article.postAs) ||
											EEntity.person,
									)}
								/>
								<div className={classes.date}>
									<span>{publishDate}</span>
									{article.postAs === EEntity.person &&
										article.worksAt && (
											<Hidden mdDown>
												<div
													className={classNames(
														classes.worksAt,
														classes.ellipsis,
													)}
												>
													<div
														style={{
															fontSize: 10,
															lineHeight: '12px',
														}}
													>
														{t('Works at')}
														{': '}
													</div>
													<span
														className={
															classes.worksAtAccent
														}
													>
														{`${article.worksAt.company}`}
													</span>
												</div>
											</Hidden>
										)}
								</div>
							</div>

							{article && article.title && (
								<h6 className={classes.articleTitle}>
									{article.title}
								</h6>
							)}
							{article && !article.isPost && (
								<Media article={article} classes={classes} />
							)}
							<FroalaEditorView model={article.body} />
							{article && article.isPost && (
								<Media article={article} classes={classes} />
							)}
						</div>
					</ConditionalWrapper>
				</div>
				<div
					style={{
						flexGrow: 1,
						paddingTop: 30,
						borderLeft: isUpMd ? '1px solid #ebebeb' : undefined,
						//display: 'flex',
						//flexDirection: 'column',
					}}
				>
					<Endorsements
						data={article.tags}
						articleId={article.id}
						articleAuthorName={articleAuthorName}
						articleAuthorId={article.author?.id}
						viewMode={true}
						commentsPerPage={5}
						handleClose={handleClose}
						containerClassName={classes.articleEndorsements}
					/>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default memo((props: any) => {
	return (
		<StoreValue
			keys={[
				'user.id|storeUserId',
				'user._ig|storeUserIg',
				'storeDispatch',
			]}
		>
			<ReadView {...props} />
		</StoreValue>
	)
})
