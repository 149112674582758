import React, { memo, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { TextField } from '@material-ui/core'

import { combineStyles } from 'utils'
import commonStyles from '../../../styles'
import styles from './styles'
import { AddTeam, TeamsQuery } from 'store'

const combinedStyles: any = combineStyles(commonStyles, styles)
const useStyles = makeStyles(combinedStyles)

const AddTeamModal: React.FunctionComponent<any> = props => {
	const { companyId, openAddTeamModal, toggleAddTeamModal } = props
	const history = useHistory()
	const { companySlug }: { companySlug?: string } = useParams()
	const classes = useStyles()
	const { i18n, t } = useTranslation()
	const [teamName, setTeamName] = useState('')
	const [teamNameError, setTeamNameError] = useState(false)
	const [
		teamNameAlreadyExistsError,
		setTeamNameAlreadyExistsError,
	] = useState(false)

	const updateTeamName = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setTeamName(e.target.value)
			setTeamNameAlreadyExistsError(false)
			if (e.target.value !== '') {
				setTeamNameError(false)
			} else {
				setTeamNameError(true)
			}
		},
		[],
	)

	// Add the new team in cache
	const [createTeamMutation] = useMutation(AddTeam, {
		update(cache, { data: { addTeam } }) {
			const query = TeamsQuery
			try {
				const previous: any = cache.readQuery({
					query,
					variables: { companyId },
				})
				const teams = [addTeam].concat(previous.teams)
				cache.writeQuery({
					query,
					variables: { companyId },
					data: { teams },
				})
			} catch (e) {
				console.log(e)
			}
		},
	})
	const label = (
		<div>
			{t('Name')}
			<span className={classes.required}>{`(${t('Required')})`}</span>
		</div>
	)

	const handleToggleAddTeamModal = useCallback(() => {
		toggleAddTeamModal()
		setTeamNameError(false)
		setTeamNameAlreadyExistsError(false)
		setTeamName('')
	}, [toggleAddTeamModal])

	const createTeam = useCallback(async () => {
		if (!teamName) {
			setTeamNameError(true)
			return
		} else {
			createTeamMutation({
				variables: {
					details: {
						language: i18n.language,
						name: teamName,
						companyId,
					},
				},
			})
				.then((data: any) => {
					const addTeam = data?.data?.addTeam
					if (addTeam) {
						setTeamName('')
						history.push(
							`/${i18n.language}/employers/${companySlug}/teams/${addTeam.slug}`,
						)
					}
				})
				.catch((err: any) => {
					const errorMessage = err?.message
					if (
						errorMessage.toLowerCase().includes('unique constraint')
					) {
						setTeamNameAlreadyExistsError(true)
					}
					setTeamNameError(true)
				})
		}
	}, [
		history,
		i18n.language,
		createTeamMutation,
		companySlug,
		teamName,
		companyId,
	])

	return (
		<Dialog
			open={openAddTeamModal}
			// onClose={handleToggleAddTeamModal}
			aria-labelledby="form-dialog-title"
			fullWidth
			maxWidth="md"
			scroll="body"
			classes={{
				paperWidthMd: classes.paperWidthMd,
			}}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
		>
			<DialogContent style={{ overflow: 'hidden' }}>
				<Grid container>
					<Grid item xs={12}>
						<TextField
							label={label}
							rows="4"
							value={teamName}
							placeholder={t('Team Name')}
							className={classes.textInputRoot}
							margin="normal"
							onChange={updateTeamName}
							fullWidth
							error={teamNameError}
							helperText={
								teamNameAlreadyExistsError &&
								t('There is another team with the same name.')
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.addTeamSubmitSection}>
							<button
								className={classes.submitBtn}
								onClick={handleToggleAddTeamModal}
							>
								{t('Cancel')}
							</button>
							<button
								className={classes.submitBtn}
								onClick={createTeam}
							>
								{t('Save')}
							</button>
						</div>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default memo(AddTeamModal)
