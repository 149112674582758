import gql from 'graphql-tag'

import { UserExperienceFragment } from 'store/fragments'

export const UpdateUserExperience = gql`
	mutation setExperience($experience: ExperienceInput!) {
		setExperience(experience: $experience) {			
			...UserExperience
		}
	}
	${UserExperienceFragment}
`
export const DeleteUserExperience = gql`
	mutation removeExperience($id: String!) {
		removeExperience(id: $id) {			
			...UserExperience
		}
	}
	${UserExperienceFragment}
`
