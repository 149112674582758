import React, {
	memo,
	useState,
	useCallback,
	useRef,
	useMemo,
	useEffect,
} from 'react'
import Dialog from '@material-ui/core/Dialog'
import Cropper from 'react-easy-crop'
import { makeStyles } from '@material-ui/styles'
import Slider from '@material-ui/core/Slider'
import { useDropzone } from 'react-dropzone'
import ClearIcon from '@material-ui/icons/ClearOutlined'
import UploadIcon from '@material-ui/icons/CloudUploadOutlined'
import Chip from '@material-ui/core/Chip'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'

import styles from './styles'
import { useS3Upload, getCroppedImg } from 'utils'
import {
	UpdateCompanyDetails,
	UpdateTeamDetails,
	UpdateProfileDetails,
	StoreValue,
} from 'store'
import { EEntity, EAppStateActionTypes } from 'models'

const useStyles = makeStyles(styles)

const UploadCrop: React.FunctionComponent<any> = ({
	handleClose,
	open,
	updatePercent,
	type,
	company,
	team,
	user,
	storeUserId,
	storeDispatch,
	onAdd,
}) => {
	const [updateMutationHandle] = useMutation(
		type === EEntity.company
			? UpdateCompanyDetails
			: type === EEntity.team
			? UpdateTeamDetails
			: UpdateProfileDetails,
	)
	const { t, i18n } = useTranslation()
	const classes = useStyles({})
	const imageContainer = useRef<HTMLDivElement>(null)
	const [image, setImage] = useState('')
	const [imageExtension, setImageExtension] = useState('')
	const [uploadData, setUploadData] = useState<Blob | null>(null)
	const [imageSize, setImageSize] = useState({
		width: 0,
		height: 0,
	})
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState<number>(1)
	const section = useMemo(
		() =>
			type === EEntity.company
				? {
						s3Index: 'company_logo',
						id: company && company.id,
						extension: `-company-logo-original.${imageExtension}`,
				  }
				: type === EEntity.team
				? {
						s3Index: 'team_cover',
						id: team && team.id,
						extension: `-team-logo-original.${imageExtension}`,
				  }
				: {
						s3Index: 'profile_cover',
						id:
							(user &&
								storeUserId &&
								(user.id !== storeUserId
									? user.id
									: storeUserId)) ||
							undefined,
						extension: `-profile-avatar-original.${imageExtension}`,
				  },
		[type, company, team, storeUserId, user, imageExtension],
	)
	const { percent, fileId, run } = useS3Upload(
		section.id,
		section.s3Index,
		(user &&
			storeUserId &&
			(user.id !== storeUserId ? user.id : storeUserId)) ||
			(storeUserId && storeUserId) ||
			null,
	)
	const updateZoom = useCallback((e, zoom) => {
		setZoom(zoom)
	}, [])
	const onCropComplete = useCallback(
		async (croppedArea, croppedAreaPixels) => {
			const croppedImage: Blob | null = await getCroppedImg(
				image,
				croppedAreaPixels,
				imageExtension,
			)
			setUploadData(croppedImage)
		},
		[image, imageExtension],
	)
	const onDrop = useCallback(acceptedFiles => {
		// @ts-ignore
		const _URL = window.URL || window.webkitURL
		const img = new Image()
		const imageType = acceptedFiles[0].type.split('/')[1]
		setImageExtension(imageType)
		img.src = _URL.createObjectURL(acceptedFiles[0])
		img.onload = () => {
			// @ts-ignore
			setImageSize({ width: img.width, height: img.height })
			setImage(img.src)
		}
	}, [])
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: [
			'image/jpeg',
			'image/jpg',
			'image/png',
			'image/tiff',
			'image/bmp',
			'image/tga',
			'image/gif',
		],
	})
	const widthFallback = imageContainer.current
		? imageContainer.current.getBoundingClientRect().width
		: undefined
	const clear = useCallback(() => {
		setImage('')
		setImageSize({
			width: 0,
			height: 0,
		})
		setZoom(1)
	}, [])

	let mutationVariables = {}
	if (type === EEntity.person) {
		mutationVariables = {
			details: {
				userId: section.id,
				featuredVideo: null,
				avatarPath: fileId + section.extension,
				hasAvatar: true,
			},
		}
	} else if (type === EEntity.company) {
		mutationVariables = {
			language: i18n.language,
			details: {
				id: section.id,
				featuredVideo: null,
				logoPath: fileId + section.extension,
				hasLogo: true,
			},
		}
	} else if (type === EEntity.team) {
		mutationVariables = {
			details: {
				id: section.id,
				featuredVideo: null,
				logoPath: fileId + section.extension,
				hasLogo: true,
			},
		}
	}
	const uploadeImage = useCallback(() => {
		setImage('')
		setImageSize({
			width: 0,
			height: 0,
		})
		setZoom(1)
		handleClose()
		run(
			uploadData,
			section.extension,
			!onAdd
				? async () => {
						const { data }: any = await updateMutationHandle({
							variables: mutationVariables,
						})
						if (user && storeUserId && user.id === storeUserId)
							if (data && data.handleProfile)
								storeDispatch({
									type: EAppStateActionTypes.updateUser,
									user: data.handleProfile,
								})
						updatePercent(0)
				  }
				: () => {
						onAdd(fileId + section.extension)
						updatePercent(0)
				  },
			() => {},
		)
	}, [
		uploadData,
		handleClose,
		run,
		section.extension,
		storeDispatch,
		updateMutationHandle,
		updatePercent,
		storeUserId,
		user,
		mutationVariables,
		onAdd,
		fileId,
	])
	const [aspect, setAspect] = useState(4 / 3)

	useEffect(() => {
		updatePercent(percent)
	}, [updatePercent, percent])

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			classes={{ paper: classes.container }}
		>
			<div
				className={classes.cropContainer}
				ref={imageContainer}
				style={{
					height:
						Boolean(image) && imageSize.height && widthFallback
							? widthFallback /
									(imageSize.width / imageSize.height) >
							  widthFallback
								? widthFallback
								: widthFallback /
								  (imageSize.width / imageSize.height)
							: undefined,
				}}
			>
				{!image && (
					<div {...getRootProps({ className: 'dropzone' })}>
						<input {...getInputProps()} />
						<p>
							Drag 'n' drop some file here, or click to select a
							file
						</p>
					</div>
				)}
				{!!image && (
					<Cropper
						image={image || ''}
						cropSize={{
							width: 67,
							height: 67,
						}}
						crop={crop}
						zoom={zoom}
						aspect={aspect}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
						restrictPosition={false}
						onMediaLoaded={mediaSize => {
							setAspect(mediaSize.width / mediaSize.height)
						}}
						classes={{
							containerClassName: classes.cropContainer,
							mediaClassName: classes.cropImage,
							cropAreaClassName: '',
						}}
					/>
				)}
			</div>
			{!!image && (
				<>
					<div className={classes.controls}>
						<Slider
							value={zoom}
							min={0.1}
							max={3}
							step={0.1}
							aria-labelledby="Zoom"
							onChange={updateZoom}
							// classes={{ container: classes.slider }}
						/>
					</div>
					<Chip
						onClick={clear}
						icon={<ClearIcon style={{ marginRight: 0 }} />}
						variant="outlined"
						clickable={true}
						classes={{
							root: classes.clearButton,
							// label: classes.editIconLabel,
							// root: classes.editRootElement,
						}}
						label={t('Clear')}
					/>
					<Chip
						onClick={uploadeImage}
						icon={<UploadIcon style={{ marginRight: 0 }} />}
						variant="outlined"
						clickable={true}
						classes={{
							root: classes.saveButton,
						}}
						label={t('Save')}
					/>
				</>
			)}
		</Dialog>
	)
}

// export default memo(UploadCrop)
export default memo((props: any) => (
	<StoreValue keys={['user.id|storeUserId', 'storeDispatch']}>
		<UploadCrop {...props} />
	</StoreValue>
))
