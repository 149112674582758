// import { setCookie } from '.'
import { EAppStateActionTypes } from 'models'

export default (state: any, action: any): any => {
	switch (action.type) {
		case EAppStateActionTypes.updateUser:
		case EAppStateActionTypes.resetUser:
			return action.firebaseUser ? action.firebaseUser : state
		case EAppStateActionTypes.setFirebaseUser:
			return action.firebaseUser
		default:
			break
	}

	return state
}
