import gql from 'graphql-tag'

export const UserExperienceFragment = gql`
	fragment UserExperience on Experience {
		id
		position
		company
		startDate
		location {
			id
			type
			name
			region2
		}
		endDate
		isCurrent
		title
		description
	}
`
