import { useState, useEffect, useMemo } from 'react'

import { getArticleCommentsCollectionPath } from '../common'

export const useCommentsCount = (articleId: string | number, firebase: any, firebaseUser: any) => {
	const firebaseApp: any = useMemo(() => (firebase && firebase.instance) || null, [firebase])

	const [count, setCount] = useState(0)
	const firestore = (firebaseApp && firebaseApp.firestore()) || null
	useEffect(() => {
		if (!firebaseUser || !firestore || !articleId) return undefined
		const commentsUnsubscribe = firestore
			.collection(getArticleCommentsCollectionPath(articleId))
			.onSnapshot((snapshot: any) => {
				setCount(snapshot.size)
			})

		return commentsUnsubscribe
	}, [firebaseUser, firestore, articleId])

	if (!firebaseApp) return 0
	else return count
}
