import { IUser, EAppStateActionTypes } from 'models'

export default (state: IUser | null, action: any): IUser | null => {
	switch (action.type) {
		case EAppStateActionTypes.updateUser:
			// delete action.user.__typename
			// delete action.user.profile.__typename

			return action.user
		case EAppStateActionTypes.updateUserOwnedCompanies: {
			return {
				...state!,
				profile: {
					...state?.profile!,
					ownedCompanies: action.ownedCompanies,
				},
			}
		}
		case EAppStateActionTypes.setUserCompanyRoles: {
			let user: any = { ...state }
			if (user) {
				user.companyRoles = action.companyRoles
			}
			return user
		}
		case EAppStateActionTypes.setUserCvFile: {
			let user = state
			if (user) {
				const result = {
					...user,
					profile: {
						...user.profile, // TODO: this code is temporary.
						cvFilePath: action.userCv.cvFilePath,
						cvFileOriginalName: action.userCv.cvFileOriginalName,
					},
				}
				return result
			} else {
				return state
			}
		}
		case EAppStateActionTypes.resetUser:
			return null
		default:
			return state
	}
}
