import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		container: {
			paddingTop: theme.spacing(1),
			display: 'flex',
			zIndex: 1,
			position: 'relative',
			flexWrap: 'wrap',
			margin: `${-1 * theme.spacing(0.5)} 0`,
		},
		overlay: {
			background:
				'linear-gradient(to left, white, rgba(255, 255, 255, 0.001) 10%)',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 10,
			pointerEvents: 'none',
		},
		chipInactive: {
			fontWeight: 300,
			border: '1px solid rgba(26, 27, 124, 1)',
			background: 'rgba(26, 27, 124, 0.1)',
			color: 'rgba(26, 27, 124, 1)',
			fontSize: 14,
			lineHeight: '18px',
			// marginLeft: theme.spacing(0.5),
			margin: theme.spacing(0.5),
			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
		},
		chip: {
			// marginLeft: theme.spacing(0.5),
			margin: theme.spacing(0.5),
			fontWeight: 300,
			fontSize: 14,
			lineHeight: '18px',
			background: 'rgba(26, 27, 124, 0.1)',
			color: 'rgba(26, 27, 124, 1)',
			border: 'none',
			// border: `1px solid ${theme.palette.grey[300]}`,
			// color: theme.palette.grey[500],
			'&:hover': {
				borderColor: theme.palette.primary.main,
				color: theme.palette.primary.main,
				cursor: 'pointer',
			},
		},
		tooltip: {
			background: theme.palette.primary.main,
			fontSize: 12,
		},
		endorsements: {
			padding: theme.spacing(2),
			position: 'relative',
		},
		endorsementsHeadline: {
			position: 'relative',
			color: theme.palette.grey[800],
			fontWeight: 300,
			fontSize: 14,
			lineHeight: '18px',
			[theme.breakpoints.up('md')]: {
				display: 'inline-flex',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: 14,
			},
			textTransform: 'none',
		},
		endorsementAction: {
			color: theme.palette.primary.main,
			fontWeight: 500,
			fontSize: 14,
			lineHeight: '18px',
			cursor: 'pointer',
			[theme.breakpoints.up('md')]: {
				display: 'inline-flex',
				marginLeft: theme.spacing(1),
			},
		},
		commentsBadge: {
			display: 'inline-flex',
			flexDirection: 'row',
			position: 'absolute',
			maxWidth: theme.spacing(25),
			height: theme.spacing(6),
			right: theme.spacing(1),
			top: -1 * theme.spacing(3),
			background: theme.palette.common.white,
			border: `1px solid #ebebeb`,
			borderRadius: theme.spacing(3),
			padding: theme.spacing(1.5),
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			'&:hover': {
				borderColor: theme.palette.primary.main,
			},
			[theme.breakpoints.down('sm')]: {
				height: theme.spacing(5),
			},
		},
		commentsBadgeCount: {
			color: theme.palette.grey[800],
			fontWeight: 300,
			fontSize: 16,
			lineHeight: '20px',
			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
		},
		commentsBadgeArrow: {
			color: theme.palette.grey[800],
			fontWeight: 300,
			opacity: 0.6,
			marginRight: theme.spacing(0.5),
			paddingRight: theme.spacing(0.5),
			borderRight: `1px solid ${theme.palette.grey[800]}`,
		},
		commentsBadgeIcon: {
			color: theme.palette.primary.main,
			marginLeft: theme.spacing(0.5),
		},
		iconSize: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.2rem',
			},
		},
		popper: {
			top: '0px !important',
			left: '0px !important',
			position: 'absolute',
			transform: 'translate3d(93px, -58px, 0px)',
			willChange: 'transform !important',
			zIndex: 1,
			marginBottom: 15,
			minWidth: 150,
			'& > div': {
				borderRadius: 8,
				background: theme.palette.primary.main,
				color: '#ffffff',
			},
			'& $arrow': {
				bottom: 0,
				left: 0,
				right: 0,
				margin: '0 auto -1em auto',
				width: '3em',
				height: '1em',
				'&::before': {
					borderWidth: '1em 1em 0 1em',
					borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
				},
			},
		},
		arrow: {
			position: 'absolute',
			fontSize: 7,
			width: '3em',
			height: '3em',
			'&::before': {
				content: '""',
				margin: 'auto',
				display: 'block',
				width: 0,
				height: 0,
				borderStyle: 'solid',
			},
		},
		closeButton: {
			width: 24,
			height: 24,
			padding: 0,
			marginLeft: 'auto',
			position: 'absolute',
			top: -30,
			right: 20,
		},
		customScrollBar: {
			'& .rcs-inner-container': {
				paddingTop: 30,
			},
		},
		profilePicture: {
			width: '40px',
			height: '40px',
			borderRadius: '50%',
			backgroundColor: '#e8e8e8',
			marginRight: '5px',
		},
		details: {
			display: 'flex',
			flexFlow: 'column',
			justifyContent: 'center',
		},
		role: {
			color: '#bababa',
			fontSize: '12px',
			fontWeight: 'normal',
			//marginTop: '5px',
		},
		name: {
			position: 'relative',
			color: '#424242',
			fontSize: '15px',
			fontWeight: 500,
			marginBottom: '-5px',
		},
		avatarContent: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			cursor: 'pointer',
			'& div:first-child': {
				display: 'flex',
				flexDirection: 'row',
			},
		},
		cardItemWrap: {
			display: 'flex',
			flex: 1,
			flexFlow: 'column',
		},
		cardItem: {
			display: 'flex',
			flex: 1,
			alignItems: 'center',
		},
		// dialog: {
		// 	position: 'absolute',
		// 	display: 'flex',
		// 	width: '150px'
		// },
		dialogTitle: {
			//display: 'flex',
			// borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
			// borderBottomLength: '10px',
			paddingBottom: '0.57rem',
			color: '#414141',
			textAlign: 'center',
			fontSize: '1rem',
		},
		closeModalButton: {
			display: 'flex',
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: 'rgba(0, 0, 0, 0.5)',
		},
		root: {
			//position: 'absolute',
			minWidth: 100,
			width: '350px',
			boxShadow: 'none',
		},
		title: {
			fontSize: 14,
		},
		modal: {
			display: 'flex',
			position: 'absolute',
			width: '400px',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		dividerTitle: {
			marginLeft: '3rem',
			marginRight: '3rem',
			backgroundColor: 'whitesmoke',
			marginTop: '-8px',
			marginBottom: '5px',
			backgroundImage:
				'radial-gradient(#dedada35, #FFF), radial-gradient(#dedada35, #FFF)',
		},
		dividerTag: {
			height: '2px',
			marginTop: '10px',
			marginBottom: '10px',
			backgroundImage:
				'radial-gradient(#CCC, #FFF), radial-gradient(#CCC, #FFF)',
		},
		button: {
			position: 'absolute',
			top: '-5px',
			left: '150px',
		},
	})
