import { useCallback, useMemo } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'

import { getArticleCommentsCollectionPath } from '../common'
import { ICommentUser } from 'models'

export const useAddComment = (
	articleId: string | number,
	storeFirebase: any,
	firebaseUser: any,
) => {
	const firebaseApp: any = useMemo(
		() => (storeFirebase && storeFirebase.instance) || null,
		[storeFirebase],
	)

	const firestore = firebaseApp.firestore()
	const handleAddComment = useCallback(
		async (text: String, userData: ICommentUser) => {
			return await firestore
				.collection(getArticleCommentsCollectionPath(articleId))
				.add({
					text,
					userId: userData.userId,
					hasAvatar: userData.hasAvatar || false,
					firstName: userData.firstName,
					lastName: userData.lastName,
					worksAt: userData.worksAt,
					date: firebase.firestore.Timestamp.now(),
					faId: firebaseUser.user.uid,
				})
		},
		[firestore, articleId, firebaseUser.user.uid],
	)

	return handleAddComment
}
