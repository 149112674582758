import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		jobItem: {
			border: '1px solid #ebebeb',
			borderRadius: '7px',
			margin: '25px 0px',
			position: 'relative',
			'&:hover': {
				cursor: 'pointer',
			},
			backgroundColor: '#ffffff',
		},
		jobResourceContainer: {
			maxHeight: '260px',
			borderRadius: '8px 8px 0px 0px',
			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
			[theme.breakpoints.up('sm')]: {
				borderRadius: '8px 0px 0px 8px',
			},
		},
		video: {
			display: 'flex',
			'&:hover': {
				cursor: 'pointer',
			},
			position: 'relative',
			paddingTop:
				'62.25%' /* Player ratio: 100 / (1280 / 720) originally was '56.25%' but I added 62.25 to fill the entire card */,
			'& .player': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				'& iframe': {
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
				},
			},
		},

		teamItemInformation: {
			padding: '35px 10px 10px 10px',
			position: 'relative',
			maxHeight: '260px',
			[theme.breakpoints.down('sm')]: {
				paddingTop: '10px',
			},
		},
		jobItemTitle: {
			fontSize: '16px',
			color: '#424242',
			marginBottom: theme.spacing(1),
		},
		jobItemDescription: {
			fontSize: '15px',
			color: '#424242',
		},
		badgeExtra: {
			position: 'absolute',
			left: theme.spacing(2),
			top: -1 * theme.spacing(4),
			[theme.breakpoints.down('sm')]: {
				left: theme.spacing(1),
			},
			zIndex: 1, // to overlap video
		},
		locationIcon: {
			fill: '#7c7c7c',
			fontSize: '1.3rem',
			marginRight: 2,
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.1rem',
			},
		},
		location: {
			position: 'absolute',
			right: theme.spacing(2),
			top: '-14px',
			fontWeight: 300,
			color: '#989898',
			fontSize: 12,
			lineHeight: '18px',
			border: `1px solid #ebebeb`,
			borderRadius: 12,
			padding: `${theme.spacing(0.5)}px 10px`,
			backgroundColor: '#ffffff',
			display: 'flex',
			alignItems: 'center',
			'&>span': {
				verticalAlign: 'super',
				color: '#595959',
				fontSize: '13px',
			},
			[theme.breakpoints.down('md')]: {
				maxWidth: '110px',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				[theme.breakpoints.down('sm')]: {
					fontSize: theme.spacing(1.2),
					right: theme.spacing(1),
					zIndex: 1,
				},
			},
		},
		chip: {
			marginTop: 2,
			marginRight: 2,
			fontWeight: 500,
			backgroundColor: '#e1e1f4',
			color: '#1B1A7C',
		},
		jobDescriptionItem: {
			color: '#989898',
			fontFamily: 'Roboto',
			fontSize: '14px',
			fontWeight: 400,
			borderLeft: '2px solid #989898',
			height: '12px',
			lineHeight: '12px',
			padding: '0px 20px 0px 10px',
			// margin: ' 0px',
		},
		jobDescription: {
			marginTop: 10,
			position: 'relative',
			lineHeight: '15px',
			maxHeight: '74px',
			textAlign: 'justify',
			[theme.breakpoints.down('md')]: {
				maxHeight: '45px',
			},
		},
		editIconLabel: {
			paddingLeft: 6,
			paddingRight: 6,
		},
		editRootElement: {
			position: 'absolute',
			top: 0,
			right: -40,
			[theme.breakpoints.down('md')]: {
				top: 0,
				right: -40,
			},
			[theme.breakpoints.down('sm')]: {
				height: '25px',
				width: '25px',
				right: -2,
				top: 12,
				zIndex: 1,
			},
		},
		editIcon: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '1rem',
			},
		},
		deleteRootElement: {
			position: 'absolute',
			top: 40,
			right: -40,
			[theme.breakpoints.down('md')]: {
				top: 40,
				right: -40,
			},
			[theme.breakpoints.down('sm')]: {
				height: '25px',
				width: '25px',
				right: -2,
				zIndex: 1,
			},
		},
		deleteIcon: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '1rem',
			},
		},
		jobDetails: {
			marginTop: 10,
		},
		imgVideoContainer: {
			position: 'relative',
			height: '100%',
			overflow: 'visible',
		},
		seeApplicantsBtn: {
			position: 'absolute',
			bottom: '-10px',
			left: '50%',
			marginLeft: '-75px',
			[theme.breakpoints.down('md')]: {
				marginLeft: '-60px',
			},
			overflow: 'visible',
			height: 'auto',
		},
		seeMore: {
			fontStyle: 'italic',
			fontWeight: 'bold',
		},
	})
