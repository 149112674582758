import React, { useContext, memo, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styles from '../styles'
import { SearchInputContext } from '../component'
import Logo from 'assets/images/logo.png'
import { StoreValue } from 'store'
import { EAppStateActionTypes, TLeftMenuProps } from 'models'

const useStyles = makeStyles(styles)

const LeftMenu: React.FunctionComponent<TLeftMenuProps> = ({
	storeDispatch,
	storeSearchQuery,
}) => {
	const history = useHistory()
	const location = useLocation()
	const classes = useStyles()
	const { t, i18n } = useTranslation()
	const theme: Theme = useTheme()
	const [isFocused] = useContext(SearchInputContext)
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const shouldShowMenus = (!isFocused && isSmDown) || isMdUp

	const clearQueryHandle = useCallback(() => {
		storeDispatch({
			type: EAppStateActionTypes.updateQuery,
			query: '',
		})
	}, [storeDispatch])

	const goHome = useCallback(() => {
		if (storeSearchQuery !== '') clearQueryHandle()
		if (
			!matchPath(location.pathname, {
				path: '/:language(en|ro)',
				exact: true,
			})
		) {
			history.push(`/${i18n.language}`)
		}
		try {
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth',
			})
		} catch (error) {
			// for older browsers
			window.scrollTo(0, 0)
		}
	}, [
		i18n.language,
		history,
		clearQueryHandle,
		storeSearchQuery,
		location.pathname,
	])

	const goToCompanies = useCallback(() => {
		history.push(`/${i18n.language}/search/employers`, {
			seeEmployersAndTeamsSection: true, // this state is for preserving the 'See employers' and 'Team' on refreshing page
		})
		storeDispatch({
			type: EAppStateActionTypes.updateSearchSection,
			section: 'employers',
		})
	}, [i18n.language, history, storeDispatch])

	if (!shouldShowMenus) return null

	return (
		<div className={classes.logoContainer}>
			<div className={classes.logoParent}>
				<img
					src={Logo}
					className={classes.logo}
					onClick={goHome}
					alt=""
				/>
				<span className={classes.beta}>beta</span>
			</div>
			<Hidden smDown>
				<Typography
					variant="h6"
					color="textSecondary"
					className={classNames(classes.menuItem, {
						[classes.menuActive]: matchPath(location.pathname, {
							path: `/${i18n.language}`,
							exact: true,
						}),
					})}
					onClick={goHome}
				>
					<Hidden mdDown>{t('What’s new?')}</Hidden>
					<Hidden lgUp>{t('What’s new?')}</Hidden>
				</Typography>
				<Typography
					variant="h6"
					color="textSecondary"
					className={classNames(
						classes.menuItem,
						classes.employersButton,
						{
							[classes.menuActive]: matchPath(location.pathname, {
								path: `/${i18n.language}/search/employers`,
								exact: true,
							}),
						},
					)}
					onClick={goToCompanies}
				>
					<Hidden mdDown>{t('See Employers')}</Hidden>
					<Hidden lgUp>{t('Employers')}</Hidden>
				</Typography>
			</Hidden>
		</div>
	)
}

export default memo((props: TLeftMenuProps) => (
	<StoreValue keys={['storeDispatch', 'search.query|storeSearchQuery']}>
		<LeftMenu {...props} />
	</StoreValue>
))
