import React, { useEffect, useState } from 'react'
import { Location } from 'history'
import { Prompt } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'


interface IRouteLeavingGuard {
	navigate: (path: string) => void
	shouldBlockNavigation: (location: Location) => boolean
	when?: boolean
}

const RouteLeavingGuard = ({
	when,
	navigate,
	shouldBlockNavigation,
}: IRouteLeavingGuard) => {
	const [modalVisible, setModalVisible] = useState(false)
	const [lastLocation, setLastLocation] = useState<Location | null>(null)
	const [confirmedNavigation, setConfirmedNavigation] = useState(false)
	const { t } = useTranslation()
	const closeModal = () => {
		setModalVisible(false)
	}

	const handleBlockedNavigation = (nextLocation: Location): boolean => {
		if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
			setModalVisible(true)
			setLastLocation(nextLocation)
			return false
		}
		return true
	}

	const handleConfirmNavigationClick = () => {
		setModalVisible(false)
		setConfirmedNavigation(true)
	}

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			// Navigate to the previous blocked location with your navigate function
			navigate(lastLocation.pathname)
		}
	}, [confirmedNavigation, navigate, lastLocation])

	return (
		<>
			<Prompt when={when} message={handleBlockedNavigation} />
			{/* Your own alert/dialog/modal component */}
			<Dialog
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					<div>
						{t(
							'Are you sure you want to leave? You will lose your data if you continue!',
						)}
					</div>
				</DialogTitle>
				<DialogActions>
					<Button color="primary" onClick={handleConfirmNavigationClick}>
						{t('Yes')}
					</Button>
					<Button
						color="primary"
						autoFocus
						onClick={closeModal}
					>
						{t('No')}
					</Button>
				</DialogActions>
			</Dialog>

			{/* <WarningDialog
        open={modalVisible}
        titleText='Close without saving?'
        contentText='You have unsaved changes. Are you sure you want to leave this page without saving?'
        cancelButtonText='DISMISS'
        confirmButtonText='CONFIRM'
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      /> */}
		</>
	)
}
export default RouteLeavingGuard;
