import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		profileContainer: {
			alignItems: 'center',
			justifyContent: 'center'
		},
		profileMainContent: {
			maxWidth: '670px',
			width: '100%',
			marginTop: '30px',
			marginBottom: '30px',
			padding: '0px 10px'
		},
		profileSection: {
			marginTop: '30px'
		},
		sectionContent: {
			fontSize: '16px',
			color: '#929292'
		},
		storiesSection: {
			width: '100%'
		},
		sectionTitle: {
			fontSize: '32px',
			lineHeight: '1.2em',
			fontWeight: 300,
			marginTop: '0px',
			marginBottom: '10px'
		},
		emphasizedText: {
			fontWeight: 700,
			display: 'block'
		},
	})
