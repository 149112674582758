import React, { memo, useState, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'

import { JobCard } from 'components'

const JobsFeed: React.FunctionComponent<any> = ({ jobsData = [] }) => {
	// TODO: the next two functions needs to be replaced with functions that manipulate cache.
	const [deletedIds, setDeletedIds]: [string[], any] = useState([])
	const updateDeletedIds = useCallback(
		(id: string) => () => {
			setDeletedIds((deletedIds: string[]) => deletedIds.concat([id]))
		},
		[],
	)

	return (
		<>
			<Grid container={true} direction="column">
				{jobsData.length > 0 &&
					jobsData
						.filter(
							(item: any) => deletedIds.indexOf(item.id) === -1,
						)
						.map((item: any, index: number) => {
							return (
								<JobCard
									key={`job-card-${index}`}
									data={item}
									refetch={updateDeletedIds(item.id)}
								/>
							)
						})}
			</Grid>
		</>
	)
}

export default memo(JobsFeed)
