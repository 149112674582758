import React, { memo, useState, useCallback, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import EditIcon from '@material-ui/icons/EditOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { useMutation, useQuery } from '@apollo/react-hooks'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import LocationIcon from 'assets/images/location.svg'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useHistory, useParams } from 'react-router-dom'

import styles from '../styles'
import { ITeamInformations } from 'models'
import { UpdateTeamDetails, TeamDepartmentQuery } from 'store'
import FormGroup from '@material-ui/core/FormGroup'
import { teamSize } from 'utils'

const useStyles = makeStyles(styles)

const TeamInformations: React.FunctionComponent<ITeamInformations> = ({
	id,
	name,
	department,
	noOfMembers,
	femaleRatio = '',
	maleRatio = '',
	averageAge = '',
	disabilityRatio = '',
	canEdit,
	locations, // team locations
	companyLocations,
}) => {
	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	}
	const {
		teamSlug,
		companySlug,
	}: { teamSlug: string; companySlug: string } = useParams()

	const history = useHistory()
	const [teamLocations, setTeamLocations] = useState<any[]>([])
	const [teamLocationsIds, setTeamLocationsIds] = useState<string[]>([])
	const classes = useStyles()
	const [updateMutationHandle] = useMutation(UpdateTeamDetails)
	const { t, i18n } = useTranslation()
	const isMobile = useMediaQuery('@media (max-width:400px)')
	const [edit, setEdit] = useState(false)
	const [teamDetails, setTeamDetails] = useState({
		name,
		department: (department && department.id) || null,
		noOfMembers,
		disabilityRatio: disabilityRatio ? disabilityRatio : '',
		averageAge: averageAge ? averageAge : '',
		maleRatio: maleRatio ? maleRatio : '',
		femaleRatio: femaleRatio ? femaleRatio : '',
	})
	const [errorFields, setErrorFields] = useState({ teamName: false })
	const { data: teamDepartmentsData } = useQuery(TeamDepartmentQuery)

	const teamDepartments = useMemo(
		() => (teamDepartmentsData ? teamDepartmentsData.teamDepartments : []),
		[teamDepartmentsData],
	)
	const toggle = useCallback(() => {
		setEdit(state => !state)
	}, [])

	const handleChangeLocations = useCallback(
		(event: React.ChangeEvent<any>) => {
			let valueParsed = event.target.value.split(',')
			let selectedLocationId = valueParsed[0]
			let selectedLocationOffice = valueParsed[1]

			if (event.target.checked) {
				// add location
				let newTeamLocationsIds = teamLocationsIds.concat(
					selectedLocationId,
				)
				let newTeamLocations = teamLocations.concat([
					{ id: selectedLocationId, office: selectedLocationOffice },
				])
				setTeamLocationsIds(newTeamLocationsIds)
				setTeamLocations(newTeamLocations)
			} else {
				// remove location
				let teamsIds = teamLocationsIds
				let index = teamsIds.indexOf(selectedLocationId)
				if (index > -1) {
					teamsIds.splice(index, 1)
				}
				let filteredTeams = teamLocations.filter(
					el => el.id !== selectedLocationId,
				)

				setTeamLocations(filteredTeams)
				setTeamLocationsIds(teamsIds)
			}
		},
		[teamLocations, teamLocationsIds],
	)

	const handleTeamDetails = useCallback(
		(event: any) => {
			if (
				event.target.name === 'noOfMembers' ||
				event.target.name === 'department' ||
				event.target.name === 'name'
			) {
				setTeamDetails({
					...teamDetails,
					[event.target.name]: event.target.value,
				})
			} else {
				const value = !event.target.value
					? ''
					: parseInt(event.target.value)
				setTeamDetails({
					...teamDetails,
					[event.target.name]: value,
				})
			}
			if (event.target.name === 'name') {
				setErrorFields({ ...errorFields, teamName: false })
			}
		},
		[teamDetails, errorFields],
	)

	const save = useCallback(() => {
		updateMutationHandle({
			variables: {
				details: {
					id,
					...teamDetails,
					companySlug,
					noOfMembers:
						teamDetails.noOfMembers === ''
							? null
							: teamDetails.noOfMembers,
					disabilityRatio:
						teamDetails.disabilityRatio === ''
							? null
							: teamDetails.disabilityRatio,
					averageAge:
						teamDetails.averageAge === ''
							? null
							: teamDetails.averageAge,
					maleRatio:
						teamDetails.maleRatio === ''
							? null
							: teamDetails.maleRatio,
					femaleRatio:
						teamDetails.femaleRatio === ''
							? null
							: teamDetails.femaleRatio,
					locations: teamLocationsIds,
				},
			},
		})
			.then((data: any) => {
				if (teamSlug !== data.data.handleTeam.slug) {
					toggle()
					history.replace(
						`/${i18n.language}/employers/${companySlug}/teams/${data.data.handleTeam.slug}`,
					)
				} else {
					toggle()
				}
			})
			.catch(err => {
				if (err?.message.toLowerCase().includes('unique constraint')) {
					setErrorFields({ ...errorFields, teamName: true })
				}
			})
	}, [
		updateMutationHandle,
		toggle,
		teamDetails,
		id,
		teamLocationsIds,
		companySlug,
		teamSlug,
		history,
		i18n.language,
		errorFields,
	])

	useEffect(() => {
		let teamLocations =
			locations &&
			locations.map((item: any) => {
				let officeString = ''
				officeString = `${item.address ? item.address + ' | ' : ''}
				${item.zipCode ? item.zipCode + ' | ' : ''}
				${item.location.name}  |  ${item.location.region2}
				`

				return {
					id: item.id,
					office: officeString,
				}
			})
		setTeamLocations(teamLocations)
		let teamLocationsIds =
			locations &&
			locations.map((item: any) => {
				return item.id
			})
		setTeamLocationsIds(teamLocationsIds)
	}, [locations, edit])

	return (
		<>
			<Grid container={true} direction={isMobile ? 'column' : 'row'}>
				{department && (
					<span className={classes.teamMainInfo}>
						{department
							? department.key.charAt(0).toUpperCase() +
							  department.key.slice(1)
							: ''}
					</span>
				)}
				{
					<span className={classes.teamMainInfo}>
						{noOfMembers ? teamSize[noOfMembers] : '-'}{' '}
						<span>{t('employees')}</span>
					</span>
				}
				{
					<span className={classes.teamMainInfo}>
						{`${femaleRatio === null ? '-' : femaleRatio}%`}{' '}
						<span>
							{t('female')} |{' '}
							{`${maleRatio === null ? '-' : maleRatio}%`}{' '}
							{t('male')}
						</span>
					</span>
				}
				{
					<span className={classes.teamMainInfo}>
						<span>
							{t('average age')}{' '}
							{averageAge === null ? '-' : averageAge}{' '}
						</span>
					</span>
				}
				{
					<span className={classes.teamMainInfo}>
						{`${disabilityRatio === null ? '-' : disabilityRatio}%`}{' '}
						<span>{t('with disability')}</span>
					</span>
				}
			</Grid>
			<Grid container={true} direction={'row'}>
				{teamLocations && teamLocations.length > 0 && (
					<div className={classes.teamLocationInfo}>
						<img
							src={LocationIcon}
							className={classes.locationIcon}
							alt=""
						/>
						<span style={{ marginRight: '2px' }}>
							{t('Offices in')}{' '}
						</span>{' '}
						{teamLocations
							.slice(0, 1)
							.map((item: any, index: number) => {
								return (
									<span key={`location_${index}`}>
										{''}
										{item.office}
										<span>&nbsp;</span>
									</span>
								)
							})}
						{teamLocations.length > 1 && (
							<Tooltip
								disableFocusListener
								disableTouchListener
								classes={{
									tooltip: classes.tooltipCustomWidth,
								}}
								title={
									<div>
										{teamLocations
											.filter((_, index) => index > 0)
											.map((item: any, index: number) => {
												return (
													<div
														key={`extra-office-${index}`}
													>
														{index + 1 + '. '}
														{item.office}
													</div>
												)
											})}
									</div>
								}
							>
								<span className={classes.additionalLocations}>
									+{teamLocations.length - 1}
								</span>
							</Tooltip>
						)}
					</div>
				)}
			</Grid>

			{canEdit && (
				<Chip
					onClick={toggle}
					style={{ margin: 2, background: '#fff' }}
					icon={<EditIcon />}
					variant="outlined"
					color={'secondary'}
					clickable={true}
					classes={{
						label: classes.editIconLabel,
						root: classes.editRootElement,
					}}
				/>
			)}
			<Dialog
				open={edit}
				onClose={toggle}
				aria-labelledby="form-dialog-title"
				fullWidth
				maxWidth="md"
				scroll="body"
				disableBackdropClick
				disableEscapeKeyDown
			>
				<DialogContent style={{ overflow: 'hidden' }}>
					<Grid
						container
						className={classes.teamDetailsInformationContainer}
						spacing={3}
					>
						<Grid item xs={12} sm={6} md={6}>
							<FormControl
								className={classes.teamDetailsInformationSelect}
							>
								<TextField
									name="name"
									label={t('Name')}
									value={teamDetails.name}
									onChange={handleTeamDetails}
									type="text"
									className={classes.textInputRoot}
									InputLabelProps={{
										shrink: true,
									}}
									margin="none"
									error={!teamDetails.name}
								/>
							</FormControl>
							{errorFields.teamName && (
								<div
									style={{
										position: 'absolute',
										color: 'red',
									}}
								>
									{t('TheNameIsAlreadyTaken')}
								</div>
							)}
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<FormControl
								className={classes.teamDetailsInformationSelect}
							>
								<InputLabel id="team-department-select-label">
									{t('Department')}
								</InputLabel>
								<Select
									// labelId="demo-simple-select-label"
									id="team-department-select"
									value={
										teamDetails.department
											? teamDetails.department
											: ''
									}
									name="department"
									onChange={handleTeamDetails}
								>
									<MenuItem value="">
										<em>{t('None')}</em>
									</MenuItem>
									{teamDepartments.map(
										(
											item: { key: string; id: string },
											index: number,
										) => (
											<MenuItem
												key={`team_department_${index}`}
												value={item.id}
											>
												{item.key
													.charAt(0)
													.toUpperCase() +
													item.key
														.slice(1)
														.toLocaleLowerCase()}
											</MenuItem>
										),
									)}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={2} md={2}>
							<FormControl
								className={classes.teamDetailsInformationSelect}
							>
								<InputLabel id="no-of-members-label">
									{t('No of members')}
								</InputLabel>
								<Select
									// labelId="demo-simple-select-label"
									id="no-of-members-select"
									value={teamDetails.noOfMembers}
									name="noOfMembers"
									onChange={handleTeamDetails}
								>
									<MenuItem value="">
										<em>{t('None')}</em>
									</MenuItem>
									{Object.keys(teamSize).map(
										(key: string, index: number) => (
											<MenuItem
												key={`no_of_members_${index}`}
												value={key}
											>
												{teamSize[key]}
											</MenuItem>
										),
									)}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<TextField
								label={`${t('Female ratio')} (%)`}
								value={teamDetails.femaleRatio}
								name="femaleRatio"
								onChange={handleTeamDetails}
								type="number"
								inputProps={{ min: '0', max: '100', step: '1' }}
								className={classes.textInputRoot}
								InputLabelProps={{
									shrink: true,
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<TextField
								label={`${t('Male ratio')} (%)`}
								value={teamDetails.maleRatio}
								onChange={handleTeamDetails}
								name="maleRatio"
								inputProps={{ min: '0', max: '100', step: '1' }}
								type="number"
								className={classes.textInputRoot}
								InputLabelProps={{
									shrink: true,
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<TextField
								label={t('Average age')}
								value={teamDetails.averageAge}
								name="averageAge"
								inputProps={{ min: '0', max: '70', step: '1' }}
								onChange={handleTeamDetails}
								type="number"
								className={classes.textInputRoot}
								InputLabelProps={{
									shrink: true,
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<TextField
								label={`${t('Disability ratio')} (%)`}
								value={teamDetails.disabilityRatio}
								name="disabilityRatio"
								inputProps={{ min: '0', max: '100', step: '1' }}
								onChange={handleTeamDetails}
								type="number"
								className={classes.textInputRoot}
								InputLabelProps={{
									shrink: true,
								}}
								margin="normal"
							/>
						</Grid>
						{companyLocations && companyLocations.length > 0 && (
							<Grid item xs={12} sm={8} md={7}>
								<FormControl
									className={
										classes.teamDetailsMultipleSelect
									}
								>
									<InputLabel id="demo-mutiple-checkbox-label">
										{t('Locations')}
									</InputLabel>
									<Select
										labelId="demo-mutiple-checkbox-label"
										id="demo-mutiple-checkbox"
										multiple
										value={teamLocations}
										//onChange={handleChangeLocations}
										input={<Input />}
										renderValue={(selected: any) => {
											return selected.map((item: any) => (
												<p key={`selected_${item.id}`}>
													{item.office}
												</p>
											))
										}}
										MenuProps={MenuProps}
									>
										<FormGroup
											className={
												classes.formControlLocations
											}
										>
											{companyLocations.map(
												(item: any) => {
													let shownLocation: string =
														''
													shownLocation = `${
														item.address
															? item.address +
															  ' | '
															: ''
													}
													${item.zipCode ? item.zipCode + ' | ' : ''}

													${item.location.name} | ${item.location.region2}
													`

													return (
														<FormControlLabel
															key={`location_${item.id}`}
															//value={item}
															control={
																<Checkbox
																	onChange={
																		handleChangeLocations
																	}
																	checked={
																		teamLocationsIds.indexOf(
																			item.id,
																		) > -1
																	}
																	value={[
																		item.id,
																		shownLocation,
																	]}
																/>
															}
															label={
																shownLocation
															}
															// <ListItemText
															// 	primary={item.office}
															// />
														/>
													)
												},
											)}
										</FormGroup>
									</Select>
								</FormControl>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={toggle} color="primary">
						{t('Cancel')}
					</Button>
					<Button
						disabled={!teamDetails.name} // TODO: in global store create an error field for team form
						onClick={save}
						color="primary"
					>
						{t('Save')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default memo(TeamInformations)
