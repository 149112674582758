import React, {
	memo,
	useState,
	useCallback,
	useEffect,
	useRef,
	useMemo,
} from 'react'
import { useMutation } from '@apollo/react-hooks'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import classNames from 'classnames'
import Hidden from '@material-ui/core/Hidden'
import moment from 'moment'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import * as loadImage from 'blueimp-load-image'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fade from '@material-ui/core/Fade'
import Box from '@material-ui/core/Box'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import dompurify from 'dompurify'

import styles from './styles'
import Endorsements from '../endorsements'
import { Badge } from '../'
import { buildArticleImage, truncateHTML } from 'utils'
import { DeleteArticleMutation, StoreValue } from 'store'
import { TCardProps, EAppStateActionTypes, EEntity } from 'models'
import { EnumUserRoles } from 'utils'
import { Loading } from 'components'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

const useStyles = makeStyles(styles)
const sanitizer = dompurify.sanitize

const CardView: React.FunctionComponent<TCardProps> = memo(
	({
		article = {
			id: '',
			readDuration: 0,
			isPost: false,
			title: '',
			body: '',
			description: '',
			author: {
				id: '',
				uid: '',
				profile: {
					firstName: 'Temp',
					lastName: 'orary',
				},
			},
			createdAt: null,
			updatedAt: null,
			featuredImage: null,
			featuredVideo: '',
			tags: [],
			postAs: EEntity.person,
			postingCompany: undefined,
		},
		extraClassesContainer,
		refetch,
		storeUserId,
		storeUserIg,
		storeDispatch,
		storeUserCompanyRoles,
	}) => {
		let matchCompanyPostsUrl = useRouteMatch({
			path: '/:lang(ro|en)/employers/:companySlug/posts',
			strict: true,
			sensitive: true,
		})
		let matchUserProfilePostsUrl = useRouteMatch({
			path: '/:lang(ro|en)/people/:personID/posts',
			strict: true,
			sensitive: true,
		})

		let matchSearchAllUrl = useRouteMatch({
			path: '/:lang(ro|en)/search/all',
			strict: true,
			sensitive: true,
		})
		let matchSearchArticlesUrl = useRouteMatch({
			path: '/:lang(ro|en)/search/articles',
			strict: true,
			sensitive: true,
		})
		const {
			companySlug = null,
			personID = null,
		}: {
			companySlug?: null | string
			personID?: null | string
		} = useParams()
		let history = useHistory()
		const [isRemoved, setIsRemoved] = useState(false)
		const classes = useStyles()
		const { t, i18n } = useTranslation()
		const isMobile = useMediaQuery('@media (max-width:650px)')

		const clearQueryHandle = useCallback(() => {
			storeDispatch({
				type: EAppStateActionTypes.updateQuery,
				query: '',
			})
		}, [storeDispatch])

		const publishDate =
			(article &&
				article.updatedAt &&
				moment(article.updatedAt).format('DD.MM.YYYY')) ||
			moment().format('DD.MM.YYYY')

		const text = article.isPost
			? (article && article.body) || ''
			: article.description && article.description.trim().length
			? article.description
			: article.body

		const sanitizeText = sanitizer(text, {
			ALLOWED_TAGS: ['p', 'a', 'strong', 'b'],
		})
		let previewText = truncateHTML(`${sanitizeText}`, 300)
		const previewTextEllipsis = Array.from(text).length > 300 ? true : false
		if (article.isPost) {
			previewText = previewTextEllipsis
				? `${previewText}...<span id="see-more-${
						article.id
				  }" style="cursor:pointer; font-weight:bold">${t(
						'see more',
				  )}</span>`
				: previewText
		} else {
			previewText = `${previewText}...<span id="see-more-${
				article.id
			}" style="cursor:pointer; font-weight:bold">${t('see more')}</span>`
		}
		const hasCurrentUserPublishPermission = useMemo(() => {
			const checkCompanyArticlePermissions =
				article && storeUserCompanyRoles
					? storeUserCompanyRoles.filter((item: any) => {
							return (
								item.company.id ===
									(article.postingCompany &&
										article.postingCompany.id) &&
								item.roles.includes(EnumUserRoles.PUBLISHER)
							)
					  })
					: []
			return checkCompanyArticlePermissions.length > 0 ? true : false
		}, [article, storeUserCompanyRoles])

		let showEditIcon = false

		if (storeUserIg) {
			showEditIcon = true
		} else {
			if (
				(article.author && storeUserId === article.author.id) ||
				hasCurrentUserPublishPermission
			) {
				showEditIcon = true
			}
		}

		const goToEdit = useCallback(
			() =>
				history.push({
					pathname: `/${i18n.language}/edit`,
					state: {
						article,
					},
				}),
			[history, i18n.language, article],
		)

		const showRead = useCallback(
			(typeOfArticle: 'article' | 'post') => () => {
				let pathname: string = ''
				let goBackPath: string = ''
				if (matchCompanyPostsUrl) {
					if (typeOfArticle === 'article') {
						pathname = `/${i18n.language}/employers/${companySlug}/posts/${article.slug}`
					} else if (typeOfArticle === 'post') {
						pathname = `/${i18n.language}/employers/${companySlug}/posts/post-${article.id}`
					}
					goBackPath = `/${i18n.language}/employers/${companySlug}/posts`
				} else if (matchUserProfilePostsUrl) {
					if (typeOfArticle === 'article') {
						pathname = `/${i18n.language}/people/${personID}/posts/${article.slug}`
					} else if (typeOfArticle === 'post') {
						pathname = `/${i18n.language}/people/${personID}/posts/post-${article.id}`
					}
					goBackPath = `/${i18n.language}/people/${personID}/posts`
				} else if (matchSearchAllUrl) {
					if (typeOfArticle === 'article') {
						pathname = `/${i18n.language}/search/all/${article.slug}`
					} else if (typeOfArticle === 'post') {
						pathname = `/${i18n.language}/search/all/post-${article.id}`
					}
					goBackPath = `/${i18n.language}/search/all`
				} else if (matchSearchArticlesUrl) {
					if (typeOfArticle === 'article') {
						pathname = `/${i18n.language}/search/articles/${article.slug}`
					} else if (typeOfArticle === 'post') {
						pathname = `/${i18n.language}/search/articles/post-${article.id}`
					}
					goBackPath = `/${i18n.language}/search/articles`
				} else {
					if (typeOfArticle === 'article') {
						pathname = `/${i18n.language}/articles/${article.slug}`
					} else if (typeOfArticle === 'post') {
						pathname = `/${i18n.language}/articles/post-${article.id}`
					}
					goBackPath = `/${i18n.language}`
				}
				history.push({
					pathname,
					state: {
						article,
						keepScroll: true,
						goBackPath,
					},
				})
			},
			[
				matchSearchArticlesUrl,
				history,
				i18n,
				article,
				matchCompanyPostsUrl,
				companySlug,
				personID,
				matchUserProfilePostsUrl,
				matchSearchAllUrl,
			],
		)
		const articleAuthorName =
			article && article.postAs === 'Company'
				? (article.postingCompany && article.postingCompany.name) || ''
				: article && article.author
				? article.author.profile.firstName
				: ''

		const featuredImageRef = useRef<HTMLDivElement>(null)
		const [deleteArticleMutationHandle] = useMutation(DeleteArticleMutation)
		const [confirmDelete, setConfirmDelete] = useState(false)
		const [openDeleteModal, setOpenDeleteModal] = useState(false)
		const toggleConfirmDelete = useCallback(() => {
			setConfirmDelete(confirmDelete => !confirmDelete)
		}, [])
		const toggleDeleteModal = useCallback(() => {
			setOpenDeleteModal(openDeleteModal => !openDeleteModal)
		}, [])

		const goToEntity = useCallback(
			(entityType: EEntity) => () => {
				let entityParams: string | undefined
				let entityPath: string | undefined
				switch (entityType) {
					case 'Company':
						entityParams =
							article &&
							article.postingCompany &&
							article.postingCompany.slug
						entityPath = 'employers'
						break
					case 'User':
						entityParams =
							article && article.author && article.author.uid
						entityPath = 'people'
						break
					default:
						break
				}
				clearQueryHandle()
				history.push(`/${i18n.language}/${entityPath}/${entityParams}`)
			},
			[history, i18n.language, article, clearQueryHandle],
		)

		useEffect(() => {
			if (
				article &&
				article.featuredImage &&
				article.featuredImage.filename
			) {
				if (
					featuredImageRef &&
					featuredImageRef.current &&
					featuredImageRef.current.childElementCount === 0
				) {
					loadImage(
						buildArticleImage(
							article.id || '',
							article.featuredImage.filename,
						),
						(img: HTMLImageElement) => {
							if (featuredImageRef && featuredImageRef.current)
								featuredImageRef.current.append(img)
						},
						{
							meta: true,
						},
					)
				}
			}
			const elem: any = document.getElementById(`see-more-${article.id}`)
			if (elem) {
				elem.onclick = showRead(article.isPost ? 'post' : 'article')
			} else {
			}
		}, [article, showRead])

		useEffect(() => {
			if (confirmDelete) {
				const mutate = async () => {
					try {
						await deleteArticleMutationHandle({
							variables: {
								id: article.id,
							},
						})
						setIsRemoved(true)
						setTimeout(refetch, 300)
					} catch (error) {
						console.error(error)
					}
				}
				mutate()
				setConfirmDelete(false)
				setOpenDeleteModal(false)
			}
		}, [refetch, confirmDelete, deleteArticleMutationHandle, article.id])

		return !article ? (
			<Loading />
		) : (
			<>
				<Box className={classes.containerSeparator}></Box>
				<Fade in={!isRemoved} timeout={300}>
					<Paper
						className={classNames(
							classes.container,
							extraClassesContainer,
							classes.cardContainerWrap,
						)}
						elevation={0}
					>
						<Badge
							type={(article && article.postAs) || EEntity.person}
							company={article && article.postingCompany}
							user={article?.author}
							extraClasses={classes.badgeExtra}
							title={
								article?.postAs === EEntity.person
									? article?.author?.profile?.firstName +
									  ' ' +
									  article?.author?.profile?.lastName
									: article?.postAs === EEntity.company
									? article?.postingCompany?.name
									: ''
							}
							description={
								article.postAs === EEntity.person
									? article?.author?.profile?.worksAt
											?.position
									: article.postAs === EEntity.company
									? article?.postingCompany?.industry?.key
									: ''
							}
							onClick={goToEntity(
								(article && article.postAs) || EEntity.person,
							)}
						/>
						<div className={classes.date}>
							<span>{publishDate}</span>
							{article.postAs === EEntity.person &&
								article.worksAt && (
									<Hidden mdDown>
										<div
											className={classNames(
												classes.worksAt,
												classes.ellipsis,
											)}
										>
											<div
												style={{
													fontSize: 10,
													lineHeight: '12px',
												}}
											>
												{t('Works at')}
												{': '}
											</div>
											<span
												className={
													classes.worksAtAccent
												}
											>
												{`${article.worksAt.company}`}
											</span>
										</div>
									</Hidden>
								)}
						</div>
						{article.postAs === EEntity.person && article.worksAt && (
							<Hidden lgUp>
								<div
									className={classNames(
										classes.worksAt,
										classes.ellipsis,
									)}
								>
									{t('Works at')} :
									<span className={classes.worksAtAccent}>
										{`${article.worksAt.company}`}
									</span>
								</div>
							</Hidden>
						)}
						{showEditIcon && (
							<>
								<Chip
									onClick={goToEdit}
									style={{ margin: 2, background: '#fff' }}
									icon={<EditIcon />}
									variant="outlined"
									color={'secondary'}
									clickable={true}
									classes={{
										label: classes.editIconLabel,
										root: classes.editRootElement,
										icon: classes.editIcon,
									}}
								/>
								<Chip
									onClick={toggleDeleteModal}
									style={{ margin: 2, background: '#fff' }}
									icon={<DeleteIcon />}
									variant="outlined"
									color={'secondary'}
									clickable={true}
									classes={{
										label: classes.editIconLabel,
										root: classes.deleteRootElement,
										icon: classes.deleteIcon,
									}}
								/>
							</>
						)}
						{article.isPost && (
							<div className={classes.description}>
								<FroalaEditorView model={previewText} />
							</div>
						)}
						{!article.isPost && (
							<div
								className={classes.articleTeaser}
								onClick={showRead('article')}
							>
								<div className={classes.titleContainer}>
									<Typography
										variant="h6"
										className={classes.articleTitle}
									>
										{article.title}
									</Typography>
								</div>
								<q className={classes.teaser}>
									{
										<div
											className={classes.description}
											dangerouslySetInnerHTML={{
												__html: previewText,
											}}
										/>
									}
								</q>
								{typeof article.readDuration !== undefined && (
									<>
										<div className={classes.seeMoreArticle}>
											<Chip
												label={
													t('Long Article') +
													(article.readDuration
														? ` ~ ${article.readDuration} min`
														: ` < 1 min`)
												}
												className={
													classes.articleDuration
												}
											/>
										</div>
									</>
								)}
							</div>
						)}
						{article.featuredImage &&
							article.featuredImage.filename && (
								<div
									className={classes.image}
									onClick={showRead(
										article.isPost ? 'post' : 'article',
									)}
									ref={featuredImageRef}
								></div>
							)}
						{article.featuredVideo && (
							<div className={classes.video}>
								<ReactPlayer
									url={article.featuredVideo}
									className={classes.player}
									controls={true}
									width="100%"
									height="100%"
								/>
							</div>
						)}
						{article.isPost &&
							isMobile &&
							!article.featuredImage &&
							!article.featuredVideo &&
							article.tags.length > 0 && (
								<div
									style={{
										borderBottom: '1px solid #ebebeb',
									}}
								/>
							)}
						<Endorsements
							data={article.tags}
							articleId={article.id}
							articleVideo={!!article.featuredVideo}
							articleAuthorName={articleAuthorName}
							articleAuthorId={article.author?.id}
							postingCompany={article.postingCompany}
						/>
					</Paper>
				</Fade>
				<Dialog
					open={openDeleteModal}
					onClose={toggleDeleteModal}
					aria-labelledby="responsive-dialog-title"
				>
					<DialogTitle id="responsive-dialog-title">
						{article && article.isPost
							? t('Are you sure you want to delete this post?')
							: t(
									'Are you sure you want to delete this article?',
							  )}
					</DialogTitle>
					<DialogActions>
						<Button color="primary" onClick={toggleConfirmDelete}>
							{t('Yes')}
						</Button>
						<Button
							color="primary"
							autoFocus
							onClick={toggleDeleteModal}
						>
							{t('No')}
						</Button>
					</DialogActions>
				</Dialog>
			</>
		)
	},
)

const Card = (props: TCardProps) => (
	<StoreValue
		keys={[
			'user.id|storeUserId',
			'user._ig|storeUserIg',
			'storeDispatch',
			'user.companyRoles|storeUserCompanyRoles',
		]}
	>
		<CardView {...props} />
	</StoreValue>
)

export default memo(Card)
