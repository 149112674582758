import React, { memo } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'

import styles from './styles'
import { capitalize } from 'utils'
import { IBadgeProps, IBadgeContentProps } from 'models'
import { EntityLogo } from 'components'
import { StoreValue } from 'store'

const useStyles = makeStyles(styles)

const BadgeContent: React.FunctionComponent<IBadgeContentProps> = memo(
	({ row, children }) => (row ? <div>{children}</div> : <>{children}</>),
)

const Badge: React.FunctionComponent<IBadgeProps> = props => {
	const {
		// id,
		extraInfoClasses,
		extraAvatarClasses,
		extraClasses,
		currentPositionClasses,
		name,
		title,
		fullView,
		description,
		company,
		team,
		type,
		avatarOnly,
		onClick,
		user,
		canEdit,
		storeUserId,
		storeUserIg,
		showUploadCrop,
		// disabledEdit = false,
	} = props
	const row = false
	const classes = useStyles()

	return (
		<Paper
			className={classNames([
				classes.container,
				extraClasses,
				{ [classes.fullView]: fullView, [classes.fullWidth]: row },
			])}
			style={{
				paddingRight: avatarOnly ? 4 : undefined,
				paddingTop: avatarOnly ? 3 : undefined,
			}}
			elevation={1}
			onClick={onClick || undefined}
		>
			<Grid container justify="flex-start" wrap="nowrap">
				<EntityLogo
					alt={title || name + ' logo'}
					type={type}
					extraAvatarClasses={extraAvatarClasses}
					fullView={fullView}
					company={company}
					team={team}
					user={user}
					disabledEdit={
						!storeUserIg &&
						storeUserId !== company?.owner?.id &&
						!canEdit
					}
					showUploadCrop={showUploadCrop}
				/>
				{!avatarOnly && (
					<Grid
						container
						justify="space-evenly"
						direction="column"
						className={classNames([
							classes.info,
							{ [classes.row]: row },
							extraInfoClasses,
						])}
					>
						<BadgeContent row={row}>
							<div
								className={classNames(
									classes.name,
									classes.ellipsis,
								)}
							>
								{title}
							</div>
							{/* {type === EEntity.person && fullView && (
								<div
									className={classNames(
										classes.currentPosition,
										classes.ellipsis,
									)}
								>
									{title}
								</div>
							)} */}
						</BadgeContent>
						{!company && (
							<div
								className={classNames(
									classes.role,
									classes.ellipsis,
									{ [classes.autoWidth]: row },
									currentPositionClasses || '',
								)}
							>
								{!team && description}
								{fullView &&
									user &&
									user.profile &&
									user.profile.worksAt &&
									user.profile.worksAt.company && (
										<div className={classes.worsAtLabel}>
											Works at
										</div>
									)}
								{(fullView &&
									user &&
									user.profile &&
									user.profile.worksAt &&
									user.profile.worksAt.company && (
										<div
											className={
												classes.currentPositionItem
											}
										>
											{user.profile.worksAt.company}
										</div>
									)) ||
									undefined}
								{fullView && team && (
									<div
										className={classes.currentPositionItem}
									>
										{' '}
										- {team}
									</div>
								)}
							</div>
						)}
						{company && (
							<div
								className={classNames(
									classes.role,
									classes.ellipsis,
									{ [classes.autoWidth]: row },
									currentPositionClasses || '',
								)}
							>
								{description && capitalize(description)}
							</div>
						)}
						{team && (
							<div
								className={classNames(
									classes.role,
									classes.ellipsis,
									{ [classes.autoWidth]: row },
									currentPositionClasses || '',
								)}
							>
								{
									// TODO: check the next line. Should I use description or department.
								}
								{description && capitalize(description)}
							</div>
						)}
					</Grid>
				)}
			</Grid>
		</Paper>
	)
}

export default memo((props: IBadgeProps) => (
	<StoreValue keys={['user.id|storeUserId', 'user._ig|storeUserIg']}>
		<Badge {...props} />
	</StoreValue>
))
