import gql from 'graphql-tag'

import { pageInfoData } from './common'

export const standardCompanyResult = gql`
	fragment standardCompanyResult on Company {
		id
		owner {
			id
			uid
		}
		name
		slug
		dateFounded
		industry {
			id
			key
		}
		headline
		description
		productsAndServices
		noOfEmployees
		offices {
			id
			address
			location {
				id
				name
				region2
			}
			zipCode
			hq
		}
		website
		phones
		emails
		featuredVideo
		hasLogo
		logoPath
		hasCover
		aboutTeams
		coverPath
		hasJobs
		__typename
	}
`

export const companyOfficesResult = gql`
	fragment companyOfficesResult on CompanyOffices {
		id
		location {
			id
			region2
			name
		}
		address
		zipCode
		hq
	}
`

export const companyConnectionData = gql`
	fragment companyConnectionData on CompaniesConnection {
		edges {
			node {
				...standardCompanyResult
			}
			cursor
		}
		pageInfo {
			...pageInfoData
		}
	}
	${standardCompanyResult}
	${pageInfoData}
`
