import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		container: {
			borderRadius: 8,
			borderColor: '#ebebeb',
			background: 'transparent',
			border: `1px solid ${theme.palette.grey.A100}`,
			backgroundColor: '#ffffff',
			position: 'relative',
			paddingTop: theme.spacing(4),
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			[theme.breakpoints.down('sm')]: {
				// marginRight: theme.spacing(2),
			},

		},
		cardContainerWrap: {
			'-webkit-box-shadow': '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			'-moz-box-shadow': '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
			boxShadow: '0px 1px 3px 0px rgba(180, 180, 180, 0.1)',
		},
		containerSeparator: {
			width: '100%',
			height: '60px',
		},
		date: {
			position: 'absolute',
			right: theme.spacing(2),
			top: -10,
			fontWeight: 300,
			color: '#989898',
			fontSize: 12,
			lineHeight: '18px',
			[theme.breakpoints.down('sm')]: {
				right: theme.spacing(1),
			},
			'& > span': {
				border: `1px solid #ebebeb`,
				borderRadius: 12,
				lineHeight: '18px',
				padding: theme.spacing(0.5),
				backgroundColor: '#ffffff',
			},
			[theme.breakpoints.up('lg')]: {
				display: 'flex',
				height: theme.spacing(5),
				lineHeight: theme.spacing(5) + 'px',
				top: -1 * theme.spacing(2.5),
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				paddingRight: 0,
				maxWidth: theme.spacing(60),
			},
		},
		worksAt: {
			[theme.breakpoints.down('sm')]: {
				paddingTop: '5px',
				paddingLeft: '10px',
				paddingRight: '10px',
				backgroundColor: '#ffffff',
			},
			backgroundColor: '#ffffff',
			padding: theme.spacing(1),
			color: theme.palette.grey[800],
			fontWeight: 300,
			fontSize: 12,
			lineHeight: '16px',
			borderBottom: `1px solid #ebebeb`,

			[theme.breakpoints.up('lg')]: {
				border: `1px solid #ebebeb`,
				borderRadius: theme.spacing(2.5),
				marginLeft: theme.spacing(1),
			},
		},
		ellipsis: {
			flexWrap: 'nowrap',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			width: '100%',
		},
		worksAtAccent: {
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '10px',
				paddingRight: '10px',
			},
			color: theme.palette.primary.main,
			fontWeight: 500,
		},
		articleTeaser: {
			padding: theme.spacing(2),
			'&:hover': {
				cursor: 'pointer',
			},
		},
		description: {
			width: '100%',
			overflowWrap: 'break-word',
			padding: theme.spacing(1),
			color: theme.palette.grey[600],
			fontWeight: 300,
			fontSize: 18,
			lineHeight: '24px',
			'& .fr-view p': {
				display: 'inline',
			},
			marginBottom: '15px',
			[theme.breakpoints.down('sm')]: {
				paddingTop: 10,
				paddingLeft: 10,
				paddingRight: 10,
				paddingBottom: 10,
				fontSize: 14
			},
		},
		image: {
			background: theme.palette.grey.A100,
			width: '100%',
			display: 'flex',
			minHeight: 0,
			maxHeight: 820,
			height: 'auto',
			overflow: 'hidden',
			'& > img': {
				maxWidth: '100%',
				maxHeight: '100%',
				objectFit: 'contain',
				flexGrow: 1,
				width: '100%',
				height: '100%',
			},
			'&:hover': {
				cursor: 'pointer',
			},
		},
		badgeExtra: {
			position: 'absolute',
			left: theme.spacing(2),
			top: -1 * theme.spacing(4),
			[theme.breakpoints.down('sm')]: {
				left: theme.spacing(1),
			},
		},
		video: {
			display: 'flex',
			'&:hover': {
				cursor: 'pointer',
			},
			position: 'relative',
			paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
			overflow: 'hidden',
		},
		player: {
			//pointerEvents: 'none',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			'& iframe': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},
		},
		activePlayer: {
			pointerEvents: 'all',
		},
		titleContainer: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		articleTitle: {
			lineHeight: 1.3,
			flexGrow: 1,
			paddingBottom: theme.spacing(1),
		},
		articleDuration: {
			color: theme.palette.grey.A400,
			backgroundColor: '#fafafa',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		teaser: {
			marginTop: 0,
			color: theme.palette.grey[500],
			fontStyle: 'italic',
			fontWeight: 400,
			quotes: '"\\201C" "\\201D" "\\2018" "\\2019"',
			fontSize: 16,
			lineHeight: '22px',
			paddingBottom: theme.spacing(1),
			display: 'flex',
		},
		editIconLabel: {
			paddingLeft: 6,
			paddingRight: 6,
		},
		editRootElement: {
			position: 'absolute',
			top: -18,
			right: -25,
			[theme.breakpoints.down('md')]: {
				top: 15,
				right: 45,
			},
			[theme.breakpoints.down('sm')]: {
				height: '25px',
				width: '25px',
				marginRight: '-5px !important',
			}
		},
		editIcon: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '1rem'
			},
		},
		deleteRootElement: {
			position: 'absolute',
			top: 15,
			right: -25,
			[theme.breakpoints.down('md')]: {
				top: 15,
				right: 5,
			},
			[theme.breakpoints.down('sm')]: {
				height: '25px',
				width: '25px',
			}
		},
		deleteIcon: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '1rem'
			},
		},
		postShowMoreBtn: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			margin: '15px 0px',
			[theme.breakpoints.down('md')]: {
				margin: 'unset',
				marginBottom: '30px',
				height: '25px',
				'& button': {
					fontSize: '11px',
				},
			},
		},
		articleShowMoreBtn: {
			display: 'flex',
			flexDirection: 'row',
			marginLeft: '10px',
			[theme.breakpoints.down('md')]: {
				height: '25px',
				'& button': {
					fontSize: '11px',
				},
			},
		},
		seeMoreArticle: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'start',
		},
	})
