import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import { Badge } from 'components'
import styles from '../styles'
import company from '../mockdata'
import { EEntity } from 'models'

const useStyles = makeStyles(styles)

const CompanyTeams: React.FunctionComponent<{}> = () => {
	const classes = useStyles()
	const { t } = useTranslation()

	return (
		<Grid
			className={classes.teamMainContent}
			container={true}
			direction="column"
		>
			<Grid item={true}>
				<h2 className={classes.sectionTitle}>
					{t('Meet our')}{' '}
					<span className={classes.emphasizedText}>{t('Teams')}</span>
				</h2>
			</Grid>
			<Grid item={true} className={classes.sectionContent}>
				{company.teamsDescription}
			</Grid>
			<Grid container={true} className={classes.teamBadgesContainer}>
				{company.teams.map((item: any, index: number) => (
					<Badge
						key={`badge-${index}`}
						type={EEntity.team}
						title={item.title}
						description={item.description}
						extraClasses={classes.teamBadge}
					/>
				))}
			</Grid>
		</Grid>
	)
}

export default memo(CompanyTeams)
