import gql from 'graphql-tag'

// Queries

export const FindTagsQuery = gql`
	query tags($keyword: String!) {
		tags(keyword: $keyword) {
			id
			title
		}
	}
`

// Mutations

export const AppreciateTagMutation = gql`
	mutation appreciate(
		$language: LanguageCodeType!
		$tagId: String!
		$articleId: String!
	) {
		appreciate(language: $language, tagId: $tagId, articleId: $articleId) {
			status
			error
		}
	}
`
