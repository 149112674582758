import { createStyles, Theme } from '@material-ui/core/styles'

export default (theme: Theme) =>
	createStyles({
		teamContainer: {
			alignItems: 'center',
			justifyContent: 'center',
		},
		teamMainContent: {
			maxWidth: '670px',
			width: '100%',
			marginBottom: '30px',
			padding: '30px 10px',
			position: 'relative',
		},
		companySection: {
			marginTop: '30px',
		},
		tabLabelContainer: {
			color: '#424242',
			fontSize: '16px',
			fontWeight: 500,
			cursor: 'pointer',
			marginRight: '28px',
			paddingBottom: '10px',
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
			},
		},
		selectedTabLabelContainer: {
			color: '#1b1a7c',
			borderBottom: '3px solid',
			cursor: 'initial',
		},
		sectionTitle: {
			fontSize: '32px',
			lineHeight: '1.2em',
			fontWeight: 300,
			marginTop: '0px',
			marginBottom: '10px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '22px',
				[theme.breakpoints.only('xs')]: {
					fontSize: '20x',
				},
			},
		},
		emphasizedText: {
			fontWeight: 700,
			display: 'block',
		},
		sectionContent: {
			fontSize: '16px',
			color: '#929292',
		},
		teamLifeSection: {
			width: '100%',
		},
		teamBadgesContainer: {
			margin: '20px 0px',
		},
		teamBadge: {
			margin: '5px 5px 5px 0px',
		},
		companyDetailsIcon: {
			height: '20px',
			marginRight: '10px',
		},
		companyMainInformationItem: {
			color: '#989898',
			fontSize: '14px',
			fontWeight: 400,
			borderLeft: '2px solid #989898',
			height: '12px',
			lineHeight: '12px',
			padding: '0px 20px 0px 10px',
			margin: '5px 0px',
		},
		companyDetailsInformation: {
			marginTop: '20px',
			marginBottom: '10px',
		},
		companyDetailsInformationItemContainer: {
			display: 'flex',
			margin: '5px 20px 0px 0px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		companyDetailsInformationLink: {
			display: 'flex',
			textDecoration: 'none',
		},
		companyDetailsInformationItem: {
			color: '#989898',
			fontSize: '14px',
			fontWeight: 700,
		},
		additionalLocations: {
			color: '#1b1a7c',
			fontWeight: 700,
			marginLeft: '5px',
			cursor: 'pointer',
		},
		qaIcon: {
			width: '18px',
			height: '18px',
			minWidth: '18px',
			minHeight: '18px',
			borderRadius: '50%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: '18px',
			fontWeight: 700,
			cursor: 'pointer',
			marginTop: '3px',
			marginRight: '10px',
			lineHeight: '18px',
		},
		expandIcon: {
			backgroundColor: '#e4e4f2',
			color: '#1b1a7c',
		},
		collapseIcon: {
			backgroundColor: '#1b1a7c',
			color: '#FFF',
		},
		teamLifeItem: {
			border: '1px solid #ebebeb',
			borderRadius: '7px',
			margin: '15px 0px',
			overflow: 'hidden',
		},
		teamLifeResourceContainer: {
			maxHeight: '260px',
			overflow: 'hidden',
			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
		},
		teamLifeItemInformation: {
			padding: '10px',
			maxHeight: '260px',
		},
		teamLifeItemTitle: {
			fontSize: '16px',
			color: '#424242',
			marginBottom: '0px',
		},
		teamLifeItemDescription: {
			fontSize: '12px',
		},
		editIconLabel: {
			paddingLeft: 6,
			paddingRight: 6,
		},
		editRootElement: {
			position: 'absolute',
			top: -15,
			right: 10,
			[theme.breakpoints.down('md')]: {
				top: 15,
				right: 5,
				[theme.breakpoints.only('xs')]: {
					width: 30,
					height: 30,
					'& svg': {
						fontSize: '1.2rem'
					}
				}
			},
		},
		textInputRoot: {
			width: '100%',
		},
		industryContainer: {
			position: 'relative',
			alignSelf: 'stretch',
			marginBottom: -8,
		},
		suggestionsContainerOpen: {
			position: 'absolute',
			zIndex: 1,
			marginTop: theme.spacing(1),
			left: 0,
			right: 0,
		},
		suggestion: {
			display: 'block',
		},
		suggestionsList: {
			margin: 0,
			padding: 0,
			listStyleType: 'none',
		},
		teamMainInfo: {
			color: '#989898',
			fontSize: '14px',
			fontWeight: 400,
			borderLeft: '2px solid #989898',
			height: '12px',
			lineHeight: '12px',
			padding: '0px 20px 0px 10px',
			margin: '5px 0px',
		},
		teamLocationInfo: {
			color: '#989898',
			fontSize: '14px',
			fontWeight: 400,
			padding: '0',
			marginTop: '20px',
			marginLeft: '-5px',
			display: 'flex',
			flexFlow: 'row',
			alignItems: 'center',
		},
		locationIcon: {
			height: '20px',
			marginRight: '5px',
		},
		cardItemWrap: {
			display: 'flex',
			flex: 1,
			flexFlow: 'row',
		},
		cardItem: {
			padding: '0.5rem',
			display: 'flex',
			flex: 1,
			alignItems: 'center',
			'& .removeCardIcon': {
				display: 'none',
			},
			'&:hover': {
				'& .removeCardIcon': {
					display: 'block',
					cursor: 'pointer',
					borderRadius: '50%',
					top: '0px',
					right: '30px',
					padding: '0px 6px',
					fontWeight: 'bold',
					fontSize: '16px',
					backgroundColor: '#c5bbbb6b',
					color: theme.palette.secondary.main,
				},
			},
		},
		avatarContent: {
			flex: 1,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			cursor: 'pointer',
			'& div:first-child': {
				display: 'flex',
				flexDirection: 'row',
			},
		},
		profilePicture: {
			width: '50px',
			height: '50px',
			borderRadius: '50%',
			backgroundColor: '#e8e8e8',
			marginRight: '5px',
		},
		details: {
			display: 'flex',
			flexFlow: 'column',
			justifyContent: 'center',
		},
		name: {
			position: 'relative',
			color: '#424242',
			fontSize: '15px',
			fontWeight: 500,
		},
		role: {
			color: '#424242',
			fontSize: '12px',
			fontWeight: 'normal',
		},
		actionTitle: {
			marginLeft: 'auto',
			color: '#1b1a7c',
			cursor: 'pointer',
			fontWeight: 700,
		},
		teamMembersSection: {
			marginTop: '30px',
		},
		meetMemberSection: {
			width: '100%',
			marginTop: '15px',
		},
		sectionTitleWrap: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		teamDetailsInformationSelect: {
			marginTop: theme.spacing(2),
			minWidth: 140,
			width: '100%',
		},
		teamDetailsInformationSelectEmpty: {
			marginTop: theme.spacing(2),
		},
		teamDetailsInformationContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'flex-start',
			},
		},
		teamDetailsMultipleSelect: {
			width: '100%',
		},
		formControlLocations: {
			paddingLeft: theme.spacing(2),
		},
		tooltipCustomWidth: {
			maxWidth: 500,
			fontSize: 14,
		},
	})
