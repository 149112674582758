import React, { memo, useState, useEffect, useCallback } from 'react'
import ReactPlayer from 'react-player'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import DOMPurify from 'dompurify'
import classNames from 'classnames'
import textVersion from 'textversionjs'

import styles from './styles'
import commonStyles from './../../styles/common'
import {
	buildArticleImage,
	combineStyles,
	replaceUnicodeToHTMLEntities,
} from 'utils'
import { TCompanyDetailsArticle } from 'models'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DeleteCompanyStory } from 'store'
import { useMutation } from '@apollo/react-hooks'

export enum LinkType {
	image = 'image',
	video = 'video',
}

const CompanyDetailsArticle: React.FunctionComponent<TCompanyDetailsArticle> = props => {
	const {
		index,
		direction,
		article,
		body,
		refetch,
		companySlug,
		companyId,
		canEdit,
	} = props
	const cleanBodyRegex = new RegExp('&amp;')
	const cleanBody: string = textVersion(
		DOMPurify.sanitize(body),
	).replace(cleanBodyRegex, matched => replaceUnicodeToHTMLEntities(matched))

	const [isRemoved /*setIsRemoved*/] = useState(false)
	const combinedStyles: any = combineStyles(commonStyles, styles)
	const useStyles = makeStyles(combinedStyles)
	const classes = useStyles()
	const isMobile = useMediaQuery('@media (max-width:650px)')
	const [
		profileArticleResourceContainerHeight,
		setprofileArticleResourceContainerHeight,
	] = useState(208)
	const [deleteCompanyStoryHandle] = useMutation(DeleteCompanyStory)
	const history = useHistory()
	const { t, i18n } = useTranslation()
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)

	const showRead = useCallback(() => {
		let pathname: string = ''
		let slug = article.isPost ? `post-${article.id}` : article.slug
		pathname = `/${i18n.language}/employers/${companySlug}/story/${slug}`
		history.push({
			pathname,
			state: {
				article,
				keepScroll: true,
				goBackPath: `/${i18n.language}/employers/${companySlug}`,
			},
		})
	}, [history, i18n, article, companySlug])

	const goToEdit = useCallback(
		() =>
			history.push({
				pathname: `/${i18n.language}/employers/${companySlug}/create-story`,
				state: {
					article,
					companyId,
				},
			}),
		[history, i18n.language, article, companyId, companySlug],
	)
	const toggleDeleteModal = useCallback(() => {
		setOpenDeleteModal(openDeleteModal => !openDeleteModal)
	}, [])

	const toggleConfirmDelete = useCallback(() => {
		setConfirmDelete(confirmDelete => !confirmDelete)
	}, [])

	useEffect(() => {
		if (confirmDelete) {
			const mutate = async () => {
				try {
					await deleteCompanyStoryHandle({
						variables: {
							storyId: article.id,
						},
					})
					//setIsRemoved(true)
					setTimeout(refetch, 300)
				} catch (error) {
					console.error(error)
				}
			}
			mutate()
			setConfirmDelete(false)
			setOpenDeleteModal(false)
		}
	}, [refetch, confirmDelete, deleteCompanyStoryHandle, article.id])

	useEffect(() => {
		const handleWindowResize = () =>
			setprofileArticleResourceContainerHeight(
				(260 * window.innerWidth) / 650,
			)
		window.addEventListener('resize', handleWindowResize)

		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	})

	return (
		<>
			<Fade in={!isRemoved} timeout={300}>
				<Grid
					style={{ position: 'relative', overflow: 'visible' }}
					key={`team-life-item-${index}`}
					container={true}
					className={classNames(
						classes.profileArticleItem,
						classes.defaultShadow,
					)}
					direction={direction}
				>
					<Grid
						onClick={showRead}
						item={true}
						xs={isMobile ? 12 : 8}
						md={8}
						className={
							direction === 'row'
								? classes.profileArticleResourceContainer
								: classes.profileArticleResourceContainerReverse
						}
						style={{
							minHeight:
								isMobile && article.featuredVideo
									? `${profileArticleResourceContainerHeight}px`
									: 'undefined',
						}}
					>
						{article.featuredImage && (
							<img
								src={buildArticleImage(
									article.id,
									article.featuredImage.filename,
								)}
								alt={article.title}
							/>
						)}

						{article.featuredVideo && (
							<div className={classes.video}>
								<ReactPlayer
									url={article.featuredVideo}
									className={'player'}
									controls={true}
									width="100%"
									height="100%"
								/>
							</div>
						)}
					</Grid>
					<Grid
						onClick={showRead}
						item={true}
						xs={isMobile ? 12 : 4}
						md={4}
						className={
							direction === 'row'
								? classes.profileArticleItemInformation
								: classes.profileArticleItemInformationReverse
						}
						style={
							article.title
								? { marginTop: 0 }
								: !article.featuredVideo &&
									!article.featuredImage &&
									isMobile
									? { borderRadius: 5 }
									: undefined
						}
					>
						<Grid container={true} direction="column">
							<h3
								className={classes.profileArticleItemTitle}
								style={
									article.title ? { marginTop: 0 } : undefined
								}
							>
								{article.title}
							</h3>
							<div
								className={
									classes.profileArticleItemDescription
								}
								style={
									article.title ? { marginTop: 4 } : undefined
								}
							>
								{/* the article will always have a title in this scenario */}
								{article.title!.length + cleanBody.length >
									180 ? (
										<div>
											{`${cleanBody.substr(
												0,
												180 - article.title!.length,
											)}`}
										&nbsp;
											<span className={classes.seeMore}>
												{`...${t('See more')}`}
											</span>
										</div>
									) : (
										cleanBody
									)}
							</div>
						</Grid>
					</Grid>
					{canEdit && (
						<>
							<Chip
								onClick={goToEdit}
								style={{ margin: 2, background: '#fff' }}
								icon={<EditIcon />}
								variant="outlined"
								color={'secondary'}
								clickable={true}
								classes={{
									label: classes.editIconLabel,
									root: classes.editRootElement,
								}}
							/>
							<Chip
								onClick={toggleDeleteModal}
								style={{ margin: 2, background: '#fff' }}
								icon={<DeleteIcon />}
								variant="outlined"
								color={'secondary'}
								clickable={true}
								classes={{
									label: classes.editIconLabel,
									root: classes.deleteRootElement,
								}}
							/>
						</>
					)}
				</Grid>
			</Fade>

			<Dialog
				open={openDeleteModal}
				onClose={toggleDeleteModal}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					{article && article.isPost
						? t('Are you sure you want to delete this post?')
						: t('Are you sure you want to delete this article?')}
				</DialogTitle>
				<DialogActions>
					<Button color="primary" onClick={toggleConfirmDelete}>
						{t('Yes')}
					</Button>
					<Button
						color="primary"
						autoFocus
						onClick={toggleDeleteModal}
					>
						{t('No')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

const ProfileArticles: React.FunctionComponent<any> = props => {
	const { articles, refetch, companySlug, companyId, ...rest } = props
	return (
		<Grid container={true} direction="column">
			{articles &&
				articles.map((item: any, index: number) => {
					const direction: any =
						index % 2 === 0 ? 'row' : 'row-reverse'

					return (
						<CompanyDetailsArticle
							{...rest}
							key={'profile_details_article_' + index}
							refetch={refetch}
							index={index}
							direction={direction}
							article={item}
							body={item.body}
							companySlug={companySlug}
							companyId={companyId}
						/>
					)
				})}
		</Grid>
	)
}

export default memo(ProfileArticles)
