import gql from 'graphql-tag'
import { standardTeamResult, teamConnectionData } from 'store/fragments'

// QUERIES
export const TeamQuery = gql`
	query team($companySlug: String!, $id: String, $slug: String) {
		team(companySlug: $companySlug, id: $id, slug: $slug) {
			...standardTeamResult
		}
	}
	${standardTeamResult}
`
export const TeamDepartmentQuery = gql`
	query teamDepartments {
		teamDepartments {
			id
			key
		}
	}
`
export const TeamsQuery = gql`
	query teams($name: String, $companyId: String, $companySlug: String) {
		teams(name: $name, companyId: $companyId, companySlug: $companySlug) {
			...standardTeamResult
		}
	}
	${standardTeamResult}
`

export const TeamsDepartmentsGroupedQuery = gql`
	query filteredTeamsDepartments($name: String) {
		filteredTeamsDepartments(name: $name) {
			roTitle
			enTitle
			id
			count
		}
	}
`

export const FeedTeamsQuery = gql`
	query feedTeams($data: feedTeamsInput) {
		feedTeams(data: $data) {
			...teamConnectionData
		}
	}
	${teamConnectionData}
`

// MUATTIONS
export const UpdateTeamDetails = gql`
	mutation handleTeam($details: HandleTeamInput!) {
		handleTeam(teamDetails: $details) {
			...standardTeamResult
		}
	}
	${standardTeamResult}
`

export const AddTeam = gql`
	mutation addTeam($details: AddTeamInput!) {
		addTeam(teamDetails: $details) {
			...standardTeamResult
		}
	}
	${standardTeamResult}
`
export const AddTeamMembers = gql`
	mutation addTeamMembers($data: AddTeamMembersInput!) {
		addTeamMembers(data: $data) {
			...standardTeamResult
		}
	}
	${standardTeamResult}
`
export const DeleteTeamMember = gql`
	mutation deleteTeamMember($data: DeleteTeamMemberInput!) {
		deleteTeamMember(data: $data) {
			...standardTeamResult
		}
	}
	${standardTeamResult}
`
export const DeleteTeam = gql`
	mutation deleteTeam($data: DeleteTeamInput!) {
		deleteTeam(data: $data) {
			error
			status
		}
	}
`
