import gql from 'graphql-tag'

import { UserProfileFragment } from './profile'

export const AuthenticationUserFragment = gql`
	fragment AuthenticationUser on User {
		id
		uid
		profile {
			...UserProfile
		}
		companyRoles {
			id
			company {
				id
				id
				slug
				name
				hasLogo
				logoPath
				coverPath
				hasCover
				description
			}
			role
			roles
		}
		currentPasswordCheck
		_ig
		_ft
	}
	${UserProfileFragment}
`
